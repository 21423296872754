/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useRedirect(pageType, redirectionType, condition) {
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.currentUserReducer);

  const redirectionCondition = condition === undefined ? false : condition;

  useEffect(() => {
    if (!(pageType === "learningcenter" || pageType === "teachingcenter")) return;
    if (!Object.keys(currentUser.accountInfo).length) return navigate(`/${pageType}`, { replace: true }); // currentUser 정보 없을 시 로그인 페이지로 이동
    if (!redirectionType) return;
    if ((pageType === "teachingcenter" && currentUser.storeInfo.allow && !currentUser.storeInfo.teacher) || redirectionCondition)
      return navigate("/", { replace: true });
    if ((redirectionType === "notallow" && currentUser.storeInfo.allow) || redirectionCondition)
      return navigate(`/${pageType}/home`, { replace: true });
    if ((redirectionType === "home" && !currentUser.storeInfo.allow) || redirectionCondition)
      return navigate(`/${pageType}/notallow`, { replace: true });
  }, []);
}
