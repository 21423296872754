/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selOptionKey } from "../../../redux/selOption/actions";
import { RequiredText } from "../../../Methods";
import { getFireStoreData } from "../../../redux/FirebaseData/DownloadController/reducer";

// select option의 스케쥴을 변경하기 위한 Select 컴포넌트
const SelectSchedule = (props) => {
  // props
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 스케쥴 라벨 리스트 reducer
  const scheduleList = useSelector((state) => state.scheduleListReducer);
  // download controler reducer
  const downloadList = useSelector(
    (state) => state.downloadControlerReducer
  ).scheduleList;

  // 과목에 해당하는 스케쥴 라벨 리스트
  const subjectScheduleList = scheduleList.filter(
    (res) => res.subject === selOption.subject
  );

  // 기본값
  const defaultValue = selOption.scid
    ? {
        id: selOption.scid,
        name: selOption.scname,
      }
    : null;

  // require message 출력 조건, props로 required가 넘어오고, selOption의 scid가 null일 경우
  const returnRequire = required && !selOption.scid;

  // onChange 함수
  // displaySchedule의 scid, scname 변경
  const changeSchedule = (e) => {
    const returnValue = e || { scid: null, scname: null };
    dispatch(selOptionKey("scid", returnValue.id));
    dispatch(selOptionKey("scname", returnValue.name));
    !e && dispatch(selOptionKey("isSet", false));
    dispatch(selOptionKey("set", e ? e.minset : ""));
    dispatch(selOptionKey("scInfo", e || { minset: 1, maxset: 1 }));
  };

  useEffect(() => {
    // 스케쥴 리스트 데이터 다운로드 토글 true로
    !downloadList && dispatch(getFireStoreData("scheduleList"));
  }, []);

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isSearchable
        isClearable
        isDisabled={disabled}
        options={subjectScheduleList}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        defaultValue={defaultValue}
        onChange={changeSchedule}
        placeholder="스케쥴을 선택하세요"
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectSchedule;
