const HEADER_SET = "HEADER_SET";
const HEADER_CLEAR = "HEADER_CLEAR";

export const setHeader = (use, headerType, title) => ({
  type: HEADER_SET,
  use,
  headerType,
  title,
});

export const clearHeader = () => ({
  type: HEADER_CLEAR,
});

const header = { use: false, headerType: "", title: "" };

const headerReducer = (state = header, action) => {
  switch (action.type) {
    case HEADER_SET:
      return {
        use: action.use,
        headerType: action.headerType,
        title: action.title,
      };
    case HEADER_CLEAR:
      return header;
    default:
      return state;
  }
};

export default headerReducer;
