import * as wordOpt from "./types";

// WordPage
// WordPage에 맞게 초기화
export const wordOptionClear = () => ({
  type: wordOpt.WORDPAGE_CLEAR,
});
// PlayTime
export const wordOptionPlayTime = (payload) => ({
  type: wordOpt.WORDPAGE_PLAYTIME,
  payload,
});
// class 변경 시 cid, scname, part 동시 변경
export const wordOptionDate = (payload) => ({
  type: wordOpt.WORDPAGE_DATE,
  payload,
});
// class 변경 시 cid, scname, part 동시 변경
export const wordOptionClass = (payload) => ({
  type: wordOpt.WORDPAGE_CLASS,
  payload,
});
// 행 추가
export const wordOptionInputClear = (payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_CLEAR,
  payload,
});
// 행 추가
export const wordOptionCreate = (payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_CREATE,
  payload,
});
// 행 삭제
export const wordOptionDelete = (id) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_DELETE,
  id,
});
// 스케쥴 변경 시 해당 스케쥴에 해당하는 정보 설정
export const wordOptionSchedule = (id, payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_SCHEDULE,
  id,
  payload,
});
// 행의 set 변경
export const wordOptionSet = (id, payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_SET,
  id,
  payload,
});
// 행의 start 변경
export const wordOptionStart = (id, payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_START,
  id,
  payload,
});
// 행의 end 변경
export const wordOptionEnd = (id, payload) => ({
  type: wordOpt.WORDPAGE_INPUTDATA_END,
  id,
  payload,
});
// 행의 end 변경
export const wordOptionKey = (key, payload) => ({
  type: wordOpt.WORDPAGE_KEY,
  key,
  payload,
});
