import { call, put, takeEvery } from "redux-saga/effects";
import { getClass } from "../../../api/classApi";

const GET_CLASSES = "GET_CLASSES";
const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
const GET_CLASSES_ERROR = "GET_CLASSES_ERROR";

export const getClassesData = () => ({ type: GET_CLASSES });

function* getClassesDataSaga() {
  try {
    const payload = yield call(getClass);
    yield put({
      type: GET_CLASSES_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_CLASSES_ERROR,
      error: true,
      e,
    });
  }
}

export function* classesSaga() {
  yield takeEvery(GET_CLASSES, getClassesDataSaga);
}

const classesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CLASSES:
      return state;
    case GET_CLASSES_SUCCESS:
      return action.payload;
    case GET_CLASSES_ERROR:
      return state;
    default:
      return state;
  }
};

export default classesReducer;
