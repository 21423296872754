import { useSelector } from "react-redux";

const PrintTitle = (props) => {
  // props
  const obj = props.obj;

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  return (
    <div className="page">
      <h2 style={{ margin: "0px", padding: "0px" }}>Growing Note</h2>
      <p
        style={{
          textAlign: "right",
          margin: "0px",
          padding: "0px",
        }}
      >
        기간 :
        {selOption.start.toLocaleDateString() +
          " ~ " +
          selOption.end.toLocaleDateString()}
      </p>
      <p
        style={{
          textAlign: "right",
          margin: "0px",
          padding: "0px",
        }}
      >
        학생 : {obj.name}
      </p>
    </div>
  );
};

export default PrintTitle;
