/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../redux/InputData/actions";
import { subjects, subjectsWithCompetition } from "../../lib/utils/constants";

// inputData 내의 개별 행 스케쥴 변경을 위해 사용하는 Select 컴포넌트
const ChangeSubject = (props) => {
  // props
  // competition subject이 필요한지 여부
  const competition = props.competition;
  // InputTable에서 넘어온 row props
  const row = props.row;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // 기본값
  const defaultValue = row.subject ? row : null;

  // onChange 함수
  // 해당 행의 scid, scname을 바꾸는 함수
  const changeSubject = (e) =>
    dispatch(inputDataKey("subject", row.id, e.subject));

  return (
    <Select
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      isSearchable
      options={competition ? subjectsWithCompetition : subjects}
      getOptionLabel={(e) => e.subject}
      getOptionValue={(e) => e.subject}
      maxMenuHeight={150}
      value={defaultValue}
      onChange={changeSubject}
      placeholder="과목"
      isDisabled={disabled}
    />
  );
};

export default ChangeSubject;
