const GET_BOARD = "GET_BOARD";

export const getBoardData = (payload) => ({
  type: GET_BOARD,
  payload,
});

const boardReducer = (state = [], action) => {
  switch (action.type) {
    case GET_BOARD:
      return action.payload;
    default:
      return state;
  }
};

export default boardReducer;
