import db from "../../../firebase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  modalShowAdd,
  modalShowModify,
  modalShowPopup,
} from "../../../redux/Modal_Show/actions";
import PopupModal from "../../PageComponent/PopupModal";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

// Modal에서 작성된 스케쥴을 업로드하기 위한 버튼 컴포넌트
const UploadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer);
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // 버튼 비활성화 조건
  const disabledCondition =
    !displaySchedule.scid ||
    !displaySchedule.scname ||
    !displaySchedule.date ||
    !displaySchedule.cid ||
    !displaySchedule.office ||
    !displaySchedule.part ||
    !displaySchedule.subject;

  // 업로드 될 객체
  const uploadObject = {
    scid: displaySchedule.scid,
    scname: displaySchedule.scname,
    date: displaySchedule.date,
    cid: displaySchedule.cid,
    office: displaySchedule.office,
    part: displaySchedule.part,
    subject: displaySchedule.subject,
  };

  // onClick 함수
  // 스케쥴을 업로드하는 함수
  const dataUpload = () => {
    show.add
      ? addDoc(collection(db, "Schedule", myInfo.office, "Plan"), uploadObject)
      : updateDoc(
          doc(db, "Schedule", myInfo.office, "Plan", displaySchedule.id),
          uploadObject
        );
    dispatch(modalShowAdd(false));
    dispatch(modalShowModify(false));
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  const uploadButton = myInfo.admin && show.modify && (
    <Modal.Footer>
      <Button
        className="Growing-button"
        disabled={disabledCondition}
        onClick={openPopup}
      >
        완료
      </Button>
    </Modal.Footer>
  );

  return (
    <>
      {uploadButton}
      <PopupModal />
    </>
  );
};

export default UploadButton;
