import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowClear } from "../../../redux/Modal_Show/actions";
import PresetTable from "./PresetTable";

const LoadPresetModal = () => {
  const dispatch = useDispatch();
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer);

  const handleClose = () => dispatch(modalShowClear());

  return (
    <Modal show={show.load} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>설정 불러오기</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PresetTable />
      </Modal.Body>
    </Modal>
  );
};

export default LoadPresetModal;
