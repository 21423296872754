import { Col, Row } from "react-bootstrap";
import SelectClass from "../../Select/SelectClass";
import SelectDate from "../../Select/SelectDate";
import SelectSubject from "../../Select/SelectSubject";

const OptionAccordion = () => {
  return (
    <div className="m-3 opt-area">
      <Row className="g-4 p-3">
        <Col md>
          <SelectClass title="반" required />
        </Col>
        <Col md>
          <SelectSubject title="과목" required />
        </Col>
        <Col md>
          <SelectDate title="날짜" required />
        </Col>
      </Row>
    </div>
  );
};

export default OptionAccordion;
