/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion } from "react-bootstrap";
import React, { useEffect } from "react";
import WordCard from "../../../../Component/WordPage/WordCard/WordCard";
import TodaySchedule from "../../../../Component/WordPage/TodaySchedule/TodaySchedule";
import SelectList from "../../../../Component/WordPage/SelectList/SelectList";
import WordTable from "../../../../Component/WordPage/WordTable";
import { useDispatch, useSelector } from "react-redux";
import { clearWordArray, randomWord } from "../../../../redux/FirebaseData/Word/reducer";
import { loadingFalse } from "../../../../redux/Loading/actions";
import { wordOptionClear } from "../../../../redux/wordOption/actions";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";

const WordStudy = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducer
  // playtime
  const playTime = useSelector((state) => state.wordOptionReducer).playTime;
  // loading reducer
  const auto = useSelector((state) => state.loadingReducer).auto;
  // download controler reducer
  const downloadSchedule = useSelector((state) => state.downloadControlerReducer).schedule;

  const wordRand = () => dispatch(randomWord());

  useEffect(() => {
    dispatch(wordOptionClear());
    dispatch(clearWordArray());
    dispatch(loadingFalse());
    // 스케쥴 데이터 다운로드 토글 true로
    !downloadSchedule && dispatch(getFireStoreData("schedule"));
  }, []);

  useEffect(() => {
    let timer = null;
    if (auto) {
      timer = setInterval(() => {
        wordRand();
      }, playTime);
    } else if (!auto) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [auto]);

  return (
    <>
      <WordCard />
      <Accordion className="mt-5" defaultActiveKey="1">
        <TodaySchedule />
        <SelectList />
        <WordTable />
      </Accordion>
    </>
  );
};

export default WordStudy;
