import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as msg from "../../../../lib/utils/msg";

const AlertCard = () => {
  // reducers
  // loading reducer의 component state
  const alert = useSelector((state) => state.loadingReducer).alert;

  return <>{alert && <Alert children={msg.infoMsg.uploading()} />}</>;
};

export default AlertCard;
