/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Card, Spinner, ButtonGroup } from "react-bootstrap";
import React, { useEffect } from "react";
import SelectStudent from "../../../Component/Select/SelectStudent";
import LoadDataButton from "../../../Component/StudentStat/LoadDataButton";
import DisplayStatCard from "../../../Component/StudentStat/DisplayStatCard/DisplayStatCard";
import ChartCompetition from "../../../Component/StudentStat/Chart/ChartCompetition";
import ChartTest from "../../../Component/StudentStat/Chart/ChartTest";
import ChartQuiz from "../../../Component/StudentStat/Chart/ChartQuiz";
import { useDispatch, useSelector } from "react-redux";
import { selOptionClear } from "../../../redux/selOption/actions";
import { statClear } from "../../../redux/StatArray/actions";
import BtnToggle from "../../../Component/StudentStat/BtnToggle";
import useRedirect from "../../../hooks/useRedirect";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

const StudentStat = () => {
  const dispatch = useDispatch();
  // selOption state
  const selOption = useSelector((state) => state.selOptionReducer);
  // loading state
  const loading = useSelector((state) => state.loadingReducer).component;

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(selOptionClear("StudentStat"));
    dispatch(statClear());
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.FullContentContainer>
          <Card className="g-2 m-3">
            <Row className="my-1">
              <Col />
              <Col lg>
                <ButtonGroup className="my-2">
                  <SelectStudent />
                  <LoadDataButton />
                </ButtonGroup>
              </Col>
              <Col lg>
                <BtnToggle />
              </Col>
            </Row>
            {loading ? (
              <Row className="g-2 m-3">
                <Col>
                  <Spinner animation="border" variant="primary" />
                </Col>
              </Row>
            ) : (
              <>
                <Row className="g-2 mx-3 my-1">
                  <DisplayStatCard />
                </Row>
                {selOption.toggleCom && (
                  <Row className="mx-3 my-1">
                    <ChartCompetition />
                  </Row>
                )}
                {selOption.toggleTest && (
                  <Row className="mx-3 my-1">
                    <ChartTest />
                  </Row>
                )}
                {selOption.toggleQuiz && (
                  <Row className="mx-3 my-1">
                    <ChartQuiz />
                  </Row>
                )}
              </>
            )}
          </Card>
        </S.FullContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default StudentStat;
