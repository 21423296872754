import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { displayDataPart } from "../../../../redux/DisplayData/actions";

// Display될 스케쥴의 파트를 선택하는 Select 컴포넌트
const SelectDisplayPart = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);

  // onChange 함수
  // displaySchedule의 part 변경
  const changePart = (e) => dispatch(displayDataPart(e.target.value));

  return (
    <>
      <p className="Form-label"> Part </p>
      <Form.Control
        type="text"
        placeholder="파트를 입력하세요"
        value={displaySchedule.part}
        onChange={(e) => changePart(e)}
      />
      {!displaySchedule.part && <RequiredText />}
    </>
  );
};

export default SelectDisplayPart;
