import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RequiredText } from "../../../Methods";
import SelectTextForm from "../../Select/SelectTextForm";

const SelectOption = () => {
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer).title || "";
  // 업로드할 데이터를 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const regQid = /^[A-Z]{2}[0-9]{4}$/;
  return (
    <Row className="g-2 mx-2 mb-3">
      <Col md>
        <SelectTextForm
          title="QID"
          objectKey="title"
          placeholder="QID"
          disabled={inputData.length !== 0}
        />
        {!regQid.test(selOption || "") && (
          <RequiredText text="영어 대문자 2자리 + 숫자 4자리 조합으로 구성해주세요." />
        )}
      </Col>
    </Row>
  );
};

export default SelectOption;
