import { Col, Row } from "react-bootstrap";
import SelectClass from "../../Select/SelectClass";
import SelectDateRange from "../../Select/SelectDateRange";
import SelectStudent from "../../Select/SelectStudent";

const SelectOption = () => {
  return (
    <Row className="g-2 mx-2 mb-3">
      <Col md>
        <SelectClass title="반" clearable required />
      </Col>
      <Col md>
        <SelectStudent title="학생" clearable />
      </Col>
      <Col md>
        <SelectDateRange title="기간" clearable />
      </Col>
    </Row>
  );
};

export default SelectOption;
