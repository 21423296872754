const GET_SETTING = "GET_SETTING";

export const getSettingData = (payload) => ({
  type: GET_SETTING,
  payload,
});

const settingReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SETTING:
      return action.payload;
    default:
      return state;
  }
};

export default settingReducer;
