import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataCreate } from "../../redux/InputData/actions";

const CreateRowButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  const createData = () => dispatch(inputDataCreate("todo"));

  return (
    <Button className="py-0 px-2" disabled={loading} onClick={createData}>
      +
    </Button>
  );
};

export default CreateRowButton;
