import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDelete } from "../../../../redux/InputData/actions";

const DeleteRowButton = (props) => {
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputData
  const inputData = useSelector((state) => state.inputDataReducer);

  // redux dispatch로 처리할 index, props.id가 없을 경우 마지막 index 반환
  const deleteID = row !== undefined ? row.id : inputData.length - 1;

  const className = row ? "" : "py-0 px-2";

  // onClick 함수
  // 해당 index에 해당하는 행을 삭제
  const deleteData = () => dispatch(inputDataDelete(deleteID));

  return (
    <Button
      className={className}
      variant="light"
      disabled={loading}
      onClick={deleteData}
    >
      -
    </Button>
  );
};

export default DeleteRowButton;
