import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../redux/selOption/actions";

// 폰트 사이즈를 설정할 수 있는 드롭다운 컴포넌트
const FontSizeDropDown = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // onClick 함수
  // 폰트 사이즈 변경 함수
  const changeFontSize = (row) => {
    dispatch(selOptionKey("fontSize", row.fontSize));
    dispatch(selOptionKey("fontLabel", row.fontLabel));
  };

  // 폰트 옵션 Array
  const fontOption = [
    { fontSize: "12px", fontLabel: "작게" },
    { fontSize: "16px", fontLabel: "보통" },
    { fontSize: "24px", fontLabel: "크게" },
  ];

  return (
    <DropdownButton
      className="mt-3"
      style={{ textAlign: "right" }}
      title={"글자 크기 : " + selOption.fontLabel}
    >
      {fontOption.map((row) => (
        <Dropdown.Item key={row.fontLabel} onClick={() => changeFontSize(row)}>
          {row.fontLabel}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default FontSizeDropDown;
