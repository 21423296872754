import {
  MODAL_SHOW_ADD,
  MODAL_SHOW_DISPLAY,
  MODAL_SHOW_MODIFY,
  MODAL_SHOW_LOAD,
  MODAL_SHOW_CLEAR,
  MODAL_SHOW_POPUP,
} from "./types";

export const modalShowDisplay = (payload) => ({
  type: MODAL_SHOW_DISPLAY,
  payload,
});

export const modalShowAdd = (payload) => ({
  type: MODAL_SHOW_ADD,
  payload,
});

export const modalShowModify = (payload) => ({
  type: MODAL_SHOW_MODIFY,
  payload,
});

export const modalShowLoad = (payload) => ({
  type: MODAL_SHOW_LOAD,
  payload,
});

export const modalShowPopup = (payload) => ({
  type: MODAL_SHOW_POPUP,
  payload,
});

export const modalShowClear = () => ({
  type: MODAL_SHOW_CLEAR,
});
