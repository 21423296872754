import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { inputDataDelete } from "../../redux/InputData/actions";

// inputData 내 데이터 제거 시 사용하는 컴포넌트
const DeleteRowButton = (props) => {
  // props
  // inputData 내 index
  const id = !props.row ? undefined : props.row.id;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // input data reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // redux dispatch로 처리할 index, props.id가 없을 경우 마지막 index 반환
  const deleteID = id !== undefined ? id : inputData.length - 1;

  // onClick 함수
  // 해당 index의 행을 삭제
  const deleteData = () => {
    dispatch(inputDataDelete(deleteID));
  };

  return (
    <Button variant="light" onClick={deleteData}>
      -
    </Button>
  );
};

export default DeleteRowButton;
