const GET_PRESET_STUDENT = "GET_PRESET_STUDENT";

export const getPresetStudentData = (payload) => ({
  type: GET_PRESET_STUDENT,
  payload,
});

const presetStudentReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PRESET_STUDENT:
      return action.payload;
    default:
      return state;
  }
};

export default presetStudentReducer;
