import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import * as CardStyles from "../styles/CardStyles";
import TodoRow from "./TodoRow";

export default function DashboardCard({ todoList, more, setMore, admin }) {
  const toggleMore = () => setMore((prev) => !prev);

  const filteredTodoList = admin || more ? todoList : todoList.filter((_, i) => i < 5);

  return (
    <div>
      <Row className="mt-4">
        <Col>
          <CardStyles.CardContainer more={more || admin}>
            {filteredTodoList.map((row, i) => (
              <TodoRow key={row.id} row={row} i={i} admin={!!admin} />
            ))}
          </CardStyles.CardContainer>
        </Col>
      </Row>
      <div style={{ height: `${(5 - todoList.length) * 8}vh` }}></div>
      {!admin && todoList.length > 5 && <MoreRow onClick={toggleMore}>{<p>{more ? "간략히" : "전체보기"}</p>}</MoreRow>}
    </div>
  );
}

const MoreRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  color: gray;

  p {
    font-size: 12px;
    margin: 0;
  }
`;
