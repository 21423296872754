export const groupBy = function (data, key) {
  const sampleList = data.reduce(function (carry, el) {
    const group = el[key];

    if (carry[group] === undefined) {
      carry[group] = el;
    } else if (carry[group].score < el.score) {
      carry[group] = el;
    } else if (carry[group].score === el.score) {
      if (carry[group].correct < el.correct) {
        carry[group] = el;
      }
    }
    return carry;
  }, {});
  return Object.keys(sampleList)
    .map((k) => ({ sid: k, name: sampleList[k].name, score: sampleList[k].score, correct: sampleList[k].correct }))
    .sort((a, b) => {
      if (a.score !== b.score) return b.score - a.score;
      if (a.correct !== b.correct) return b.correct - a.correct;
      return 0;
    });
};

export const getRank = function (data, sid, rankDoNotPlayQuiz) {
  const list = groupBy(data, "sid");
  const tempRank = list.map((result) => result.sid).indexOf(sid);
  return tempRank === -1 ? 34 : tempRank + 1;
};
