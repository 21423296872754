import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

const ToggleSwitch = (props) => {
  const isAllow = props.isAllow;
  const isState = props.isState;
  const setIsAllow = props.setIsAllow;
  const setIsState = props.setIsState;

  const optionText = (option) => {
    switch (option) {
      case 1:
        return "O";
      case 2:
        return "X";
      default:
        return "전체";
    }
  };

  const optionChange = (option) => {
    const opt = option === "allow" ? isAllow : isState;
    const changeOpt = option === "allow" ? setIsAllow : setIsState;
    changeOpt((opt + 1) % 3);
  };

  const optionColor = (option) => {
    switch (option) {
      case 1:
        return "primary";
      case 2:
        return "danger";
      default:
        return "dark";
    }
  };

  return (
    <Row className="mb-3">
      <Col>
        <ButtonGroup style={{ float: "left" }}>
          <Button variant="outline-dark" disabled>
            state
          </Button>
          <Button
            variant={optionColor(isState)}
            onClick={() => optionChange("state")}
          >
            {optionText(isState)}
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mx-2" style={{ float: "left" }}>
          <Button variant="outline-dark" disabled>
            allow
          </Button>
          <Button
            variant={optionColor(isAllow)}
            onClick={() => optionChange("allow")}
          >
            {optionText(isAllow)}
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default ToggleSwitch;
