const shuffle = () => Math.random() - 0.5;

export function randomNum(min, max) {
  const randNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return randNum;
}

export function randomArray(a) {
  return a[Math.floor(Math.random() * a.length)];
}

export function randomQuizIndexArray(max) {
  const language = (value) => (value ? "eng" : "kor");
  const randomSortQuizIndexArray = Array.from({ length: max }, (_, i) => i).sort(shuffle);
  const randomOptionIndexArray = (questionIndex) => {
    const remainIndexArray = randomSortQuizIndexArray.filter((idx) => idx !== questionIndex);
    const optionIndexArray = remainIndexArray.sort(shuffle).slice(0, 3);
    return [questionIndex, ...optionIndexArray].sort(shuffle);
  };
  let quizIndexArray = [];
  for (let i = 0; i < 10; i++) {
    quizIndexArray.push({
      language: language(Math.round(Math.random())),
      questionIndex: randomSortQuizIndexArray[i],
      optionIndex: randomOptionIndexArray(randomSortQuizIndexArray[i]),
    });
  }
  return quizIndexArray;
}
