import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selOptionKey } from "../../../../redux/selOption/actions";

const SelectType = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // 본문 리스트 reducer
  const passageList = useSelector((state) => state.passageReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // passageList 내 타입 리스트 리턴
  const passageType = passageList
    .map((row) => {
      return row.type;
    })
    .filter((val, index, arr) => {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ type: row }));

  // select option reducer의 type 변경 함수
  const changeType = (e) => {
    const returnValue = e ? e.type : null;
    dispatch(selOptionKey("type", returnValue));
    !e && dispatch(selOptionKey("textbook", null));
  };

  return (
    <>
      <p className="Form-label"> 타입 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="타입"
        isSearchable
        isClearable
        options={passageType}
        value={selOption.type ? selOption : null}
        getOptionLabel={(e) => e.type}
        getOptionValue={(e) => e.type}
        maxMenuHeight={150}
        onChange={changeType}
        isDisabled={inputData.length !== 0}
      />
    </>
  );
};

export default SelectType;
