import styled from "styled-components";
import { GrowingTheme } from "./styleProperties";

export const CardContainer = styled.div`
  width: 100%;
  ${(props) => (props.more ? "" : "max-height: 40vh")};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  transition: all 0.2s linear;
`;

export const CardRow = styled.div`
  width: 100%;
  min-height: 8vh;
  // border-radius: 10px;
  display: flex;
  background-color: ${(props) => (props.i % 2 ? "white" : "#f7f7f7")};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  div:nth-child(1) {
    color: ${(props) => (props.isCheck ? "#4b82fa" : "lightgrey")};
    width: 15%;
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: ${(props) => (props.admin ? "55%" : "65%")};

    p {
      margin: 0;
    }
    p:nth-child(1) {
      text-align: left;
      // font-size: 8px;
      // color: grey;
    }
    p:nth-child(2) {
      text-align: left;
    }
  }
  div:nth-child(3) {
    width: ${(props) => (props.admin ? "15%" : "20%")};
  }

  ${(props) =>
    props.admin
      ? `
      div:nth-child(4) {
    width: ${(props) => (props.admin ? "15%" : "20%")};
  }
  `
      : ""};
`;

export const CardQuizRow = styled.div`
  width: 100%;
  min-height: 8vh;
  // border-radius: 10px;
  display: flex;
  background-color: ${(props) => (props.i % 2 ? "#fafafa" : "#f0f0f0")};
  color: ${(props) => (props.isPlayed ? "#bbbbbb" : props.isCheck ? "#4b82fa" : "black")};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  div:nth-child(1) {
    width: 20%;
  }
  div:nth-child(2) {
    width: 10%;
  }
  div:nth-child(3) {
    width: 5%;
  }
  div:nth-child(4) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    width: 45%;

    p {
      margin: 0;
    }
    p:nth-child(1) {
      text-align: left;
      // font-size: 8px;
      // color: grey;
    }
    p:nth-child(2) {
      text-align: left;
    }
  }
  div:nth-child(5) {
    width: 20%;
  }
`;

export const CardTimeWordRow = styled.div`
  width: 100%;
  min-height: 8vh;
  // border-radius: 10px;
  display: flex;
  background-color: ${(props) => (props.i % 2 ? "#fafafa" : "#f0f0f0")};
  color: ${(props) => (props.isPlayed ? "#bbbbbb" : props.isCheck ? "#4b82fa" : "black")};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  div:nth-child(1) {
    width: 20%;
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;

    p {
      margin: 0;
    }
    p:nth-child(1) {
      text-align: center;
      // font-size: 8px;
      // color: grey;
    }
    p:nth-child(2) {
      text-align: center;
    }
  }
  div:nth-child(3) {
    width: 20%;
  }
`;

export const CardQuizResultRow = styled.div`
  width: 100%;
  min-height: 8vh;
  display: flex;
  outline: 2px solid ${GrowingTheme.color.white};
  background-color: ${(props) => {
    if (props.category) {
      return GrowingTheme.color.border;
    }
    if (props.ranking) {
      return props.correct ? GrowingTheme.color.primary : GrowingTheme.color.border;
    }
    if (props.quizResult) {
      return GrowingTheme.color.primary;
    }
    return props.correct ? GrowingTheme.color.primary : GrowingTheme.color.secondary;
  }};
  color: ${(props) => {
    if (props.category) {
      return GrowingTheme.color.black;
    }
    if (props.ranking) {
      return props.correct ? GrowingTheme.color.white : GrowingTheme.color.black;
    }
    if (props.quizResult) {
      return GrowingTheme.color.white;
    }
    return GrowingTheme.color.white;
  }};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: max(14px, 2vw);
  }

  div:nth-child(1) {
    width: 15%;
  }
  div:nth-child(2) {
    width: ${(props) => (props.ranking ? "25%" : "35%")};
  }
  div:nth-child(3) {
    width: ${(props) => (props.ranking ? "30%" : "35%")};
  }
  div:nth-child(4) {
    width: ${(props) => (props.ranking ? "30%" : "35%")};
  }
`;

export const WordCardContainer = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: ${GrowingTheme.color.black};
  color: white;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div:nth-child(1) {
    height: 15%;
  }
  div:nth-child(2) {
    height: 70%;
    font-size: 28px;
    font-weight: bold;
  }
  div:nth-child(3) {
    height: 15%;
  }
`;

export const WordAnswerCardContainer = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid ${GrowingTheme.color.primary};
  border-radius: 10px;
  background-color: ${GrowingTheme.color.white};
  color: ${GrowingTheme.color.black};
`;

export const CardBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${GrowingTheme.color.primary};
  border-radius: 5px;

  h1 {
    font-size: 5vh;
    color: ${GrowingTheme.color.primary};
  }
`;

export const CorrectBox = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${GrowingTheme.color.border};
  border-radius: 5px;

  p,
  h1 {
    margin: 0;
  }

  p {
    font-size: 2vh;
  }

  h1 {
    font-size: 3vh;
    font-weight: normal;
    color: ${GrowingTheme.color.primary};
  }
`;

export const TimerBox = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${GrowingTheme.color.border};
  border-radius: 5px;

  p {
    margin: 0;
    font-size: 2.5vh;
  }
`;

export const ScoreBox = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${GrowingTheme.color.border};
  border-radius: 5px;

  p,
  h1 {
    margin: 0;
  }

  p {
    font-size: 2vh;
  }

  h1 {
    font-size: 3vh;
    color: ${GrowingTheme.color.primary};
  }
`;
