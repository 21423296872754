const PASSAGE_OBJECT_CLEAR = "PASSAGE_OBJECT_CLEAR";
const PASSAGE_OBJECT_SET = "PASSAGE_OBJECT_SET";
const PASSAGE_OBJECT_INPUTDATA = "PASSAGE_OBJECT_INPUTDATA";
const PASSAGE_OBJECT_INPUTDATA_PARAGRAPH = "PASSAGE_OBJECT_INPUTDATA_PARAGRAPH";
const PASSAGE_OBJECT_INPUTDATA_REMOVE = "PASSAGE_OBJECT_INPUTDATA_REMOVE";

export const clearPassageObj = () => ({ type: PASSAGE_OBJECT_CLEAR });
export const setPassage = (language, payload) => ({
  type: PASSAGE_OBJECT_SET,
  language,
  payload,
});
export const setPassageInputData = (id, key, payload) => ({
  type: PASSAGE_OBJECT_INPUTDATA,
  id,
  key,
  payload,
});
export const setPassageInputDataParagraph = (id) => ({
  type: PASSAGE_OBJECT_INPUTDATA_PARAGRAPH,
  id,
});
export const setPassageInputDataRemove = (id) => ({
  type: PASSAGE_OBJECT_INPUTDATA_REMOVE,
  id,
});

const passageObject = { eng: "", kor: "", inputData: [] };

export const passageObjectReducer = (state = passageObject, action) => {
  switch (action.type) {
    case PASSAGE_OBJECT_CLEAR:
      return passageObject;
    case PASSAGE_OBJECT_SET:
      return { ...state, [action.language]: action.payload };
    case PASSAGE_OBJECT_INPUTDATA:
      return {
        ...state,
        inputData: state.inputData.map((row) =>
          row.id === action.id ? { ...row, [action.key]: action.payload } : row
        ),
      };
    case PASSAGE_OBJECT_INPUTDATA_PARAGRAPH:
      return {
        ...state,
        inputData: state.inputData.map((row) =>
          row.id === action.id ? { ...row, paragraph: !row.paragraph } : row
        ),
      };
    case PASSAGE_OBJECT_INPUTDATA_REMOVE:
      return {
        ...state,
        inputData: state.inputData.filter((row) => row.id !== action.id),
      };
    default:
      return state;
  }
};

export default passageObjectReducer;
