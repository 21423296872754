import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataClear } from "../../../redux/InputData/actions";

// inputData 초기화를 위해 사용하는 버튼 컴포넌트
const ClearButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // onClick 함수
  // inputData 초기화
  const inputClear = () => dispatch(inputDataClear());

  return (
    <>
      {inputData.length !== 0 && (
        <Button variant="warning" onClick={inputClear}>
          초기화
        </Button>
      )}
    </>
  );
};

export default ClearButton;
