const POPUP_MODAL_SET = "POPUP_MODAL_SET";
const POPUP_MODAL_CLEAR = "POPUP_MODAL_CLEAR";

export const setPopupModal = (header, content, run, btnText) => ({
  type: POPUP_MODAL_SET,
  header,
  content,
  run,
  btnText,
});

export const clearPopupModal = () => ({
  type: POPUP_MODAL_CLEAR,
});

const popupModal = { header: "", content: "", run: () => {}, btnText: "" };

const popupModalReducer = (state = popupModal, action) => {
  switch (action.type) {
    case POPUP_MODAL_SET:
      return {
        header: action.header,
        content: action.content,
        run: action.run,
        btnText: action.btnText,
      };
    case POPUP_MODAL_CLEAR:
      return popupModal;
    default:
      return state;
  }
};

export default popupModalReducer;
