import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { distance } from "../../../../lib/utils/calculator";
import LastMonthText from "../../LastMonthText";
import DropDownClass from "./DropDownClass";
import ThisMontText from "../ThisMonthText";

const CardCompetition = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const competition = useSelector((state) => state.statArrayReducer).comArray;
  // stat reducer
  const stat = useSelector((state) => state.statArrayReducer).stat;

  let displayStat = {
    com: {
      this:
        stat.com.this[selOption.cid + "sum"] !== undefined
          ? Math.round(
              (stat.com.this[selOption.cid + "sum"] /
                stat.com.this[selOption.cid + "count"]) *
                10
            ) / 10
          : 0,
      last:
        stat.com.last[selOption.cid + "sum"] !== undefined
          ? Math.round(
              (stat.com.last[selOption.cid + "sum"] /
                stat.com.last[selOption.cid + "count"]) *
                10
            ) / 10
          : 0,
    },
  };

  const cardStyle = (stat) => {
    if (stat.this < stat.last && stat.this !== 0 && stat.last !== 0) {
      return "card-blueberry";
    } else if (stat.this > stat.last && stat.this !== 0 && stat.last !== 0) {
      return "card-guaba";
    } else return "card-mango";
  };

  const statScore = (stat) => {
    if (stat.this === 0) return "기록 없음";
    else if (stat.last === 0) return "신규";
    else return distance(stat.last, stat.this);
  };

  const arrow = (stat) => {
    if (stat.last > stat.this && stat.this !== 0 && stat.last !== 0) {
      return " ⬆";
    } else if (stat.last < stat.this && stat.this !== 0 && stat.last !== 0) {
      return " ⬇︎";
    } else if (stat.last !== 0 && stat.this !== 0) return " -";
  };

  return (
    <>
      {competition.length !== 0 && selOption.toggleCom && (
        <Col lg>
          <Card className={cardStyle(displayStat.com)}>
            <Card.Body>
              <Row>
                <Col>
                  <DropDownClass />
                  <ThisMontText />
                  <h1 className="App-left"> {displayStat.com.this} </h1>
                </Col>
                <Col>
                  <LastMonthText />
                  <h2 className="App-right">
                    {statScore(displayStat.com)}
                    {arrow(displayStat.com)}
                  </h2>
                  <Card.Text className="App-right">
                    {displayStat.com.last} → {displayStat.com.this}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default CardCompetition;
