/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, ButtonGroup } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selOptionClear } from "../../../../redux/selOption/actions";
import UploadButton from "../../../../Component/Quiz/Input/UploadButton";
import ResultTable from "../../../../Component/Quiz/Input/ResultTable";
import UploadAlert from "../../../../Component/Quiz/Input/UploadAlert";
import SummaryData from "../../../../Component/Quiz/Input/SummaryData";
import { clearQuizUploadData } from "../../../../redux/Quiz_UploadData/actions";
import { loadingAlert, loadingComponent, loadingFalse } from "../../../../redux/Loading/actions";
import OptionAccordion from "../../../../Component/Quiz/Input/OptionAccordion";
import { modalShowPopup } from "../../../../redux/Modal_Show/actions";
import { setQuizInputDataClear } from "../../../../redux/Quiz_InputData/actions";
import { setNameOptionClear } from "../../../../redux/Quiz_NameOption/actions";
import usePageBlock from "../../../../hooks/usePageBlock";
import useRedirect from "../../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

// 퀴즈 정답 데이터 입력 시 사용하는 컨테이너
const QuizInput = () => {
  // file reference
  const fileRef = useRef();

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // loading reducer의 alert state
  const alertClick = useSelector((state) => state.loadingReducer).alert;
  // 서버에 등록될 데이터 reducer
  const uploadData = useSelector((state) => state.quizUploadDataReducer);

  // loading reducer의 component state 변경 시 loading이 true일 경우 실행
  useEffect(() => {
    loading &&
      // 3초 뒤 component state를 false로 변경
      setTimeout(() => {
        dispatch(loadingComponent(false));
        dispatch(modalShowPopup(false));
      }, 3000);
  }, [loading]);

  // loading reducer의 alert state 변경 시 alertClick이 true일 경우 실행
  useEffect(() => {
    alertClick &&
      // 1초 뒤 실행
      setTimeout(() => {
        // alert state를 false로 변경
        dispatch(loadingAlert(false));
        // uploadData 초기화
        dispatch(clearQuizUploadData());
        // file reference 초기화
        fileRef.current.value = "";
      }, 1000);
  }, [alertClick]);

  useRedirect("teachingcenter", "home");

  // 마운트 시 최초 실행
  useEffect(() => {
    // select option을 quiz input용으로 초기화
    dispatch(selOptionClear("QuizInput"));
    // loading state 초기화
    dispatch(loadingFalse());
    // 초기화
    dispatch(setQuizInputDataClear());
    dispatch(clearQuizUploadData());
    dispatch(setNameOptionClear());
  }, []);

  // uploadData가 존재하면 페이지 이탈 방지
  usePageBlock(uploadData.length !== 0);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <OptionAccordion fileRef={fileRef} />
          <Row className="g-4 mb-5 px-2 pb-2 mx-3">
            <Col>
              <ButtonGroup style={{ float: "right" }}>
                <SummaryData />
                <UploadButton />
              </ButtonGroup>
              <UploadAlert />
            </Col>
          </Row>
          <Row className="g-4 mb-5 px-2 pb-2 mx-3">
            <Col>
              <ResultTable />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default QuizInput;
