// STUDENT STAT

import { call, put, takeEvery } from "redux-saga/effects";
import { statDataLoad } from "./function";
import {
  STATARRAY_CLEAR,
  STATARRAY_LOAD,
  STATARRAY_LOAD_ERROR,
  STATARRAY_LOAD_SUCCESS,
} from "./types";

// stat 초기화
export const statClear = () => ({
  type: STATARRAY_CLEAR,
});
// stat data load
export const statLoad = (payload, dateRange) => ({
  type: STATARRAY_LOAD,
  payload,
  dateRange,
});
// 해당 조건에 맞는 stat 불러오기 saga
function* statLoadSaga(action) {
  const selOption = action.payload;
  const dateRange = action.dateRange;
  try {
    // 해당 조건에 맞는 stat 불러오기 성공 시
    const payload = yield call(statDataLoad, selOption, dateRange);
    yield put({
      type: STATARRAY_LOAD_SUCCESS,
      payload,
    });
  } catch (e) {
    // 해당 조건에 맞는 stat 불러오기 실패 시
    yield put({
      type: STATARRAY_LOAD_ERROR,
      error: true,
      e,
    });
  }
}

export function* statArraySaga() {
  yield takeEvery(STATARRAY_LOAD, statLoadSaga);
}
