import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selOptionKey } from "../../../../redux/selOption/actions";

const SelectTextBook = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // 본문 리스트 reducer
  const passageList = useSelector((state) => state.passageReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // passageList 내 selOption의 타입과 일치하는 교과서 리스트 리턴
  const passageTextbook = passageList
    .filter((row) => row.type === selOption.type)
    .map((row) => {
      return row.textbook;
    })
    .filter((val, index, arr) => {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ textbook: row }));

  // select option reducer의 textbook 변경 함수
  const changeTextbook = (e) => {
    const returnValue = e ? e.textbook : null;
    dispatch(selOptionKey("textbook", returnValue));
  };

  return (
    <>
      <p className="Form-label"> 교과서 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="교과서"
        isSearchable
        isClearable
        options={passageTextbook}
        value={selOption.textbook ? selOption : null}
        getOptionLabel={(e) => e.textbook}
        getOptionValue={(e) => e.textbook}
        maxMenuHeight={150}
        onChange={changeTextbook}
        isDisabled={!selOption.type || inputData.length !== 0}
      />
    </>
  );
};

export default SelectTextBook;
