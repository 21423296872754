import { Button, ButtonGroup, Form, ListGroupItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { displayDataSet } from "../../../redux/DisplayData/actions";
import { selOptionKey } from "../../../redux/selOption/actions";

const SentenceDiv = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  const displayData = useSelector((state) => state.displayDataReducer);

  const passage = selOption.passage || [
    { id: 0, eng: "", kor: "", start: true },
  ];

  const changeToggleAnswer = () => {
    dispatch(selOptionKey("answer", !selOption.answer));
  };

  const changeToggleEng = () => {
    dispatch(selOptionKey("eng", !selOption.eng));
  };

  const changeSentenceSize = (e) =>
    dispatch(selOptionKey("sentenceSize", e.target.value));

  const randomDisplay = () => {
    const randomNumber = Math.floor(Math.random() * passage.length);
    dispatch(selOptionKey("number", randomNumber));
    dispatch(displayDataSet(passage.find((row) => row.id === randomNumber)));
  };

  return (
    <>
      <Form.Range
        min={8}
        max={100}
        value={selOption.sentenceSize || 16}
        onChange={changeSentenceSize}
      />
      <ListGroupItem>
        <ButtonGroup>
          <Button variant="outline-primary" onClick={randomDisplay}>
            다음
          </Button>
          <Button variant="outline-dark" onClick={changeToggleEng}>
            English
          </Button>
          <Button variant="outline-dark" onClick={changeToggleAnswer}>
            Korean
          </Button>
        </ButtonGroup>
      </ListGroupItem>
      {selOption.eng && (
        <ListGroupItem style={{ fontSize: selOption.sentenceSize + "px" }}>
          {displayData.eng}
        </ListGroupItem>
      )}
      {selOption.answer && (
        <ListGroupItem style={{ fontSize: selOption.sentenceSize + "px" }}>
          {displayData.kor}
        </ListGroupItem>
      )}
    </>
  );
};

export default SentenceDiv;
