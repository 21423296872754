import { call, put, takeEvery } from "redux-saga/effects";
import { getMyWordQuizListAPI } from "../../api/wordQuizApi";

const GET_MY_WORD_QUIZ = "GET_MY_WORD_QUIZ";
const GET_MY_WORD_QUIZ_SUCCESS = "GET_MY_WORD_QUIZ_SUCCESS";
const GET_MY_WORD_QUIZ_ERROR = "GET_MY_WORD_QUIZ_ERROR";
const CHECK_MY_WORD_QUIZ = "CHECK_MY_WORD_QUIZ";

export const getMyWordQuizList = (sid) => ({
  type: GET_MY_WORD_QUIZ,
  sid,
});

function* getMyWordQuizListSaga(action) {
  const sid = action.sid;
  try {
    const payload = yield call(getMyWordQuizListAPI, sid);
    yield put({
      type: GET_MY_WORD_QUIZ_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_MY_WORD_QUIZ_ERROR,
      error: true,
      e,
    });
  }
}

export const checkMyWordQuiz = (scheduleId) => ({
  type: CHECK_MY_WORD_QUIZ,
  scheduleId,
});

export function* studentWordQuizSaga() {
  yield takeEvery(GET_MY_WORD_QUIZ, getMyWordQuizListSaga);
}

const studentWordQuizReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MY_WORD_QUIZ:
      return state;
    case GET_MY_WORD_QUIZ_SUCCESS:
      return action.payload;
    case GET_MY_WORD_QUIZ_ERROR:
      return [];
    default:
      return state;
  }
};

export default studentWordQuizReducer;
