/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs } from "react-bootstrap";
import React, { useEffect } from "react";
import GameTable from "../../../../Component/Competition/CompetitionGame/GameTable";
import { useDispatch, useSelector } from "react-redux";
import { leagueRosterClear } from "../../../../redux/League_Roster/actions";
import { allGameArrayClear } from "../../../../redux/League_GameArray/actions";
import usePageBlock from "../../../../hooks/usePageBlock";
import useRedirect from "../../../../hooks/useRedirect";
import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";
import WordStudy from "pages/word/components/word-study";

// competition game 컨테이너
const CompetitionGame = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // competition 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);
  // league 인원 reducer
  const leagueRoster = useSelector((state) => state.leagueRosterReducer);

  // inputData 내 인원이 있는지 판단
  const ifExist = inputData.length !== 0;
  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);

  useRedirect("teachingcenter", "home");

  // competition game 컨테이너 마운트 시 한 번만 실행
  useEffect(() => {
    // inputData 내 인원들을 리그에 따라 분류
    const newLeagueRoster = leagueLable.map((a) => inputData.filter((v) => v.league === a));
    // 인원 수에 맞게 각 리그에 빈 gameArray 생성
    const newGameArray = newLeagueRoster.map((obj) => Array.from(Array(obj.length), () => Array(obj.length).fill(0)));

    // inputData 내 인원이 있을 경우 leagueRoster, gameArray 인원 수에 맞게 초기화, 없으면 빈 배열로 초기화
    if (ifExist) {
      dispatch(leagueRosterClear(newLeagueRoster));
      dispatch(allGameArrayClear(newGameArray));
    } else {
      dispatch(leagueRosterClear([]));
      dispatch(allGameArrayClear([]));
    }
  }, []);

  // 페이지 이탈 방지
  usePageBlock();

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Tabs defaultActiveKey="word" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="word" title="Word">
              <WordStudy />
            </Tab>
            {leagueRoster.map((obj, level) => {
              return (
                league[leagueLable[level]].active && (
                  <Tab
                    key={"league " + leagueLable[level]}
                    className="m-2"
                    eventKey={"league " + leagueLable[level]}
                    title={selOption.class + " League " + leagueLable[level]}
                  >
                    <GameTable level={level} />
                  </Tab>
                )
              );
            })}
          </Tabs>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default CompetitionGame;
