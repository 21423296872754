import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selOptionArrayKey,
  selOptionKey,
} from "../../../../redux/selOption/actions";

// inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용하는 버튼 컴포넌트
const CheckDeleteBtn = (props) => {
  // props
  // inputData 내 index
  const row = props.row;
  // disabled condition
  const disabled = props.disabled;
  // 전체 체크
  const isAll = props.isAll;

  // redux dispatch
  const dispatch = useDispatch();

  const passageList =
    useSelector((state) => state.selOptionReducer).passageList || [];

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  // onClick 함수
  // inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용
  const checkEdit = () => {
    if (isAll) {
      dispatch(
        selOptionKey(
          "passageList",
          passageList.map((row) => ({
            ...row,
            delete: passageList.find((d) => !d.delete) !== undefined,
          }))
        )
      );
    } else if (!(loading || disabled)) {
      dispatch(selOptionArrayKey("passageList", row.id, "delete", !row.delete));
    }
  };

  return (
    <td
      onClick={checkEdit}
      style={
        !(loading || disabled) ? { cursor: "pointer" } : { cursor: "default" }
      }
    >
      {isAll ? (
        <Button
          variant="light"
          className={
            passageList.find((d) => !d.delete) === undefined ? "px-1" : "px-2"
          }
          style={{ color: "black" }}
          disabled={loading || disabled}
        >
          <strong>
            삭제{passageList.find((d) => !d.delete) === undefined ? "✔︎" : " "}
          </strong>
        </Button>
      ) : (
        <Button
          variant="light"
          className={row.delete ? "px-1" : "px-2"}
          style={{ color: "black" }}
          disabled={loading || disabled}
        >
          {row.delete ? "✔︎" : " "}
        </Button>
      )}
    </td>
  );
};

export default CheckDeleteBtn;
