/* eslint-disable react-hooks/exhaustive-deps */
import db from "../../../firebase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingComponent } from "../../../redux/Loading/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";
import {
  inputDataClear,
  inputDataSetting,
} from "../../../redux/InputData/actions";
import { selOptionKey } from "../../../redux/selOption/actions";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // inputData reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // inputData reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // 스케쥴 라벨 리스트 reducer
  const scheduleList = useSelector((state) => state.scheduleListReducer);

  const displayObject = scheduleList.find(
    (row) => row.id === selOption.scid
  ) || { maxset: 1, last: 25 };

  let rawWord = selOption.rawWord || "";

  let wordArray = rawWord.split(/\n/).filter((row) => row !== "");

  const returnArray = () => {
    const numberReg = /[0-9]/;
    const engReg = /[a-zA-Z]/;
    const korReg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

    const returnArrays = wordArray.map((row, i) => {
      const isRightValue = (obj) =>
        numberReg.test(obj) || engReg.test(obj) || korReg.test(obj);
      const wordRow = row.split(/\t/).filter((obj) => isRightValue(obj));
      const returnNumber = (displayObject.last + i + 1) % 25 || 25;
      const returnSet =
        displayObject.maxset + parseInt((displayObject.last + i) / 25);
      return {
        id: i,
        eng: wordRow[0],
        kor: wordRow[1],
        number: returnNumber,
        set: returnSet,
      };
    });
    return returnArrays;
  };

  // onChange 함수
  // inputData 변경 함수
  const changeinputData = () => {
    dispatch(inputDataSetting(returnArray()));
    dispatch(selOptionKey("displayPage", displayObject.maxset + 1));
  };

  const clearData = () => {
    dispatch(selOptionKey("rawWord", ""));
    dispatch(inputDataClear());
    dispatch(setPopupModal());
  };

  const dataUpload = async () => {
    dispatch(loadingComponent(true));
    inputData.map(
      async (row) =>
        await addDoc(collection(db, "Words"), {
          category: selOption.scname,
          scid: selOption.scid,
          eng: row.eng,
          kor: row.kor,
          set: row.set,
          number: row.number,
        })
    );
    await updateDoc(
      doc(db, "Schedule", myInfo.office, "List", selOption.scid),
      {
        maxset: inputData[inputData.length - 1].set,
        last: inputData[inputData.length - 1].number,
      }
    );
    setTimeout(() => {
      dispatch(loadingComponent(false));
      clearData();
    }, 3000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  const passageDisabled = !(selOption.rawWord && selOption.scid);

  return (
    <Row className="g-2 mb-3">
      <Col>
        <ButtonGroup>
          <Button disabled={passageDisabled} onClick={changeinputData}>
            단어 나누기
          </Button>
          <Button variant="warning" onClick={clearData}>
            초기화
          </Button>
        </ButtonGroup>
        <Button className="mx-3" onClick={openPopup}>
          단어 업로드
        </Button>
      </Col>
    </Row>
  );
};

export default ControlButton;
