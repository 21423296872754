import { Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import AnswerCell from "./AnswerCell";
import NameHeader from "./NameHeader";
import SumCell from "./SumCell";

const HitMap = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // quiz Input Data reducer
  const qIDData = useSelector((state) => state.qIDDataReducer);

  return (
    <>
      {qIDData.length !== 0 && (
        <Row className="g-4 mb-5 pb-2 mx-3">
          <Col md>
            <p className="Form-label">
              정답률 기준 ({selOption.percentage}%)에 따른 Heatmap
            </p>
            <Table
              hover
              bordered
              size="sm"
              className="p-0"
              style={{ fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th width="5%">QID</th>
                  <NameHeader />
                  <th>합계</th>
                </tr>
              </thead>
              <tbody>
                <AnswerCell />
                <SumCell />
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
};

export default HitMap;
