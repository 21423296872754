// WordPage
// WordPage에 맞게 초기화
export const WORDPAGE_CLEAR = "WORDPAGE_CLEAR";
// PlayTime
export const WORDPAGE_PLAYTIME = "WORDPAGE_PLAYTIME";
// date 변경
export const WORDPAGE_DATE = "WORDPAGE_DATE";
// class 변경 시 cid, scname, part 동시 변경
export const WORDPAGE_CLASS = "WORDPAGE_CLASS";
// class 변경 시 cid, scname, part 동시 변경
export const WORDPAGE_KEY = "WORDPAGE_KEY";

// 스케쥴 변경 시 해당 스케쥴에 해당하는 정보 설정
export const WORDPAGE_INPUTDATA_SCHEDULE = "WORDPAGE_INPUTDATA_SCHEDULE";
// inputData 초기화
export const WORDPAGE_INPUTDATA_CLEAR = "WORDPAGE_INPUTDATA_CLEAR";
// 행 추가
export const WORDPAGE_INPUTDATA_CREATE = "WORDPAGE_INPUTDATA_CREATE";
// 행 삭제
export const WORDPAGE_INPUTDATA_DELETE = "WORDPAGE_INPUTDATA_DELETE";
// set
export const WORDPAGE_INPUTDATA_SET = "WORDPAGE_INPUTDATA_SET";
// 행의 start 변경
export const WORDPAGE_INPUTDATA_START = "WORDPAGE_INPUTDATA_START";
// 행의 end 변경
export const WORDPAGE_INPUTDATA_END = "WORDPAGE_INPUTDATA_END";
