const GET_PAYMENT = "GET_PAYMENT";
const SET_PAYMENT = "SET_PAYMENT";

export const getPaymentData = (payload) => ({
  type: GET_PAYMENT,
  payload,
});

export const setPaymentData = (payload) => ({
  type: SET_PAYMENT,
  payload,
});

const paymentReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT:
      return action.payload;
    case SET_PAYMENT:
      return action.payload;
    default:
      return state;
  }
};

export default paymentReducer;
