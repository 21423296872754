import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { inputDataCreate } from "../../../redux/InputData/actions";

// 스케쥴을 추가하기 위한 버튼 컴포넌트
const AddButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // loading reducer의 componet state
  const loading = useSelector((state) => state.loadingReducer).component;

  const buttonDisabled =
    !selOption.cid ||
    !selOption.subject ||
    !selOption.month ||
    !selOption.year ||
    loading;

  const checkAdd = () =>
    !buttonDisabled &&
    dispatch(
      inputDataCreate("scheduleUdt", { ...selOption, office: myInfo.office })
    );

  return (
    <Button
      className="Growing-button"
      disabled={buttonDisabled}
      onClick={checkAdd}
    >
      {loading ? <LoadingSpinner /> : "+"}
    </Button>
  );
};

export default AddButton;
