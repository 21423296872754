import { call, put, takeEvery } from "redux-saga/effects";
import { getUsersInfo } from "../../../api/usersApi";

const GET_USERS = "GET_USERS";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_USERS_ERROR = "GET_USERS_ERROR";
const UPDATE_USERS = "UPDATE_USERS";

export const getUsersData = (officeId) => ({ type: GET_USERS, officeId });
export const updateUserData = (key, value, data) => ({ type: UPDATE_USERS, key, value, data });

function* getUsersDataSaga(action) {
  const officeId = action.officeId;
  try {
    const payload = yield call(getUsersInfo, officeId);
    yield put({
      type: GET_USERS_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_USERS_ERROR,
      error: true,
      e,
    });
  }
}

export function* usersSaga() {
  yield takeEvery(GET_USERS, getUsersDataSaga);
}

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_USERS:
      return state;
    case GET_USERS_SUCCESS:
      return action.payload;
    case GET_USERS_ERROR:
      return state;
    case UPDATE_USERS:
      return state.map((row) => (row[action.key] === action.value ? { ...row, ...action.data } : row));
    default:
      return state;
  }
};

export default usersReducer;
