/* eslint-disable react-hooks/exhaustive-deps */
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import db from "../firebase";

export async function getUserInfo(uid) {
  const docRef = await getDoc(doc(db, "Users", uid));
  if (docRef.exists) {
    return { ...docRef.data(), id: docRef.id };
  }
  return { error: true };
}

export function createUserInfo(uid, data) {
  return setDoc(doc(db, "Users", uid), data);
}

export function updateUserInfo(uid, data) {
  return setDoc(doc(db, "Users", uid), data, { merge: true });
}

export function deleteUserInfo(uid) {
  return deleteDoc(doc(db, "Users", uid));
}

export async function getUsersInfo(officeId) {
  const userCollection = officeId
    ? query(collection(db, "Users"), orderBy("name", "asc"))
    : query(collection(db, "Users"), where("office", "==", officeId), orderBy("name", "asc"));
  const querySnapshot = await getDocs(userCollection);
  let userArray = [];
  querySnapshot.forEach((doc) => userArray.push({ ...doc.data(), id: doc.id }));
  return userArray;
}

export async function updateUsersAge(officeId) {
  const userCollection = officeId
    ? query(collection(db, "Users"), orderBy("name", "asc"))
    : query(collection(db, "Users"), where("office", "==", officeId), orderBy("name", "asc"));
  const querySnapshot = await getDocs(userCollection);
  let userArray = [];
  querySnapshot.forEach((doc) => userArray.push({ ...doc.data(), id: doc.id }));
  userArray.forEach((doc) => doc.age && updateUserInfo(doc.id, { age: doc.age + 1 }));
  return userArray;
}
