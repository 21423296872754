import { useRef } from "react";
import { Button, Container, Dropdown, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/growing_symbol_sm.png";

// 학생용 내비게이션 바
const GrowingNavT = (props) => {
  // props
  // GrowingNav.js 에서 넘어온 로그아웃 함수
  const handleLogout = props.handleLogout;

  const offCanvasRef = useRef();

  const closeOffCanvas = () => offCanvasRef.current.backdrop.click();

  // reducers
  // firebase 로그인 정보 reducer
  const currentUser = useSelector((state) => state.currentUserReducer).accountInfo;
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // 로그인 정보가 없거나 허가 되지 않은 사용자이거나 선생님이 아닐 경우 사용할 수 없도록 하는 조건
  const canUse = currentUser.uid && myInfo.allow === true && myInfo.teacher === true;

  return (
    <Navbar className="growing-navbar" variant="light" expand={false} fixed="top">
      <Container>
        {canUse ? (
          <>
            <Navbar.Brand as={Link} to="/teachingcenter/home" className="nav-title">
              <img src={logo} alt="logo" />
              <strong className="text-title">Teaching Center</strong>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas id="offcanvasNavbar-expand-false" aria-labelledby="offcanvasNavbar-expand-false" placement="start" ref={offCanvasRef}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbar-expand-false">{myInfo.name + (myInfo.teacher && " 선생")}님, 안녕하세요.</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <hr />
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} to="/teachingcenter" onClick={closeOffCanvas}>
                    Home
                  </Nav.Link>
                  <NavDropdown title="원생" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/teachingcenter/students" onClick={closeOffCanvas}>
                      그래프
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/teachingcenter/students/list" onClick={closeOffCanvas}>
                      명단 및 출석부
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="테스트" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/teachingcenter/test/reading" onClick={closeOffCanvas}>
                      본문
                    </NavDropdown.Item>
                    <Dropdown.Divider />
                    <NavDropdown.Item as={Link} to="/teachingcenter/test/input" onClick={closeOffCanvas}>
                      점수 입력
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/teachingcenter/test/modify" onClick={closeOffCanvas}>
                      점수 수정
                    </NavDropdown.Item>
                    {myInfo.admin && (
                      <>
                        <Dropdown.Divider />
                        <NavDropdown.Item as={Link} to="/teachingcenter/admin/uploadpassage" onClick={closeOffCanvas}>
                          본문 업로드 (관리자)
                        </NavDropdown.Item>
                      </>
                    )}
                  </NavDropdown>
                  <NavDropdown title="퀴즈" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/teachingcenter/quiz/reviewnote" onClick={closeOffCanvas}>
                      오답 노트
                    </NavDropdown.Item>
                    <Dropdown.Divider />
                    <NavDropdown.Item as={Link} to="/teachingcenter/quiz/input" onClick={closeOffCanvas}>
                      퀴즈 결과 입력
                    </NavDropdown.Item>
                    {myInfo.admin && (
                      <>
                        <Dropdown.Divider />
                        <NavDropdown.Item as={Link} to="/teachingcenter/admin/modifyquestion" onClick={closeOffCanvas}>
                          문제 수정 (관리자)
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/teachingcenter/admin/uploadquestion" onClick={closeOffCanvas}>
                          문제 업로드 (관리자)
                        </NavDropdown.Item>
                      </>
                    )}
                  </NavDropdown>
                  <NavDropdown title="컴퍼티션" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/teachingcenter/competition" onClick={closeOffCanvas}>
                      게임 시작
                    </NavDropdown.Item>
                    <Dropdown.Divider />
                    <NavDropdown.Item as={Link} to="/teachingcenter/competition/modify" onClick={closeOffCanvas}>
                      게임 결과 수정
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/teachingcenter/schedule" onClick={closeOffCanvas}>
                    스케쥴
                  </Nav.Link>
                  <Nav.Link as={Link} to="/teachingcenter/tool" onClick={closeOffCanvas}>
                    도구
                  </Nav.Link>
                  {myInfo.admin === true && (
                    <Nav.Link as={Link} to="/teachingcenter/admin" onClick={closeOffCanvas}>
                      관리자
                    </Nav.Link>
                  )}
                  {myInfo.admin === true && (
                    <Nav.Link as={Link} to="/teachingcenter/system" onClick={closeOffCanvas}>
                      시스템
                    </Nav.Link>
                  )}
                  <hr />
                  <Nav.Link as={Link} to="/teachingcenter/mypage" onClick={closeOffCanvas}>
                    마이페이지
                  </Nav.Link>
                  <hr />
                  <Nav.Link onClick={handleLogout}>로그아웃</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </>
        ) : (
          <>
            <Navbar.Brand className="nav-title">
              <img src={logo} alt="logo" />
              <strong className="text-title">Teaching Center</strong>
            </Navbar.Brand>
            <Button disabled={!currentUser.uid} variant="light" onClick={handleLogout}>
              {currentUser.uid ? "로그아웃" : "로그인이 필요합니다"}
            </Button>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default GrowingNavT;
