import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { keyQuestionOption } from "../../../../redux/QuestionOption/reducer";

const InputShortAnswer = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const changeAnswer = (e) =>
    dispatch(keyQuestionOption("answer", e.target.value));

  return (
    <>
      <p className="Form-label"> Answer </p>
      <Form.Control
        as="textarea"
        rows={4}
        value={questionOption.answer}
        style={{ background: "lightblue" }}
        onChange={(e) => changeAnswer(e)}
      />
      {questionOption.answer === "" && <RequiredText />}
    </>
  );
};

export default InputShortAnswer;
