import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { rowColor } from "../../../Methods";
import ChangeTextForm from "../../SelectTable/ChangeTextForm";
import CheckAllModifyButton from "../../SelectTable/CheckAllModifyButton";
import CheckModifyButton from "../../SelectTable/CheckModifyButton";

const InputTable = () => {
  // inputData reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <>
      {inputData.length !== 0 && (
        <Table hover striped size="sm" bordered className="InputTable">
          <thead>
            <tr>
              <CheckAllModifyButton check="modify" width="10%" />
              <th width="10%">Category</th>
              <th width="5%">Set</th>
              <th width="5%">No.</th>
              <th width="30%">eng</th>
              <th width="30%">kor</th>
              <CheckAllModifyButton check="delete" width="10%" />
            </tr>
          </thead>
          <tbody>
            {inputData.map((row, i) => (
              <tr key={i} className={rowColor(row)}>
                <CheckModifyButton row={row} type="modify" />
                <td>{row.category}</td>
                <td>{row.set}</td>
                <td>{row.number}</td>
                <td>
                  <ChangeTextForm
                    objectKey="eng"
                    row={row}
                    placeholder="단어"
                    disabled={!row.modify}
                  />
                </td>
                <td>
                  <ChangeTextForm
                    objectKey="kor"
                    row={row}
                    placeholder="해석"
                    disabled={!row.modify}
                  />
                </td>
                <CheckModifyButton
                  row={row}
                  type="delete"
                  disabled={!row.modify}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default InputTable;
