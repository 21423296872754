const MonthLabel = () => {
  return (
    <>
      <h5
        style={{
          float: "right",
          fontSize: "1.5vh",
          color: "#06a001",
        }}
      >
        이번달
      </h5>
      <h5
        className="mx-3"
        style={{
          float: "right",
          fontSize: "1.5vh",
          color: "#9b22fd",
        }}
      >
        지난달
      </h5>
    </>
  );
};

export default MonthLabel;
