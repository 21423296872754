import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../../../redux/InputData/actions";

const CheckParagraphBtn = (props) => {
  const row = props.row;

  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  const changeParagraph = () => {
    !disabled && dispatch(inputDataKey("paragraph", row.id, !row.paragraph));
  };

  return (
    <td onClick={changeParagraph} className={disabled ? "" : "pointer"}>
      <Button
        variant="light"
        className={row.paragraph ? "px-1" : "px-2"}
        style={{ color: "black" }}
        disabled={disabled}
      >
        {row.paragraph ? "✔︎" : " "}
      </Button>
    </td>
  );
};

export default CheckParagraphBtn;
