import {
  QUIZ_SETUPLOADDATA,
  QUIZ_SETUPLOADDATA_SUCCESS,
  QUIZ_SETUPLOADDATA_ERROR,
  QUIZ_CLEARUPLOADDATA,
} from "./types";

const uploadData = [];

const quizUploadDataReducer = (state = uploadData, action) => {
  // state 의 초깃값을 league 으로 지정했습니다.
  switch (action.type) {
    case QUIZ_SETUPLOADDATA:
      return state;
    case QUIZ_SETUPLOADDATA_SUCCESS:
      return action.uploadData;
    case QUIZ_SETUPLOADDATA_ERROR:
      return state;
    case QUIZ_CLEARUPLOADDATA:
      return [];
    default:
      return state;
  }
};

export default quizUploadDataReducer;
