import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { displayDataPhone } from "../../redux/DisplayData/actions";

const DisplayChangePhone = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const changePhone = (e) => dispatch(displayDataPhone(e.target.value));

  return (
    <>
      <p className="Form-label"> 전화번호 </p>
      <Form.Control
        type="text"
        value={displayData.phone || 0}
        onChange={(e) => changePhone(e)}
      />
      {!displayData.phone && <RequiredText />}
    </>
  );
};

export default DisplayChangePhone;
