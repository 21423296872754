import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import db from "../firebase";

/**
 * @typedef wordQuiz
 * @property {string} id
 * @property {string} cid - 반 ID
 * @property {string} scheduleId - 스케쥴 id
 * @property {string} sid - 학생의 id
 * @property {number} correct - 정답 갯수
 * @property {number} score - 점수
 * @property {string} detail - detail
 */

/**
 * @description 전체 wordQuiz list 불러오기
 * @returns {wordQuiz[]}
 */
export async function getWordQuizListAPI() {
  const wordQuizCollection = query(collection(db, "WordQuiz"));
  const querySnapshot = await getDocs(wordQuizCollection);
  /** @type {wordQuiz[]} */
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  return wordQuizArray;
}

/**
 * @description 사용자의 sid를 이용해 wordQuiz list 불러오기
 * @param {string} sid
 * @returns {wordQuiz[]}
 */
export async function getMyWordQuizListAPI(sid) {
  const wordQuizCollection = query(collection(db, "WordQuiz"), where("sid", "==", sid));
  const querySnapshot = await getDocs(wordQuizCollection);

  /** @type {wordQuiz[]} */
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  return wordQuizArray;
}

/**
 * @description cid, date를 이용해 wordQuiz list 불러오기
 * @param {string} sid
 * @returns {wordQuiz[]}
 */
export async function getWordQuizRankingByCidAndDateAPI(cid, date, callback) {
  const wordQuizCollection = query(collection(db, "WordQuiz"), where("cid", "==", cid), where("date", "==", date));
  const querySnapshot = await getDocs(wordQuizCollection);

  /** @type {wordQuiz[]} */
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  callback(wordQuizArray);
}

/**
 * @description cid, date를 이용해 wordQuiz list 불러오기
 * @param {string} sid
 * @returns {wordQuiz[]}
 */
export async function getWordQuizRankingByDateAPI(date, callback) {
  const wordQuizCollection = query(collection(db, "WordQuiz"), where("date", "==", date));
  const querySnapshot = await getDocs(wordQuizCollection);

  /** @type {wordQuiz[]} */
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  callback(wordQuizArray);
}

/**
 * @description wordQuiz 생성
 * @param {wordQuiz} data
 * @returns {void}
 */
export async function createWordQuizAPI(data) {
  await addDoc(collection(db, "WordQuiz"), data);
}

/**
 * @description update wordQuiz
 * @param {string} wordQuizId
 * @param {wordQuiz} data
 * @returns {void}
 */
export async function updateWordQuizAPI(wordQuizId, data) {
  const { id, ...updateData } = data;
  await setDoc(doc(db, "WordQuiz", wordQuizId), updateData, { merge: true });
}

/**
 * @description delete wordQuiz
 * @param {string} wordQuizId
 * @returns {void}
 */
export async function deleteWordQuizAPI(wordQuizId) {
  await deleteDoc(doc(db, "WordQuiz", wordQuizId));
}

export async function setWordQuizIfHighScoreAPI(payload) {
  const { sid, cid, date, correct, score } = payload;
  const wordQuizCollection = query(collection(db, "WordQuiz"), where("sid", "==", sid), where("cid", "==", cid), where("date", "==", date));
  const querySnapshot = await getDocs(wordQuizCollection);

  /** @type {wordQuiz[]} */
  let isHighScore = true;
  let [highScore, highCorrect] = [score, correct];
  let updateId = "";
  console.log(querySnapshot.size);
  if (!querySnapshot.size) {
    createWordQuizAPI(payload);
    return;
  }
  querySnapshot.forEach((doc) => {
    updateId = doc.id;
    if (highScore < doc.data().score || (highScore === doc.data().score && highCorrect < doc.data().correct)) {
      isHighScore = false;
      [highScore, highCorrect] = [doc.data().score, doc.data().correct];
    }
  });
  if (isHighScore) {
    updateWordQuizAPI(updateId, payload);
  }

  // callback({ isHighScore, highScore, highCorrect });
}
