import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  modalShowAdd,
  modalShowModify,
} from "../../../redux/Modal_Show/actions";

// 관리자가 스케쥴을 수정하기 위해 사용하는 버튼 컴포넌트
const ModifyButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer);

  // onClick 함수
  // Modal 창을 수정 용도로 사용하기 위한 함수
  const onModify = () => {
    // modalShow reducer의 add state를 false로
    dispatch(modalShowAdd(false));
    // modalShow reducer의 modify state를 true로
    dispatch(modalShowModify(true));
  };

  // 수정 버튼 컴포넌트
  // modalShow reducer의 modify state가 false이고 관리자일 경우에만 display
  const modifyButton = myInfo.admin && !show.modify && (
    <Modal.Footer>
      <Button className="Growing-button" onClick={onModify}>
        수정
      </Button>
    </Modal.Footer>
  );

  return <>{modifyButton}</>;
};

export default ModifyButton;
