import * as inputObj from "./object";
import * as inputFn from "./function";
import { leagueLable } from "./function";
import * as inputType from "./types";

const inputDataReducer = (state = [], action) => {
  switch (action.type) {
    // 초기화
    case inputType.INPUTDATA_CLEAR:
      return [];
    // 행 추가
    case inputType.INPUTDATA_CREATE:
      switch (action.createType) {
        case "competition":
          return [...state, inputObj.newRowCompetition(state, action.payload)];
        case "schedule":
          return [...state, inputObj.newRowSchedule(state)];
        case "scheduleUdt":
          return [...state, inputObj.newRowScheduleUdt(state, action.payload)];
        case "test":
          return [...state, inputObj.newRowTest(state)];
        case "carousel":
          return [...state, inputObj.newRowCarousel(state, action.payload)];
        case "todo":
          return [...state, inputObj.newRowTodo(state)];
        default:
          return state;
      }
    // 행 삭제
    case inputType.INPUTDATA_DELETE:
      const newInputData = state.filter(({ id }) => id !== action.id);
      switch (action.createType) {
        case "competition":
          return inputObj.deleteRowCompetition({ state: newInputData, payload: action.payload });
        default:
          return newInputData.map((row, i) => inputObj.deleteRowDefault(row, i));
      }
    // 행의 순위권 변경
    case inputType.INPUTDATA_PODIUM:
      return state.map((row) =>
        row.id === action.id
          ? {
              ...row,
              podium: inputObj.returnPodium(action.payload),
            }
          : row,
      );
    // payload로 넘어온 Array를 InputData로 입력
    case inputType.INPUTDATA_SET:
      return action.payload;
    // KEY를 입력하여 그 KEY에 해당하는 값 수정
    case inputType.INPUTDATA_KEY:
      return state.map((row) =>
        row.id === action.id
          ? {
              ...row,
              [action.key]: action.payload,
            }
          : row,
      );
    // 프리셋에서 받아온 데이터를 InputData로 입력
    case inputType.INPUTDATA_PRESET_LOAD:
      return action.payload.map((res, i) => ({
        id: i,
        sid: res.sid,
        name: res.name,
      }));
    // 데이터를 수정/삭제하겠다고 체크
    case inputType.INPUTDATA_CHECK:
      return action.check === "modify"
        ? state.map((row) => {
            if (row.id === action.id) {
              return {
                ...row,
                modify: !row.modify,
                delete: false,
              };
            } else return row;
          })
        : state.map((row) => {
            if (row.id === action.id) {
              return {
                ...row,
                delete: !row.delete,
              };
            } else return row;
          });
    // 전체 데이터를 수정/삭제하겠다고 체크
    case inputType.INPUTDATA_ALL_CHECK:
      return action.check === "modify"
        ? state.map((row) => ({
            ...row,
            modify: action.payload,
            delete: action.payload === false ? false : row.delete,
          }))
        : state.map((row) => ({
            ...row,
            modify: action.payload,
            delete: action.payload,
          }));
    // 체크 해제 및 삭제된 데이터 제거
    case inputType.INPUTDATA_MODIFY_ARRAY:
      switch (action.payload) {
        case "competition":
          return inputObj.modifyArrayCompetition(state);
        default:
          return inputObj.modifyArrayDefault(state);
      }

    // Competition
    // 행에 순위 추가 및 변경
    case inputType.INPUTDATA_COM_RANK:
      return inputObj.changeRankArray(state, action.id, action.rank, action.league);
    // 행의 순위를 사용하여 리그 자동 리턴
    case inputType.INPUTDATA_COM_LEAGUE:
      return state.map((row) => ({
        ...row,
        league: leagueLable(row.realRank, action.payload),
      }));
    // Competiton 테이블 정렬
    case inputType.INPUTDATA_COM_SORT:
      switch (action.sortType) {
        // 평균으로 정렬
        case "average":
          return inputObj.sortAvgCompetition(state, action.payload);
        // 평균으로 정렬
        case "quiz":
          return inputObj.sortQuizCompetition(state, action.payload);
        // 순위로 정렬
        case "rank":
          return inputObj.sortRankCompetition(state, action.payload);
        // 새로운 플레이어는 평균, 기존 플레이어는 순위로 정렬
        case "newPlayer":
          return inputObj.sortNPCompetition(state, action.payload);
        default:
          return state;
      }
    // Competition Saga
    // 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기
    case inputType.INPUTDATA_COM_LOADDATA:
      return state;
    // 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 성공 시
    case inputType.INPUTDATA_COM_LOADDATA_SUCCESS:
      return inputObj.sortRankCompetition(action.loadData);
    // 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 실패 시
    case inputType.INPUTDATA_COM_LOADDATA_ERROR:
      return state;
    // 행에 학생 추가 및 변경 + 평균 불러오기
    case inputType.INPUTDATA_COM_SID:
      return state;
    // 행에 학생 추가 및 변경 + 평균 불러오기 성공 시
    case inputType.INPUTDATA_COM_SID_SUCCESS:
      return inputObj.sortRankCompetition(
        state.map((row) => {
          if (row.id === action.id)
            return {
              ...row,
              sid: action.payload.id,
              name: action.payload.name,
              average: action.average.average,
              quizRank: action.average.quizRank,
              finalRank: action.average.finalRank,
            };
          else return row;
        }),
      );
    // 행에 학생 추가 및 변경 + 평균 불러오기 실패 시
    case inputType.INPUTDATA_COM_SID_ERROR:
      return state.map((row) => {
        if (row.id === action.id)
          return {
            ...row,
            sid: action.payload.id,
            name: action.payload.name,
          };
        else return row;
      });
    // 수정할 데이터 로드
    case inputType.INPUTDATA_COM_MODIFY_SET:
      return state;
    // 수정할 데이터 로드 성공 시
    case inputType.INPUTDATA_COM_MODIFY_SET_SUCCESS:
      return action.payload;
    // 수정할 데이터 로드 실패 시
    case inputType.INPUTDATA_COM_MODIFY_SET_ERROR:
      return state;

    // Schedule
    // 평일 불러오기
    case inputType.INPUTDATA_SCHEDULE_DAYSET:
      return inputFn.dayArray(action.selOption);
    // Schedule Update
    // 해당 조건에 맞는 스케쥴 불러오기
    case inputType.INPUTDATA_SCHEDULE_UDT_DATASET:
      return state;
    // 해당 조건에 맞는 스케쥴 불러오기 성공 시
    case inputType.INPUTDATA_SCHEDULE_UDT_DATASET_SUCCESS:
      return action.payload;
    // 해당 조건에 맞는 스케쥴 불러오기 실패 시
    case inputType.INPUTDATA_SCHEDULE_UDT_DATASET_ERROR:
      return state;

    // Test
    // 수정할 데이터 로드
    case inputType.INPUTDATA_TEST_MODIFY_SET:
      return state;
    // 수정할 데이터 로드 성공 시
    case inputType.INPUTDATA_TEST_MODIFY_SET_SUCCESS:
      return action.payload;
    // 수정할 데이터 로드 실패 시
    case inputType.INPUTDATA_TEST_MODIFY_SET_ERROR:
      return state;

    // Quiz
    // Quiz 행 생성
    case inputType.INPUTDATA_QUIZ_CREATE:
      return state;
    // Quiz 행 생성
    case inputType.INPUTDATA_QUIZ_CREATE_SUCCESS:
      return action.modify === "create"
        ? [...state.map((row, i) => ({ ...row, id: i })), { id: state.length, ...action.payload }]
        : state.map((row) => {
            if (row.id === action.payload.id) return { id: row.id, ...action.payload };
            else return row;
          });
    // Quiz 행 생성
    case inputType.INPUTDATA_QUIZ_CREATE_ERROR:
      return state;
    // Quiz Upload Saga
    // Quiz Excel 파일에서 업로드
    case inputType.INPUTDATA_QUIZ_UPLOAD:
      return state;
    // Quiz Excel 파일에서 업로드 성공 시
    case inputType.INPUTDATA_QUIZ_UPLOAD_SUCCESS:
      return [...state, ...action.payload];
    // Quiz Excel 파일에서 업로드 실패 시
    case inputType.INPUTDATA_QUIZ_UPLOAD_ERROR:
      return state;
    // Question modify saga
    // 수정할 Question 로드
    case inputType.INPUTDATA_QUESTION_MODIFY:
      return state;
    // 수정할 Question 로드 성공 시
    case inputType.INPUTDATA_QUESTION_MODIFY_SUCCESS:
      return action.payload;
    // 수정할 Question 로드 실패 시
    case inputType.INPUTDATA_QUESTION_MODIFY_ERROR:
      return [{ error: action.error }];

    // words modify saga
    // 수정할 Data 로드
    case inputType.INPUTDATA_WORDS_MODIFY_SET:
      return state;
    // 수정할 Data 로드 성공 시
    case inputType.INPUTDATA_WORDS_MODIFY_SET_SUCCESS:
      return action.payload;
    // 수정할 Data 로드 실패 시
    case inputType.INPUTDATA_WORDS_MODIFY_SET_ERROR:
      return state;

    default:
      return state;
  }
};

export default inputDataReducer;
