import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";

const BtnToggle = () => {
  const dispatch = useDispatch();

  const selOption = useSelector((state) => state.selOptionReducer);

  const loading = useSelector((state) => state.loadingReducer).component;

  const comArray = useSelector((state) => state.statArrayReducer).comArray;

  const testArray = useSelector((state) => state.statArrayReducer).testArray;

  const stat = useSelector((state) => state.statArrayReducer).stat.quiz;
  const sumArray = (acc, cur) => acc + cur;
  const sumQuizStat =
    stat.GRA.reduce(sumArray, 0) +
      stat.GRB.reduce(sumArray, 0) +
      stat.LIS.reduce(sumArray, 0) +
      stat.TEP.reduce(sumArray, 0) !==
    0;

  const changeToggle = (key) => dispatch(selOptionKey(key, !selOption[key]));

  const variant = (key) => (selOption[key] ? "primary" : "secondary");

  return (
    <ButtonGroup className="my-2">
      <Button
        variant={variant("toggleCom")}
        onClick={() => changeToggle("toggleCom")}
        disabled={loading || comArray.length === 0}
      >
        Competition
      </Button>
      <Button
        variant={variant("toggleTest")}
        onClick={() => changeToggle("toggleTest")}
        disabled={loading || testArray.length === 0}
      >
        Test
      </Button>
      <Button
        variant={variant("toggleQuiz")}
        onClick={() => changeToggle("toggleQuiz")}
        disabled={loading || !sumQuizStat}
      >
        Quiz
      </Button>
    </ButtonGroup>
  );
};

export default BtnToggle;
