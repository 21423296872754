import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";

const SelectPageSingle = (props) => {
  // props
  // 범위의 최솟값
  const min = props.min;
  // 범위의 최댓값
  const max = props.max;

  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const changeDisplaySet = (number) => {
    const returnPageNum = () => {
      const calculatePageNum = selOption.displayPage + number;
      switch (true) {
        case calculatePageNum < min:
          return min;
        case calculatePageNum > max:
          return max;
        default:
          return calculatePageNum;
      }
    };
    dispatch(selOptionKey("displayPage", returnPageNum()));
  };

  const changeDisplaySetM = (type) => {
    const returnPageNum = type === "min" ? min : max;
    dispatch(selOptionKey("displayPage", returnPageNum));
  };

  const disabledCondition = (type) => {
    return type === "min"
      ? selOption.displayPage <= min
      : selOption.displayPage >= max;
  };

  return (
    <ButtonGroup>
      <Button
        variant="dark"
        onClick={() => changeDisplaySetM("min")}
        disabled={disabledCondition("min")}
      >
        처음
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(-5)}
        disabled={disabledCondition("min")}
      >
        ◀︎◀︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(-1)}
        disabled={disabledCondition("min")}
      >
        ◀︎
      </Button>
      <Button variant="dark" disabled>
        {selOption.displayPage}
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(1)}
        disabled={disabledCondition("max")}
      >
        ▶︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(5)}
        disabled={disabledCondition("max")}
      >
        ▶︎▶︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySetM("max")}
        disabled={disabledCondition("max")}
      >
        마지막
      </Button>
    </ButtonGroup>
  );
};

export default SelectPageSingle;
