/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ControlButton from "../../../../Component/Admin/Word/ControlButton";
import DisplayWordProperty from "../../../../Component/Admin/Word/DisplayWordProperty";
import InputTable from "../../../../Component/Admin/Word/InputTable";
import InputWord from "../../../../Component/Admin/Word/InputWord";
import useRedirect from "../../../../hooks/useRedirect";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";
import { inputDataClear } from "../../../../redux/InputData/actions";
import { selOptionClear } from "../../../../redux/selOption/actions";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const UploadWord = () => {
  const dispatch = useDispatch();

  // firestore에서 schedule list data 다운로드 받는 신호 보내는 토글
  const downloadList = useSelector((state) => state.downloadControlerReducer).scheduleList;

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    !downloadList && dispatch(getFireStoreData("scheduleList"));
    dispatch(selOptionClear("WordInput"));
    dispatch(inputDataClear());
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="g-2 m-2">
            <Col>
              <DisplayWordProperty />
            </Col>
          </Row>
          <Row className="m-2">
            <Col>
              <InputWord />
            </Col>
          </Row>
          <Row className="m-2">
            <Col>
              <ControlButton />
            </Col>
          </Row>
          <Row className="m-2">
            <Col>
              <InputTable />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default UploadWord;
