export const sumObj = (objArr, quiz) => {
  if (quiz === "Kahoot") {
    // an object to store the `created` and `amount` as key=>value
    let newObj = {};
    // loop over the objects in the array
    objArr.forEach(function (obj) {
      // if the key is present in `newObj` then we need to add the amount to it's value
      if (obj.sid + obj.qid + obj.part + obj.name in newObj) {
        newObj[obj.sid + obj.qid + obj.part + obj.name].correct += obj.correct;
        newObj[obj.sid + obj.qid + obj.part + obj.name].answer =
          newObj[obj.sid + obj.qid + obj.part + obj.name].correct === 1
            ? newObj[obj.sid + obj.qid + obj.part + obj.name].answer
            : obj.answer;
      } else {
        // else just add the key and the amount as value
        newObj[obj.sid + obj.qid + obj.part + obj.name] = {
          correct: obj.correct,
          answer: obj.answer,
        };
      }
    });
    //  create an array to store the final objects
    let arr = [];
    // loop over the properties in `newObj`
    for (let prop in newObj) {
      // push an object each time
      arr.push({
        sid: prop.substring(0, 28),
        qid: prop.substring(28, 34),
        part: prop.substring(34, 37),
        name: prop.substring(37).trim(),
        correct: Math.ceil(newObj[prop].correct / 10),
        answer: newObj[prop].answer,
      });
    }
    // return the final result
    return arr;
  } else return objArr;
};
