import { addDoc, collection, doc, increment, setDoc } from "firebase/firestore";
import db from "../../../firebase";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  leagueType,
  useScoreSum,
  useMajor,
  useMinor,
  useTotalRank,
  rank,
} from "./function";
import { loadingAlert, loadingComponent } from "../../../redux/Loading/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { gameArrayClear } from "../../../redux/League_GameArray/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

// 리그 내 게임 결과 업로드시 사용하는 버튼 컴포넌트
const UploadResultButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // select option
  const selOption = useSelector((state) => state.selOptionReducer);
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);
  // league 인원 reducer
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];
  // game 점수 reducer
  const gameArray = useSelector((state) => state.gameArrayReducer)[level];
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);
  // 리그 제목
  const title = "League " + leagueLable[level];

  // function.js에서 사용한 함수
  // 상위 리그 인원의 합
  const major = useMajor(level);
  // 바로 아래 리그의 인원 수
  const minor = useMinor(level);
  // 리그의 타입, (major, minor)에 의해 결정
  const type = leagueType(major, minor);
  // 각 인원의 점수의 합 Array
  const scoreSum = useScoreSum(gameArray);
  // 각 인원의 해당 리그 내 순위
  const nowRank = scoreSum.map((obj) => rank(obj, scoreSum));
  // 각 인원의 게임 전채 순위
  const totalRank = useTotalRank(scoreSum, selOption.promotion, major, type);

  // firebase 업로드시 이용되는 정보
  // 연+월 ex) 2022년 3월 => 2203
  const dateid =
    new Date().getFullYear().toString().slice(-2) +
    ("0" + (new Date().getMonth() + 1)).slice(-2);
  // 연+월+일 ex) 2022년 3월 21일 => 22321
  const attendId =
    new Date().getFullYear().toString() +
    (new Date().getMonth() + 1).toString() +
    new Date().getDate().toString();

  // 업로드 후 실행할 함수
  const clearLoading = () => {
    dispatch(gameArrayClear(leagueRoster.length, level)); // gameArray 초기화
    dispatch(loadingComponent(false)); // component loading false로
    dispatch(loadingAlert(false)); // Alert false로
    dispatch(modalShowPopup(false));
  };

  // onClick 함수
  // firebase upload
  const dataUpload = () => {
    // component loading state true로 변경
    dispatch(loadingComponent(true));
    // alert loading state true로 변경
    dispatch(loadingAlert(true));
    // 리그 인원별 업로드 정보
    leagueRoster.forEach((a, i) => {
      // 해당 월 순위 평균 업로드
      setDoc(
        doc(db, "Users", a.sid, "competitionStat", dateid),
        {
          [selOption.cid + "sum"]: increment(totalRank[i]),
          [selOption.cid + "count"]: increment(1),
          [selOption.cid + "podium"]: {
            first: nowRank[i] === 1 ? increment(1) : increment(0),
            second: nowRank[i] === 2 ? increment(1) : increment(0),
          },
        },
        { merge: true }
      );
      // 해당 일 출석 체크
      setDoc(doc(db, "Users", a.sid, "attend", attendId), {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: new Date(new Date().toDateString()),
        attend: true,
        sid: a.sid,
      });
      // 순위 기록
      addDoc(collection(db, "Competition"), {
        sid: a.sid,
        name: a.name,
        date: new Date(new Date().toDateString()),
        office: myInfo.office,
        cid: selOption.cid,
        rank: totalRank[i],
        log: new Date(),
        tid: myInfo.uid,
        podium: { first: nowRank[i] === 1, second: nowRank[i] === 2 },
      });
    });
    setTimeout(clearLoading, 1000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  return (
    <>
      <Button
        className="mx-3"
        style={{
          float: "right",
          backgroundColor: "#0b3885",
          color: "whitesmoke",
          border: "#0b3885 solid 1px",
        }}
        disabled={loading}
        onClick={openPopup}
      >
        {loading ? <LoadingSpinner /> : title + " 결과 업로드"}
      </Button>
    </>
  );
};

export default UploadResultButton;
