import {
  MODAL_SHOW_ADD,
  MODAL_SHOW_DISPLAY,
  MODAL_SHOW_MODIFY,
  MODAL_SHOW_LOAD,
  MODAL_SHOW_CLEAR,
  MODAL_SHOW_POPUP,
} from "./types";

const modalShow = {
  display: false,
  add: false,
  modify: false,
  load: false,
  popup: false,
};

const modalShowReducer = (state = modalShow, action) => {
  switch (action.type) {
    case MODAL_SHOW_DISPLAY: {
      return { ...state, display: action.payload };
    }
    case MODAL_SHOW_ADD: {
      return { ...state, add: action.payload };
    }
    case MODAL_SHOW_MODIFY: {
      return { ...state, modify: action.payload };
    }
    case MODAL_SHOW_LOAD: {
      return { ...state, load: action.payload };
    }
    case MODAL_SHOW_POPUP: {
      return { ...state, popup: action.payload };
    }
    case MODAL_SHOW_CLEAR: {
      return modalShow;
    }
    default:
      return state;
  }
};

export default modalShowReducer;
