import { QUIZ_NAMEBEFORE, QUIZ_NAMEAFTER, QUIZ_NAMEOPTIONCLEAR } from "./types";

const nameOption = {
  before: null,
  sid: null,
  after: null,
};

const quizNameOptionReducer = (state = nameOption, action) => {
  // state 의 초깃값을 inputData 으로 지정했습니다.
  switch (action.type) {
    case QUIZ_NAMEBEFORE:
      return { ...state, before: action.payload.name };
    case QUIZ_NAMEAFTER:
      return { ...state, after: action.payload.name, sid: action.payload.id };
    case QUIZ_NAMEOPTIONCLEAR:
      return nameOption;
    default:
      return state;
  }
};

export default quizNameOptionReducer;
