/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingComponent } from "../../../redux/Loading/actions";
import { useRef } from "react";
import { inputDataQuizUpload } from "../../../redux/InputData/actions";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // loading reducer
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputdata reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const fileRef = useRef();

  const templateUrl =
    "https://firebasestorage.googleapis.com/v0/b/growingenglish-fd3a1.appspot.com/o/Question%20Input%20Template.xlsx?alt=media&token=4a79a163-1ede-4d88-977f-030762636ea4";

  const updateFile = async (e) => {
    dispatch(loadingComponent(true));
    dispatch(inputDataQuizUpload(e, inputData.length));
    setTimeout(() => {
      dispatch(loadingComponent(false));
      fileRef.current.value = "";
    }, 1000);
  };

  return (
    <Row className="g-4 pb-2 mx-3 mb-3">
      <Col>
        <Button
          href={templateUrl}
          style={{ float: "right" }}
          className="Growing-button"
        >
          템플릿 다운로드
        </Button>
        <p className="Form-label"> 파일 (.xls/ .xlsx만 가능) </p>
        <Form.Control
          disabled={loading}
          ref={fileRef}
          type="file"
          accept=".xls, .xlsx"
          onChange={(e) => updateFile(e)}
        />
      </Col>
    </Row>
  );
};

export default ControlButton;
