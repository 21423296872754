import styled from "@emotion/styled";
import { Colors, OpacityColors } from "styles/colors";
import { Button1 } from "styles/typography";
import { ButtonVariant } from "types";

const buttonStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case "primary":
      return {
        color: Colors.white,
        background: Colors.blue1,

        disabledColor: OpacityColors.whiteOpacity20,
        disabledBackground: OpacityColors.blue1Opacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.blue3,
      };
    case "secondary":
      return {
        color: Colors.black,
        border: `2px solid ${Colors.blue1}`,
        background: Colors.white,

        disabledColor: OpacityColors.blackOpacity20,
        disabledBorder: `2px solid ${OpacityColors.blue1Opacity20}`,
        disabledBackground: OpacityColors.whiteOpacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.blue1,
      };
    case "outline":
      return {
        color: Colors.black,
        border: `2px solid ${Colors.gray4}`,
        background: Colors.white,

        disabledColor: OpacityColors.blackOpacity20,
        disabledBorder: `2px solid ${OpacityColors.gray4Opacity20}`,
        disabledBackground: OpacityColors.whiteOpacity20,

        hoverColor: Colors.black,
        hoverBackground: Colors.gray4,
      };
  }
};

export const Container = styled.button<{ variant: ButtonVariant; disabled?: boolean }>`
  width: 100%;

  border: ${({ variant }) => buttonStyles(variant).border ?? "none"};
  border-radius: 8px;

  padding: ${({ variant }) => (variant === "primary" ? "16px 0" : "12px 0")};

  background-color: ${({ variant }) => buttonStyles(variant).background};
  color: ${({ variant }) => buttonStyles(variant).color};
  ${Button1};

  &:disabled {
    background-color: ${({ variant }) => buttonStyles(variant).disabledBackground};
    border: ${({ variant }) => buttonStyles(variant).disabledBorder ?? "none"};
    color: ${({ variant }) => buttonStyles(variant).disabledColor};
  }
  &:hover {
    ${({ variant, disabled }) =>
      disabled
        ? ""
        : `
        background-color: ${buttonStyles(variant).hoverBackground};
        color: ${buttonStyles(variant).hoverColor};
    `}
  }
  &:active {
    ${({ variant, disabled }) =>
      disabled
        ? ""
        : `
        background-color: ${buttonStyles(variant).hoverBackground};
        color: ${buttonStyles(variant).hoverColor};
    `}
  }
`;
