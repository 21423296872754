import { useSelector } from "react-redux";

export const useStudentList = () => {
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  const users = useSelector((state) => state.usersReducer);

  return users.filter(
    (v) => v.teacher === false && v.state === true && v.office === myInfo.office
  );
};

export const useTeacherList = () => {
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  const users = useSelector((state) => state.usersReducer);

  return users.filter(
    (v) => v.teacher === true && v.state === true && v.office === myInfo.office
  );
};
