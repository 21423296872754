/* eslint-disable react-hooks/exhaustive-deps */
import "../../../../App.css";
import { Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OptionAccordion from "../../../../Component/Test/Input/OptionAccordion";
import UploadButton from "../../../../Component/Test/Input/UploadButton";
import { selOptionClear } from "../../../../redux/selOption/actions";
import InputTable from "../../../../Component/Test/Input/InputTable";
import AlertCard from "../../../../Component/Test/Input/UploadAlert";
import { loadingFalse } from "../../../../redux/Loading/actions";
import SavePresetModal from "../../../../Component/StudentPreset/Save/SavePresetModal";
import LoadPresetModal from "../../../../Component/StudentPreset/Load/LoadPresetModal";
import { modalShowClear } from "../../../../redux/Modal_Show/actions";
import { inputDataClear } from "../../../../redux/InputData/actions";
import usePageBlock from "../../../../hooks/usePageBlock";
import useRedirect from "../../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

function TestInput() {
  const dispatch = useDispatch();

  // Reducer
  // test Input 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(selOptionClear("TestInput"));
    dispatch(inputDataClear());
    dispatch(loadingFalse());
    dispatch(modalShowClear());
  }, []);

  usePageBlock(inputData.length);

  return (
    <BasicLayout headerType="teacher" headerText="점수 입력">
      <S.Container>
        <S.ContentContainer>
          <OptionAccordion />
          <Row className="g-4 mb-5 px-2 pb-2 mx-3">
            <Col>
              <UploadButton />
              <InputTable />
            </Col>
          </Row>
          <AlertCard />
          <SavePresetModal />
          <LoadPresetModal />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}
export default TestInput;
