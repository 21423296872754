/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../../redux/selOption/actions";

const DropdownSubject = () => {
  // dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // select option reducer
  const testScore = useSelector((state) => state.statArrayReducer).testArray;
  // loading reducer component state
  const loading = useSelector((state) => state.loadingReducer).component;

  let testScoreSubject = testScore
    .map(function (val, index) {
      return val["subject"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ subject: row }));

  const changeSubject = (row) => dispatch(selOptionKey("subject", row.subject));

  useEffect(() => {
    testScoreSubject[0] !== []
      ? changeSubject(testScoreSubject[0])
      : changeSubject({ subject: "Listening" });
  }, [loading]);

  return (
    <DropdownButton
      variant="light"
      className="App-left mb-3"
      title={selOption.subject || "Listening"}
      size="sm"
    >
      {testScoreSubject.map((row) => {
        return (
          <Dropdown.Item
            key={row.subject}
            eventKey={row.subject}
            onClick={() => changeSubject(row)}
          >
            {row.subject}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default DropdownSubject;
