export const SET_TOGGLE = "SET_TOGGLE";

export const setToggle = ({ loading, modal }, toggle) => ({
  type: SET_TOGGLE,
  loading,
  modal,
  toggle,
});

const defaultState = {
  loading: {
    main: true,
    component: false,
    alert: false,
    active: false,
    auto: false,
    write: false,
    warning: false,
    danger: false,
  },
  modal: {
    display: false,
    add: false,
    modify: false,
    load: false,
    popup: false,
  },
};

const toggleStateReducer = (state = defaultState, action) => {
  const setValue = () => {
    const newState = (k) => {
      const newKey = { ...state[k] };
      if (!action[k]) return newKey;
      if (typeof action[k] === "string") {
        newKey[action[k]] = action.toggle;
        return newKey;
      } else {
        action[k].forEach((d) => {
          newKey[action[k]][d] = action.toggle;
        });
        return newKey;
      }
    };
    return { loading: newState("loading"), modal: newState("modal") };
  };

  switch (action.type) {
    case SET_TOGGLE:
      return setValue();
    default:
      return state;
  }
};

export default toggleStateReducer;
