import * as wordOpt from "./types";

const wordOption = {
  date: null,
  cid: null,
  scname: null,
  part: null,
  playTime: 500,
  inputData: [],
};

const wordOptionReducer = (state = wordOption, action) => {
  switch (action.type) {
    // WordPage
    // WordPage에 맞게 초기화
    case wordOpt.WORDPAGE_CLEAR:
      return {
        date: null,
        cid: null,
        scname: null,
        part: null,
        displayPage: 0,
        playTime: 500,
        inputData: [],
      };
    // PlayTime
    case wordOpt.WORDPAGE_PLAYTIME:
      return {
        ...state,
        playTime: action.payload,
      };
    // class 변경 시 cid, scname, part 동시 변경
    case wordOpt.WORDPAGE_DATE:
      return {
        ...state,
        date: action.payload ? action.payload.date : null,
      };
    // class 변경 시 cid, scname, part 동시 변경
    case wordOpt.WORDPAGE_CLASS:
      return {
        ...state,
        cid: action.payload ? action.payload.cid : null,
        scname: action.payload ? action.payload.scname : null,
        part: action.payload ? action.payload.part : null,
      };
    // class 변경 시 cid, scname, part 동시 변경
    case wordOpt.WORDPAGE_KEY:
      return {
        ...state,
        [action.key]: action.payload,
      };
    // 행 추가
    case wordOpt.WORDPAGE_INPUTDATA_CLEAR:
      return {
        ...state,
        inputData: [],
      };
    // 행 추가
    case wordOpt.WORDPAGE_INPUTDATA_CREATE:
      return {
        ...state,
        inputData: [
          ...state.inputData,
          {
            id: state.inputData.length,
            scid: action.payload[0].id,
            category: action.payload[0].name,
            set: action.payload[0].minset.toString(),
            start: 1,
            end: 25,
            minset: action.payload[0].minset,
            maxset: action.payload[0].maxset,
          },
        ],
      };
    // 행 삭제
    case wordOpt.WORDPAGE_INPUTDATA_DELETE:
      state.inputData.splice(action.id, 1);
      return {
        ...state,
        inputData: state.inputData.map((row, i) => {
          if (row.id === action.id)
            return {
              ...row,
              id: i,
            };
          else return row;
        }),
      };
    // 스케쥴 변경 시 해당 스케쥴에 해당하는 정보 설정
    case wordOpt.WORDPAGE_INPUTDATA_SCHEDULE:
      return {
        ...state,
        inputData: state.inputData.map((row) => {
          if (row.id === action.id)
            return {
              ...row,
              scid: action.payload.id,
              category: action.payload.name,
              set: action.payload.minset.toString(),
              minset: action.payload.minset,
              maxset: action.payload.maxset,
            };
          else return row;
        }),
      };
    // 행의 set 변경
    case wordOpt.WORDPAGE_INPUTDATA_SET:
      return {
        ...state,
        inputData: state.inputData.map((row) => {
          if (row.id === action.id)
            return {
              ...row,
              set: action.payload,
            };
          else return row;
        }),
      };
    // 행의 start 변경
    case wordOpt.WORDPAGE_INPUTDATA_START:
      return {
        ...state,
        inputData: state.inputData.map((row) => {
          if (row.id === action.id)
            return {
              ...row,
              start: action.payload,
            };
          else return row;
        }),
      };
    // 행의 end 변경
    case wordOpt.WORDPAGE_INPUTDATA_END:
      return {
        ...state,
        inputData: state.inputData.map((row) => {
          if (row.id === action.id)
            return {
              ...row,
              end: action.payload,
            };
          else return row;
        }),
      };
    default:
      return state;
  }
};

export default wordOptionReducer;
