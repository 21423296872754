import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import ChangeComSchedule from "./ChangeComSchedule";
import ChangeNumber from "./ChangeNumber";
import ChangeSet from "./ChangeSet";

const InputTable = () => {
  // reducers
  const inputData = useSelector((state) => state.wordOptionReducer).inputData;

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Category</th>
          <th style={{ width: "20%" }}>회차</th>
          <th style={{ width: "15%" }}>시작</th>
          <th style={{ width: "15%" }}>끝</th>
        </tr>
      </thead>
      <tbody>
        {inputData.map((row) => (
          <tr key={row.id}>
            <ChangeComSchedule row={row} />
            <ChangeSet row={row} />
            <ChangeNumber row={row} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InputTable;
