import { BeatLoader } from "react-spinners";

// 로딩 시 보여줄 페이지
const LoadingPage = () => {
  return (
    <div className="loader">
      <BeatLoader color="#1155cc" loading={true} size={15} margin={2} />
    </div>
  );
};

export default LoadingPage;
