import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { randomWord } from "../../../redux/FirebaseData/Word/reducer";
import AutoPlayButton from "./AutoPlayButton";
import DisplayAnswer from "./DisplayAnswer";
import DisplayWord from "./DisplayWord";
import PlayTimeRange from "./PlayTimeRange";

const WordCard = () => {
  // redux dispatch
  const dispatch = useDispatch();
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;
  const auto = useSelector((state) => state.loadingReducer).auto;
  /**
   * 단어 넘기기 함수
   */
  const wordRand = () => active && !auto && dispatch(randomWord());

  return (
    <Row>
      <Col>
        <Card key="WordCard" className="card-dark mt-3">
          <Card.Header>
            <PlayTimeRange />
            <AutoPlayButton />
          </Card.Header>
          <Card.Body
            // style={{ height: "40vh" }}
            className={`center-wrap ${active && !auto ? "display-answer" : ""}`}
            onClick={wordRand}
          >
            <DisplayWord />
          </Card.Body>
          {active && (
            <Card.Footer>
              <Row>
                <DisplayAnswer />
              </Row>
            </Card.Footer>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default WordCard;
