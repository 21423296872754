import { Col, Row } from "react-bootstrap";
import DashboardCard from "../../../Component/DashboardCard";
import { useDispatch, useSelector } from "react-redux";
import useRedirect from "../../../hooks/useRedirect";
import * as CardStyles from "../../../styles/CardStyles";
import { useEffect, useState } from "react";
import { getTodoListBySidAndDeadline } from "../../../redux/FirebaseData/Todo/teacherTodoReducer";
import { selOptionClear } from "../../../redux/selOption/actions";
import SelectStudent from "../../../Component/Select/SelectStudent";
import SelectDate from "../../../Component/Select/SelectDate";
import moment from "moment";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

const AdminTodoCheck = () => {
  const dispatch = useDispatch();

  const selOption = useSelector((state) => state.selOptionReducer);
  const todoList = useSelector((state) => state.teacherTodoReducer); // todoList

  const [more, setMore] = useState(false);

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(selOptionClear("CheckTodo"));
  }, [dispatch]);

  useEffect(() => {
    if (!(selOption.sid && selOption.date)) return;
    dispatch(getTodoListBySidAndDeadline(selOption.sid, moment(selOption.date).format("YYYY-MM-DD")));
  }, [dispatch, selOption.date, selOption.sid]);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left">
            <Col>
              <SelectStudent />
              <SelectDate unlimit />
            </Col>
            <Col>
              <CardStyles.CardBox>
                <p style={{ margin: 0 }}>To-do</p>
                <h1 style={{ margin: 0 }}>{todoList.filter((row) => !row.check).length}</h1>
              </CardStyles.CardBox>
            </Col>
          </Row>
          <DashboardCard todoList={todoList} more={more} setMore={setMore} admin />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default AdminTodoCheck;
