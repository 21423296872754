import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RequiredText } from "../../../../Methods";
import { displayDataSubject } from "../../../../redux/DisplayData/actions";
import { subjectsWithCompetition } from "../../../../lib/utils/constants";

// Display될 스케쥴의 과목을 선택하는 Select 컴포넌트
const SelectDisplaySubject = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);

  // 기본값
  const defaultValue = displaySchedule.subject
    ? {
        subject: displaySchedule.subject,
      }
    : null;

  // onChange 함수
  // displaySchedule의 subject 변경
  const changeSubject = (e) => dispatch(displayDataSubject(e));

  return (
    <>
      <p className="Form-label"> 과목 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isSearchable
        options={subjectsWithCompetition}
        getOptionLabel={(e) => e.subject}
        getOptionValue={(e) => e.subject}
        maxMenuHeight={150}
        defaultValue={defaultValue}
        onChange={(e) => changeSubject(e)}
        placeholder="과목을 선택하세요"
      />
      {!displaySchedule.subject && <RequiredText />}
    </>
  );
};

export default SelectDisplaySubject;
