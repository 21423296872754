import { Col, Row, Tab, Tabs } from "react-bootstrap";
import AttendTable from "./AttendTable";
import SelectYearMonthLoadButton from "./SelectYearMonthLoadButton";
import StudentListModal from "./StudentListModal";
import StudentListTable from "./StudentListTable";

const TabList = () => {
  return (
    <Tabs
      defaultActiveKey="StudentList"
      id="uncontrolled-tab-example"
      className="mb-2"
    >
      <Tab eventKey="StudentList" title="명단" key="StudentList">
        <StudentListTable />
        <StudentListModal />
      </Tab>
      <Tab eventKey="Attendant" title="출석부" key="Attendant">
        <Row className="g-4 px-5 mt-2 mb-4">
          <Col lg />
          <SelectYearMonthLoadButton />
          <Col lg />
        </Row>
        <AttendTable />
      </Tab>
    </Tabs>
  );
};

export default TabList;
