import "../../App.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";
import { RequiredText } from "../../Methods";

// 달력을 한국어로
registerLocale("ko", ko);

const SelectDateRange = (props) => {
  // props
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  const dateRange = selOption.dateRange || [null, null];
  const setDateRange = (e) => dispatch(selOptionKey("dateRange", e || null));

  // require message 출력 조건, props로 required가 넘어오고, selOption의 date가 null일 경우
  const returnRequire = required && !selOption.dateRange;

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <DatePicker
        dateFormat="yyyy.MM.dd"
        selectsRange={true}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        minDate={new Date(new Date().setDate(new Date().getDate() - 90))}
        maxDate={new Date()}
        showWeekNumbers
        todayButton="today"
        onChange={setDateRange}
        isClearable
        disabled={disabled}
        locale={ko}
        placeholderText="기간 (최대 90일)"
        className="Date-picker-border"
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectDateRange;
