import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { selOptionKey } from "../../redux/selOption/actions";

// inputData 내의 개별 행 본문 변경을 위해 사용하는 Select 컴포넌트
const SelectTextForm = (props) => {
  // props
  // 제목 출력 여부
  const title = props.title;
  // inputData 내 변경할 key
  const key = props.objectKey;
  // placeholder
  const placeholder = props.placeholder;
  // 비활성화 조건
  const disabled = props.disabled;
  // require message 출력 여부
  const required = props.required;

  // redux dispatch
  const dispatch = useDispatch();

  const selOption = useSelector((state) => state.selOptionReducer);

  // onClick 함수
  // inputData 내 타이틀 변경
  const changeKey = (e) => dispatch(selOptionKey(key, e.target.value));

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Form.Control
        type="text"
        style={{ textAlign: "center" }}
        value={selOption[key] || ""}
        onChange={changeKey}
        placeholder={placeholder || "값을 입력하세요"}
        disabled={disabled}
      />
      {required && !selOption[key] && <RequiredText />}
    </>
  );
};

export default SelectTextForm;
