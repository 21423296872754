import {
  LOADING_MAIN,
  LOADING_COMPONENT,
  LOADING_ALERT,
  LOADING_ACTIVE,
  LOADING_WARNING,
  LOADING_DANGER,
  LOADING_FALSE,
  LOADING_AUTO,
  LOADING_WRITE,
} from "./types";

export const loadingMain = (payload) => ({
  type: LOADING_MAIN,
  payload,
});

export const loadingComponent = (payload) => ({
  type: LOADING_COMPONENT,
  payload,
});

export const loadingAlert = (payload) => ({
  type: LOADING_ALERT,
  payload,
});

export const loadingActive = (payload) => ({
  type: LOADING_ACTIVE,
  payload,
});

export const loadingAuto = (payload) => ({
  type: LOADING_AUTO,
  payload,
});

export const loadingWrite = (payload) => ({
  type: LOADING_WRITE,
  payload,
});

export const loadingWarning = (payload) => ({
  type: LOADING_WARNING,
  payload,
});

export const loadingDanger = (payload) => ({
  type: LOADING_DANGER,
  payload,
});

export const loadingFalse = () => ({
  type: LOADING_FALSE,
});
