import * as S from './StudentNavigator.styled';
import logo from '../../../../assets/images/growing_symbol_sm.png';
import wordmarkGray from '../../../../assets/images/growing_wordmark_gray1.png';
import wordmarkBlue from '../../../../assets/images/growing_wordmark_blue.png';
import { HamburgerIcon, CloseIcon } from 'components/atoms';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const HOME_PATH = '/learningcenter/home';

interface StudentNavigatorProps {
  handleLogout: () => Promise<void>;
}

export default function StudentNavigator({ handleLogout }: StudentNavigatorProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogoSection = () => {
    if (pathname === HOME_PATH) {
      return;
    }
    navigate(HOME_PATH);
  };

  const handleMenuButton = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleListItem = (link: string) => {
    navigate(link);

    setIsMenuOpen((prev) => !prev);
  };

  const menuItemList = [
    { label: '단어', link: '/learningcenter/voca' },
    { label: '본문', link: '/learningcenter/reading' },
    { label: '마이페이지', link: '/learningcenter/mypage' },
  ];

  return (
    <S.Container>
      <S.HeaderContainer>
        <S.LogoSection onClick={handleLogoSection}>
          <S.Logo src={logo} />
          <S.Wordmark src={isMenuOpen ? wordmarkBlue : wordmarkGray} />
        </S.LogoSection>
        <S.MenuButton onClick={handleMenuButton}>
          {isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
        </S.MenuButton>
      </S.HeaderContainer>
      <S.MenuContainer isMenuOpen={isMenuOpen}>
        {menuItemList.map(({ label, link }) => (
          <S.ListItem
            onClick={() => {
              handleListItem(link);
            }}
            key={label}
          >
            {label}
          </S.ListItem>
        ))}
        <S.ListItem
          onClick={() => {
            handleLogout();
          }}
        >
          로그아웃
        </S.ListItem>
      </S.MenuContainer>
    </S.Container>
  );
}
