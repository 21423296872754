import styled from "@emotion/styled";
import { Colors } from "styles/colors";
import { Caption1 } from "styles/typography";

export const HrSectText = styled.span`
  display: flex;
  flex-basis: 100%;
  ${Caption1};
  color: ${Colors.gray3};

  align-items: center;

  &::before {
    content: "";
    flex-grow: 1;
    background: ${Colors.gray4};
    height: 1px;
    margin-right: 16px;
  }
  &::after {
    content: "";
    flex-grow: 1;
    background: ${Colors.gray4};
    height: 1px;
    margin-left: 16px;
  }
`;
