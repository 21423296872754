/* eslint-disable no-unused-vars */
import { Card, Col, Row } from "react-bootstrap";
import "chartjs-adapter-moment";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import chartTrendline from "chartjs-plugin-trendline";
import { useSelector } from "react-redux";
import { colors } from "../../../lib/utils/constants";
import { toStringByFormatting } from "../functions";
import MonthLabel from "./MonthLabel";

const ChartTest = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // test score reducer
  const testScore = useSelector((state) => state.statArrayReducer).testArray;
  // stat reducer
  const stat = useSelector((state) => state.statArrayReducer).stat;
  // maxScore reducer
  const maxScore = useSelector((state) => state.settingReducer).maxScore;

  const subject = selOption.subject || "Listening";

  const horizonalArbitraryLine = {
    id: "horizonalArbitraryLine",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();

      for (let index = 0; index < options.length; index++) {
        const line = options[index];

        ctx.fillStyle = line.lineColor;
        ctx.fillRect(
          left,
          y.getPixelForValue(line.yPosition) - line.yThickness / 2,
          width,
          line.yThickness
        );
        // ctx.fillText((line.text + ": " + line.yPosition.toString()), left, y.getPixelForValue(line.yPosition) + line.yThickness + 5)
        ctx.restore();
      }
    },
  };

  let testScoreSubject = testScore
    .map(function (val, index) {
      return val["subject"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ subject: row }));

  let testScoreDataArray = testScoreSubject.map((a, i) => {
    const subjectScore = (obj) => {
      switch (obj.subject) {
        case "Grammar":
          return obj.score * (100 / maxScore.grammar);
        case "Reading":
          return obj.score * (100 / maxScore.reading);
        case "Listening":
          return obj.score * (100 / maxScore.listening);
        case "모의고사":
          return obj.score * (100 / maxScore.mock);
        default:
          return obj.score;
      }
    };
    return {
      label: a.subject,
      data: testScore
        .filter((row) => row.subject === a.subject)
        .map((d) => ({ x: d.date.toDate(), y: subjectScore(d) })),
      backgroundColor: colors.main[i],
      borderColor: colors.main[i],
      trendlineLinear: {
        style: colors.sub[i],
        lineStyle: "solid",
        width: 10,
      },
    };
  });

  const testScoreData = {
    datasets: testScoreDataArray,
  };

  const yPosition = () => {
    switch (subject) {
      case "Grammar":
        return {
          last: stat.test[subject].last * (100 / maxScore.grammar),
          this: stat.test[subject].this * (100 / maxScore.grammar),
        };
      case "Reading":
        return {
          last: stat.test[subject].last * (100 / maxScore.reading),
          this: stat.test[subject].this * (100 / maxScore.reading),
        };
      case "Listening":
        return {
          last: stat.test[subject].last * (100 / maxScore.listening),
          this: stat.test[subject].this * (100 / maxScore.listening),
        };
      case "모의고사":
        return {
          last: stat.test[subject].last * (100 / maxScore.mock),
          this: stat.test[subject].this * (100 / maxScore.mock),
        };
      default:
        return { last: stat.test[subject].last, this: stat.test[subject].this };
    }
  };

  const testScoreOptions = {
    title: { text: "This is a test" },
    scales: {
      x: {
        display: true,
        title: "time",
        type: "time",
        distribution: "series",
        gridLines: {
          lineWidth: 1,
        },
        time: {
          unit: "day",
          unitStepSize: 1000,
          displayFormats: {
            day: "M.D",
          },
        },
      },
      y: {
        min: 0,
        max: 100,
      },
    },
    plugins: {
      horizonalArbitraryLine: [
        {
          lineColor: "#9b22fd",
          yPosition: yPosition().last,
          yThickness: 3,
        },
        {
          lineColor: "#06a001",
          yPosition: yPosition().this,
          yThickness: 3,
        },
      ],
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            return (
              toStringByFormatting(tooltipItem[0].raw.x) +
              " (" +
              selOption.name +
              ")"
            );
          },
          label: (tooltipItem) => {
            switch (tooltipItem.dataset.label) {
              case "Grammar":
                return (
                  tooltipItem.dataset.label +
                  ": " +
                  tooltipItem.parsed.y / (100 / maxScore.grammar)
                );
              case "Reading":
                return (
                  tooltipItem.dataset.label +
                  ": " +
                  tooltipItem.parsed.y / (100 / maxScore.reading)
                );
              case "Listening":
                return (
                  tooltipItem.dataset.label +
                  ": " +
                  tooltipItem.parsed.y / (100 / maxScore.listening)
                );
              case "모의고사":
                return (
                  tooltipItem.dataset.label +
                  ": " +
                  tooltipItem.parsed.y / (100 / maxScore.mock)
                );
              default:
                return tooltipItem.dataset.label + ": " + tooltipItem.parsed.y;
            }
          },
        },
      },
      trendlineLinear: {
        style: "rgba(255,105,180, .8)",
        lineStyle: "solid",
        width: 2,
      },
    },
  };
  Chart.register(horizonalArbitraryLine);
  Chart.register(chartTrendline);

  return (
    <>
      {testScore.length !== 0 && selOption.toggleTest && (
        <Col xxl>
          <Card>
            <Card.Body>
              <Card.Title style={{ float: "left" }} className="App-left">
                {`Test Score - ${selOption.nowStudent} `}
              </Card.Title>
              <MonthLabel />
              <Line
                data={testScoreData}
                options={testScoreOptions}
                width={10}
                height={6}
              />
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ChartTest;
