/* eslint-disable react-hooks/exhaustive-deps */
import db from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import {
  loadingAlert,
  loadingComponent,
  loadingFalse,
} from "../../../redux/Loading/actions";
import { doc, setDoc } from "firebase/firestore";
import { inputDataClear } from "../../../redux/InputData/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

const UploadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // loading reducer
  const loading = useSelector((state) => state.loadingReducer).component;
  // data load 시 reducer
  const loadData = useSelector((state) => state.loadingReducer).alert;
  // inputdata reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const dataUpload = () => {
    dispatch(loadingComponent(true));
    dispatch(loadingAlert(true));
    inputData.map(async (row) => {
      await setDoc(doc(db, "Question", row.qid), {
        answerArray: row.answerArray,
        answer: row.answer,
        label: row.label,
        num1: row.num1,
        num2: row.num2,
        num3: row.num3,
        num4: row.num4,
        num5: row.num5,
        numAnswer: row.numAnswer,
        question: row.question,
        type: row.type,
      });
    });
    setTimeout(() => {
      dispatch(modalShowPopup(false));
      dispatch(loadingFalse());
      dispatch(inputDataClear());
    }, 2000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  return (
    <Row className="g-4 pb-2 mx-3">
      <Col>
        <Button
          className="Growing-button"
          disabled={loading || inputData.length === 0}
          style={{ float: "right" }}
          onClick={openPopup}
        >
          {loadData || loading ? <LoadingSpinner /> : "문제 업로드"}
        </Button>
      </Col>
    </Row>
  );
};

export default UploadButton;
