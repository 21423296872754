import { useSelector } from "react-redux";

const MyPageGuidance = () => {
  // reducers
  // firebase 로그인 정보 reducer
  const currentUser = useSelector(
    (state) => state.currentUserReducer
  ).accountInfo;
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  return (
    <>
      <h2>
        {currentUser.displayName}
        {myInfo.teacher === true && " 선생"}님, 안녕하세요!
      </h2>
      <p>
        {currentUser.displayName}
        {myInfo.teacher === true && " 선생"}님의 정보를 확인할 수 있습니다.
      </p>
    </>
  );
};

export default MyPageGuidance;
