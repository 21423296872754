/* eslint-disable react-hooks/exhaustive-deps */
import "../../../App.css";
import { Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";
import SelectExistGrade from "../../../Component/Select/SelectExistGrade";
import SelectExistSchool from "../../../Component/Select/SelectExistSchool";
import TabList from "../../../Component/StudentList/TabList";
import { useDispatch } from "react-redux";
import { displayDataClear } from "../../../redux/DisplayData/actions";
import { loadingFalse } from "../../../redux/Loading/actions";
import { modalShowClear } from "../../../redux/Modal_Show/actions";
import { selOptionClear } from "../../../redux/selOption/actions";
import useRedirect from "../../../hooks/useRedirect";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

function StudentList() {
  // dispatch
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(displayDataClear());
    dispatch(modalShowClear());
    dispatch(loadingFalse());
    dispatch(selOptionClear("StudentList"));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <div className="opt-area mb-3">
            <Row className="p-3">
              <Col md>
                <SelectExistGrade title="학년" clearable />
              </Col>
              <Col md>
                <SelectExistSchool title="학교" clearable />
              </Col>
            </Row>
          </div>
          <TabList />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}

export default StudentList;
