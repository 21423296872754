import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as msg from "../../lib/utils/msg";

const CompleteAlert = () => {
  // loading reducer
  const alert = useSelector((state) => state.loadingReducer).alert;

  return <>{alert && <Alert children={msg.infoMsg.editComplete()} />}</>;
};

export default CompleteAlert;
