import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLeagueMinus } from "../../../redux/league/actions";

// 점수 증가-감소 설정하는 컴포넌트
const PlusMinusButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);
  // 해당 리그 내 점수 변경 모드가 -1인지 1인지
  const minus = league[leagueLable[level]].minus;
  // 증가인지 감소인지 알려주는 텍스트
  const minusLabel = minus === 1 ? "Plus" : "Minus";

  // onClick 함수
  // 클릭 시 minus 변경 (1->-1, -1->1)
  const changeMinus = () => dispatch(setLeagueMinus(leagueLable[level]));

  return (
    <ButtonGroup className="mx-3" style={{ float: "left" }}>
      <Button variant="outline-dark" disabled>
        점수
      </Button>
      <Button
        onClick={changeMinus}
        style={{ backgroundColor: "#454648", border: "none" }}
        disabled={loading}
      >
        {minusLabel}
      </Button>
    </ButtonGroup>
  );
};

export default PlusMinusButton;
