import "../../App.css";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { RequiredText } from "../../Methods";
import { subjects, subjectsWithCompetition } from "../../lib/utils/constants";
import { selOptionKey } from "../../redux/selOption/actions";

// select option의 과목을 변경하기 위한 Select 컴포넌트
const SelectSubject = (props) => {
  // props
  // competition subject이 필요한지 여부
  const competition = props.competition;
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // require message 출력 조건, props로 required가 넘어오고, selOption의 subject가 null일 경우
  const returnRequire = required && !selOption.subject;

  // onChange 함수
  // select option reducer의 subject 변경 함수
  const changeSubject = (e) => {
    const returnValue = e ? e : { subject: null };
    dispatch(selOptionKey("subject", returnValue.subject));
  };

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="과목"
        isClearable={clearable}
        isSearchable
        value={selOption.subject ? selOption : null}
        options={competition ? subjectsWithCompetition : subjects}
        getOptionLabel={(e) => e.subject}
        getOptionValue={(e) => e.subject}
        maxMenuHeight={150}
        onChange={(e) => changeSubject(e)}
        isDisabled={disabled}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectSubject;
