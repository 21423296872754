import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingComponent } from "../../redux/Loading/actions";
import LoadingSpinner from "../PageComponent/LoadingSpinner";
import { statLoad } from "../../redux/StatArray/actions";
import { selOptionKey } from "../../redux/selOption/actions";

const LoadDataButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const dateRange = selOption.dateRange;
  // loading reducer component state
  const loading = useSelector((state) => state.loadingReducer).component;

  const getDatas = async () => {
    dispatch(loadingComponent(true));
    dispatch(selOptionKey("nowStudent", selOption.name));
    dispatch(selOptionKey("toggleCom", false));
    dispatch(selOptionKey("toggleTest", false));
    dispatch(selOptionKey("toggleQuiz", false));
    dispatch(statLoad(selOption, dateRange));
    setTimeout(() => {
      dispatch(loadingComponent(false));
    }, 2000);
  };

  return (
    <Button disabled={!selOption.sid || loading} onClick={getDatas}>
      {loading ? <LoadingSpinner /> : "조회하기"}
    </Button>
  );
};

export default LoadDataButton;
