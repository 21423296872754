export default function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 13.4143L17.479 18.8933L18.8933 17.479L13.4143 12.0001L18.8933 6.52115L17.479 5.10693L12.0001 10.5859L6.52115 5.10693L5.10693 6.52115L10.5859 12.0001L5.10694 17.479L6.52115 18.8933L12.0001 13.4143Z"
        fill="black"
      />
    </svg>
  );
}
