import * as selObj from "./objects";

export const clearState = (state, action) => {
  switch (action.clearType) {
    case "Competition":
      return selObj.defaultCompetition;
    case "QuizInput":
      return selObj.defaultQuizInput;
    case "ReviewNote":
      return selObj.defaultReviewnote;
    case "Schedule":
      return selObj.defaultSchedule;
    case "ScheduleInput":
      return selObj.defaultScheduleInput;
    case "TestInput":
      return selObj.defaultTestInput;
    case "TestModify":
      return selObj.defaultTestModify;
    case "PresetStudent":
      return selObj.defaultPresetStudent(state);
    case "MyPage":
      return selObj.defaultMyPage(action.payload);
    case "Word":
      return selObj.defaultWord(state, action.payload);
    case "WordInput":
      return selObj.defaultWordInput;
    case "Board":
      return selObj.defaultBoard;
    case "BPM":
      return selObj.defaultBPM;
    case "StudentStat":
      return selObj.defaultStudentStat;
    case "StudentList":
      return selObj.defaultStudentList;
    case "Payment":
      return selObj.defaultpaymentList;
    case "Reading":
      return selObj.defaultReading;
    case "ReadingInput":
      return selObj.defaultReadingInput(state);
    case "Set":
      return selObj.defaultSet(action.payload);
    case "CheckTodo":
      return selObj.defaultCheckTodo;
    default:
      return selObj.defaultState;
  }
};
