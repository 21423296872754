// inputData 내 잘못된 학생 이름 찾는 함수
export const nullPlayer = (inputData) =>
  inputData
    .filter((row) => row.sid === null)
    .map(function (val) {
      return val["name"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ name: row }));

// uploadData 내 학생 수 리턴 함수
export const getPlayerNum = (uploadData) =>
  uploadData
    .filter((arr, index, callback) => {
      return callback.findIndex((t) => t.sid === arr.sid) === index;
    })
    .map((row) => ({ sid: row.sid, name: row.name }));

// uploadData 내 퀴즈 수 리턴 함수
export const getQIDNum = (uploadData) =>
  uploadData
    .filter((arr, index, callback) => {
      return callback.findIndex((t) => t.qid === arr.qid) === index;
    })
    .map((row) => ({ qid: row.qid }));
