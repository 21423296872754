import { addDoc, collection, collectionGroup, deleteDoc, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import React, { forwardRef, useEffect } from "react";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SelectYearMonthLoadButton from "../../../Component/StudentList/SelectYearMonthLoadButton";
import db from "../../../firebase";
import { displayDataClear } from "../../../redux/DisplayData/actions";
import { getPaymentData, setPaymentData } from "../../../redux/FirebaseData/Payment/reducer";
import { loadingComponent, loadingFalse } from "../../../redux/Loading/actions";
import { modalShowClear, modalShowPopup } from "../../../redux/Modal_Show/actions";
import { selOptionClear, selOptionKey } from "../../../redux/selOption/actions";
import moment from "moment";
import { CRUD, DATEDATA } from "../../../lib/utils/constants";
import { setPopupModal } from "../../../redux/PopupModal/reducer";
import ReactDatePicker from "react-datepicker";
import useRedirect from "../../../hooks/useRedirect";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

const AdminPayment = () => {
  // dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const paymentList = useSelector((state) => state.paymentReducer);

  const loading = useSelector((state) => state.loadingReducer).component;

  useRedirect("teachingcenter", "home");

  const setPayment = () => {
    dispatch(selOptionKey("nowYear", selOption.year));
    dispatch(loadingComponent(true));
    const payments = query(collectionGroup(db, "payment"), where("year", "==", selOption.nowYear || new Date().getFullYear()));

    const paymentQuery = onSnapshot(payments, (snapshot) => {
      dispatch(
        getPaymentData(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            crud: CRUD.R,
          })),
        ),
      );
    });
    setTimeout(() => {
      dispatch(loadingComponent(false));
      paymentQuery();
    }, 500);
  };

  useEffect(() => {
    dispatch(displayDataClear());
    dispatch(modalShowClear());
    dispatch(loadingFalse());
    dispatch(selOptionClear("Payment"));
    setPayment(2022);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (loading) {
        dispatch(modalShowClear());
        dispatch(loadingFalse());
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // reducers

  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // user list reducer
  const users = useSelector((state) => state.usersReducer);

  let student = users.filter((v) => v.teacher === false && v.state === true && v.office === myInfo.office);

  const cellData = (row, res) => {
    const thisData = paymentList.find((d) => d.sid === row.id && res === d.month);
    return thisData && thisData.paid && thisData;
  };

  const updatePayment = () => {
    dispatch(loadingComponent(true));
    paymentList.forEach((element) => {
      const { id, crud, ...uploadData } = element;
      if (crud === "C") {
        addDoc(collection(db, "Users", uploadData.sid, "payment"), uploadData);
      } else if (crud === "U") {
        setDoc(doc(db, "Users", uploadData.sid, "payment", id), uploadData);
      } else if (crud === "D") {
        deleteDoc(doc(db, "Users", uploadData.sid, "payment", id));
      }
    });
  };

  const isDateLate = (date, month) => date > new Date(selOption.year, month + 1, 1);

  const monthCellStyle = (row, month) => {
    const thisPayment = paymentList.filter((d) => d.sid === row.id && month + 1 === d.month);
    const thisDate = thisPayment.length === 0 || thisPayment[0].crud === CRUD.D ? new Date() : new Date(thisPayment[0].date);

    function backgroundColor() {
      if (isDateLate(row.registrationDate.toDate(), month)) {
        return "grey";
      } else if (
        (thisPayment.length === 0 || thisPayment[0].crud === CRUD.D) &&
        thisDate > new Date(selOption.year, month, row.registrationDate.toDate().getDate() + 7)
      ) {
        return "salmon";
      } else return "white";
    }
    const cursor = isDateLate(row.registrationDate.toDate(), month) ? "default" : "pointer";

    return { backgroundColor: backgroundColor(), cursor, padding: 0 };
  };

  const onChangeDate = (e, row, res) => {
    const setCRUDAndDate = (d) => {
      if (d.crud === CRUD.C) {
        return { crud: CRUD.C, date: moment(e).format("YYYY-MM-DD") };
      } else return { crud: CRUD.U, date: moment(e).format("YYYY-MM-DD") };
    };

    dispatch(
      setPaymentData(
        [...paymentList].map((d) => (d.sid === row.id && d.month === res && d.year === selOption.year ? { ...d, ...setCRUDAndDate(d) } : d)),
      ),
    );
  };

  const onDeleteDate = (row, res) => {
    dispatch(
      setPaymentData([...paymentList].map((d) => (d.sid === row.id && d.month === res && d.year === selOption.year ? { ...d, crud: CRUD.D } : d))),
    );
  };

  const onClick = (row, res) => {
    if (!cellData(row, res)) {
      dispatch(
        setPaymentData([
          ...paymentList,
          {
            year: selOption.year,
            month: res,
            date: moment().format("YYYY-MM-DD"),
            paid: true,
            sid: row.id,
            crud: CRUD.C,
          },
        ]),
      );
    } else if (cellData(row, res).crud === CRUD.D) {
      dispatch(
        setPaymentData([...paymentList].map((d) => (d.sid === row.id && d.month === res && d.year === selOption.year ? { ...d, crud: CRUD.U } : d))),
      );
    }
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "업로드 하시겠습니까?", updatePayment, "업로드"));
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button size="sm" variant="outline-dark" onClick={onClick} ref={ref} children={value} />
  ));

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="mb-4">
            <Col lg />
            <SelectYearMonthLoadButton type="payment" />
            <Col lg>
              <Button onClick={openPopup}>업로드</Button>
            </Col>
            <Col lg />
          </Row>
          <Table striped hover bordered size="sm" style={{ fontSize: "14px", verticalAlign: "middle" }}>
            <thead>
              <tr>
                <th width="3%">{"학생"}</th>
                <th width="3%">{"납부일"}</th>
                {DATEDATA.monthArray.map((row) => (
                  <th width="7%">{row}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {student.map((row) => (
                <tr key={row.id}>
                  <th>{row.name}</th>
                  <th>{row.registrationDate && row.registrationDate.toDate().getDate()}</th>
                  {DATEDATA.monthArray.map((res) => (
                    <td onClick={() => !isDateLate(row.registrationDate.toDate(), res - 1) && onClick(row, res)} style={monthCellStyle(row, res - 1)}>
                      {!isDateLate(row.registrationDate.toDate(), res - 1) && cellData(row, res) && cellData(row, res).crud !== CRUD.D && (
                        <ButtonGroup>
                          <ReactDatePicker
                            selected={new Date(cellData(row, res).date)}
                            onChange={(date) => onChangeDate(date, row, res)}
                            maxDate={new Date()}
                            customInput={<ExampleCustomInput />}
                            dateFormat="yy-M-d"
                          />
                          <Button variant="danger" size="sm" onClick={() => onDeleteDate(row, res)} />
                        </ButtonGroup>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default AdminPayment;
