import { Accordion, ButtonGroup } from "react-bootstrap";
import ClearButton from "./ClearButton";
import CreateButton from "./CreateButton";
import DeleteButton from "./DeleteButton";
import LoadWordButton from "./LoadWordButton";
import InputTable from "./Table/InputTable";

const SelectList = () => {
  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>단어 리스트 설정</Accordion.Header>
      <Accordion.Body>
        <InputTable />
        <ButtonGroup>
          <CreateButton />
          <DeleteButton />
          <ClearButton />
          <LoadWordButton />
        </ButtonGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SelectList;
