import '../../../styles/ManageTable.scss';
import { Tabs, Tab } from 'react-bootstrap';
import UserManageTable from '../../../Component/Admin/UserManage/UserManageTable';
import useRedirect from '../../../hooks/useRedirect';
import UploadData from 'uploadData';

import BasicLayout from 'components/templates/basic-layout';
import * as S from 'components/templates/common-container/CommonContainer.styled';
// import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
// import db from '../../../firebase';
// import { Button } from 'components/atoms';

// function rangeArray(start, end) {
//   const result = [];

//   // 시작이 끝보다 크면 swap
//   if (start > end) {
//     [start, end] = [end, start];
//   }

//   for (let i = start; i <= end; i++) {
//     result.push(i);
//   }

//   return result;
// }

// 사용자 관리를 위한 컨테이너
const ManageUser = () => {
  useRedirect('teachingcenter', 'home');

  // const deleteWords = async () => {
  //   const wordsQuery = query(
  //     collection(db, 'Words'),
  //     where('category', '==', '초등'),
  //     where('set', 'in', rangeArray(481, 510)),
  //   );

  //   const wordsQuerySnapshot = await getDocs(wordsQuery);

  //   wordsQuerySnapshot.forEach((docs) => {
  //     try {
  //       console.log(docs.data());
  //       deleteDoc(doc(db, 'Words', docs.id));
  //     } catch (e) {
  //       console.error(e);
  //     } finally {
  //       console.log('삭제 완료!');
  //     }
  //   });
  // };

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Tabs defaultActiveKey="student" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="student" title="학생">
              <UserManageTable />
            </Tab>
            <Tab eventKey="teacher" title="선생님">
              <UserManageTable isTeacher />
            </Tab>
          </Tabs>
          <UploadData />
          {/* <Button onClick={deleteWords} /> */}
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ManageUser;
