import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { keyQuestionOption } from "../../../../redux/QuestionOption/reducer";

const ChangeLabel = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const changeLabel = (e) =>
    dispatch(keyQuestionOption("label", e.target.value));

  return (
    <>
      <h4>Label</h4>
      <Form.Control
        className="App"
        type="text"
        value={questionOption.label}
        onChange={(e) => changeLabel(e)}
      />
      {questionOption.label === "" && <RequiredText />}
    </>
  );
};

export default ChangeLabel;
