import styled from '@emotion/styled';
import { Colors, OpacityColors } from 'styles/colors';
import { Body1, Caption1, Text2 } from 'styles/typography';

interface BorderColorConditions {
  isError: boolean;
  isFocused: boolean;
  disabled: boolean;
}

const borderColor = ({ isError, isFocused }: BorderColorConditions) => {
  if (isFocused) {
    return Colors.blue1;
  }
  if (isError) {
    return Colors.red;
  }
  return Colors.gray5;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const InputContainer = styled.div<BorderColorConditions>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 54px;

  padding: 0 15px;

  gap: 16px;

  border: 1px solid ${borderColor};
  border-radius: 8px;

  background-color: ${({ disabled }) => (disabled ? Colors.gray6 : Colors.white)};

  transition: all 0.2s ease-in-out;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;

  position: relative;
`;

export const Label = styled.label<{ isFocused?: boolean }>`
  color: ${OpacityColors.gray1Opacity30};
  top: 16px;

  ${Body1};

  position: absolute;

  ${({ isFocused }) =>
    isFocused &&
    `
    top: 8px;
    font-size: 10px;
    line-height: 12px;
    `};

  transition: all 0.3s ease-in-out;
`;

export const Input = styled.input<{ disabled: boolean }>`
  width: 100%;

  position: absolute;

  bottom: 8px;

  border: none;
  outline: none;

  padding: 0;

  color: ${({ disabled }) => (disabled ? OpacityColors.gray1Opacity30 : Colors.gray1)};

  ${Body1};

  &:focus {
    border: none;
    outline: none;
  }

  &:invalid {
    box-shadow: none;
  }
`;

export const ErrorIconContainer = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;

  opacity: ${({ isError }) => (isError ? 1 : 0)};
`;

export const HelperText = styled.span<{ isError?: boolean }>`
  color: ${({ isError: isShowError }) => (isShowError ? Colors.red : Colors.black)};
  ${Caption1};
  text-align: left;

  position: absolute;

  left: 16px;
  bottom: -20px;
`;

export const OptionListContainer = styled.div`
  width: 100%;

  max-height: 500px;

  position: relative;

  left: 0;
  bottom: -8px;

  border-radius: 8px;

  border: 1px solid ${Colors.gray5};

  padding: 12px 0;
  margin-bottom: 12px;

  overflow: auto;

  display: flex;
  flex-direction: column;

  ${Text2};
  color: ${Colors.gray3};

  z-index: 2;
`;

export const OptionItem = styled.div`
  width: 100%;

  padding: 12px 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  cursor: pointer;

  &:hover {
    color: ${Colors.blue1};
    background-color: ${Colors.gray6};
  }
`;
