import { doc, increment, setDoc } from "firebase/firestore";
import db from "../../../firebase";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingAlert, loadingComponent } from "../../../redux/Loading/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { getQIDNum } from "./function";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";
import { weekNumberByMonth } from "../../../lib/utils/date";

// 업로드시 사용하는 버튼 컴포넌트
const UploadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 서버에 등록될 데이터 reducer
  const uploadData = useSelector((state) => state.quizUploadDataReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  // 버튼 비활성화 조건, 퀴즈/날짜/uploadData가 비어있거나 로딩 중일 경우
  const buttonDisabled =
    !selOption.quiz || !selOption.date || uploadData.length === 0 || loading;

  // uploadData 에서 퀴즈의 중복을 제거한 Array
  const qidNum = getQIDNum(uploadData);

  // 업로드 시 사용하는 함수
  const dataUpload = () => {
    // loading reducer의 component state를 true로 변경
    dispatch(loadingComponent(true));
    // loading reducer의 alert state를 true로 변경
    dispatch(loadingAlert(true));
    // uploadData 내 데이터를 업로드하는 과정
    uploadData.forEach((a) => {
      // 학생 별로 해당 주의 파트별 시도 횟수, 정답 횟수 업데이트
      setDoc(
        doc(db, "Users", a.sid, "quizStat", weekNumberByMonth(selOption.date)),
        {
          [a.part.toLowerCase() + "correct"]: increment(a.correct),
          [a.part.toLowerCase() + "count"]: increment(1),
        },
        { merge: true }
      );
      // 학생 별로 해당 주의 퀴즈별 시도 횟수, 정답 횟수 업데이트
      setDoc(
        doc(
          db,
          "Users",
          a.sid,
          "quizStat",
          weekNumberByMonth(selOption.date),
          "qidStat",
          a.qid
        ),
        { correct: increment(a.correct), count: increment(1), part: a.part },
        { merge: true }
      );
    });
    // 업로드한 사용자의 해당 주의 퀴즈 수와 시도 횟수, 정답 횟수 업데이트
    setDoc(
      doc(
        db,
        "Users",
        myInfo.uid,
        "quizUploadStat",
        weekNumberByMonth(selOption.date)
      ),
      {
        question: increment(qidNum.length),
        count: increment(uploadData.length),
        correct: increment(
          uploadData.filter((row) => row.correct === 1).length
        ),
      },
      { merge: true }
    );
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  return (
    <>
      <Button
        disabled={buttonDisabled}
        variant={buttonDisabled ? "secondary" : ""}
        className="Growing-button"
        onClick={openPopup}
      >
        {loading ? <LoadingSpinner /> : "퀴즈 결과 업로드"}
      </Button>
    </>
  );
};

export default UploadButton;
