import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export default function usePageBlock(condition = true, text) {
  const { navigator } = useContext(NavigationContext);

  const message = text ?? "정말 떠나실건가요? \n업로드 되지 않은 데이터는 저장되지 않습니다.";

  // const blocker = useCallback(
  //   (tx) => {
  //     if (window.confirm(message)) tx.retry();
  //   },
  //   [message],
  // );

  // useEffect(() => {
  //   if (!condition) {
  //     return;
  //   }
  //   return () => {
  //     history.block(message);
  //   };
  // }, [history]);
}
