import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, ButtonGroup, Row, Col } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { displayDataScheduleSet } from "../../../redux/DisplayData/actions";
import { modalShowDisplay } from "../../../redux/Modal_Show/actions";
import { colors } from "../../../lib/utils/constants";

// 시간을 한국어로
registerLocale("ko", ko);

// 스케쥴을 Display할 달력 컴포넌트
const ScheduleCalendar = () => {
  // 달력을 한국어로
  moment.locale("ko-KR");
  let localizer = momentLocalizer(moment);

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 반 리스트 reducer
  const classes = useSelector((state) => state.classesReducer);
  // 스케쥴 데이터 reducer
  const scheduleData = useSelector((state) => state.scheduleReducer);

  // 스케쥴을 달력에 Display 할 수 있게 가공
  let schedule = scheduleData.map((row) => ({
    ...row,
    title: row.scname + "-" + row.part,
    allDay: true,
    start: row.date && row.date.toDate(),
    end: row.date && row.date.toDate(),
    color: colors.main[classes.findIndex((c) => c.id === row.cid)],
  }));

  // selOption의 cid와 subject에 따라 Display할 스케쥴 필터링
  let eventsList = () => {
    switch (true) {
      case selOption.cid !== null && selOption.subject !== null:
        return schedule.filter(
          (row) =>
            row.cid === selOption.cid && row.subject === selOption.subject
        );
      case selOption.cid !== null && selOption.subject === null:
        return schedule.filter((row) => row.cid === selOption.cid);
      case selOption.cid === null && selOption.subject !== null:
        return schedule.filter((row) => row.subject === selOption.subject);
      default:
        return schedule;
    }
  };

  // onClick 함수
  // 달력에 Display된 스케쥴 클릭 시 실행
  const handleShow = (e) => {
    // 해당 스케쥴을 display할 schedule으로 해당 reducer로 넘김
    dispatch(displayDataScheduleSet(e));
    // modalShow reducer의 display state를 true로 변경
    dispatch(modalShowDisplay(true));
  };

  return (
    <Calendar
      popup
      localizer={localizer}
      events={eventsList()}
      style={{ height: 700 }}
      eventPropGetter={(event) => ({
        style: {
          backgroundColor: event.color,
          fontSize: selOption.fontSize,
        },
      })}
      components={{
        toolbar: Toolbar,
      }}
      onSelectEvent={(e) => handleShow(e)}
    />
  );
};

// 달력에 사용할 Toolbar 컴포넌트
const Toolbar = (props) => {
  const { date } = props;

  const navigate = (action) => {
    props.onNavigate(action);
  };

  const onView = (action) => {
    props.onView(action);
  };

  return (
    <div className="container-fluid">
      <Row className="mb-3">
        <Col>
          <ButtonGroup className="mx-5 my-1">
            <Button variant="warning" onClick={navigate.bind(null, "TODAY")}>
              Today
            </Button>
            <Button
              className="Growing-button"
              onClick={navigate.bind(null, "PREV")}
            >
              이전
            </Button>
            <Button variant="outline-dark" disabled>{`${date.getFullYear()}년 ${
              date.getMonth() + 1
            }월`}</Button>
            <Button
              className="Growing-button"
              onClick={navigate.bind(null, "NEXT")}
            >
              다음
            </Button>
          </ButtonGroup>
          <ButtonGroup className="mx-1 my-1">
            <Button
              className="Growing-button"
              onClick={onView.bind(null, "week")}
            >
              주간
            </Button>
            <Button
              className="Growing-button"
              onClick={onView.bind(null, "month")}
            >
              월간
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleCalendar;
