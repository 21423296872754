import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createWordQuizAPI, setWordQuizIfHighScoreAPI } from "../api/wordQuizApi";

import * as CardStyles from "../styles/CardStyles";

const WordGameQuizArea = ({ state, quizWordList, quizResultList, setQuizResultList, quizTimer, setQuizTimer }) => {
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  const quizTime = useSelector((state) => state.settingReducer).quizTime;
  const navigate = useNavigate();
  const score = (correct, quizTimer) => {
    if (!correct) return 0;
    if (quizTimer.second === 0 && quizTimer.milli === 0) return 0;
    const time = quizTime * 100 - (quizTimer.second * 100 + quizTimer.milli);
    return Math.round(1000 - time * (1000 / (quizTime * 100)));
  };
  const saveQuizResult = (option, correct) => {
    setQuizResultList((prev) => [
      ...prev,
      { question: quizWordList[quizResultList.length].question, response: option, correct, score: score(correct, quizTimer) },
    ]);
    setQuizTimer((prev) => ({ ...prev, second: Math.floor(quizTime), milli: (quizTime % 1) * 10 }));
  };
  const goResult = (option, correct) => {
    setQuizResultList((prev) => [
      ...prev,
      { question: quizWordList[quizResultList.length].question, response: option, correct, score: score(correct, quizTimer) },
    ]);
    const list = [
      ...quizResultList,
      { question: quizWordList[quizResultList.length].question, response: option, correct, score: score(correct, quizTimer) },
    ];
    const payload = {
      sid: myInfo.uid,
      name: myInfo.name,
      cid: state ? state.cid : "",
      date: state ? state.date : "",
      result: list,
      correct: list.filter((result) => result.correct).length,
      score: list.map((result) => result.score).reduce((stack, el) => stack + el, 0),
    };
    createWordQuizAPI(payload);
    navigate("/learningcenter/wordgame/result", { replace: true, state: payload });
  };
  const onClick = (option, correct) => (quizResultList.length === 9 ? goResult(option, correct) : saveQuizResult(option, correct));

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <CardStyles.WordCardContainer>
            <div>Q. {quizResultList.length + 1}</div>
            <div>{quizWordList[quizResultList.length].question}</div>
            <div />
          </CardStyles.WordCardContainer>
        </Col>
      </Row>
      {quizWordList[quizResultList.length].optionList.map((option, i) => (
        <Row className={`mt-${i === 0 ? "3" : "1"}`} key={i}>
          <Col>
            <CardStyles.WordAnswerCardContainer
              children={option}
              onClick={() => onClick(option, quizWordList[quizResultList.length].answerIndex === i)}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default WordGameQuizArea;
