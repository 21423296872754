import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataAllCheck } from "../../redux/InputData/actions";

// inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용하는 버튼 컴포넌트
const CheckAllModifyButton = (props) => {
  // props
  // modify or delete
  const check = props.check;
  // th width
  const width = props.width;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputData reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // modify나 delete가 전체 선택 되어 있는지 여부
  const isAllCheck =
    inputData.length === 0
      ? false
      : inputData.filter((row) => row[check]).length === inputData.length;

  // onClick 함수
  // inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용
  const checkAll = () =>
    !(loading || inputData.length === 0) &&
    dispatch(inputDataAllCheck(check, !isAllCheck));

  return (
    <th
      onClick={checkAll}
      style={
        !(loading || inputData.length === 0)
          ? { cursor: "pointer", textAlign: "center" }
          : { cursor: "default" }
      }
      width={width}
    >
      {check === "modify" ? "수정 " : "삭제 "}
      <Button
        variant="light"
        className={isAllCheck ? "px-1" : "px-2"}
        style={{ color: "black" }}
        disabled={loading || inputData.length === 0}
      >
        {isAllCheck ? "✔︎" : " "}
      </Button>
    </th>
  );
};

export default CheckAllModifyButton;
