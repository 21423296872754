import { call, put, takeEvery } from "redux-saga/effects";
import { kahootReport, quizizzReport } from "./function";
import {
  QUIZ_KAHOOTINPUTDATA,
  QUIZ_KAHOOTINPUTDATA_ERROR,
  QUIZ_KAHOOTINPUTDATA_SUCCESS,
  QUIZ_QUIZIZZINPUTDATA,
  QUIZ_QUIZIZZINPUTDATA_ERROR,
  QUIZ_QUIZIZZINPUTDATA_SUCCESS,
  QUIZ_NAMECHANGE,
  QUIZ_LOADDATA,
  QUIZ_INPUTDATA_CLEAR,
} from "./types";

export const setKahootInput = (payload, student, quizPart) => ({
  type: QUIZ_KAHOOTINPUTDATA,
  file: payload,
  student,
  quizPart,
});

function* setKahootInputSaga(action) {
  const file = action.file;
  const student = action.student;
  const quizPart = action.quizPart;
  try {
    const inputData = yield call(kahootReport, file, student, quizPart);
    yield put({
      type: QUIZ_KAHOOTINPUTDATA_SUCCESS,
      inputData,
    });
  } catch (e) {
    yield put({
      type: QUIZ_KAHOOTINPUTDATA_ERROR,
      error: true,
      e,
    });
  }
}

export const setQuizizzInput = (payload, student, quizPart) => ({
  type: QUIZ_QUIZIZZINPUTDATA,
  file: payload,
  student,
  quizPart,
});

function* setQuizizzInputSaga(action) {
  const file = action.file;
  const student = action.student;
  const quizPart = action.quizPart;
  try {
    const inputData = yield call(quizizzReport, file, student, quizPart);
    yield put({
      type: QUIZ_QUIZIZZINPUTDATA_SUCCESS,
      inputData,
    });
  } catch (e) {
    yield put({
      type: QUIZ_QUIZIZZINPUTDATA_ERROR,
      error: true,
      e,
    });
  }
}

export const setNameChange = (payload) => ({
  type: QUIZ_NAMECHANGE,
  payload,
});

export const setQuizLoadData = (payload) => ({
  type: QUIZ_LOADDATA,
  payload,
});
export const setQuizInputDataClear = () => ({
  type: QUIZ_INPUTDATA_CLEAR,
});

export function* quizInputDataSaga() {
  yield takeEvery(QUIZ_KAHOOTINPUTDATA, setKahootInputSaga);
  yield takeEvery(QUIZ_QUIZIZZINPUTDATA, setQuizizzInputSaga);
}
