import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setPassageInputData,
  setPassageInputDataParagraph,
  setPassageInputDataRemove,
} from "../../../../redux/PassageObject/reducer";

const InputTable = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const inputData =
    useSelector((state) => state.passageObjectReducer).inputData || [];

  const changeInputData = (id, key, payload) =>
    dispatch(setPassageInputData(id, key, payload));
  const changeInputDataParagraph = (id) =>
    dispatch(setPassageInputDataParagraph(id));
  const changeInputDataRemove = (id) => dispatch(setPassageInputDataRemove(id));

  return (
    <Row className="g-2">
      <Col>
        <Table bordered hover striped size="sm">
          <thead>
            <tr>
              <th width="5%">첫줄</th>
              <th width="45%">Sentence</th>
              <th width="45%">해석</th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            {inputData.map((row) => (
              <tr
                style={
                  row.eng === "" || row.kor === ""
                    ? { background: "salmon" }
                    : { verticalAlign: "middle" }
                }
              >
                <td
                  onClick={() => changeInputDataParagraph(row.id)}
                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                >
                  <Button
                    variant="light"
                    className={row.paragraph ? "px-1" : "px-2"}
                    style={{ color: "black" }}
                  >
                    {row.paragraph ? "✔︎" : " "}
                  </Button>
                </td>
                <td>
                  <Form.Control
                    key={row.id + "eng"}
                    as="textarea"
                    rows={3}
                    value={row.eng || ""}
                    style={{ fontSize: "12px" }}
                    onChange={(e) =>
                      changeInputData(row.id, "eng", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    key={row.id + "kor"}
                    as="textarea"
                    rows={3}
                    value={row.kor || ""}
                    style={{ fontSize: "12px" }}
                    onChange={(e) =>
                      changeInputData(row.id, "kor", e.target.value)
                    }
                  />
                </td>
                <td
                  onClick={() => changeInputDataRemove(row.id)}
                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                >
                  <Button variant="light" aria-readonly>
                    -
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default InputTable;
