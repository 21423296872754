import db from "../../../firebase";
import {
  deleteDoc,
  doc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  inputDataClear,
  inputDataModifyArray,
  inputDataTestModifySet,
} from "../../../redux/InputData/actions";
import { loadingComponent, loadingFalse } from "../../../redux/Loading/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 업로드할 데이터를 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const loadClearData = () =>
    inputData.length === 0
      ? dispatch(inputDataTestModifySet(selOption))
      : dispatch(inputDataClear());

  // 버튼 비활성화 조건, inputData 내 수정할 데이터 값이 하나도 없을 시 비활성화
  const modifyNull = inputData.filter((row) => row.modify).length === 0;

  // 버튼 비활성화 조건, inputData 내 null 값이 하나라도 존재 시 비활성화
  const isNull = inputData
    .map((row) => (row.date === null || row.score === null ? true : false))
    .some((e) => e === true);

  const dateid = (date) =>
    date.getFullYear().toString().slice(-2) +
    ("0" + (date.getMonth() + 1)).slice(-2);

  const updateScore = async (row) => {
    if (row.subject === "Listening") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.date)),
        { lissum: increment(row.score), liscount: increment(1) },
        { merge: true }
      );
    } else if (row.subject === "Reading") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.date)),
        { reasum: increment(row.score), reacount: increment(1) },
        { merge: true }
      );
    } else if (row.subject === "Grammar") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.date)),
        { grasum: increment(row.score), gracount: increment(1) },
        { merge: true }
      );
    } else if (row.subject === "모의고사") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.date)),
        { mocksum: increment(row.score), mockcount: increment(1) },
        { merge: true }
      );
    }
  };

  const deleteOriginalScore = async (row) => {
    if (row.original.subject === "Listening") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.original.date)),
        { lissum: increment(-1 * row.original.score), liscount: increment(-1) },
        { merge: true }
      );
    } else if (row.original.subject === "Reading") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.original.date)),
        { reasum: increment(-1 * row.original.score), reacount: increment(-1) },
        { merge: true }
      );
    } else if (row.original.subject === "Grammar") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.original.date)),
        { grasum: increment(-1 * row.original.score), gracount: increment(-1) },
        { merge: true }
      );
    } else if (row.original.subject === "모의고사") {
      await setDoc(
        doc(db, "Users", row.sid, "testScoreStat", dateid(row.original.date)),
        {
          mocksum: increment(-1 * row.original.score),
          mockcount: increment(-1),
        },
        { merge: true }
      );
    }
  };

  // 데이터 수정 함수, row.modify만 true일 때
  const modifyData = async (row) => {
    updateScore(row);
    deleteOriginalScore(row);
    await updateDoc(doc(db, "TestScore", row.id), {
      date: row.date,
      cid: row.cid,
      subject: row.subject,
      score: row.score,
    });
  };

  // 데이터 삭제 함수, row.delete와 row.modify가 모두 true일 때
  const deleteData = async (row) => {
    deleteOriginalScore(row);
    await deleteDoc(doc(db, "TestScore", row.id));
  };

  // onClick 함수
  // selOption에 맞는 스케쥴 필터링
  // const scheduleLoad = () =>
  //   dispatch(inputDataSchUdtDataSet(scheduleData, selOption));

  // modify/delete 조건에 맞는 업로드 함수
  const dataUpload = () => {
    dispatch(loadingComponent(true));
    inputData
      .filter((row) => row.modify)
      .forEach((res) => {
        switch (true) {
          case res.delete:
            deleteData(res);
            break;
          default:
            modifyData(res);
            break;
        }
      });
    setTimeout(() => {
      dispatch(loadingFalse());
      dispatch(modalShowPopup(false));
      dispatch(inputDataModifyArray());
    }, 2000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "수정하시겠습니까?", dataUpload, "수정"));
  };

  return (
    <Row className="g-2 mx-2 mb-3">
      <Col md>
        <ButtonGroup>
          <Button onClick={loadClearData}>
            {inputData.length === 0 ? "데이터 불러오기" : "초기화"}
          </Button>
          <Button
            disabled={isNull || modifyNull || inputData.length === 0}
            variant="warning"
            onClick={openPopup}
          >
            수정 완료
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default ControlButton;
