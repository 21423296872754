import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { rowColor } from "../../../../Methods";
import ChangeTextAreaForm from "../../../SelectTable/ChangeTextAreaForm";
import CheckModifyButton from "../../../SelectTable/CheckModifyButton";
import CheckParagraphBtn from "./CheckParagraphBtn";

const InputTable = () => {
  // input data reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <>
      {inputData.length !== 0 && (
        <Table bordered hover striped size="sm" className="InputTable">
          <thead>
            <tr>
              <th width="10%">수정</th>
              <th width="10%">첫줄</th>
              <th width="35%">Sentence</th>
              <th width="35%">해석</th>
              <th width="10%">제거</th>
            </tr>
          </thead>
          <tbody>
            {inputData.map((row) => (
              <tr
                key={row.id}
                className={
                  row.eng === "" || row.kor === "" ? "danger" : rowColor(row)
                }
              >
                <CheckModifyButton row={row} type="modify" />
                <CheckParagraphBtn
                  row={row}
                  disabled={!(row.modify || row.delete)}
                />
                <td>
                  <ChangeTextAreaForm
                    objectKey="eng"
                    row={row}
                    disabled={!(row.modify || row.delete)}
                  />
                </td>
                <td>
                  <ChangeTextAreaForm
                    objectKey="kor"
                    row={row}
                    disabled={!(row.modify || row.delete)}
                  />
                </td>
                <CheckModifyButton
                  row={row}
                  type="delete"
                  disabled={!row.modify}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default InputTable;
