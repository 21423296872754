import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faA, faBookOpen, faCircleQuestion, faClock } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function ShortCutCard() {
  const navigate = useNavigate();
  return (
    <Container>
      <ShortCutContainer>
        <ShortCutIcon onClick={() => navigate("/learningcenter/wordgame/main")}>
          <FontAwesomeIcon style={{ color: "#4b82fa" }} icon={faCircleQuestion} size="2x" />
          <p>Quiz</p>
        </ShortCutIcon>
        <ShortCutIcon onClick={() => navigate("/learningcenter/timeword/main")}>
          <FontAwesomeIcon style={{ color: "#4b82fa" }} icon={faClock} size="2x" />
          <p>Time Attack</p>
        </ShortCutIcon>
      </ShortCutContainer>
      <ShortCutContainer>
        <ShortCutIcon onClick={() => navigate("/learningcenter/voca")}>
          <FontAwesomeIcon style={{ color: "#4b82fa" }} icon={faA} size="2x" />
          <p>Voca</p>
        </ShortCutIcon>
        <ShortCutIcon onClick={() => navigate("/learningcenter/reading")}>
          <FontAwesomeIcon style={{ color: "#4b82fa" }} icon={faBookOpen} size="2x" />
          <p>Reading</p>
        </ShortCutIcon>
      </ShortCutContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ShortCutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShortCutIcon = styled.div`
  width: 25vmin;
  max-width: 150px;
  height: 25vmin;
  max-height: 150px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  p {
    margin: 0;
    margin-top: 5px;
    font-size: 14px;
  }
`;
