import growinglogo from "../../lib/assets/images/growingenglish.png";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";

const GrowingCarousel = (props) => {
  const pageType = props.pageType;

  // redux reducers
  const carouselList = useSelector((state) => state.carouselReducer); // 대문 item

  const carouselData = pageType === "teacher" ? carouselList.filter((row) => row.teacher) : carouselList.filter((row) => row.teacher === false);

  return (
    <Carousel variant="dark" fade className="mb-4">
      {carouselData.map((row, i) => {
        return (
          <Carousel.Item key={i}>
            <img className="d-block w-100" src={growinglogo} alt={i + "image"} />
            <Carousel.Caption style={{ color: "black" }}>
              <h4> {row.title} </h4>
              <p> {row.content} </p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default GrowingCarousel;
