/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GameTypeButton from "./GameTypeButton";
import PlusMinusButton from "./PlusMinusButton";
import GameClearButton from "./GameClearButton";
import UploadResultButton from "./UploadResultButton";
import AlertCard from "./AlertCard";
import GameBoard from "./GameBoard";
import AddPlayerButton from "./AddPlayerButton";
import { Col, Row } from "react-bootstrap";
import ReducePlayerButton from "./ReducePlayerButton";
import InputPromotion from "../Main/InputPromotion";

// 게임 테이블 컴포넌트
const GameTable = (props) => {
  // props
  // 현재 League 번호
  const level = props.level;

  return (
    <>
      <Row>
        <Col>
          <GameTypeButton level={level} />
          <PlusMinusButton level={level} />
          <GameClearButton level={level} />
          <UploadResultButton level={level} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <AddPlayerButton level={level} />
          <ReducePlayerButton level={level} />
          <InputPromotion table />
        </Col>
      </Row>
      <GameBoard level={level} />
      <AlertCard level={level} />
    </>
  );
};

export default GameTable;
