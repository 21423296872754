import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { displayDataClass } from "../../../../redux/DisplayData/actions";

// Display될 스케쥴의 반을 선택하는 Select 컴포넌트
const SelectDisplayClass = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);
  // 반 리스트 reducer
  const classes = useSelector((state) => state.classesReducer);

  // 기본값
  const defaultValue = displaySchedule.cid
    ? {
        id: displaySchedule.cid,
        name: classes.find((row) => row.id === displaySchedule.cid).name,
      }
    : null;

  // onChange 함수
  // displaySchedule의 cid, class 변경
  const changeClass = (e) => dispatch(displayDataClass(e));

  return (
    <>
      <p className="Form-label"> 반 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isClearable
        isSearchable
        options={classes}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        defaultValue={defaultValue}
        onChange={(e) => changeClass(e)}
        placeholder="반"
      />
      {!displaySchedule.cid && <RequiredText />}
    </>
  );
};

export default SelectDisplayClass;
