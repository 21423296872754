import { Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { rowColor } from "../../../Methods";
import ChangeDate from "../../SelectTable/ChangeDate";
import ChangeNumberForm from "../../SelectTable/ChangeNumberForm";
import ChangePodium from "../../SelectTable/ChangePodium";
import CheckAllModifyButton from "../../SelectTable/CheckAllModifyButton";
import CheckModifyButton from "../../SelectTable/CheckModifyButton";

const InputTable = () => {
  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <Row className="g-2 mx-2 mb-3">
      <Col md>
        <Table bordered striped hover size="sm" className="InputTable">
          <thead>
            <tr>
              <CheckAllModifyButton check="modify" width="10%" />
              <th width="35%">날짜</th>
              <th width="15%">이름</th>
              <th width="20%">순위</th>
              <th width="10%">1/2</th>
              <CheckAllModifyButton check="delete" width="10%" />
            </tr>
          </thead>
          <tbody>
            {inputData.map((row, i) => (
              <tr key={i} className={rowColor(row)}>
                <CheckModifyButton row={row} type="modify" />
                <td>
                  <ChangeDate row={row} disabled={!row.modify} />
                </td>
                <td>{row.name}</td>
                <td>
                  <ChangeNumberForm
                    objectKey="rank"
                    row={row}
                    disabled={!row.modify}
                    placeholder="Rank"
                  />
                </td>
                <td>
                  <ChangePodium row={row} disabled={!row.modify} />
                </td>
                <CheckModifyButton
                  row={row}
                  type="delete"
                  disabled={!row.modify}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default InputTable;
