import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../Methods";
import { selOptionKey } from "../../../redux/selOption/actions";

const ChangeSet = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const scInfo = selOption.scInfo || { minset: 1, maxset: 1 };

  // onChange 함수
  // selOption의 set 변경
  const changeSet = (e) => {
    dispatch(selOptionKey("set", Number(e.target.value)));
  };
  // selOption의 isSet 변경
  const changeIsSet = () => {
    dispatch(selOptionKey("isSet", !selOption.isSet));
  };

  return (
    <>
      <p className="Form-label"> 세트 </p>
      <InputGroup>
        <Form.Control
          style={{ textAlign: "center" }}
          min={scInfo.minset}
          max={scInfo.maxset}
          type="number"
          onChange={(e) => changeSet(e)}
          placeholder="회차"
          disabled={disabled}
          value={selOption.set || ""}
        />
        <Button onClick={changeIsSet}>
          {selOption.isSet ? "해제" : "사용"}
        </Button>
      </InputGroup>
      {!disabled &&
        (selOption.set > scInfo.maxset || selOption.set < scInfo.minset) && (
          <RequiredText
            text={
              scInfo.minset + "~" + scInfo.maxset + " 사이의 숫자를 입력하세요."
            }
          />
        )}
    </>
  );
};

export default ChangeSet;
