/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useStudentList } from '../../../firestoreData';
import { getFireStoreData } from '../../../redux/FirebaseData/DownloadController/reducer';
import { loadingComponent } from '../../../redux/Loading/actions';
import { setKahootInput, setQuizizzInput } from '../../../redux/Quiz_InputData/actions';

// 퀴즈 파일을 선택하여 inputData로 전송할 파일 선택 컴포넌트
const SelectFile = (props) => {
  // props
  // QuizInput.js에서 넘어온 파일 reference props
  const fileRef = props.fileRef;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // select option reducer
  const qidPart = useSelector((state) => state.qidPartReducer);
  // download controler reducer
  const downloadPart = useSelector((state) => state.downloadControlerReducer).qidPart;

  // 파일 Select 컴포넌트 비활성화 조건, selOption 내 퀴즈와 날짜가 비어있으면 비활성화
  const fileDisabled = selOption.quiz === null || selOption.date === null;

  // useFirestoreData.js 의 해당 지점 전체 학생 데이터
  const student = useStudentList();

  // onChange 함수
  // 파일 선택 시 inputData로 넘기기 전 계산 과정 선택 함수
  const quizReport = (e) => {
    // loading reducer 내 component state를 true로 변경
    dispatch(loadingComponent(true));
    // selOption 내 quiz state가 Kahoot일 경우 setKahootInput, 아닐 경우 setQuizizzInput 실행
    selOption.quiz === 'Kahoot'
      ? dispatch(setKahootInput(e, student, qidPart))
      : dispatch(setQuizizzInput(e, student, qidPart));
  };

  useEffect(() => {
    // qid 데이터 다운로드 토글 true로
    !downloadPart && dispatch(getFireStoreData('qidPart'));
  }, []);

  return (
    <Col>
      <p className="Form-label"> 파일 (.xls/ .xlsx만 가능) </p>
      <Form.Control
        disabled={fileDisabled}
        ref={fileRef}
        type="file"
        accept=".xls, .xlsx"
        onChange={quizReport}
      />
    </Col>
  );
};

export default SelectFile;
