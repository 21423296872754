import db from "../../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

const DeletePresetButton = (props) => {
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 사용자 정보 reducers
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  const deletePreset = () => {
    deleteDoc(doc(db, "Users", myInfo.uid, "studentSetting", row.id));
    dispatch(modalShowPopup(false));
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal(
        "삭제하시겠습니까?",
        "이 작업은 실행 후 되돌릴 수 없습니다.",
        deletePreset,
        "삭제"
      )
    );
  };

  return (
    <>
      <Button onClick={openPopup} className="py-0" variant="dark">
        삭제
      </Button>
    </>
  );
};

export default DeletePresetButton;
