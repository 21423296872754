import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import {
  MoveUserDatas,
  MoveCompetitionScore,
  MoveTestScore,
  MoveUserAttendStat,
  MoveUserCompetitionStat,
  MoveUserQuizStat,
  MoveUserTestScoreStat,
  MoveStudentSetting,
  DeleteWords,
} from "./moveUserData";

export default function UploadData() {
  const [userID, setUserID] = useState({ uID: "", changeUID: "" });

  const changeOriginalID = (e) => {
    setUserID({ ...userID, uID: e.target.value });
  };
  const changeNewID = (e) => {
    setUserID({ ...userID, changeUID: e.target.value });
  };

  const uID = userID.uID;
  const changeUID = userID.changeUID;
  const changeStats = () => {
    // MoveUserDatas(uID, changeUID);
    // MoveTestScore(uID, changeUID);
    // MoveCompetitionScore(uID, changeUID);
    // MoveUserAttendStat(uID, changeUID);
    // MoveUserCompetitionStat(uID, changeUID);
    // MoveUserQuizStat(uID, changeUID);
    // MoveUserTestScoreStat(uID, changeUID);
    // MoveStudentSetting(uID, changeUID);
    DeleteWords("중등");
  };

  return (
    <>
      <Row className="g-2 m-3">
        <Col>
          <p className="Form-label"> 원래 UID </p>
          <Form.Control type="text" onChange={changeOriginalID} />
        </Col>
        <Col>
          <p className="Form-label"> 바꿀 UID </p>
          <Form.Control type="text" onChange={changeNewID} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={changeStats}>Move Stats</Button>
        </Col>
      </Row>
    </>
  );
}
