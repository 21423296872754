import styled from "@emotion/styled";
import { Colors } from "styles/colors";
import { Body1 } from "styles/typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;

  width: 100%;
  max-width: 1440px;

  padding: 16px 24px;

  position: fixed;
  top: 0;

  background-color: ${Colors.white};

  z-index: 100;
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;

  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  padding: 0;

  gap: 8px;

  flex: 1;
`;

export const Logo = styled.img`
  width: 20px;
  height: 20px;
`;

export const Wordmark = styled.img`
  width: auto;
  height: 12px;
`;

export const MenuButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuContainer = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1440px;

  padding: 16px 24px;

  gap: 16px;

  background-color: ${Colors.white};

  position: fixed;
  top: ${({ isMenuOpen }) => (isMenuOpen ? 56 : -100)}px;

  transition: all 0.3s ease-in-out;

  z-index: 99;
`;

export const ListItem = styled.span`
  ${Body1};
  color: ${Colors.gray1};

  text-align: left;

  user-select: none;

  cursor: pointer;

  &:hover,
  &:active {
    color: ${Colors.blue1};
  }

  transition: all 0.2s ease-in-out;
`;
