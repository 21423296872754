import { Col, Row } from "react-bootstrap";
import DashboardCard from "../../Component/DashboardCard";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import useRedirect from "../../hooks/useRedirect";
import ShortCutCard from "../../Component/ShortCutCard";
import { useEffect, useState } from "react";
import { getMyTodoList } from "../../redux/FirebaseData/Todo/studentTodoReducer";
import { calculateDate } from "../../lib/utils/date";
import NoticeCard from "../../Component/NoticeCard";
import BasicLayout from "components/templates/basic-layout";
import * as S from "./DashBoard.styled";
import { Todo } from "types";

const DashBoard = () => {
  const dispatch = useDispatch();

  const todoList = useSelector((state: RootStateOrAny) => state.studentTodoReducer); // todoList
  const validTodoList = todoList.filter(({ check, deadline }: Todo) => !check || new Date(deadline) >= calculateDate(-7));
  const myInfo = useSelector((state: RootStateOrAny) => state.currentUserReducer).storeInfo; // 사용자 정보

  const [more, setMore] = useState(false);

  useRedirect("learningcenter", "home");

  useEffect(() => {
    if (!myInfo.uid) return;
    dispatch(getMyTodoList(myInfo.uid));
  }, [dispatch, myInfo.uid]);

  return (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <S.ProfileSection>
            <S.NameSection>
              <S.Label>Hello!</S.Label>
              <S.Name>{myInfo.name}</S.Name>
            </S.NameSection>
            <S.ToDoNubmerBox>
              <S.Label>To-do</S.Label>
              <S.ToDoNumber>{validTodoList.filter(({ check }: Todo) => !check).length}</S.ToDoNumber>
            </S.ToDoNubmerBox>
          </S.ProfileSection>
          <NoticeCard />
          <DashboardCard todoList={validTodoList} more={more} setMore={setMore} admin={false} />
          <Row className="mt-4">
            <Col>
              <ShortCutCard />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default DashBoard;
