/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, ButtonGroup } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import InputTable from "../../../Component/Quiz/ReviewNote/InputTable";
import SavePresetModal from "../../../Component/StudentPreset/Save/SavePresetModal";
import LoadPresetModal from "../../../Component/StudentPreset/Load/LoadPresetModal";
import SelectQuizPart from "../../../Component/Select/SelectQuizPart";
import { useDispatch, useSelector } from "react-redux";
import { selOptionClear } from "../../../redux/selOption/actions";
import SelectDateRange from "../../../Component/Quiz/ReviewNote/SelectDateRange";
import DisplayStartEnd from "../../../Component/Quiz/ReviewNote/DisplayStartEnd";
import SelectPercentage from "../../../Component/Quiz/ReviewNote/SelectPercentage";
import LoadDataButton from "../../../Component/Quiz/ReviewNote/LoadDataButton";
import ClearDataButton from "../../../Component/Quiz/ReviewNote/ClearDataButton";
import { loadingFalse } from "../../../redux/Loading/actions";
import LoadStatButton from "../../../Component/Quiz/ReviewNote/LoadStatButton";
import PrintButton from "../../../Component/Quiz/ReviewNote/PrintButton";
import HitMap from "../../../Component/Quiz/ReviewNote/HitMap/HitMap";
import PrintPage from "../../../Component/Quiz/ReviewNote/PrintPage/PrintPage";
import { inputDataClear } from "../../../redux/InputData/actions";
import useRedirect from "../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

// 오답노트 컨테이너
const ReviewNote = () => {
  // redux dispatch
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  // 프린트할 컴포넌트
  const printRef = useRef(null);

  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // loading reducer
  const warning = useSelector((state) => state.loadingReducer).warning;

  // 마운트시 최초 한 번만 실행
  useEffect(() => {
    dispatch(loadingFalse());
    dispatch(inputDataClear());
    dispatch(selOptionClear("ReviewNote"));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="g-4 mb-5 pb-2 mx-3">
            <Col md>
              <p className="Form-label"> 오답노트 학생 설정 </p>
              <InputTable />
            </Col>
            <Col md>
              <SelectQuizPart title="퀴즈 파트" required disabled={warning && quizInputData.length !== 0} />
              <SelectDateRange disabled={warning && quizInputData.length !== 0} />
              <DisplayStartEnd />
              <SelectPercentage />

              <ButtonGroup>
                <LoadDataButton />
                <ClearDataButton />
                <LoadStatButton />
                <PrintButton printRef={printRef} />
              </ButtonGroup>
            </Col>
          </Row>
          <HitMap />
          <PrintPage printRef={printRef} />
          <SavePresetModal />
          <LoadPresetModal />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ReviewNote;
