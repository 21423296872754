/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  pageTypeNormal,
  pageTypeStudent,
  pageTypeTeacher,
} from "../redux/PageType/reducer";

export default function usePageType(type) {
  const dispatch = useDispatch();
  const pageType = () => {
    switch (type) {
      case "student":
        return dispatch(pageTypeStudent());
      case "teacher":
        return dispatch(pageTypeTeacher());
      default:
        return dispatch(pageTypeNormal());
    }
  };

  useEffect(() => {
    pageType();
  }, []);
}
