import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataClear } from "../../../redux/InputData/actions";
import { setQuizInputDataClear } from "../../../redux/Quiz_InputData/actions";
import { clearQIDData } from "../../../redux/QIDData/reducers";
import { loadingFalse } from "../../../redux/Loading/actions";

const ClearDataButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);

  const onClick = () => {
    dispatch(inputDataClear());
    dispatch(setQuizInputDataClear());
    dispatch(clearQIDData());
    dispatch(loadingFalse());
  };

  return (
    <>
      {quizInputData.length !== 0 && (
        <Button variant="warning" className="mt-3" onClick={onClick}>
          초기화
        </Button>
      )}
    </>
  );
};

export default ClearDataButton;
