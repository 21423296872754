import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import db from "../../../firebase";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import {
  loadingComponent,
  loadingActive,
  loadingWarning,
} from "../../../redux/Loading/actions";
import { setQuizLoadData } from "../../../redux/Quiz_InputData/actions";
import { clearQIDData } from "../../../redux/QIDData/reducers";

const LoadDataButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // loading reducer
  const loading = useSelector((state) => state.loadingReducer).component;

  function sumObj(objArr) {
    // an object to store the `created` and `amount` as key=>value
    let newObj = {};
    // loop over the objects in the array
    objArr.forEach(function (obj) {
      // if the key is present in `newObj` then we need to add the amount to it's value
      if (obj.sid + obj.qid + obj.name in newObj) {
        newObj[obj.sid + obj.qid + obj.name].correct += obj.correct;
        newObj[obj.sid + obj.qid + obj.name].count += obj.count;
      } else {
        // else just add the key and the amount as value
        newObj[obj.sid + obj.qid + obj.name] = {
          correct: obj.correct,
          count: obj.count,
        };
      }
    });
    // create an array to store the final objects
    let arr = [];
    // loop over the properties in `newObj`
    for (let prop in newObj) {
      // push an object each time
      arr.push({
        sid: prop.substring(0, 28),
        qid: prop.substring(28, 34),
        name: prop.substring(34).trim(),
        correct: newObj[prop].correct,
        count: newObj[prop].count,
      });
    }
    // return the final result
    return arr;
  }

  const loadData = () => {
    dispatch(loadingComponent(true));
    dispatch(clearQIDData());
    let quizArray = [];
    inputData.map(async (row) => {
      selOption.week.map(async (res) => {
        const userCollection =
          selOption.part !== "모든 문제"
            ? query(
                collection(db, "Users", row.sid, "quizStat", res, "qidStat"),
                where("part", "==", selOption.part)
              )
            : query(
                collection(db, "Users", row.sid, "quizStat", res, "qidStat")
              );
        const unsub = onSnapshot(userCollection, (snapshot) => {
          quizArray = [
            ...quizArray,
            ...snapshot.docs.map((doc) => ({
              ...doc.data(),
              sid: row.sid,
              name: row.name,
              qid: doc.id,
            })),
          ];
          dispatch(setQuizLoadData(sumObj(quizArray)));
          quizArray.length !== 0 && loadingActive(true);
          setTimeout(() => unsub(), 1000);
        });
      });
    });
    dispatch(loadingWarning(true));
    dispatch(loadingActive(true));
    setTimeout(() => {
      dispatch(loadingComponent(false));
    }, 2000);
  };

  return (
    <>
      {quizInputData.length === 0 && (
        <Button
          className="mt-3 Growing-button"
          onClick={loadData}
          disabled={inputData.length === 0}
        >
          {loading ? <LoadingSpinner /> : "데이터 불러오기"}
        </Button>
      )}
    </>
  );
};

export default LoadDataButton;
