/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../../redux/selOption/actions";

const DropDownClass = () => {
  // dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // select option reducer
  const competition = useSelector((state) => state.statArrayReducer).comArray;

  const classes = useSelector((state) => state.classesReducer);
  // loading reducer component state
  const loading = useSelector((state) => state.loadingReducer).component;

  let competitionClass = competition
    .map(function (val, index) {
      return val["cid"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({
      id: row,
      name: classes.find((a) => a.id === row).name,
    }));

  const changeCID = (row) => {
    const payload = row ? row : { id: null, name: null };
    dispatch(selOptionKey("cid", payload.id));
    dispatch(selOptionKey("class", payload.name));
  };

  useEffect(() => {
    competitionClass[0] !== []
      ? changeCID(competitionClass[0])
      : changeCID({ id: "", class: "Competition" });
  }, [loading]);

  return (
    <DropdownButton
      variant="light"
      className="App-left mb-3"
      title={selOption.class || "Competition"}
      size="sm"
    >
      {competitionClass.map((row) => {
        return (
          <Dropdown.Item
            key={row.id}
            eventKey={row.id}
            onClick={() => changeCID(row)}
          >
            {row.name}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default DropDownClass;
