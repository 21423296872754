import { collection, getDocs, orderBy, query } from "firebase/firestore";
import db from "../firebase";

export async function getOffice() {
  const officeCollection = query(collection(db, "Office"), orderBy("name", "asc"));
  const querySnapshot = await getDocs(officeCollection);
  let officeArray = [];
  querySnapshot.forEach((doc) => officeArray.push({ ...doc.data(), id: doc.id }));
  return officeArray;
}
