import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { inputDataQuizCreate } from "../../../../redux/InputData/actions";
import { modalShowClear } from "../../../../redux/Modal_Show/actions";
import { clearQuestionOption } from "../../../../redux/QuestionOption/reducer";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);
  const answerArray = useSelector(
    (state) => state.questionOptionReducer
  ).answerArray;
  const add = useSelector((state) => state.modalShowReducer).add;

  const regQid = /^[A-Z]{2}[0-9]{4}$/;

  const buttonDisabled =
    !regQid.test(questionOption.qid) ||
    questionOption.label === "" ||
    questionOption.question === "" ||
    (questionOption.answer === "" && questionOption.type === "short") ||
    ((questionOption.num1 === "" || questionOption.num2 === "") &&
      questionOption.type !== "short") ||
    (questionOption.numAnswer === "" && questionOption.type === "choice") ||
    ((questionOption.numAnswer === "" ||
      Object.values(answerArray).filter((x) => x).length < 2) &&
      questionOption.type === "checkBox");

  const onClick = () => {
    if (add) {
      dispatch(inputDataQuizCreate(questionOption, "create"));
    } else {
      dispatch(inputDataQuizCreate(questionOption, "modify"));
    }
    dispatch(modalShowClear());
    dispatch(clearQuestionOption());
  };

  const requireCondition =
    (questionOption.numAnswer === "" && questionOption.type === "choice") ||
    ((questionOption.numAnswer === "" ||
      Object.values(answerArray).filter((x) => x).length < 2) &&
      questionOption.type === "checkBox");

  return (
    <>
      <Button
        className="Growing-button"
        disabled={buttonDisabled}
        onClick={onClick}
      >
        완료
      </Button>
      {requireCondition && <RequiredText text="정답을 선택하세요." />}
    </>
  );
};

export default ControlButton;
