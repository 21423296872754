const GET_CAROUSEL = "GET_CAROUSEL";

export const getCarouselData = (payload) => ({
  type: GET_CAROUSEL,
  payload,
});

const carouselReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CAROUSEL:
      return action.payload;
    default:
      return state;
  }
};

export default carouselReducer;
