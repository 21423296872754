/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";
import { RequiredText } from "../../Methods";

// select option의 지점을 변경하기 위한 Select 컴포넌트
const SelectQuizPart = (props) => {
  // props
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 퀴즈 파트
  const quizParts = [
    { part: "모든 문제" },
    { part: "GRA" },
    { part: "GRB" },
    { part: "LIS" },
    { part: "TEP" },
  ];

  // require message 출력 조건, props로 required가 넘어오고, selOption의 part가 null일 경우
  const returnRequire = required && !selOption.part;

  // onChange 함수
  // select option reducer의 part 변경 함수
  const changePart = (e) => {
    const returnValue = e ? e : { part: null };
    dispatch(selOptionKey("part", returnValue.part));
  };

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="파트 선택"
        isSearchable
        isClearable={clearable}
        isDisabled={disabled}
        options={quizParts}
        getOptionLabel={(e) => e.part}
        getOptionValue={(e) => e.part}
        maxMenuHeight={150}
        // isDisabled={warning && quizInputData.length !== 0}
        value={selOption}
        onChange={(e) => changePart(e)}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectQuizPart;
