/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ButtonGroup, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OptionAccordion from "../../../../Component/Schedule/Input/OptionAccordion";
import { selOptionClear } from "../../../../redux/selOption/actions";
import DayLoadButton from "../../../../Component/Schedule/Input/DayLoadButton";
import ClearButton from "../../../../Component/Schedule/Input/ClearButton";
import UploadButton from "../../../../Component/Schedule/Input/UploadButton";
import InputTable from "../../../../Component/Schedule/Input/InputTable";
import CreateRowButton from "../../../../Component/Schedule/Input/CreateRowButton";
import DeleteRowButton from "../../../../Component/Schedule/Input/DeleteRowButton";
import { inputDataClear } from "../../../../redux/InputData/actions";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";
import usePageBlock from "../../../../hooks/usePageBlock";
import useRedirect from "../../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

// 스케쥴을 입력하기 위한 컨테이너
const ScheduleInput = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // download controler reducer
  const downloadSchedule = useSelector((state) => state.downloadControlerReducer).schedule;

  // inputData 내 데이터가 있으면 페이지 이탈 방지
  usePageBlock(inputData.length);

  useRedirect("teachingcenter", "home");

  // 컨테이너 마운트 시 최초 한번만 실행
  useEffect(() => {
    // select option reducer를 스케쥴 인풋에 맞게 재설정
    dispatch(selOptionClear("ScheduleInput"));
    // schedule inputData 초기화
    dispatch(inputDataClear());
    // 스케쥴 데이터 다운로드 토글 true로
    !downloadSchedule && dispatch(getFireStoreData("schedule"));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <OptionAccordion />
          <Row className="px-2 mx-3">
            <Col>
              <ButtonGroup style={{ float: "right" }}>
                <DayLoadButton />
                <ClearButton />
                <UploadButton />
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="g-4 pb-2 mx-3">
            <Col>
              <hr />
            </Col>
          </Row>
          <Row className="g-4 mb-5 pb-2 mx-3">
            <Col>
              <InputTable />
              <CreateRowButton />
              <DeleteRowButton />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ScheduleInput;
