// 공용
// 초기화
export const INPUTDATA_CLEAR = "INPUTDATA_CLEAR";
// 행 추가
export const INPUTDATA_CREATE = "INPUTDATA_CREATE";
// 행 삭제
export const INPUTDATA_DELETE = "INPUTDATA_DELETE";
// 행에 순위권 변경
export const INPUTDATA_PODIUM = "INPUTDATA_PODIUM";
// payload로 넘어온 Array를 InputData로 입력
export const INPUTDATA_SET = "INPUTDATA_SET";
// KEY를 입력하여 그 KEY에 해당하는 값 수정
export const INPUTDATA_KEY = "INPUTDATA_KEY";
// 프리셋에서 받아온 데이터를 InputData로 입력
export const INPUTDATA_PRESET_LOAD = "INPUTDATA_PRESET_LOAD";
// 데이터를 수정/삭제하겠다고 체크
export const INPUTDATA_CHECK = "INPUTDATA_CHECK";
// 전체 데이터를 수정/삭제하겠다고 체크
export const INPUTDATA_ALL_CHECK = "INPUTDATA_ALL_CHECK";
// 체크 해제 및 삭제된 데이터 제거
export const INPUTDATA_MODIFY_ARRAY = "INPUTDATA_MODIFY_ARRAY";

// Competition
// 행에 순위 추가 및 변경
export const INPUTDATA_COM_RANK = "INPUTDATA_COM_RANK";
// 행의 순위를 사용하여 리그 자동 리턴
export const INPUTDATA_COM_LEAGUE = "INPUTDATA_COM_LEAGUE";
// Competition 테이블 정렬
export const INPUTDATA_COM_SORT = "INPUTDATA_COM_SORT";
// Competition Saga
// 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기
export const INPUTDATA_COM_LOADDATA = "INPUTDATA_COM_LOADDATA";
// 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 성공 시
export const INPUTDATA_COM_LOADDATA_SUCCESS = "INPUTDATA_COM_LOADDATA_SUCCESS";
// 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 실패 시
export const INPUTDATA_COM_LOADDATA_ERROR = "INPUTDATA_COM_LOADDATA_ERROR";
// 행에 학생 추가 및 변경 + 평균 불러오기
export const INPUTDATA_COM_SID = "INPUTDATA_COM_SID";
// 행에 학생 추가 및 변경 + 평균 불러오기 성공 시
export const INPUTDATA_COM_SID_SUCCESS = "INPUTDATA_COM_SID_SUCCESS";
// 행에 학생 추가 및 변경 + 평균 불러오기 실패 시
export const INPUTDATA_COM_SID_ERROR = "INPUTDATA_COM_SID_ERROR";
// competition modify saga
// 수정할 데이터 로드
export const INPUTDATA_COM_MODIFY_SET = "INPUTDATA_COM_MODIFY_SET";
// 수정할 데이터 로드 성공 시
export const INPUTDATA_COM_MODIFY_SET_SUCCESS =
  "INPUTDATA_COM_MODIFY_SET_SUCCESS";
// 수정할 데이터 로드 실패 시
export const INPUTDATA_COM_MODIFY_SET_ERROR = "INPUTDATA_COM_MODIFY_SET_ERROR";

// Schedule
// 평일 불러오기
export const INPUTDATA_SCHEDULE_DAYSET = "INPUTDATA_SCHEDULE_DAYSET";
// Schedule Update
// Schedule Update Saga
// 해당 조건에 맞는 스케쥴 불러오기
export const INPUTDATA_SCHEDULE_UDT_DATASET = "INPUTDATA_SCHEDULE_UDT_DATASET";
// 해당 조건에 맞는 스케쥴 불러오기 성공 시
export const INPUTDATA_SCHEDULE_UDT_DATASET_SUCCESS =
  "INPUTDATA_SCHEDULE_UDT_DATASET_SUCCESS";
// 해당 조건에 맞는 스케쥴 불러오기 실패 시
export const INPUTDATA_SCHEDULE_UDT_DATASET_ERROR =
  "INPUTDATA_SCHEDULE_UDT_DATASET_ERROR";

// Test
// Test Modify Saga
// 수정할 데이터 로드
export const INPUTDATA_TEST_MODIFY_SET = "INPUTDATA_TEST_MODIFY_SET";
// 수정할 데이터 로드 성공 시
export const INPUTDATA_TEST_MODIFY_SET_SUCCESS =
  "INPUTDATA_TEST_MODIFY_SET_SUCCESS";
// 수정할 데이터 로드 실패 시
export const INPUTDATA_TEST_MODIFY_SET_ERROR =
  "INPUTDATA_TEST_MODIFY_SET_ERROR";

// Quiz
// Quiz 행 생성
export const INPUTDATA_QUIZ_CREATE = "INPUTDATA_QUIZ_CREATE";
// Quiz 행 생성 성공 시
export const INPUTDATA_QUIZ_CREATE_SUCCESS = "INPUTDATA_QUIZ_CREATE_SUCCESS";
// Quiz 행 생성 실패 시
export const INPUTDATA_QUIZ_CREATE_ERROR = "INPUTDATA_QUIZ_CREATE_ERROR";
// Quiz Upload Saga
// Quiz Excel 파일에서 업로드
export const INPUTDATA_QUIZ_UPLOAD = "INPUTDATA_QUIZ_UPLOAD";
// Quiz Excel 파일에서 업로드 성공 시
export const INPUTDATA_QUIZ_UPLOAD_SUCCESS = "INPUTDATA_QUIZ_UPLOAD_SUCCESS";
// Quiz Excel 파일에서 업로드 실패 시
export const INPUTDATA_QUIZ_UPLOAD_ERROR = "INPUTDATA_QUIZ_UPLOAD_ERROR";
// Quiz Modify Saga
// 수정할 Question 로드
export const INPUTDATA_QUESTION_MODIFY = "INPUTDATA_QUESTION_MODIFY";
// 수정할 Question 로드 성공 시
export const INPUTDATA_QUESTION_MODIFY_SUCCESS =
  "INPUTDATA_QUESTION_MODIFY_SUCCESS";
// 수정할 Question 로드 실패 시
export const INPUTDATA_QUESTION_MODIFY_ERROR =
  "INPUTDATA_QUESTION_MODIFY_ERROR";

// Words
// Words Modify Saga
// 수정할 Data 로드
export const INPUTDATA_WORDS_MODIFY_SET = "INPUTDATA_WORDS_MODIFY_SET";
// 수정할 Data 로드 성공 시
export const INPUTDATA_WORDS_MODIFY_SET_SUCCESS =
  "INPUTDATA_WORDS_MODIFY_SET_SUCCESS";
// 수정할 Data 로드 실패 시
export const INPUTDATA_WORDS_MODIFY_SET_ERROR =
  "INPUTDATA_WORDS_MODIFY_SET_ERROR";
