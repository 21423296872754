import * as inputType from "./types";
import * as inputFn from "./function";
import { call, put, takeEvery } from "redux-saga/effects";

// 공용
// 초기화
export const inputDataClear = () => ({
  type: inputType.INPUTDATA_CLEAR,
});
// 행 추가
export const inputDataCreate = (createType, payload) => ({
  type: inputType.INPUTDATA_CREATE,
  createType,
  payload,
});
// 행 삭제
export const inputDataDelete = (id, createType, payload) => ({
  type: inputType.INPUTDATA_DELETE,
  id,
  createType,
  payload,
});
// 행의 순위권 변경
export const inputDataPodium = (id, payload) => ({
  type: inputType.INPUTDATA_PODIUM,
  id,
  payload,
});
// payload로 넘어온 Array를 InputData로 입력
export const inputDataSetting = (payload) => ({
  type: inputType.INPUTDATA_SET,
  payload,
});
// KEY를 입력하여 그 KEY에 해당하는 값 수정
export const inputDataKey = (key, id, payload) => ({
  type: inputType.INPUTDATA_KEY,
  key,
  id,
  payload,
});
// 프리셋에서 받아온 데이터를 InputData로 입력
export const inputDataPresetLoad = (payload) => ({
  type: inputType.INPUTDATA_PRESET_LOAD,
  payload: payload.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
});
// 데이터를 수정/삭제하겠다고 체크
export const inputDataCheck = (id, check) => ({
  type: inputType.INPUTDATA_CHECK,
  id,
  check,
});
// 전체 데이터를 수정/삭제하겠다고 체크
export const inputDataAllCheck = (check, payload) => ({
  type: inputType.INPUTDATA_ALL_CHECK,
  check,
  payload,
});
// 체크 해제 및 삭제된 데이터 제거
export const inputDataModifyArray = (payload) => ({
  type: inputType.INPUTDATA_MODIFY_ARRAY,
  payload,
});

// Competition
// 행에 순위 추가 및 변경
export const inputDataComRank = (id, rank, league) => ({
  type: inputType.INPUTDATA_COM_RANK,
  id,
  rank,
  league,
});
// 행의 순위를 사용하여 리그 자동 리턴
export const inputDataComLeague = (payload) => ({
  type: inputType.INPUTDATA_COM_LEAGUE,
  payload,
});
// Competition 테이블 정렬
export const inputDataComSort = (sortType, payload) => ({
  type: inputType.INPUTDATA_COM_SORT,
  sortType,
  payload,
});
// 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기
export const inputDataComLoadData = (selOption, payload, extra, proportion) => ({
  type: inputType.INPUTDATA_COM_LOADDATA,
  selOption,
  payload,
  extra,
  proportion,
});
// 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 Saga
function* inputDataComLoadDataSaga(action) {
  const selOption = action.selOption;
  const league = action.payload;
  const extra = action.extra;
  const proportion = action.proportion;
  try {
    // 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 성공 시
    const loadData = yield call(inputFn.getDatas, selOption, league, extra, proportion);
    yield put({
      type: inputType.INPUTDATA_COM_LOADDATA_SUCCESS,
      loadData,
    });
  } catch (e) {
    // 해당하는 날짜와 반의 조건에 맞는 데이터 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_COM_LOADDATA_ERROR,
      error: true,
      e,
    });
  }
}
// 행에 학생 추가 및 변경 + 평균 불러오기
export const inputDataComSid = (id, payload, cid, extra, proportion) => ({
  type: inputType.INPUTDATA_COM_SID,
  id,
  payload,
  cid,
  extra,
  proportion,
});
// 행에 학생 추가 및 변경 + 평균 불러오기 Saga
function* inputDataComSidSaga(action) {
  const id = action.id;
  const payload = action.payload;
  const cid = action.cid;
  const extra = action.extra;
  const proportion = action.proportion;
  try {
    // 행에 학생 추가 및 변경 + 평균 불러오기 성공 시
    const average = yield call(inputFn.syncAverage, payload.id, cid, extra, proportion);
    yield put({
      type: inputType.INPUTDATA_COM_SID_SUCCESS,
      id,
      payload,
      average,
    });
  } catch (e) {
    // 행에 학생 추가 및 변경 + 평균 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_COM_SID_ERROR,
      id,
      payload,
      error: true,
      e,
    });
  }
}
// competition modify saga
// 수정할 데이터 로드
export const inputDataComModifySet = (payload) => ({
  type: inputType.INPUTDATA_COM_MODIFY_SET,
  payload,
});
// 수정할 데이터 로드 saga
function* inputDataComModifySetSaga(action) {
  const selOption = action.payload;
  try {
    // 해당 조건에 맞는 스케쥴 불러오기 성공 시
    const payload = yield call(inputFn.getCompetitionData, selOption);
    yield put({
      type: inputType.INPUTDATA_COM_MODIFY_SET_SUCCESS,
      payload,
    });
  } catch (e) {
    // 해당 조건에 맞는 스케쥴 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_COM_MODIFY_SET_ERROR,
      error: true,
      e,
    });
  }
}

// Schedule
// 평일 불러오기
export const inputDataSchDaySet = (selOption) => ({
  type: inputType.INPUTDATA_SCHEDULE_DAYSET,
  selOption,
});
// Schedule Update
// Schedule Update Saga
// 해당 조건에 맞는 스케쥴 불러오기
export const inputDataSchUdtDataSet = (scheduleData, selOption) => ({
  type: inputType.INPUTDATA_SCHEDULE_UDT_DATASET,
  scheduleData,
  selOption,
});
// 해당 조건에 맞는 스케쥴 불러오기 saga
function* inputDataSchUdtDataSetSaga(action) {
  const scheduleData = action.scheduleData;
  const selOption = action.selOption;
  try {
    // 해당 조건에 맞는 스케쥴 불러오기 성공 시
    const payload = yield call(inputFn.scheduleLoad, scheduleData, selOption);
    yield put({
      type: inputType.INPUTDATA_SCHEDULE_UDT_DATASET_SUCCESS,
      payload,
    });
  } catch (e) {
    // 해당 조건에 맞는 스케쥴 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_SCHEDULE_UDT_DATASET_ERROR,
      error: true,
      e,
    });
  }
}

// Test
// test modify saga
// 수정할 데이터 로드
export const inputDataTestModifySet = (payload) => ({
  type: inputType.INPUTDATA_TEST_MODIFY_SET,
  payload,
});
// 수정할 데이터 로드 saga
function* inputDataTestModifySetSaga(action) {
  const selOption = action.payload;
  try {
    // 해당 조건에 맞는 스케쥴 불러오기 성공 시
    const payload = yield call(inputFn.getTestData, selOption);
    yield put({
      type: inputType.INPUTDATA_TEST_MODIFY_SET_SUCCESS,
      payload,
    });
  } catch (e) {
    // 해당 조건에 맞는 스케쥴 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_TEST_MODIFY_SET_ERROR,
      error: true,
      e,
    });
  }
}

// Quiz
// Quiz 행 생성
export const inputDataQuizCreate = (payload, modify) => ({
  type: inputType.INPUTDATA_QUIZ_CREATE,
  payload,
  modify,
});
// Quiz 행 생성 saga
function* inputDataQuizCreateSaga(action) {
  const question = action.payload;
  const modify = action.modify;
  try {
    // 수정할 Question 로드 성공 시
    const payload = yield call(inputFn.getQuizRowData, question, modify);
    yield put({
      type: inputType.INPUTDATA_QUIZ_CREATE_SUCCESS,
      payload,
      modify,
    });
  } catch (e) {
    // 수정할 Question 로드 실패 시
    yield put({
      type: inputType.INPUTDATA_QUIZ_CREATE_ERROR,
      error: true,
      e,
    });
  }
}
// Question modify saga
// 수정할 Question 로드
export const inputDataQuestionModifySet = (payload) => ({
  type: inputType.INPUTDATA_QUESTION_MODIFY,
  payload,
});
// 수정할 Question 로드 saga
function* inputDataQuestionModifySetSaga(action) {
  const qid = action.payload;
  try {
    // 수정할 Question 로드 성공 시
    const payload = yield call(inputFn.getQuestionData, qid);
    yield put({
      type: inputType.INPUTDATA_QUESTION_MODIFY_SUCCESS,
      payload,
    });
  } catch (e) {
    // 수정할 Question 로드 실패 시
    yield put({
      type: inputType.INPUTDATA_QUESTION_MODIFY_ERROR,
      error: true,
      e,
    });
  }
}
// Quiz Upload Saga
// Quiz Excel 파일에서 업로드
export const inputDataQuizUpload = (payload, inputDataLength) => ({
  type: inputType.INPUTDATA_QUIZ_UPLOAD,
  payload,
  inputDataLength,
});
// Quiz Excel 파일에서 업로드 saga
function* inputDataQuizUploadSaga(action) {
  const file = action.payload;
  const inputDataLength = action.inputDataLength;
  try {
    // Quiz Excel 파일에서 업로드 성공 시
    const payload = yield call(inputFn.getQuizData, file, inputDataLength);
    yield put({
      type: inputType.INPUTDATA_QUIZ_UPLOAD_SUCCESS,
      payload,
    });
  } catch (e) {
    // Quiz Excel 파일에서 업로드 실패 시
    yield put({
      type: inputType.INPUTDATA_QUIZ_UPLOAD_ERROR,
      error: true,
      e,
    });
  }
}

// words modify saga
// 수정할 데이터 로드
export const inputDataWordsModifySet = (payload) => ({
  type: inputType.INPUTDATA_WORDS_MODIFY_SET,
  payload,
});
// 수정할 데이터 로드 saga
function* inputDataWordsModifySetSaga(action) {
  const selOption = action.payload;
  try {
    // 해당 조건에 맞는 스케쥴 불러오기 성공 시
    const payload = yield call(inputFn.getWordsData, selOption);
    yield put({
      type: inputType.INPUTDATA_WORDS_MODIFY_SET_SUCCESS,
      payload,
    });
  } catch (e) {
    // 해당 조건에 맞는 스케쥴 불러오기 실패 시
    yield put({
      type: inputType.INPUTDATA_WORDS_MODIFY_SET_ERROR,
      error: true,
      e,
    });
  }
}

export function* inputDataSaga() {
  yield takeEvery(inputType.INPUTDATA_COM_LOADDATA, inputDataComLoadDataSaga);
  yield takeEvery(inputType.INPUTDATA_COM_SID, inputDataComSidSaga);
  yield takeEvery(inputType.INPUTDATA_SCHEDULE_UDT_DATASET, inputDataSchUdtDataSetSaga);
  yield takeEvery(inputType.INPUTDATA_TEST_MODIFY_SET, inputDataTestModifySetSaga);
  yield takeEvery(inputType.INPUTDATA_COM_MODIFY_SET, inputDataComModifySetSaga);
  yield takeEvery(inputType.INPUTDATA_QUIZ_UPLOAD, inputDataQuizUploadSaga);
  yield takeEvery(inputType.INPUTDATA_QUESTION_MODIFY, inputDataQuestionModifySetSaga);
  yield takeEvery(inputType.INPUTDATA_QUIZ_CREATE, inputDataQuizCreateSaga);
  yield takeEvery(inputType.INPUTDATA_WORDS_MODIFY_SET, inputDataWordsModifySetSaga);
}
