const GET_FIRESTORE_DATA = "GET_FIRESTORE_DATA";

export const getFireStoreData = (collection) => ({
  type: GET_FIRESTORE_DATA,
  collection,
});

const downloadControler = {
  carousel: true,
  classes: false,
  office: false,
  presetStudent: false,
  qidPart: false,
  schedule: false,
  scheduleList: false,
  users: false,
  passage: false,
};

const downloadControlerReducer = (state = downloadControler, action) => {
  switch (action.type) {
    case GET_FIRESTORE_DATA: {
      return { ...state, [action.collection]: true };
    }
    default:
      return state;
  }
};

export default downloadControlerReducer;
