import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../../../redux/InputData/actions";

// competition 인원 테이블에서 순위를 선택하는 컴포넌트
const SelectInputScore = (props) => {
  // props
  // InputTable.js에서 넘어온 표의 index
  const row = props.row;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // onClick 함수
  // 해당 index의 행에 score 변경
  const changeScore = (e) =>
    dispatch(inputDataKey("score", row.id, Number(e.target.value)));

  return (
    <Form.Control
      style={{ textAlign: "center" }}
      min={0}
      max={100}
      type="number"
      onChange={changeScore}
      placeholder="점수를 입력하세요"
      value={row.score || 0}
      disabled={disabled}
    />
  );
};

export default SelectInputScore;
