import React from "react";
import { useSelector } from "react-redux";
import PrintTitle from "./PrintTitle";
import PrintList from "./PrintList";

const PrintPage = (props) => {
  // props
  // 인쇄할 컴포넌트
  const printRef = props.printRef;

  // reducers
  // Input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // quiz Input Data reducer
  const qIDData = useSelector((state) => state.qIDDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  let displayQuizData = () => {
    if (qIDData.length !== 0) {
      let dataArray = [];
      inputData.forEach((row) => {
        dataArray.push(
          quizInputData
            .filter(
              (row) => (row.correct / row.count) * 100 <= selOption.percentage
            )
            .filter((res) => res.sid === row.sid)
        );
      });
      return dataArray;
    } else return [];
  };

  return (
    <div style={{ display: "none" }}>
      <div ref={printRef}>
        {displayQuizData().map(
          (row, i) =>
            row.length !== 0 && (
              <React.Fragment key={inputData[i].sid}>
                <PrintTitle obj={inputData[i]} />
                <PrintList row={row} obj={inputData[i]} />
              </React.Fragment>
            )
        )}
      </div>
    </div>
  );
};

export default PrintPage;
