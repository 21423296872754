import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { keyQuestionOption } from "../../../../redux/QuestionOption/reducer";

const SelectQuestionType = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const questionTypeArray = [
    { type: "short", typeName: "short" },
    { type: "choice", typeName: "choice" },
    { type: "checkBox", typeName: "check-box" },
  ];

  const displayValue = {
    type: questionOption.type,
    typeName:
      questionOption.type === "checkBox" ? "check-box" : questionOption.type,
  };

  const changeType = (e) => dispatch(keyQuestionOption("type", e.type));

  return (
    <Col>
      <Select
        options={questionTypeArray}
        placeholder="Select Quiz Type"
        getOptionLabel={(e) => e.typeName}
        getOptionValue={(e) => e.type}
        value={displayValue}
        isSearchable
        className="mx-5 App"
        onChange={(e) => changeType(e)}
      />
    </Col>
  );
};

export default SelectQuestionType;
