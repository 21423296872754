import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100vw;

  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1440px;
  flex-direction: column;

  padding: 40px 24px;
`;

export const RowContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1440px;

  padding: 40px 24px;

  gap: 40px;
`;

export const FullContentContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;

  padding: 40px 24px;
`;

export const FlexBox = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: column;

  ${({ flex }) => (flex ? `flex: ${flex}` : '')};
`;

export const RowFlexContainer = styled.div`
  display: flex;
  gap: 24px;
`;
