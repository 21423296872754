export const QUIZ_KAHOOTINPUTDATA = "QUIZ_KAHOOTINPUTDATA";
export const QUIZ_KAHOOTINPUTDATA_SUCCESS = "QUIZ_KAHOOTINPUTDATA_SUCCESS";
export const QUIZ_KAHOOTINPUTDATA_ERROR = "QUIZ_KAHOOTINPUTDATA_ERROR";
export const QUIZ_QUIZIZZINPUTDATA = "QUIZ_QUIZIZZINPUTDATA";
export const QUIZ_QUIZIZZINPUTDATA_SUCCESS = "QUIZ_QUIZIZZINPUTDATA_SUCCESS";
export const QUIZ_QUIZIZZINPUTDATA_ERROR = "QUIZ_QUIZIZZINPUTDATA_ERROR";
export const QUIZ_NAMECHANGE = "QUIZ_NAMECHANGE";

export const QUIZ_LOADDATA = "QUIZ_LOADDATA";

export const QUIZ_INPUTDATA_CLEAR = "QUIZ_INPUTDATA_CLEAR";
