/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRedirect from "../../../hooks/useRedirect";
import * as CardStyles from "../../../styles/CardStyles";
import { calculateDate } from "../../../lib/utils/date";
import moment from "moment";
import { clearWordArray, getQuizWordList } from "../../../redux/FirebaseData/Word/reducer";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

export default function SelectQuiz() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classList = useSelector((state) => state.classesReducer);
  const quizList = useSelector((state) => state.scheduleReducer)
    .map((row) => ({ ...row, date: moment(row.date.toDate()).format("YYYY-MM-DD") }))
    .filter((row) => row.subject === "Competition")
    .filter((row) => {
      return row.date === moment(calculateDate(0)).format("YYYY-MM-DD") || row.date === moment(calculateDate(1)).format("YYYY-MM-DD");
    });

  const scheduleList = useSelector((state) => state.scheduleListReducer);

  const myWordQuizList = useSelector((state) => state.studentWordQuizReducer);

  const [myQuizList, setMyQuizList] = useState(
    quizList.map((row) => {
      const wordQuizLog = myWordQuizList.find((wordQuiz) => wordQuiz.scheduleId === row.id);
      return {
        ...row,
        isCheck: false,
        score: wordQuizLog ? wordQuizLog.score : "-",
      };
    }),
  );

  const clickMyQuiz = (scheduleId) => {
    setMyQuizList((prev) => prev.map((row) => ({ ...row, isCheck: row.id === scheduleId })));
  };

  const quizOption = () => {
    const now = myQuizList.find((quizList) => quizList.isCheck);
    if (!now) return;
    return { scid: now.scid, part: now.part, scheduleList };
  };

  useRedirect("learningcenter", "home");

  const isCheck = myQuizList.filter((row) => row.isCheck).length;

  const goGame = async () => {
    dispatch(getQuizWordList(quizOption()));
    navigate("/learningcenter/wordgame/play", {
      replace: true,
      state: { cid: myQuizList.find((quizList) => quizList.isCheck).cid, date: myQuizList.find((quizList) => quizList.isCheck).date, start: true },
    });
  };

  useEffect(() => {
    dispatch(clearWordArray());
  }, []);

  return (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left" style={{ marginTop: 24 }}>
            <Col>
              <h2>반을 선택해주세요!</h2>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CardStyles.CardContainer more>
                {myQuizList.map((row, i) => (
                  <CardStyles.CardQuizRow i={i} key={row.id} isPlayed={row.score !== "-"} isCheck={row.isCheck} onClick={() => clickMyQuiz(row.id)}>
                    <div>{row.date.slice(5)}</div>
                    <div>{classList.find((cls) => cls.id === row.cid).name}</div>
                    <div />
                    <div>{`${row.scname}-${row.part}`}</div>
                    <div>{row.score}</div>
                  </CardStyles.CardQuizRow>
                ))}
              </CardStyles.CardContainer>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Button variant={isCheck ? "primary" : "outline-primary"} style={{ width: "100%" }} disabled={!isCheck} onClick={goGame}>
                퀴즈 시작
              </Button>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}
