import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLeagueRound } from "../../../redux/league/actions";

// 일반-연장으로 게임 타입 설정하는 컴포넌트
const GameTypeButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);
  // 라운드 알려주는 텍스트 ("일반"/"연장")
  const round = league[leagueLable[level]].round;

  // onClick 함수
  // 클릭 시 라운드 변경 (일반->연장, 연장->일반)
  const setGameRound = () => dispatch(setLeagueRound(leagueLable[level]));

  return (
    <ButtonGroup style={{ float: "left" }}>
      <Button variant="outline-dark" disabled>
        Game Mode
      </Button>
      <Button
        onClick={setGameRound}
        style={{ backgroundColor: "#454648", border: "none" }}
        disabled={loading}
      >
        {round}
      </Button>
    </ButtonGroup>
  );
};

export default GameTypeButton;
