/* eslint-disable no-unused-vars */
import { Button, Card, Col, Form } from "react-bootstrap";
import "chartjs-adapter-moment";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import chartTrendline from "chartjs-plugin-trendline";
import { useDispatch, useSelector } from "react-redux";
import { toStringByFormatting } from "../functions";
import MonthLabel from "./MonthLabel";
import { selOptionKey } from "../../../redux/selOption/actions";
import { colors } from "../../../lib/utils/constants";

const ChartCompetition = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const competition = useSelector((state) => state.statArrayReducer).comArray;

  const classes = useSelector((state) => state.classesReducer);
  // stat reducer
  const stat = useSelector((state) => state.statArrayReducer).stat;

  const rangeMin = Math.max(...competition.map((row) => row.rank)) + 1;

  const horizonalArbitraryLine = {
    id: "horizonalArbitraryLine",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();

      for (let index = 0; index < options.length; index++) {
        const line = options[index];

        ctx.fillStyle = line.lineColor;
        ctx.fillRect(
          left,
          y.getPixelForValue(line.yPosition) - line.yThickness / 2,
          width,
          line.yThickness
        );
        // ctx.fillText((line.text + ": " + line.yPosition.toString()), left, y.getPixelForValue(line.yPosition) + line.yThickness + 5)
        ctx.restore();
      }
    },
  };
  Chart.register(horizonalArbitraryLine);
  Chart.register(chartTrendline);

  let competitionClass = competition
    .map(function (val, index) {
      return val["cid"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({
      cid: row,
      name: classes.find((a) => a.id === row).name,
    }));

  let competitionDataArray = competitionClass.map((a, i) => {
    return {
      label: a.name,
      data: competition
        .filter((row) => row.cid === a.cid)
        .map((d) => ({ x: d.date.toDate(), y: d.rank })),
      backgroundColor: colors.main[i],
      borderColor: colors.main[i],
      trendlineLinear: {
        style: colors.sub[i],
        lineStyle: "solid",
        width: 10,
      },
    };
  });

  const competitionData = {
    datasets: competitionDataArray,
  };

  let displayStat = {
    com: {
      this:
        stat.com.this[selOption.cid + "sum"] !== undefined
          ? Math.round(
              (stat.com.this[selOption.cid + "sum"] /
                stat.com.this[selOption.cid + "count"]) *
                10
            ) / 10
          : 0,
      last:
        stat.com.last[selOption.cid + "sum"] !== undefined
          ? Math.round(
              (stat.com.last[selOption.cid + "sum"] /
                stat.com.last[selOption.cid + "count"]) *
                10
            ) / 10
          : 0,
    },
  };

  const competitionOptions = {
    title: { text: "This is a test" },
    scales: {
      x: {
        display: true,
        title: "time",
        type: "time",
        distribution: "series",
        gridLines: {
          lineWidth: 1,
        },
        time: {
          unit: "day",
          unitStepSize: 1000,
          displayFormats: {
            day: "M.D",
          },
        },
      },
      y: {
        reverse: true,
        min: 0,
        max: selOption.max,
      },
    },
    plugins: {
      horizonalArbitraryLine: [
        {
          lineColor: "#9b22fd",
          yPosition: displayStat.com.last !== 0 ? displayStat.com.last : 100,
          yThickness: 3,
        },
        {
          lineColor: "#06a001",
          yPosition: displayStat.com.this !== 0 ? displayStat.com.this : 100,
          yThickness: 3,
        },
      ],
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return (
              toStringByFormatting(tooltipItem[0].raw.x) +
              " (" +
              selOption.name +
              ")"
            );
          },
        },
      },
    },
  };

  const changeMax = (e) =>
    dispatch(selOptionKey("max", Number(e.target.value)));

  return (
    <>
      {competition.length !== 0 && selOption.toggleCom && (
        <Col xxl>
          <Card>
            <Card.Body>
              <p className="Form-label">
                {" "}
                <strong>Max Range ({selOption.max})</strong>{" "}
              </p>
              <Form.Range
                min={rangeMin}
                max={30}
                value={selOption.max || 15}
                onChange={changeMax}
              />
              <Card.Title style={{ float: "left" }} className="App-left">
                {`Competition Rank - ${selOption.nowStudent} `}
              </Card.Title>
              <MonthLabel />
              <Line
                data={competitionData}
                options={competitionOptions}
                width={10}
                height={6}
              />
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ChartCompetition;
