/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import DisplayQuiz from "../../../../Component/Quiz/Upload/DisplayQuiz/DisplayQuiz";
import QuestionModal from "../../../../Component/Quiz/Upload/QuestionModal.js/QuestionModal";
import { useDispatch } from "react-redux";
import { clearQuestionOption } from "../../../../redux/QuestionOption/reducer";
import { loadingFalse } from "../../../../redux/Loading/actions";
import { modalShowClear } from "../../../../redux/Modal_Show/actions";
import { inputDataClear } from "../../../../redux/InputData/actions";
import SelectOption from "../../../../Component/Quiz/Modify/SelectOption";
import ControlButton from "../../../../Component/Quiz/Modify/ControlButton";
import { selOptionClear, selOptionKey } from "../../../../redux/selOption/actions";
import useRedirect from "../../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const QuestionModify = () => {
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(modalShowClear());
    dispatch(loadingFalse());
    dispatch(clearQuestionOption());
    dispatch(selOptionClear());
    dispatch(inputDataClear());
    dispatch(selOptionKey("modify", true));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <SelectOption />
          <ControlButton />
          <DisplayQuiz />
          <QuestionModal />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default QuestionModify;
