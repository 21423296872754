export const GrowingTheme = {
  color: {
    primary: "#4b82fa",
    secondary: "#d376ff",
    danger: "#fa4b82",
    white: "#ffffff",
    black: "#000000",
    border: "lightgrey",
  },
};
