import { useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../redux/selOption/actions";

// Promotion 인원 수 설정 컴포넌트
const InputPromotion = (props) => {
  // props
  // GameTable 사용 여부 (사용시 true, 미사용(Competition 설정 창)시 false
  const table = props.table;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 변경 가능 switch
  const [changeActive, setChangeActive] = useState(false);
  // 변경 값
  const [changeNumber, setChangeNumber] = useState(selOption.promotion);

  // Form Display 값
  const displayValue = () => {
    if (changeActive) {
      return Number(changeNumber);
    } else if (selOption.promotion) {
      return Number(selOption.promotion);
    } else return 0;
  };

  // Competition 설정 창용 promotion 값 변경 함수
  const changePromotion = (e) => {
    dispatch(selOptionKey("promotion", Number(e.target.value)));
  };

  // GameTable용 promotion 값 변경 함수
  const onClickTable = () => {
    changeActive && dispatch(selOptionKey("promotion", Number(changeNumber)));
    setChangeActive(!changeActive);
    setChangeNumber(selOption.promotion);
  };

  return (
    <>
      {/* Competition 설정 창용 Input Promotion */}
      {!table && (
        <>
          <p className="Form-label"> # of players to be promoted </p>
          <Form.Control
            min={0}
            max={5}
            type="number"
            placeholder="플레이어 수를 설정하세요"
            value={displayValue()}
            style={{ textAlign: "center" }}
            onChange={(e) => changePromotion(e)}
          />
        </>
      )}
      {/* GameTable용 Input Promotion */}
      {table && (
        <ButtonGroup className="mb-3" style={{ float: "left" }}>
          <Form.Control
            min={0}
            max={5}
            type="number"
            placeholder="플레이어 수를 설정하세요"
            disabled={!changeActive}
            value={displayValue()}
            style={{ textAlign: "center" }}
            onChange={(e) => setChangeNumber(Number(e.target.value))}
          />
          <Button
            variant={!changeActive ? "outline-dark" : "dark"}
            onClick={onClickTable}
          >
            {changeActive ? "Done" : "Promotion"}
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default InputPromotion;
