import { combineReducers } from "redux";
import selOptionReducer from "./selOption/reducer";
import leagueReducer from "./league/reducer";
import { all } from "redux-saga/effects";
import { quizInputDataSaga } from "./Quiz_InputData/actions";
import quizInputDataReducer from "./Quiz_InputData/reducer";
import quizNameOptionReducer from "./Quiz_NameOption/reducer";
import quizUploadDataReducer from "./Quiz_UploadData/reducer";
import displayDataReducer from "./DisplayData/reducer";
import modalShowReducer from "./Modal_Show/reducer";
import leagueRosterReducer from "./League_Roster/reducer";
import gameArrayReducer from "./League_GameArray/reducer";
import currentUserReducer from "./FirebaseData/currentUser/reducer";
import loadingReducer from "./Loading/reducer";
import pageTypeReducer from "./PageType/reducer";
import usersReducer, { usersSaga } from "./FirebaseData/Users/reducer";
import scheduleReducer from "./FirebaseData/Schedule/reducer";
import carouselReducer from "./FirebaseData/Carousel/reducer";
import presetStudentReducer from "./FirebaseData/PresetStudent/reducer";
import popupModalReducer from "./PopupModal/reducer";
import headerReducer from "./Header/reducer";
import classesReducer, { classesSaga } from "./FirebaseData/Classes/reducer";
import { quizUploadDataSaga } from "./Quiz_UploadData/actions";
import officeReducer, { officeSaga } from "./FirebaseData/Office/reducer";
import scheduleListReducer, { scheduleListSaga } from "./FirebaseData/ScheduleList/reducer";
import inputDataReducer from "./InputData/reducer";
import qIDDataReducer from "./QIDData/reducers";
import downloadControlerReducer from "./FirebaseData/DownloadController/reducer";
import wordReducer, { wordSaga } from "./FirebaseData/Word/reducer";
import { inputDataSaga } from "./InputData/actions";
import boardReducer from "./FirebaseData/Board/reducer";
import wordOptionReducer from "./wordOption/reducers";
import statArrayReducer from "./StatArray/reducer";
import attendReducer from "./FirebaseData/Attend/reducer";
import { statArraySaga } from "./StatArray/actions";
import passageReducer, { passageSaga } from "./FirebaseData/Passage/reducer";
import passageObjectReducer from "./PassageObject/reducer";
import questionOptionReducer from "./QuestionOption/reducer";
import qidPartReducer, { qidPartSaga } from "./FirebaseData/QIDPart/reducer";
import bpmReducer from "./FirebaseData/Board/bpmReducer";
import settingReducer from "./FirebaseData/Setting/reducer";
import paymentReducer from "./FirebaseData/Payment/reducer";
import toggleStateReducer from "./toggleState/reducer";
import studentTodoReducer, { studentTodoSaga } from "./FirebaseData/Todo/studentTodoReducer";
import teacherTodoReducer, { teacherTodoSaga } from "./FirebaseData/Todo/teacherTodoReducer";
import studentWordQuizReducer, { studentWordQuizSaga } from "./WordQuiz/studentWordQuizReducer";

// 만든 reducers 합쳐 주기
const rootReducer = combineReducers({
  selOptionReducer,
  leagueReducer,
  quizInputDataReducer,
  quizNameOptionReducer,
  quizUploadDataReducer,
  displayDataReducer,
  modalShowReducer,
  leagueRosterReducer,
  gameArrayReducer,
  currentUserReducer,
  loadingReducer,
  pageTypeReducer,
  officeReducer,
  usersReducer,
  classesReducer,
  scheduleReducer,
  scheduleListReducer,
  carouselReducer,
  presetStudentReducer,
  inputDataReducer,
  popupModalReducer,
  downloadControlerReducer,
  headerReducer,
  qIDDataReducer,
  wordReducer,
  boardReducer,
  wordOptionReducer,
  statArrayReducer,
  attendReducer,
  passageReducer,
  passageObjectReducer,
  questionOptionReducer,
  qidPartReducer,
  bpmReducer,
  settingReducer,
  paymentReducer,
  toggleStateReducer,
  studentTodoReducer,
  teacherTodoReducer,
  studentWordQuizReducer,
});

export function* rootSaga() {
  yield all([
    quizInputDataSaga(),
    quizUploadDataSaga(),
    officeSaga(),
    classesSaga(),
    usersSaga(),
    scheduleListSaga(),
    inputDataSaga(),
    wordSaga(),
    statArraySaga(),
    passageSaga(),
    qidPartSaga(),
    studentTodoSaga(),
    teacherTodoSaga(),
    studentWordQuizSaga(),
  ]);
}

export default rootReducer;
