import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../redux/InputData/actions";

// inputData 내의 개별 행 본문 변경을 위해 사용하는 Select 컴포넌트
const ChangeTextForm = (props) => {
  // props
  // inputData 내 변경할 key
  const key = props.objectKey;
  // inputData 내 index
  const row = props.row;
  // placeholder
  const placeholder = props.placeholder;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // onClick 함수
  // inputData 내 타이틀 변경
  const changeKey = (e) => dispatch(inputDataKey(key, row.id, e.target.value));

  return (
    <Form.Control
      type="text"
      style={{ textAlign: "center" }}
      value={row[key] || ""}
      onChange={(e) => changeKey(e)}
      placeholder={placeholder || "값을 입력하세요"}
      disabled={disabled}
    />
  );
};

export default ChangeTextForm;
