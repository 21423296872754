/* eslint-disable react-hooks/exhaustive-deps */
import "../../../App.css";
import { Row, Col, Button, Table, ButtonGroup, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertCard from "../../../Component/Test/Input/UploadAlert";
import { loadingComponent, loadingFalse } from "../../../redux/Loading/actions";
import SavePresetModal from "../../../Component/StudentPreset/Save/SavePresetModal";
import LoadPresetModal from "../../../Component/StudentPreset/Load/LoadPresetModal";
import { modalShowClear, modalShowPopup } from "../../../redux/Modal_Show/actions";
import { inputDataClear } from "../../../redux/InputData/actions";
import useRedirect from "../../../hooks/useRedirect";
import LoadingSpinner from "../../../Component/PageComponent/LoadingSpinner";
import { setPopupModal } from "../../../redux/PopupModal/reducer";
import SaveModalButton from "../../../Component/StudentPreset/SaveModalButton";
import LoadModalButton from "../../../Component/StudentPreset/LoadModalButton";
import CreateRowButton from "../../../Component/Todo/CreateRowButton";
import DeleteRowButton from "../../../Component/Todo/DeleteRowButton";
import ChangeStudent from "../../../Component/SelectTable/ChangeStudent";
import CreateTodoRowButton from "../../../Component/Todo/CreateTodoRowButton";
import DeleteTodoRowButton from "../../../Component/Todo/DeleteTodoRowButton";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { ko } from "date-fns/locale";
import { createTodoAPI } from "../../../api/todoApi";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

registerLocale("ko", ko);

function AdminTodo() {
  const dispatch = useDispatch();

  // Reducer
  // test Input 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo; // 사용자 정보

  const [todoTable, setTodoTable] = useState([]);
  const [todoDate, setTodoDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const createRowTable = () => setTodoTable((prev) => [...prev, { id: prev.length, title: "" }]);
  const deleteRowTable = (id) => setTodoTable((prev) => prev.filter((todo) => todo.id !== id));
  const onChangeTodoTitle = (e, row) => setTodoTable((prev) => prev.map((todo) => (todo.id === row.id ? { ...row, title: e.target.value } : todo)));
  const onChangeDate = (e) => setTodoDate(moment(e).format("YYYY-MM-DD"));

  useRedirect("teachingcenter", "home");

  const dataUpload = () => {
    if (!myInfo) {
      return;
    }
    dispatch(loadingComponent(true));
    todoTable.forEach((todo) => {
      inputData.forEach((student) =>
        createTodoAPI({
          check: false,
          content: "",
          date: moment(new Date()).format("YYYY-MM-DD"),
          deadline: moment(todoDate).format("YYYY-MM-DD"),
          scheduleId: "",
          sid: student.sid,
          title: todo.title,
          todoType: "etc",
          tid: myInfo.uid,
          teacherName: myInfo.name,
        }),
      );
    });
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드"));
  };

  // 버튼 비활성화 조건
  const buttonDisabled = loading || inputData.length === 0 || !myInfo;

  useEffect(() => {
    dispatch(inputDataClear());
    dispatch(loadingFalse());
    dispatch(modalShowClear());
  }, []);

  useEffect(() => {
    loading &&
      setTimeout(() => {
        dispatch(modalShowPopup(false));
        dispatch(loadingComponent(false));
        dispatch(inputDataClear());
        setTodoTable([]);
      }, 500);
  }, [loading]);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="g-4 px-2 mx-3">
            <Col>
              <InputDate date={todoDate} changeDate={onChangeDate} />
            </Col>
            <Col>
              <Button disabled={buttonDisabled} className="Growing-button mb-1" style={{ float: "right" }} onClick={openPopup}>
                {loading ? <LoadingSpinner /> : "Todo 입력하기"}
              </Button>
            </Col>
          </Row>
          <Row className="g-4 mb-5 px-2 pb-2 mx-3">
            <Col>
              <Table striped bordered hover size="sm" className="mb-5">
                <thead>
                  <tr>
                    <th>
                      <ButtonGroup className="mx-3">
                        <CreateTodoRowButton createRowTable={createRowTable} />
                        <DeleteTodoRowButton header id={todoTable.length - 1} deleteRowTable={deleteRowTable} />
                      </ButtonGroup>
                    </th>
                    <th style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {todoTable.map((row) => (
                    <tr key={row.id}>
                      <td>
                        <InputTodo row={row} onChangeTodoTitle={onChangeTodoTitle} />
                      </td>
                      <td>
                        <DeleteTodoRowButton id={row.id} deleteRowTable={deleteRowTable} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered hover size="sm" className="mb-5">
                <thead>
                  <tr>
                    <th>
                      <ButtonGroup className="mx-3">
                        <CreateRowButton />
                        <DeleteRowButton />
                      </ButtonGroup>
                      <ButtonGroup>
                        <SaveModalButton />
                        <LoadModalButton />
                      </ButtonGroup>
                    </th>
                    <th style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {inputData.map((row) => (
                    <tr key={row.id}>
                      <td>
                        <ChangeStudent row={row} />
                      </td>
                      <td>
                        <DeleteRowButton row={row} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <AlertCard />
          <SavePresetModal />
          <LoadPresetModal />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}

const InputTodo = ({ row, onChangeTodoTitle }) => <Form.Control placeholder="todo" value={row.title} onChange={(e) => onChangeTodoTitle(e, row)} />;
const InputDate = ({ date, changeDate }) => (
  <DatePicker
    dateFormat="yyyy-MM-dd"
    selected={new Date(date)}
    todayButton="today"
    onChange={changeDate}
    locale={ko}
    placeholderText="날짜"
    className="Date-picker-border"
  />
);
export default AdminTodo;
