import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  inputDataClear,
  inputDataSchUdtDataSet,
} from "../../../redux/InputData/actions";

// 조건에 맞는 스케쥴 가져오기 위한 버튼 컴포넌트
const ScheduleLoadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // 서버에 저장된 스케쥴 데이터 reducer
  const scheduleData = useSelector((state) => state.scheduleReducer);

  // 버튼 비활성화 조건
  const buttonDisabled =
    !selOption.cid ||
    !selOption.subject ||
    !selOption.month ||
    !selOption.year ||
    selOption.year < 2021 ||
    selOption.month < 0 ||
    selOption.month > 13 ||
    loading;

  // onClick 함수
  // selOption에 맞는 스케쥴 필터링
  const scheduleLoad = () =>
    inputData.length === 0
      ? dispatch(inputDataSchUdtDataSet(scheduleData, selOption))
      : dispatch(inputDataClear());

  return (
    <Button
      className="Growing-button"
      disabled={buttonDisabled}
      style={{ float: "right" }}
      onClick={scheduleLoad}
    >
      {inputData.length === 0 ? "스케쥴 불러오기" : "원래대로"}
    </Button>
  );
};

export default ScheduleLoadButton;
