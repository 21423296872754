import { QUIZ_NAMEBEFORE, QUIZ_NAMEAFTER, QUIZ_NAMEOPTIONCLEAR } from "./types";

export const setNameOptionBefore = (payload) => ({
  type: QUIZ_NAMEBEFORE,
  payload,
});

export const setNameOptionAfter = (payload) => ({
  type: QUIZ_NAMEAFTER,
  payload,
});

export const setNameOptionClear = () => ({
  type: QUIZ_NAMEOPTIONCLEAR,
});
