import { create } from 'zustand';

interface UseLoadingIndicatorStoreStates {
  isShow: boolean;
  setIsShow: (isLoadingIndicatorShow: boolean) => void;
}

const useLoadingIndicatorStore = create<UseLoadingIndicatorStoreStates>((set) => ({
  isShow: false,

  setIsShow: (isShow) => {
    set((state) => ({ ...state, isShow }));
  },
}));

export default useLoadingIndicatorStore;
