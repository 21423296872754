import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteRowButton from "./DeleteRowButton";
import ChangeDate from "../../SelectTable/ChangeDate";
import ChangePart from "./Table/ChangePart";
import ChangeSchedule from "../../SelectTable/ChangeSchedule";

// inputData를 Display하고 수정하기 위한 테이블 컴포넌트
const InputTable = () => {
  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <>
      {inputData.length !== 0 && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>날짜</th>
              <th>스케쥴</th>
              <th>Part</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inputData.map((row, i) => (
              <tr key={i}>
                <td>
                  <ChangeDate row={row} />
                </td>
                <td>
                  <ChangeSchedule row={row} />
                </td>
                <td>
                  <ChangePart row={row} />
                </td>
                <td>
                  <DeleteRowButton row={row} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default InputTable;
