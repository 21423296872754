/* eslint-disable react-hooks/exhaustive-deps */
import db from "../../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingComponent } from "../../../redux/Loading/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { useEffect } from "react";
import { inputDataSchUdtDataSet } from "../../../redux/InputData/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

// 스케쥴을 업데이트하기 위한 버튼 컴포넌트
const UpdateButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // 서버에 저장된 스케쥴 데이터 reducer
  const scheduleData = useSelector((state) => state.scheduleReducer);
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  // 버튼 비활성화 조건, inputData 내 null 값이 하나라도 존재 시 비활성화
  const isNull = inputData
    .map((row) =>
      row.date === null || row.scid === null || row.part === null ? true : false
    )
    .some((e) => e === true);

  // 버튼 비활성화 조건, inputData 내 수정할 데이터 값이 하나도 없을 시 비활성화
  const modifyNull = inputData.filter((row) => row.modify).length === 0;

  // 데이터 추가 함수, row.add와 row.modify가 모두 true일 때
  const addData = async (row) => {
    await addDoc(collection(db, "Schedule", myInfo.office, "Plan"), {
      date: row.date,
      scname: row.scname,
      scid: row.scid,
      part: row.part,
      cid: selOption.cid,
      office: myInfo.office,
      subject: selOption.subject,
    });
  };

  // 데이터 수정 함수, row.modify만 true일 때
  const modifyData = async (row) => {
    await updateDoc(doc(db, "Schedule", myInfo.office, "Plan", row.id), {
      date: row.date,
      scid: row.scid,
      scname: row.scname,
      part: row.part,
      cid: row.cid,
      office: row.office,
      subject: row.subject,
    });
  };

  // 데이터 삭제 함수, row.delete와 row.modify가 모두 true일 때
  const deleteData = async (row) => {
    await deleteDoc(doc(db, "Schedule", myInfo.office, "Plan", row.id));
  };

  // onClick 함수
  // selOption에 맞는 스케쥴 필터링
  const scheduleLoad = () =>
    dispatch(inputDataSchUdtDataSet(scheduleData, selOption));

  // add/modify/delete 조건에 맞는 업로드 함수
  const dataUpload = () => {
    dispatch(loadingComponent(true));
    inputData
      .filter((row) => row.modify)
      .forEach((res) => {
        switch (true) {
          case res.add:
            addData(res);
            break;
          case res.delete:
            deleteData(res);
            break;
          default:
            modifyData(res);
            break;
        }
      });
    setTimeout(() => {
      dispatch(loadingComponent(false));
      dispatch(modalShowPopup(false));
    }, 2000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  // 조건에 맞게 재로드
  useEffect(() => !loading && scheduleLoad(), [loading]);

  return (
    <>
      {inputData.length !== 0 && (
        <Button
          onClick={openPopup}
          disabled={isNull || modifyNull || inputData.length === 0}
        >
          {loading ? <LoadingSpinner /> : "수정 완료"}
        </Button>
      )}
    </>
  );
};

export default UpdateButton;
