import {
  SET_LEAGUE_ACTIVE,
  SET_LEAGUE_CLEAR,
  SET_LEAGUE_MINUS,
  SET_LEAGUE_PLAYER,
  SET_LEAGUE_PLAYER_ADD,
  SET_LEAGUE_PLAYER_REMOVE,
  SET_LEAGUE_ROUND,
  SET_LEAGUE_REMOVE,
} from "./types";

const league = {
  A: {
    league: "A",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  B: {
    league: "B",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  C: {
    league: "C",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  D: {
    league: "D",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  E: {
    league: "E",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  F: {
    league: "F",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  G: {
    league: "G",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
  H: {
    league: "H",
    player: 0,
    active: false,
    minus: 1,
    round: "일반",
    score: 1,
    remove: false,
  },
};

const leagueReducer = (state = league, action) => {
  // state 의 초깃값을 league 으로 지정했습니다.
  switch (action.type) {
    // 플레이어 수 지정
    case SET_LEAGUE_PLAYER:
      return {
        ...state,
        [action.league]: { ...state[action.league], player: action.player },
      };
    // 플레이어 1명 추가
    case SET_LEAGUE_PLAYER_ADD:
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          player: state[action.league].player + 1,
        },
      };
    // 플레이어 제거
    case SET_LEAGUE_PLAYER_REMOVE:
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          player: state[action.league].player - action.payload,
        },
      };
    // 활성화 설정
    case SET_LEAGUE_ACTIVE:
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          active: !state[action.league].active,
        },
      };
    // 플레이어 제거 설정
    case SET_LEAGUE_REMOVE:
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          remove: !state[action.league].remove,
        },
      };
    // 마이너스 설정
    case SET_LEAGUE_MINUS:
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          minus: state[action.league].minus * -1,
        },
      };
    // 라운드 설정
    case SET_LEAGUE_ROUND:
      const gameRound = state[action.league].score === 1 ? { round: "연장", score: 0.1 } : { round: "일반", score: 1 };
      return {
        ...state,
        [action.league]: {
          ...state[action.league],
          ...gameRound,
        },
      };
    // 초기화
    case SET_LEAGUE_CLEAR:
      return league;
    default:
      return state;
  }
};

export default leagueReducer;
