import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const DisplayWord = () => {
  // reducers
  // word reducer
  /**
   * 게임에 사용될 단어 Object
   */
  const randomWord = useSelector((state) => state.wordReducer).randomWord;
  /**
   * 0: 한국어, 1: 영어
   * @type {0|1}
   */
  const nu = useSelector((state) => state.wordReducer).nu;

  return (
    <Card.Title style={{ fontSize: "12vmin" }}>
      {randomWord.eng && nu === 0 ? randomWord.eng : randomWord.kor}
    </Card.Title>
  );
};

export default DisplayWord;
