import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { wordOptionDelete } from "../../../redux/wordOption/actions";

// inputData 내 행 제거 시 사용하는 컴포넌트
const DeleteButton = (props) => {
  // props
  // inputData 내 index
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // input 테이블 reducer
  const inputData = useSelector((state) => state.wordOptionReducer).inputData;
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  // redux dispatch로 처리할 index, props.id가 없을 경우 마지막 index 반환
  const deleteID = row !== undefined ? row.id : inputData.length - 1;

  // onClick 함수
  // 해당 index의 행을 삭제
  const deleteData = () => {
    dispatch(wordOptionDelete(deleteID));
  };

  return (
    <Button variant="light" disabled={active} onClick={deleteData}>
      -
    </Button>
  );
};

export default DeleteButton;
