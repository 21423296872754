import { oldDate } from "../../Component/Competition/function";
import { weekNumberByMonth } from "../../lib/utils/date";

const dateZero = [
  new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1))),
  new Date(new Date().setDate(new Date().getDate() + (new Date().getDay() !== 0 ? 7 - new Date().getDay() : 0))),
];

export const defaultState = {
  sid: null,
  name: null,
  cid: null,
  class: null,
  date: null,
};
// Competition 페이지 Option State 초기값
export const defaultCompetition = {
  cid: null,
  class: null,
  date: oldDate(),
  avgDate: null,
  promotion: 0,
};
// 퀴즈 결과 입력 페이지 Option State 초기값
export const defaultQuizInput = {
  quiz: null,
  date: new Date(),
};
// 오답노트 페이지 Option State 초기값
export const defaultReviewnote = {
  part: "모든 문제",
  percentage: 0,
  start: dateZero[0],
  end: dateZero[1],
  week: [weekNumberByMonth(dateZero[0])],
  title: "",
  comment: "",
};
// 스케쥴 페이지 Option State 초기값
export const defaultSchedule = {
  cid: null,
  subject: null,
  fontSize: "15px",
  fontLabel: "보통",
};
// 스케쥴 입력 페이지 Option State 초기값
export const defaultScheduleInput = {
  cid: null,
  subject: null,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  scid: null,
  scname: null,
};
// 성적 입력 페이지 Option State 초기값
export const defaultTestInput = {
  cid: null,
  subject: "Listening",
  date: new Date(),
  title: "",
  comment: "",
};
// 성적/컴퍼티션 수정 페이지 Option State 초기값
export const defaultTestModify = {
  cid: null,
  subject: "Listening",
  dateRange: [null, null],
  sid: null,
};
// 학생 프리셋 페이지 Option State 초기값
export const defaultPresetStudent = (state) => {
  const selectOption = state || {};
  return {
    ...selectOption,
    title: "",
    comment: "",
  };
};
// 마이페이지 Option State 초기값
export const defaultMyPage = (action) => {
  const response = action || {
    currentUser: { displayName: "" },
    myInfo: { office: "", phone: "" },
  };
  return {
    name: response.currentUser.displayName,
    office: response.myInfo.office,
    phone: response.myInfo.phone,
  };
};
// 단어 페이지 Option State 초기값
export const defaultWord = (state, action) => {
  const selectOption = state || { cid: null };
  const response = action || {
    payload: { scname: null, part: null },
  };
  return {
    ...state,
    cid: selectOption.cid || null,
    date: new Date().toLocaleDateString(),
    scname: response.payload.scname || null,
    part: response.payload.part || null,
    playTime: 500,
  };
};
// 단어 페이지 Option State 초기값
export const defaultWordInput = {
  subject: "Competition",
  set: 1,
  scid: null,
  scname: null,
  rawWord: "",
  displayPage: 0,
  eng: "",
  kor: "",
  isSet: false,
};
// 게시판 페이지 Option State 초기값
export const defaultBoard = {
  title: "",
  content: "",
  file: [],
  progress: 0,
};
// 게시판 페이지 Option State 초기값
export const defaultBPM = {
  ...defaultBoard,
  ccArray: [],
  approvalArray: [],
  dateArray: [],
};
// 학생 스탯 페이지 Option State 초기값
export const defaultStudentStat = {
  sid: "",
  name: "",
  dateRange: [null, null],
  cid: "",
  class: "Competition",
  subject: "Listening",
  quiz: "GR",
  toggleCom: false,
  toggleTest: false,
  toggleQuiz: false,
  max: 20,
};
// 학생 리스트 페이지 Option State 초기값
export const defaultStudentList = {
  grade: null,
  school: null,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  nowYear: new Date().getFullYear(),
  nowMonth: new Date().getMonth() + 1,
};
// 학생 납부 기록 페이지 Option State 초기값
export const defaultpaymentList = {
  year: new Date().getFullYear(),
  nowYear: new Date().getFullYear(),
};
// 본문 읽기 페이지 Option State 초기값
export const defaultReading = {
  passageSize: 16,
  sentenceSize: 16,
  type: "",
  textbook: "",
  lesson: "",
  passage: [],
  passageList: [],
  nowPassage: { type: "", textbook: "", lesson: "" },
  answer: false,
  eng: false,
  number: 0,
};
// 본문 입력 페이지 Option State 초기값
export const defaultReadingInput = (state) => {
  const selectOption = state || {};
  return {
    ...selectOption,
    type: "",
    textbook: "",
    lesson: "",
    passage: [],
    answer: false,
    number: 0,
    passageList: [],
    nowPassage: { type: "", textbook: "", lesson: "" },
  };
};
// 설정 입력 페이지 Option State 초기값
export const defaultSet = (payload) => {
  return (
    payload || {
      bpmCategory: [],
      maxScore: {
        grammar: 5,
        reading: 10,
        listening: 25,
        mock: 100,
      },
      competitionExtra: 1,
      id: "만덕",
    }
  );
};
// 설정 입력 페이지 Option State 초기값
export const defaultCheckTodo = {
  sid: "",
  date: new Date(),
};

export const defaultSelectOption = {
  default: defaultState,
  Board: defaultBoard,
  BPM: defaultBPM,
  CheckTodo: defaultCheckTodo,
  Competition: defaultCompetition,
  MyPage: defaultMyPage(),
  Payment: defaultpaymentList,
  PresetStudent: defaultPresetStudent(),
  QuizInput: defaultQuizInput,
  Reading: defaultReading,
  ReadingInput: defaultReadingInput(),
  ReviewNote: defaultReviewnote,
  Schedule: defaultSchedule,
  ScheduleInput: defaultScheduleInput,
  Set: defaultSet(),
  StudentStat: defaultStudentStat,
  StudentList: defaultStudentList,
  TestInput: defaultTestInput,
  TestModify: defaultTestModify,
  Word: defaultWord(),
  WordInput: defaultWordInput,
};
