import { Card } from "react-bootstrap";

const ThisMontText = () => {
  const thisMonth =
    new Date().getFullYear().toString().slice(-2) +
    ("0" + (new Date().getMonth() + 1)).slice(-2);

  return (
    <Card.Subtitle className="App-left">
      이번 달 평균 ({thisMonth.substring(0, 2) + ". " + thisMonth.substring(2)})
    </Card.Subtitle>
  );
};

export default ThisMontText;
