import * as selOpt from "./types";

// 공용
// 초기화
export const selOptionClear = (clearType, payload) => ({
  type: selOpt.SEL_CLEAR,
  clearType,
  payload,
});
// 입력받은 Key의 값을 payload로 변경
export const selOptionKey = (key, payload) => ({
  type: selOpt.SEL_KEY,
  key,
  payload,
});
// selOption의 array 중 id 행의 입력받은 Key의 값을 payload로 변경
export const selOptionArrayKey = (array, id, key, payload) => ({
  type: selOpt.SEL_ARRAY_KEY,
  array,
  id,
  key,
  payload,
});
// selOption의 array의 행 추가
export const selOptionArrayCreate = (array) => ({
  type: selOpt.SEL_ARRAY_CREATE,
  array,
});
// selOption의 array의 행 삭제
export const selOptionArrayDelete = (array, id) => ({
  type: selOpt.SEL_ARRAY_DELETE,
  array,
  id,
});

// File 하나 삭제
export const selOptionFileRemove = (array, payload) => ({
  type: selOpt.SEL_FILE_REMOVE,
  array,
  payload,
});
