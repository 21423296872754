import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingComponent } from "../../redux/Loading/actions";
import { modalShowPopup } from "../../redux/Modal_Show/actions";
import { setPopupModal } from "../../redux/PopupModal/reducer";
import { updateUserInfo } from "../../api/usersApi";
import { updateUserData } from "../../redux/FirebaseData/Users/reducer";
import { Timestamp } from "firebase/firestore";

const UpdateButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const disabledCondition = !displayData.name || !displayData.age || !displayData.school || !displayData.phone || !displayData.registrationDate;

  const dataUpload = () => {
    const data = {
      name: displayData.name,
      age: displayData.age,
      school: displayData.school,
      phone: displayData.phone,
      registrationDate: Timestamp.fromDate(displayData.registrationDate),
    };
    dispatch(loadingComponent(true));
    updateUserInfo(displayData.id, data);
    dispatch(updateUserData("id", displayData.id, data));
    setTimeout(() => {
      dispatch(loadingComponent(false));
      dispatch(modalShowPopup(false));
    }, 2000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "수정 하시겠습니까?", dataUpload, "수정"));
  };

  return (
    <Button disabled={disabledCondition} onClick={openPopup}>
      수정
    </Button>
  );
};

export default UpdateButton;
