import { updateProfile } from "firebase/auth";
import db from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useSelector, RootStateOrAny } from "react-redux";

export default function usePutMemberInfo() {
  const currentUser = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo;

  const putMemberInfo = async ({ name, phone }: { name: string; phone: string }) => {
    if (!currentUser) {
      return;
    }

    updateProfile(currentUser, { displayName: name });
    setDoc(doc(db, "Users", currentUser.uid), { name, phone }, { merge: true });
  };

  return { mutate: putMemberInfo };
}
