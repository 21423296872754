import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectSchedule from "../../Select/SelectSchedule";

const DisplayWordProperty = () => {
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 스케쥴 라벨 리스트 reducer
  const scheduleList = useSelector((state) => state.scheduleListReducer);

  const displayObject = scheduleList.find(
    (row) => row.id === selOption.scid
  ) || { maxset: "", last: "" };

  return (
    <Row className="g-2 mx-2">
      <Col>
        <SelectSchedule title="카테고리 선택" required />
      </Col>
      <Col md>
        <p className="Form-label"> 현재 마지막 세트 번호 </p>
        <Form.Control
          type="text"
          value={displayObject.maxset}
          readOnly
          style={{ textAlign: "center" }}
        />
      </Col>
      <Col md>
        <p className="Form-label"> 현재 마지막 번호 </p>
        <Form.Control
          type="text"
          value={displayObject.last}
          readOnly
          style={{ textAlign: "center" }}
        />
      </Col>
    </Row>
  );
};

export default DisplayWordProperty;
