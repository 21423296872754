import { useSelector } from "react-redux";

// 학생의 정답률 및 전체 정답률 Display
const SumCell = () => {
  // reducers
  // Input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 학생별 정답률
  const sidRate = (a) => {
    let rate = { correct: 0, count: 0 };
    quizInputData
      .filter((row) => row.sid === a)
      .forEach((res) => {
        rate.correct += res.correct;
        rate.count += res.count;
      });
    return Math.round((rate.correct / rate.count) * 10000) / 100;
  };

  // 전체 정답률
  const totalRate = () => {
    let rate = { correct: 0, count: 0 };
    quizInputData.forEach((row) => {
      rate.correct += row.correct;
      rate.count += row.count;
    });
    return Math.round((rate.correct / rate.count) * 10000) / 100;
  };

  // 학생별 정답률에 따라 셀 스타일 변경
  const sidRateStyle = (sid) =>
    sidRate(sid) <= selOption.percentage
      ? { background: "Salmon", color: "white" }
      : { background: "SeaGreen", color: "white" };

  // 전체 정답률에 따라 셀 스타일 변경
  const totalRateStyle = () =>
    totalRate() <= selOption.percentage
      ? { background: "DarkRed", color: "white" }
      : { background: "DarkGreen", color: "white" };

  return (
    <tr>
      <td>
        <strong>합계</strong>
      </td>
      {inputData.map((row, i) => (
        <td key={i} style={sidRateStyle(row.sid)}>
          <strong>
            {isNaN(sidRate(row.sid)) ? "-" : sidRate(row.sid) + "%"}
          </strong>
        </td>
      ))}
      <td style={totalRateStyle()}>
        <strong>{isNaN(totalRate()) ? "-" : totalRate() + "%"}</strong>
      </td>
    </tr>
  );
};

export default SumCell;
