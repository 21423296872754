import { collection, getDocs, orderBy, query } from "firebase/firestore";
import db from "../firebase";

export async function getClass() {
  const classCollection = query(collection(db, "Class"), orderBy("name", "asc"));
  const querySnapshot = await getDocs(classCollection);
  let classArray = [];
  querySnapshot.forEach((doc) => classArray.push({ ...doc.data(), id: doc.id }));
  return classArray;
}

export async function updateClass(cid, data) {}
