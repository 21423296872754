export const oldDate = () => {
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const week = yesterday.getDay();

  switch (week) {
    case 0:
      yesterday.setDate(yesterday.getDate() - 2);
      return yesterday;
    case 6:
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    default:
      return yesterday;
  }
};
