import {
  QUIZ_KAHOOTINPUTDATA,
  QUIZ_KAHOOTINPUTDATA_ERROR,
  QUIZ_KAHOOTINPUTDATA_SUCCESS,
  QUIZ_QUIZIZZINPUTDATA,
  QUIZ_QUIZIZZINPUTDATA_ERROR,
  QUIZ_QUIZIZZINPUTDATA_SUCCESS,
  QUIZ_NAMECHANGE,
  QUIZ_LOADDATA,
  QUIZ_INPUTDATA_CLEAR,
} from "./types";

const inputData = [];

const quizInputDataReducer = (state = inputData, action) => {
  // state 의 초깃값을 inputData 으로 지정했습니다.
  switch (action.type) {
    case QUIZ_KAHOOTINPUTDATA:
      return state;
    case QUIZ_KAHOOTINPUTDATA_SUCCESS:
      return action.inputData;
    case QUIZ_KAHOOTINPUTDATA_ERROR:
      return state;
    case QUIZ_QUIZIZZINPUTDATA:
      return state;
    case QUIZ_QUIZIZZINPUTDATA_SUCCESS:
      return action.inputData;
    case QUIZ_QUIZIZZINPUTDATA_ERROR:
      return state;
    case QUIZ_NAMECHANGE:
      return state.map((row) => {
        if (row.name === action.payload.before) {
          return {
            ...row,
            sid: action.payload.sid,
            name: action.payload.after,
          };
        } else return row;
      });
    case QUIZ_LOADDATA:
      return action.payload;
    case QUIZ_INPUTDATA_CLEAR:
      return [];
    default:
      return state;
  }
};

export default quizInputDataReducer;
