import { Col, Row } from "react-bootstrap";
import TitleCard from "../../../Component/Title/TitleCard";
import useRedirect from "../../../hooks/useRedirect";
import { cards } from "../../../lib/utils/constants";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

const DisplayCards = (props) => {
  const displayType = props.displayType;

  // 숏컷이 제공될 데이터
  const cardData = () => {
    switch (displayType) {
      case "teacherTools":
        return cards.tool;
      case "adminTools":
        return cards.admin;
      default:
        return [];
    }
  };

  useRedirect("teachingcenter", "home");

  const displayArray = () => {
    const rowRange = [Math.min(...cardData().map((row) => row.row)), Math.max(...cardData().map((row) => row.row))];

    let returnArray = [];

    for (let i = rowRange[0]; i <= rowRange[1]; i++) {
      returnArray[i] = cardData().filter((row) => row.row === i);
    }

    return returnArray;
  };

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          {displayArray().map((row, i) => {
            return (
              <Row className="g-4 mx-2 mb-3" key={i}>
                {row.map((obj) => (
                  <Col lg key={obj.id}>
                    <TitleCard cardData={obj} />
                  </Col>
                ))}
              </Row>
            );
          })}
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default DisplayCards;
