// 공용
// 초기화
export const SEL_CLEAR = "SEL_CLEAR";
// 키
export const SEL_KEY = "SEL_KEY";
// 배열 수정
export const SEL_ARRAY_KEY = "SEL_ARRAY_KEY";
// 배열 행 추가
export const SEL_ARRAY_CREATE = "SEL_ARRAY_CREATE";
// 배열 행 삭제
export const SEL_ARRAY_DELETE = "SEL_ARRAY_DELETE";

// Board
// file 하나 삭제
export const SEL_FILE_REMOVE = "SEL_FILE_REMOVE";
