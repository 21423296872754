import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingAuto } from "../../../redux/Loading/actions";

const AutoPlayButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const wordOption = useSelector((state) => state.wordOptionReducer);
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;
  const auto = useSelector((state) => state.loadingReducer).auto;

  const changeAutoTrue = () => dispatch(loadingAuto(true));
  const changeAutoFalse = () => dispatch(loadingAuto(false));

  return (
    <ButtonGroup className="center-wrap2">
      <Button
        className="Growing-button"
        disabled={!active || auto}
        onClick={changeAutoTrue}
      >
        {(wordOption.playTime / 1000).toFixed(1) + "초"}
      </Button>
      <Button
        variant="warning"
        disabled={!active || !auto}
        onClick={changeAutoFalse}
      >
        Stop
      </Button>
    </ButtonGroup>
  );
};

export default AutoPlayButton;
