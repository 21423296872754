import { call, put, takeEvery } from "redux-saga/effects";
import { getMyTodoListAPI } from "../../../api/todoApi";

const GET_MY_TODO = "GET_MY_TODO";
const GET_MY_TODO_SUCCESS = "GET_MY_TODO_SUCCESS";
const GET_MY_TODO_ERROR = "GET_MY_TODO_ERROR";
const CHECK_TODO = "CHECK_TODO";

export const getMyTodoList = (sid) => ({
  type: GET_MY_TODO,
  sid,
});

export const checkTodo = (id) => ({
  type: CHECK_TODO,
  id,
});

function* getMyTodoListSaga(action) {
  const sid = action.sid;
  try {
    const payload = yield call(getMyTodoListAPI, sid);
    yield put({
      type: GET_MY_TODO_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_MY_TODO_ERROR,
      error: true,
      e,
    });
  }
}

export function* studentTodoSaga() {
  yield takeEvery(GET_MY_TODO, getMyTodoListSaga);
}

const studentTodoReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MY_TODO:
      return state;
    case GET_MY_TODO_SUCCESS:
      return action.payload;
    case GET_MY_TODO_ERROR:
      return [];
    case CHECK_TODO:
      return state.map((row) => (row.id === action.id ? { ...row, check: !row.check } : row));
    default:
      return state;
  }
};

export default studentTodoReducer;
