import db from "../../../firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { call, put, takeEvery } from "redux-saga/effects";
import { splitPage } from "../../../Methods";
import { randomArray, randomNum } from "../../../lib/utils/random";
import { getWordListAPI } from "../../../api/wordApi";

// types
const QUIZ_WORD_LOAD = "QUIZ_WORD_LOAD";
const QUIZ_WORD_LOAD_SUCCESS = "QUIZ_WORD_LOAD_SUCCESS";
const QUIZ_WORD_LOAD_ERROR = "QUIZ_WORD_LOAD_ERROR";
const WORD_LOAD = "WORD_LOAD";
const WORD_LOAD_SUCCESS = "WORD_LOAD_SUCCESS";
const WORD_LOAD_ERROR = "WORD_LOAD_ERROR";
const NU_SET = "NU_SET";
const SHOW_WORD = "SHOW_WORD";
const WORD_CLEAR = "WORD_CLEAR";

// function
const setGameWord = async (inputData) => {
  const returnArray = inputData.map(async (row) => {
    const setArr = splitPage(row.set);
    const setArray = setArr.map(async (obj) => {
      let setSampleArr = [];
      const wordCollection = query(
        collection(db, "Words"),
        where("scid", "==", row.scid),
        where("set", "==", obj),
        where("number", ">=", row.start),
        where("number", "<=", row.end),
        orderBy("number", "asc")
      );
      const querySnapshot = await getDocs(wordCollection);
      querySnapshot.forEach((doc) => {
        setSampleArr = [...setSampleArr, doc.data()];
      });
      return setSampleArr;
    });
    return await Promise.all(setArray);
  });
  return await Promise.all(returnArray);
};

// actions

export const getQuizWordList = ({ scid, part, scheduleList }) => ({
  type: QUIZ_WORD_LOAD,
  scid,
  part,
  scheduleList,
});

export const setWordArray = (payload) => ({
  type: WORD_LOAD,
  payload,
});

function* setWordArraySaga(action) {
  const inputData = action.payload;
  try {
    const loadData = yield call(setGameWord, inputData);
    yield put({
      type: WORD_LOAD_SUCCESS,
      loadData,
    });
  } catch (e) {
    yield put({
      type: WORD_LOAD_ERROR,
      error: true,
      e,
    });
  }
}

function* getQuizWordListSaga(action) {
  const { scid, part, scheduleList } = action;
  try {
    const wordList = yield call(getWordListAPI, { scid, part, scheduleList });
    yield put({
      type: QUIZ_WORD_LOAD_SUCCESS,
      wordList: wordList.flat(),
    });
  } catch (e) {
    yield put({
      type: QUIZ_WORD_LOAD_ERROR,
      error: true,
      e,
    });
  }
}

export const randomWord = () => ({
  type: NU_SET,
});

export const showWord = (payload) => ({
  type: SHOW_WORD,
  payload,
});

export const clearWordArray = () => ({
  type: WORD_CLEAR,
});

export function* wordSaga() {
  yield takeEvery(WORD_LOAD, setWordArraySaga);
  yield takeEvery(QUIZ_WORD_LOAD, getQuizWordListSaga);
}

// reducer
const wordObj = { wordArray: [], randomWord: "", nu: 0, show: false };

const wordReducer = (state = wordObj, action) => {
  switch (action.type) {
    case WORD_LOAD:
      return state;
    case WORD_LOAD_SUCCESS:
      return {
        ...state,
        wordArray: action.loadData.flat(2),
        randomWord: randomArray(action.loadData.flat(2)),
      };
    case WORD_LOAD_ERROR:
      return state;
    case QUIZ_WORD_LOAD:
      return state;
    case QUIZ_WORD_LOAD_SUCCESS:
      return {
        ...state,
        wordArray: action.wordList,
      };
    case QUIZ_WORD_LOAD_ERROR:
      return state;
    case SHOW_WORD:
      return {
        ...state,
        show: action.payload === undefined ? !state.show : action.payload,
      };
    case NU_SET:
      return {
        ...state,
        nu: randomNum(0, 1),
        randomWord: randomArray(state.wordArray),
      };
    case WORD_CLEAR:
      return wordObj;
    default:
      return state;
  }
};

export default wordReducer;
