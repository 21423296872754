import * as displayType from "./types";

const displayDataReducer = (state = {}, action) => {
  switch (action.type) {
    // 초기화
    case displayType.DISPLAY_CLEAR:
      return {};
    // 기본 Row Setting
    case displayType.DISPLAY_SET:
      return action.payload;
    // office
    case displayType.DISPLAY_OFFICE:
      return {
        office: action.payload,
      };
    // subject
    case displayType.DISPLAY_SUBJECT:
      return {
        ...state,
        subject: action.payload.subject,
      };
    // scid, scname
    case displayType.DISPLAY_SCHEDULE:
      return {
        ...state,
        scid: action.payload.id,
        scname: action.payload.name,
      };
    // part
    case displayType.DISPLAY_PART:
      return {
        ...state,
        part: action.payload,
      };
    // class
    case displayType.DISPLAY_CLASS:
      return {
        ...state,
        cid: action.payload.id,
      };
    // date
    case displayType.DISPLAY_DATE:
      return {
        ...state,
        date: action.payload,
      };
    // name
    case displayType.DISPLAY_NAME:
      return {
        ...state,
        name: action.payload,
      };
    // age
    case displayType.DISPLAY_AGE:
      return {
        ...state,
        age: action.payload,
      };
    // school
    case displayType.DISPLAY_SCHOOL:
      return {
        ...state,
        school: action.payload.school,
      };
    // phone
    case displayType.DISPLAY_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    // registrationDate
    case displayType.DISPLAY_DATE_REGI:
      return {
        ...state,
        registrationDate: action.payload,
      };

    // Schedule
    // Schedule Row Setting
    case displayType.DISPLAY_SCHEDULE_CLEAR:
      return {
        id: 0,
        scid: "",
        scname: "",
        part: "",
        cid: "",
        office: "",
        subject: "",
        date: "",
      };
    // Schedule Row Setting
    case displayType.DISPLAY_SCHEDULE_SET:
      return {
        id: action.payload.id,
        scid: action.payload.scid,
        scname: action.payload.scname,
        part: action.payload.part,
        cid: action.payload.cid,
        office: action.payload.office,
        subject: action.payload.subject,
        date: action.payload.date.toDate(),
      };

    // Schedule
    // Schedule Row Clear
    case displayType.DISPLAY_BOARD_CLEAR:
      return {
        id: "",
        title: "",
        content: "",
        image: [],
        file: [],
        tid: "",
        writer: "",
        log: "",
      };
    default:
      return state;
  }
};

export default displayDataReducer;
