const PAGE_TYPE_STUDENT = "PAGE_TYPE_STUDENT";
const PAGE_TYPE_TEACHER = "PAGE_TYPE_TEACHER";
const PAGE_TYPE_NORMAL = "PAGE_TYPE_NORMAL";

export const pageTypeStudent = () => ({
  type: PAGE_TYPE_STUDENT,
});

export const pageTypeTeacher = () => ({
  type: PAGE_TYPE_TEACHER,
});

export const pageTypeNormal = () => ({
  type: PAGE_TYPE_NORMAL,
});

const pageType = "normal";

const pageTypeReducer = (state = pageType, action) => {
  switch (action.type) {
    case PAGE_TYPE_STUDENT:
      return "student";
    case PAGE_TYPE_TEACHER:
      return "teacher";
    case PAGE_TYPE_NORMAL:
      return "normal";
    default:
      return state;
  }
};

export default pageTypeReducer;
