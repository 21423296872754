import { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getWordQuizRankingByCidAndDateAPI } from "../../../api/wordQuizApi";
import useRedirect from "../../../hooks/useRedirect";
import { groupBy } from "../../../lib/utils/getRank";
import { clearWordArray } from "../../../redux/FirebaseData/Word/reducer";

import * as CardStyles from "../../../styles/CardStyles";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const QuizResult = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  const [view, setView] = useState(0);
  const [rankingList, setRankingList] = useState([]);

  useRedirect("learningcenter", "home", !location.state);

  const quizResultList = location.state ? location.state.result : [];

  const getQuizResultData = useCallback(() => {
    dispatch(clearWordArray());
    location.state && getWordQuizRankingByCidAndDateAPI(location.state.cid, location.state.date, setRankingList);
  }, [dispatch, location.state]);

  useEffect(() => {
    getQuizResultData();
  }, [getQuizResultData]);

  return view === 0 ? (
    <Result quizResultList={quizResultList} setView={setView} />
  ) : (
    <Ranking rankingList={rankingList} setView={setView} myId={myInfo.uid} />
  );
};

const Result = ({ quizResultList, setView }) => (
  <BasicLayout headerType="student">
    <S.Container>
      <S.ContentContainer>
        <Row className="App-left" style={{ marginTop: 24 }}>
          <Col>
            <h2>Result</h2>
          </Col>
          <Col>
            <Button onClick={() => setView(1)} style={{ width: "100%" }}>
              랭킹 보기
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <CardStyles.CardContainer more>
              <CardStyles.CardQuizResultRow category>
                <div>번호</div>
                <div>문제</div>
                <div>답변</div>
                <div>점수</div>
              </CardStyles.CardQuizResultRow>
              {quizResultList.map((result, i) => (
                <CardStyles.CardQuizResultRow i={i} key={i} correct={result.correct}>
                  <div>{i + 1}</div>
                  <div>{result.question}</div>
                  <div>{result.response}</div>
                  <div>{result.score}</div>
                </CardStyles.CardQuizResultRow>
              ))}
            </CardStyles.CardContainer>
          </Col>
        </Row>
      </S.ContentContainer>
    </S.Container>
  </BasicLayout>
);

const Ranking = ({ rankingList, setView, myId }) => {
  const list = groupBy(rankingList, "sid");
  return (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left" style={{ marginTop: 24 }}>
            <Col>
              <h2>Ranking</h2>
            </Col>
            <Col>
              <Button onClick={() => setView(0)} style={{ width: "100%" }}>
                결과 보기
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CardStyles.CardContainer more>
                <CardStyles.CardQuizResultRow category ranking>
                  <div>순위</div>
                  <div>이름</div>
                  <div>점수</div>
                  <div>정답 갯수</div>
                </CardStyles.CardQuizResultRow>
                {list.map(
                  (result, i) =>
                    (i < 15 || result.sid === myId) && (
                      <CardStyles.CardQuizResultRow ranking key={result.sid} correct={result.sid === myId}>
                        <div>{i + 1}</div>
                        <div>{result.name}</div>
                        <div>{result.score}</div>
                        <div>{result.correct}</div>
                      </CardStyles.CardQuizResultRow>
                    ),
                )}
              </CardStyles.CardContainer>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};
export default QuizResult;
