import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { keyQuestionOption } from "../../../../redux/QuestionOption/reducer";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ChangeQuestion = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const changeQuestion = (e) =>
    dispatch(keyQuestionOption("question", e.getData()));

  return (
    <>
      <p className="Form-label"> Question </p>
      <CKEditor
        editor={ClassicEditor}
        config={{
          // 여기에 config 입력
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
            "insertTable",
          ],
          placeholder: "글을 입력하세요",
        }}
        data={questionOption.question}
        onChange={(event, editor) => {
          changeQuestion(editor);
        }}
      />
      {questionOption.question === "" && <RequiredText />}
    </>
  );
};

export default ChangeQuestion;
