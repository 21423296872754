import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { splitPage } from "../../Methods";
import { wordOptionKey } from "../../redux/wordOption/actions";

const SelectPage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // inputData reducer
  const wordOption = useSelector((state) => state.wordOptionReducer);
  // inputData reducer
  const inputData = useSelector((state) => state.wordOptionReducer).inputData;

  const wordSetList =
    inputData.length !== 0
      ? inputData
          .map((row) =>
            splitPage(row.set).map((obj) => ({
              scid: row.scid,
              category: row.category,
              set: obj,
            }))
          )
          .flat()
          .map((d, i) => ({ id: i, ...d }))
      : [];

  const changeDisplaySet = (number) => {
    const max = wordSetList.length - 1;
    const returnPageNum = () => {
      const calculatePageNum = wordOption.displayPage + number;
      switch (true) {
        case calculatePageNum < 0:
          return 0;
        case calculatePageNum > max:
          return max;
        default:
          return calculatePageNum;
      }
    };
    dispatch(wordOptionKey("displayPage", returnPageNum()));
  };

  const changeDisplaySetM = (type) => {
    const max = wordSetList.length - 1;
    const returnPageNum = type === "min" ? 0 : max;
    dispatch(wordOptionKey("displayPage", returnPageNum));
  };

  const disabledCondition = (type) => {
    const max = wordSetList.length - 1;
    return type === "min"
      ? wordOption.displayPage <= 0
      : wordOption.displayPage >= max;
  };

  return (
    <ButtonGroup className="mb-3">
      <Button
        variant="dark"
        onClick={() => changeDisplaySetM("min")}
        disabled={disabledCondition("min")}
      >
        처음
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(-5)}
        disabled={disabledCondition("min")}
      >
        ◀︎◀︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(-1)}
        disabled={disabledCondition("min")}
      >
        ◀︎
      </Button>
      <Button variant="dark" disabled>
        {wordSetList[wordOption.displayPage].category +
          "-" +
          wordSetList[wordOption.displayPage].set}
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(1)}
        disabled={disabledCondition("max")}
      >
        ▶︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySet(5)}
        disabled={disabledCondition("max")}
      >
        ▶︎▶︎
      </Button>
      <Button
        className="p-1"
        variant="dark"
        onClick={() => changeDisplaySetM("max")}
        disabled={disabledCondition("max")}
      >
        마지막
      </Button>
    </ButtonGroup>
  );
};

export default SelectPage;
