import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageType } from "types";

export default function useGoToMainPage(pageType: PageType) {
  const navigate = useNavigate();

  // firebase 로그인 정보 reducer
  // TODO: zustand로 교체
  const currentUser = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo.uid;

  useEffect(() => {
    if (currentUser) {
      pageType === "teacher" ? navigate("/teachingcenter/home", { replace: true }) : navigate("/learningcenter/home", { replace: true });
    }
  }, [currentUser, navigate, pageType]);
}
