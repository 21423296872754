import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import ChangeDate from "../../SelectTable/ChangeDate";
import ChangePart from "../Input/Table/ChangePart";
import ChangeSchedule from "../../SelectTable/ChangeSchedule";
import CheckModifyButton from "../../SelectTable/CheckModifyButton";
import { rowColor } from "../../../Methods";
import CheckAllModifyButton from "../../SelectTable/CheckAllModifyButton";

// 데이터의 정보를 표현하기 위한 table 컴포넌트
const InputTable = () => {
  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <>
      {inputData.length !== 0 && (
        <Table striped bordered hover size="sm" className="InputTable">
          <thead>
            <tr>
              <CheckAllModifyButton check="modify" width="10%" />
              <th width="35%">날짜</th>
              <th width="25%">스케쥴</th>
              <th width="20%">Part</th>
              <CheckAllModifyButton check="delete" width="10%" />
            </tr>
          </thead>
          <tbody>
            {inputData.map((row, i) => {
              return (
                <tr key={i} className={rowColor(row)}>
                  <CheckModifyButton type="modify" row={row} />
                  <td>
                    <ChangeDate
                      row={row}
                      type="modify"
                      disabled={!row.modify || row.delete}
                    />
                  </td>
                  <td>
                    <ChangeSchedule
                      row={row}
                      disabled={!row.modify || row.delete}
                    />
                  </td>
                  <td>
                    <ChangePart
                      row={row}
                      disabled={!row.modify || row.delete}
                    />
                  </td>
                  <CheckModifyButton
                    row={row}
                    type="delete"
                    disabled={!row.modify || row.add}
                  />
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default InputTable;
