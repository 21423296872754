import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDelete } from "../../../redux/InputData/actions";

// 행 제거 버튼 컴포넌트
const DeleteRowButton = (props) => {
  // props
  // InputTable에서 넘어온 row props의 id만 사용
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // redux dispatch로 처리할 index, props.id가 없을 경우 마지막 index 반환
  const deleteID = row !== undefined ? row.id : inputData.length - 1;

  // 버튼 비활성화 조건, selOption 내 반, 과목, 연, 월 선택 안되어 있을시 비활성화
  const buttonDisabled =
    !selOption.cid || !selOption.subject || !selOption.year || !selOption.month;

  // onClick 함수
  // 해당 index의 행을 삭제
  const deleteData = () => dispatch(inputDataDelete(deleteID));

  return (
    <Button variant="light" disabled={buttonDisabled} onClick={deleteData}>
      -
    </Button>
  );
};

export default DeleteRowButton;
