import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import db from "../firebase";
/**
 * @typedef wordQuiz
 * @property {string} id
 * @property {string} cid - 반 ID
 * @property {string} scheduleId - 스케쥴 id
 * @property {string} sid - 학생의 id
 * @property {number} correct - 정답 갯수
 * @property {number} score - 점수
 * @property {string} detail - detail
 */

/**
 * @description 전체 wordQuiz list 불러오기
 * @returns {wordQuiz[]}
 */
export async function getWordListAPI({ scid, part, scheduleList }) {
  const partArray = part.split(",");
  const setArray = () => {
    let wordSetArray = [];
    let nowScid = scid;
    for (let i = 0; i < partArray.length; i++) {
      if (partArray[i].indexOf("-") !== -1) {
        const [newScname, newPart] = partArray[i].split("-");
        nowScid = scheduleList.find((schedule) => schedule.name === newScname).id;
        wordSetArray.push({ scid: nowScid, part: Number(newPart) });
        continue;
      }
      wordSetArray.push({ scid: nowScid, part: Number(partArray[i]) });
    }
    return wordSetArray;
  };

  const wordList = setArray().map(async (set) => {
    let wordArray = [];
    const wordCollection = query(collection(db, "Words"), where("scid", "==", set.scid), where("set", "==", set.part));
    const querySnapshot = await getDocs(wordCollection);
    querySnapshot.forEach((doc) => wordArray.push(doc.data()));
    return wordArray;
  });

  return await Promise.all(wordList);
}

/**
 * @description 사용자의 sid를 이용해 wordQuiz list 불러오기
 * @param {string} sid
 * @returns {wordQuiz[]}
 */
export async function getMyWordQuizListAPI(sid) {
  const wordQuizCollection = query(collection(db, "WordQuiz"), where("sid", "==", sid));
  const querySnapshot = await getDocs(wordQuizCollection);

  /** @type {wordQuiz[]} */
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  return wordQuizArray;
}

/**
 * @description wordQuiz 생성
 * @param {wordQuiz} data
 * @returns {void}
 */
export async function createWordQuizAPI(data) {
  await addDoc(doc(db, "wordQuiz"), data);
}

/**
 * @description update wordQuiz
 * @param {string} wordQuizId
 * @param {wordQuiz} data
 * @returns {void}
 */
export async function updateWordQuizAPI(wordQuizId, data) {
  const { id, ...updateData } = data;
  await setDoc(doc(db, "wordQuiz", wordQuizId), updateData, { merge: true });
}

/**
 * @description delete wordQuiz
 * @param {string} wordQuizId
 * @returns {void}
 */
export async function deleteWordQuizAPI(wordQuizId) {
  await deleteDoc(doc(db, "wordQuiz", wordQuizId));
}
