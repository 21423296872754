/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ControlButton from "../../../../Component/Test/Modify/ControlButton";
import InputTable from "../../../../Component/Test/Modify/InputTable";
import SelectOption from "../../../../Component/Test/Modify/SelectOption";
import useRedirect from "../../../../hooks/useRedirect";
import { inputDataClear } from "../../../../redux/InputData/actions";
import { selOptionClear } from "../../../../redux/selOption/actions";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const TestModify = () => {
  // redux dispatch
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(selOptionClear("TestModify"));
    dispatch(inputDataClear());
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <SelectOption />
          <ControlButton />
          <hr />
          <InputTable />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default TestModify;
