import {
  SET_ALLGAMEARRAY_CLEAR,
  SET_GAMEARRAY_ADD,
  SET_GAMEARRAY_CHANGE,
  SET_GAMEARRAY_CLEAR,
  SET_GAMEARRAY_REMOVE,
} from "./types";

//
export const allGameArrayClear = (payload) => ({
  type: SET_ALLGAMEARRAY_CLEAR,
  payload,
});

export const gameArrayClear = (payload, league) => ({
  type: SET_GAMEARRAY_CLEAR,
  payload,
  league,
});

export const gameArrayChange = (payload, league) => ({
  type: SET_GAMEARRAY_CHANGE,
  payload,
  league,
});

export const gameArrayAdd = (payload) => ({
  type: SET_GAMEARRAY_ADD,
  payload,
});

export const gameArrayRemove = (payload, league) => ({
  type: SET_GAMEARRAY_REMOVE,
  payload,
  league,
});
