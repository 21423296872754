/* eslint-disable react-hooks/exhaustive-deps */
import db from "../../../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { Badge, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  inputDataClear,
  inputDataDelete,
} from "../../../../redux/InputData/actions";
import { modalShowPopup } from "../../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../../redux/PopupModal/reducer";
import {
  loadingComponent,
  loadingFalse,
} from "../../../../redux/Loading/actions";

const QuizHeader = (props) => {
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // inputdata reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const modify = useSelector((state) => state.selOptionReducer).modify;

  const deleteData = () =>
    dispatch(inputDataDelete(inputData.map((x) => x.id).indexOf(row.id)));

  const deleteFirebaseData = async () => {
    dispatch(loadingComponent(true));
    await deleteDoc(doc(db, "Question", row.id));
    setTimeout(() => {
      dispatch(modalShowPopup(false));
      dispatch(loadingFalse());
      dispatch(inputDataClear());
    }, 1000);
  };

  const bg = () => {
    switch (true) {
      case (row.exists || { exist: false }).exist:
        return "danger";
      case row.type === "short":
        return "dark";
      case row.type === "choice":
        return "primary";
      case row.type === "checkBox":
        return "success";
      default:
        return "danger";
    }
  };

  const badgeText = (row.exists || { exist: false }).exist
    ? "삭제하지 않고 업데이트 시 기존 문제가 변경됩니다."
    : row.type + " type";

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal(
        "삭제하시겠습니까?",
        "이 작업은 되돌릴 수 없습니다.",
        deleteFirebaseData,
        "삭제"
      )
    );
  };

  const badgeCondition = !(modify && (row.exists || { exist: false }).exist);

  return (
    <Card.Header>
      <Badge bg={bg()}>{row.qid}</Badge>
      {badgeCondition && (
        <Badge className="mx-2" bg={bg()}>
          {badgeText}
        </Badge>
      )}
      {modify ? (
        <Button
          variant="danger"
          style={{ float: "right" }}
          onClick={openPopup}
          className="px-2 py-0"
        >
          삭제
        </Button>
      ) : (
        <Button
          variant="danger"
          style={{ float: "right" }}
          onClick={deleteData}
          className="px-2 py-0"
        >
          -
        </Button>
      )}
    </Card.Header>
  );
};

export default QuizHeader;
