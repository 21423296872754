/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFireStoreData } from "../../redux/FirebaseData/DownloadController/reducer";
import {
  modalShowDisplay,
  modalShowLoad,
} from "../../redux/Modal_Show/actions";

const LoadModalButton = () => {
  const dispatch = useDispatch();
  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputData
  const studentSetting = useSelector((state) => state.presetStudentReducer);

  const downloadPreset = useSelector(
    (state) => state.downloadControlerReducer
  ).presetStudent;

  const setShow = () => {
    dispatch(modalShowDisplay(false));
    dispatch(modalShowLoad(true));
  };

  useEffect(() => {
    !downloadPreset && dispatch(getFireStoreData("presetStudent"));
  }, []);

  return (
    <Button
      disabled={studentSetting.length === 0 || loading}
      variant="warning"
      className="py-0 px-2"
      onClick={setShow}
    >
      설정 불러오기
    </Button>
  );
};

export default LoadModalButton;
