/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonGroup, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeletePresetButton from "./DeletePresetButton";
import LoadPresetButton from "./LoadPresetButton";

const PresetTable = () => {
  const studentSetting = useSelector((state) => state.presetStudentReducer);

  return (
    <Table bordered hover striped size="sm" style={{ textAlign: "center" }}>
      <thead>
        <tr>
          <th width="40%">Title</th>
          <th width="30%">Comment</th>
          <th width="30%" />
        </tr>
      </thead>
      <tbody>
        {studentSetting.map((row) => (
          <tr key={row.id}>
            <td>{row.title}</td>
            <td>{row.comment}</td>
            <td>
              <ButtonGroup>
                <LoadPresetButton row={row} />
                <DeletePresetButton row={row} />
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PresetTable;
