import * as S from "./BasicLayout.styled";
import { PageType } from "types";
import StudentNavigator from "components/blocks/top-navigation/student-navigator/StudentNavigator";
import GrowingNavT from "Component/NavBar/GrowingNavT";
import { TextHeader } from "components/blocks";
import { useState } from "react";
import { logout } from "api/authApi";
import { useNavigate } from "react-router-dom";
import useLoadingIndicator from "hooks/useLoadingIndicator";
import useGetMemberInfo from "api/useGetMemberInfo";
import * as msg from "../../../lib/utils/msg";

interface BasicLayoutProps {
  children: React.ReactNode;
  headerType?: PageType;
  headerText?: string;
}

export default function BasicLayout({ children, headerType = "", headerText = "" }: BasicLayoutProps) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { data: memberInfo } = useGetMemberInfo();
  // 로그아웃 후 로그인 페이지로 이동시키는 함수
  const handleLogout = async () => {
    if (!memberInfo) {
      return;
    }

    setIsLoading(true);

    try {
      await logout();
      // 선생님일 경우 선생님용 로그인 페이지, 선생님이 아닐 경우 학생용 로그인 페이지로 이동
      memberInfo.teacher ? navigate("/teachingcenter") : navigate("/learningcenter");
    } catch {
      // 오류 시 오류 메세지 출력
      alert(msg.errorMsg.default());
    }
    // loading reducer의 main state를 false로 변경
    setIsLoading(false);
  };

  useLoadingIndicator(isLoading);

  return (
    <S.Container isHeader={!!headerType}>
      {headerText && <TextHeader headerText={headerText} />}
      <Header headerType={headerType} handleLogout={handleLogout} />
      {children}
    </S.Container>
  );
}

const Header = ({ headerType, handleLogout }: { headerType: PageType; handleLogout: () => Promise<void> }) => {
  if (!headerType) {
    return null;
  }
  if (headerType === "student") {
    return <StudentNavigator handleLogout={handleLogout} />;
  }
  return <GrowingNavT handleLogout={handleLogout} />;
};
