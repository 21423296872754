import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataCheck } from "../../redux/InputData/actions";

// inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용하는 버튼 컴포넌트
const CheckModifyButton = (props) => {
  // props
  // inputData 내 index
  const row = props.row;
  // modify / delete
  const type = props.type;
  // disabled condition
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  // onClick 함수
  // inputData 내 스케쥴을 수정하겠다는 체크를 할때 사용
  const checkEdit = () =>
    !(loading || disabled) && dispatch(inputDataCheck(row.id, type));

  return (
    <td
      onClick={checkEdit}
      style={
        !(loading || disabled) ? { cursor: "pointer" } : { cursor: "default" }
      }
    >
      <Button
        variant="light"
        className={row[type] ? "px-1" : "px-2"}
        style={{ color: "black" }}
        disabled={loading || disabled}
      >
        {row[type] ? "✔︎" : " "}
      </Button>
    </td>
  );
};

export default CheckModifyButton;
