import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import gradeCalc from "../../lib/utils/gradeCalc";
import { displayDataSet } from "../../redux/DisplayData/actions";
import { modalShowDisplay } from "../../redux/Modal_Show/actions";

const StudentListTable = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // user list reducer
  const users = useSelector((state) => state.usersReducer);

  let student = users.filter((v) => v.teacher === false && v.state === true && v.office === myInfo.office);

  let studentList = () => {
    switch (true) {
      case selOption.grade !== null && selOption.school !== null:
        return student.filter((row) => gradeCalc(row.age) === selOption.grade && row.school === selOption.school);
      case !selOption.grade && selOption.school !== null:
        return student.filter((row) => row.school === selOption.school);
      case selOption.grade !== null && !selOption.school:
        return student.filter((row) => gradeCalc(row.age) === selOption.grade);
      default:
        return student;
    }
  };

  // onClick 함수
  const showDisplay = (row) => {
    dispatch(modalShowDisplay(true));
    dispatch(
      displayDataSet({
        ...row,
        registrationDate: row.registrationDate ? row.registrationDate.toDate() : new Date(),
      })
    );
  };

  return (
    <Table striped hover bordered size="sm" style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th colSpan={7}>총 {studentList().length}명</th>
        </tr>
        <tr>
          <th>이름</th>
          <th>나이</th>
          <th>학년</th>
          <th>학교</th>
          <th>지점</th>
          <th>전화번호</th>
          <th>등록일</th>
        </tr>
      </thead>
      <tbody>
        {studentList().map((row) => (
          <tr key={row.id} style={myInfo.admin ? { cursor: "pointer" } : { cursor: "default" }} onClick={() => showDisplay(row)}>
            <td>{row.name}</td>
            <td>{row.age}</td>
            <td>{gradeCalc(row.age)}</td>
            <td>{row.school}</td>
            <td>{row.office}</td>
            <td>{row.phone}</td>
            <td>{row.registrationDate && row.registrationDate.toDate().toLocaleDateString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default StudentListTable;
