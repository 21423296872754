/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useStudentList } from "../../firestoreData";
import { RequiredText } from "../../Methods";
import { getFireStoreData } from "../../redux/FirebaseData/DownloadController/reducer";
import { selOptionKey } from "../../redux/selOption/actions";

// select option의 학생을 변경하기 위한 Select 컴포넌트
const SelectStudent = (props) => {
  // props
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // download controler reducer
  const downloadUser = useSelector(
    (state) => state.downloadControlerReducer
  ).users;

  // require message 출력 조건, props로 required가 넘어오고, selOption의 sid가 null일 경우
  const returnRequire = required && !selOption.sid;

  // onChange 함수
  // select option reducer의 sid와 name 변경 함수
  const changeSID = (e) => {
    const returnValue = e || { id: "", name: "" };
    dispatch(selOptionKey("sid", returnValue.id));
    dispatch(selOptionKey("name", returnValue.name));
  };

  // useFirestoreData.js 의 해당 지점 전체 학생 데이터
  const student = useStudentList();

  useEffect(() => {
    // 유저 데이터 다운로드 토글 true로
    !downloadUser && dispatch(getFireStoreData("users"));
  }, []);

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="학생"
        isSearchable
        isClearable={clearable}
        isDisabled={disabled}
        options={student}
        value={selOption.sid ? selOption : null}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        onChange={changeSID}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectStudent;
