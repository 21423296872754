import { ListGroupItem } from "react-bootstrap";

const DisplayAnswer = (props) => {
  const row = props.row;
  const number = props.number;

  const circleChar = () => {
    switch (number) {
      case 1:
        return "①";
      case 2:
        return "②";
      case 3:
        return "③";
      case 4:
        return "④";
      case 5:
        return "⑤";
      default:
        return "error!";
    }
  };

  const isAnswerStyle =
    row.type === "choice"
      ? row.numAnswer.indexOf(number.toString()) !== -1
      : row.answerArray[number.toString()];

  return (
    <>
      {row["num" + number.toString()] !== "" && (
        <ListGroupItem
          className="py-1"
          style={
            isAnswerStyle
              ? { background: "lightblue", fontSize: "15px" }
              : { background: "none", fontSize: "15px" }
          }
        >
          {circleChar() + " " + row["num" + number.toString()]}
        </ListGroupItem>
      )}
    </>
  );
};

export default DisplayAnswer;
