import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../../../redux/InputData/actions";

// inputData 내의 개별 행 파트 변경을 위해 사용하는 Select 컴포넌트
const SelectTablePart = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const row = props.row;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // onChange 함수
  // 해당 행의 scid, scname을 바꾸는 함수
  const changeSchedule = (row, e) =>
    dispatch(inputDataKey("part", row.id, e.target.value));

  // 기본값
  const defaultValue = row.part || "";

  return (
    <Form.Control
      type="text"
      style={{ textAlign: "center" }}
      value={defaultValue}
      onChange={(e) => changeSchedule(row, e)}
      placeholder="파트"
      disabled={disabled}
    />
  );
};

export default SelectTablePart;
