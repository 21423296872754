import Select from "react-select";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../../redux/selOption/actions";
import { passages } from "../../../../lib/utils/constants";

const ChangePassageType = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const nowPassage =
    useSelector((state) => state.selOptionReducer).nowPassage || {};

  // onChange 함수
  // select option reducer의 type 변경 함수
  const changeType = (e) => {
    dispatch(selOptionKey("nowPassage", { ...nowPassage, type: e.name }));
  };
  // select option reducer의 textbook 변경 함수
  const changeTextbook = (e) => {
    dispatch(selOptionKey("nowPassage", { ...nowPassage, textbook: e.name }));
  };
  // select option reducer의 lesson 변경 함수
  const changeLesson = (e) => {
    dispatch(
      selOptionKey("nowPassage", { ...nowPassage, lesson: e.target.value })
    );
  };

  const textbookList = () => {
    return passages.textbooks.filter(
      (row) => row.type.indexOf(nowPassage.type) !== -1
    );
  };

  return (
    <>
      {nowPassage.type !== "" && (
        <Row className="g-2 mb-3">
          <Col md>
            <p className="Form-label"> 타입 </p>
            <Select
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              placeholder="타입"
              isSearchable
              options={passages.types}
              value={nowPassage.type ? { name: nowPassage.type } : null}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.name}
              maxMenuHeight={150}
              onChange={changeType}
            />
          </Col>
          <Col md>
            <p className="Form-label"> 교과서 </p>
            <Select
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              placeholder="교과서"
              isSearchable
              options={textbookList()}
              value={nowPassage.textbook ? { name: nowPassage.textbook } : null}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.name}
              maxMenuHeight={150}
              onChange={changeTextbook}
            />
          </Col>
          <Col md>
            <p className="Form-label"> 레슨/번호 </p>
            <Form.Control
              style={{ textAlign: "center" }}
              placeholder="레슨"
              value={nowPassage.lesson || ""}
              onChange={changeLesson}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ChangePassageType;
