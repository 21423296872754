import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setPassage } from "../../../../redux/PassageObject/reducer";

const InputPassage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const passage = useSelector((state) => state.passageObjectReducer);

  // onChange 함수
  // passageObject reducer의 passage 변경 함수
  const changePassage = (language, e) => dispatch(setPassage(language, e));

  return (
    <Row className="g-2 mb-3">
      <Col md>
        <p className="Form-label">
          지문{" "}
          <strong>
            (문장 부호 (. ! ?)를 기준으로 한 문장으로 인식합니다.)
          </strong>
        </p>
        <Form.Control
          as="textarea"
          rows={5}
          style={{ fontSize: "12px" }}
          onChange={(e) => changePassage("eng", e.target.value)}
          value={passage.eng || ""}
        />
      </Col>
      <Col md>
        <p className="Form-label">
          해석{" "}
          <strong>
            (문장 부호 (. ! ?)를 기준으로 한 문장으로 인식합니다.)
          </strong>
        </p>
        <Form.Control
          as="textarea"
          rows={5}
          style={{ fontSize: "12px" }}
          onChange={(e) => changePassage("kor", e.target.value)}
          value={passage.kor || ""}
        />
      </Col>
    </Row>
  );
};

export default InputPassage;
