import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import { useDispatch } from "react-redux";
import { inputDataKey } from "../../redux/InputData/actions";

// 날짜를 한국어로
registerLocale("ko", ko);

// inputData 내의 개별 행 날짜 변경을 위해 사용하는 Select 컴포넌트
const ChangeDate = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const row = props.row;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // onChange 함수
  // 해당 행의 date를 바꾸는 함수
  const changeDate = (e) => dispatch(inputDataKey("date", row.id, e));

  return (
    <DatePicker
      dateFormat="yy.MM.dd (eee)"
      todayButton="today"
      selected={row.date}
      locale={ko}
      className="Date-picker-border"
      onChange={changeDate}
      disabled={disabled}
    />
  );
};

export default ChangeDate;
