import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';
import * as XLSX from 'xlsx';

export const kahootReport = async (e, student, quizPart) => {
  const file = e.target.files[0];
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets['RawReportData Data'];
  let range = XLSX.utils.decode_range(worksheet['!ref']);
  range.e.r = 10000;
  worksheet['!ref'] = XLSX.utils.encode_range(range);
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
  return jsonData.map((row, i) => {
    return {
      id: i,
      sid: student.find((a) => a.name === row.Player.trim())
        ? student.find((a) => a.name === row.Player.trim()).id
        : null,
      name: row.Player,
      qid: row.Question.substring(0, 6),
      part: quizPart.find((q) => q.id === row.Question.substring(0, 2))
        ? quizPart.find((q) => q.id === row.Question.substring(0, 2)).part
        : 'GRA',
      correct: row.Correct,
      answer: row.answer,
    };
  });
};

export const quizizzReport = async (e, student, quizPart) => {
  const file = e.target.files[0];
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets['Overview'];
  let range = XLSX.utils.decode_range(worksheet['!ref']);
  range.e.r = 10000;
  worksheet['!ref'] = XLSX.utils.encode_range(range);
  const jsonData = XLSX.utils.sheet_to_json(worksheet, {
    defval: 'unattempted',
  });
  jsonData.splice(jsonData.length - 2, 2);
  const keys = Object.keys(jsonData[0]).slice(11);

  const qidArray = await Promise.all(
    jsonData.map(async (row) => {
      const quizd = await getDoc(doc(db, 'Question', row.Question.substring(0, 6)));
      return { qid: quizd.id, ...quizd.data() };
    }),
  );

  const qidMap = Object.fromEntries(qidArray.map((q) => [q.qid, q]));

  try {
    const returnArray = await Promise.all(
      jsonData.map((row) => {
        const arrmap = keys
          .map((a) => {
            let replacePlace = a.indexOf('(');
            let name = a.substring(0, replacePlace).replace(/\*/g, '').trim();
            const studentEntry = student.find((s) => s.name === name);
            const quizPartEntry = quizPart.find((q) => q.id === row.Question.substring(0, 2));

            const correctAnswer =
              row[a] && row[a] !== 'unattempted'
                ? qidMap[row.Question.substring(0, 6)]?.answer?.toString().trim() ===
                  row[a].toString().trim()
                  ? 1
                  : 0
                : 'unattempted';

            return {
              qid: row.Question.substring(0, 6),
              sid: studentEntry ? studentEntry.id : null,
              name: name,
              part: quizPartEntry ? quizPartEntry.part : 'GRA',
              correct: correctAnswer,
              answer: row[a].toString().trim(),
            };
          })
          .filter((res) => res.correct !== 'unattempted');

        return arrmap;
      }),
    );

    return returnArray.flat();
  } catch (error) {
    console.error(error);
  }
};
