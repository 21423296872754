import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const CreateTodoRowButton = ({ createRowTable }) => {
  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  return (
    <Button className="py-0 px-2" disabled={loading} onClick={createRowTable}>
      +
    </Button>
  );
};

export default CreateTodoRowButton;
