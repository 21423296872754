import { ButtonVariant } from "types";
import * as S from "./Button.styled";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: "SM" | "MD" | "LG";
}

export default function Button({ children, variant = "primary", size = "MD", ...rest }: ButtonProps) {
  return (
    <S.Container variant={variant} {...rest}>
      {children}
    </S.Container>
  );
}
