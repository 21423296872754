import * as S from "./TextHeader.styled";

interface TextHeaderProps {
  headerText: string;
}

export default function TextHeader({ headerText }: TextHeaderProps) {
  return (
    <S.Container>
      <S.ContentContainer>
        <S.HeaderText>{headerText}</S.HeaderText>
      </S.ContentContainer>
    </S.Container>
  );
}
