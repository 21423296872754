import { useEffect } from "react";
import * as CardStyles from "../styles/CardStyles";

const QuizTimerBox = ({ quizTimer, setQuizTimer }) => {
  useEffect(() => {
    if (!quizTimer.start) return;
    const timer = setInterval(() => {
      if (quizTimer.second === 0 && quizTimer.milli === 0) {
        clearInterval(timer);
        return;
      }
      if (quizTimer.milli === 0) {
        setQuizTimer((prev) => ({ ...prev, second: prev.second - 1, milli: 99 }));
        return;
      }
      setQuizTimer((prev) => ({ ...prev, milli: prev.milli - 1 }));
    }, 10);

    return () => clearInterval(timer);
  });

  return (
    <CardStyles.TimerBox>
      <p>{`${quizTimer.second}.${quizTimer.milli.toString().padStart(2, "0")}`}</p>
    </CardStyles.TimerBox>
  );
};

export default QuizTimerBox;
