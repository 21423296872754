import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import {
  keyQuestionOption,
  keyQuestionOptionAnswerArray,
} from "../../../../redux/QuestionOption/reducer";

const InputNumberAnswer = (props) => {
  // props
  // number
  const number = props.number;

  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);
  const answerArray = useSelector(
    (state) => state.questionOptionReducer
  ).answerArray;

  const circleChar = () => {
    switch (number) {
      case 1:
        return "①";
      case 2:
        return "②";
      case 3:
        return "③";
      case 4:
        return "④";
      case 5:
        return "⑤";
      default:
        return "error!";
    }
  };

  const buttonVariant = () => {
    const style = (condition) => (condition ? "primary" : "light");

    return questionOption.type === "choice"
      ? style(questionOption.numAnswer === number.toString())
      : style(answerArray[number]);
  };

  const saveAnswer = () => {
    questionOption.type === "choice"
      ? dispatch(keyQuestionOption("numAnswer", number.toString()))
      : dispatch(keyQuestionOptionAnswerArray(number));
  };

  const inputBackground = () => {
    const style = (condition) =>
      condition ? { background: "lightblue" } : { background: "none" };

    return questionOption.type === "choice"
      ? style(questionOption.numAnswer === number.toString())
      : style(answerArray[number]);
  };

  const changeOptions = (e) =>
    dispatch(keyQuestionOption("num" + number.toString(), e.target.value));

  const requireCondition =
    (number === 1 || number === 2) &&
    questionOption["num" + number.toString()] === "" &&
    questionOption.type !== "short";

  return (
    <>
      <Button
        variant={buttonVariant()}
        onClick={saveAnswer}
        className="Form-label py-0"
      >
        {circleChar()}
      </Button>
      <Form.Control
        as="textarea"
        rows={2}
        value={questionOption["num" + number.toString()]}
        style={inputBackground()}
        onChange={(e) => changeOptions(e)}
      />
      {requireCondition && <RequiredText />}
    </>
  );
};

export default InputNumberAnswer;
