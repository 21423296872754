import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataSchDaySet } from "../../../redux/InputData/actions";

// selOption에서 선택한 조건들에 맞게 inputData 생성하기 위한 버튼 컴포넌트
const DayLoadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // 버튼 비활성화 조건
  // selOption의 반, 과목, 연, 월이 설정 안 되어 있거나 정상적인 날짜가 아닐 시 비활성화
  const buttonDisabled =
    !selOption.cid ||
    !selOption.subject ||
    !selOption.year ||
    !selOption.month ||
    selOption.year < 2022 ||
    selOption.month < 0 ||
    selOption.month > 13;

  // onClick 함수
  // selOption에서 선택한 조건들에 맞게 inputData 생성
  const daySet = () => dispatch(inputDataSchDaySet(selOption));

  return (
    <>
      {inputData.length === 0 && (
        <Button
          className="Growing-button"
          disabled={buttonDisabled}
          onClick={daySet}
        >
          평일 불러오기
        </Button>
      )}
    </>
  );
};

export default DayLoadButton;
