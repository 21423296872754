/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { RequiredText } from "../../../Methods";
import { useEffect, useState } from "react";
import { getFireStoreData } from "../../../redux/FirebaseData/DownloadController/reducer";
import { selOptionClear, selOptionKey } from "../../../redux/selOption/actions";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import {
  displayDataClear,
  displayDataSet,
} from "../../../redux/DisplayData/actions";

// select option의 반을 변경하기 위한 Select 컴포넌트
const SelectPassage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const [passage, setPassage] = useState([]);

  // reducers
  // 본문 리스트 reducer
  const passageList = useSelector((state) => state.passageReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // download controler reducer
  const downloadPassageList = useSelector(
    (state) => state.downloadControlerReducer
  ).passage;

  // onChange 함수
  // select option reducer의 type 변경 함수
  const changeType = (e) => {
    const returnValue = e || { type: "" };
    dispatch(selOptionKey("type", returnValue.type));
  };
  // select option reducer의 textbook 변경 함수
  const changeTextbook = (e) => {
    const returnValue = e || { texbook: "" };
    dispatch(selOptionKey("textbook", returnValue.textbook));
  };
  // select option reducer의 lesson 변경 함수
  const changeLesson = (e) => {
    const returnValue = e || { lesson: "", passage: [] };
    dispatch(selOptionKey("lesson", returnValue.lesson));
    setPassage(returnValue);
  };
  // select option reducer의 passage 변경 함수
  const changePassage = () => {
    const returnValue = passage.passage ?? [];
    dispatch(selOptionKey("passage", returnValue));
    dispatch(displayDataSet(returnValue[0]));
  };
  // option reducer clear
  const optionClear = () => {
    dispatch(selOptionClear("ReadingInput"));
    dispatch(displayDataClear());
  };

  // passageList 내 타입 리스트 리턴
  const passageType = passageList
    .map((row) => {
      return row.type;
    })
    .filter((val, index, arr) => {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ type: row }));

  // passageList 내 selOption의 타입과 일치하는 교과서 리스트 리턴
  const passageTextbook = passageList
    .filter((row) => row.type === selOption.type)
    .map((row) => {
      return row.textbook;
    })
    .filter((val, index, arr) => {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ textbook: row }));

  // passageList 내 selOption의 타입과 교과서 둘 다 일치하는 리스트 리턴
  const passageLesson = passageList.filter(
    (row) => row.type === selOption.type && row.textbook === selOption.textbook
  );

  useEffect(() => {
    // 본문 데이터 다운로드 토글 true로
    !downloadPassageList && dispatch(getFireStoreData("passage"));
  }, []);

  return (
    <>
      <Row className="g-2 mb-3">
        <Col md>
          <p className="Form-label"> 타입 </p>
          <Select
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            placeholder="타입"
            isSearchable
            options={passageType}
            value={selOption.type ? selOption : null}
            getOptionLabel={(e) => e.type}
            getOptionValue={(e) => e.type}
            maxMenuHeight={150}
            onChange={changeType}
            isDisabled={(selOption.passage || []).length}
          />
        </Col>
        <Col md>
          <p className="Form-label"> 교과서 </p>
          <Select
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            placeholder="교과서"
            isSearchable
            options={passageTextbook}
            value={selOption.textbook ? selOption : null}
            getOptionLabel={(e) => e.textbook}
            getOptionValue={(e) => e.textbook}
            maxMenuHeight={150}
            onChange={changeTextbook}
            isDisabled={!selOption.type || (selOption.passage || []).length}
          />
        </Col>
        <Col md>
          <p className="Form-label"> 과/번호 </p>
          <Select
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            placeholder="과/번호"
            isSearchable
            options={passageLesson}
            value={selOption.lesson ? selOption : null}
            getOptionLabel={(e) => e.lesson}
            getOptionValue={(e) => e.lesson}
            maxMenuHeight={150}
            onChange={changeLesson}
            isDisabled={!selOption.textbook || (selOption.passage || []).length}
          />
          {!selOption.lesson && <RequiredText />}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md>
          <ButtonGroup>
            <Button
              onClick={changePassage}
              disabled={(selOption.passage || []).length || !selOption.lesson}
            >
              지문 불러오기
            </Button>
            <Button variant="warning" onClick={optionClear}>
              초기화
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};

export default SelectPassage;
