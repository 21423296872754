import styled from "@emotion/styled";
import React from "react";
import { useEffect, useState } from "react";

const Styles = {
  Container: styled.div`
    width: 100%;
    padding-top: 16px;
  `,
  TimerText: styled.span`
    font-size: 24px;
    font-weight: bold;
    ${({ warning }) => (warning ? `color: red` : "")};
  `,
};

const CountdownTimer = ({ showModal, setShowModal }) => {
  const [countdown, setCountdown] = useState(600);

  const minute = Math.floor(countdown / 60);
  const second = countdown % 60;

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prev) => prev - 1);
      } else {
        setShowModal(true);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown, setCountdown, setShowModal]);

  return (
    <Styles.Container>
      <Styles.TimerText warning={countdown < 60}>{`${minute}:${String(second).padStart(2, "0")}`}</Styles.TimerText>
    </Styles.Container>
  );
};

export default CountdownTimer;
