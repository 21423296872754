import React, { useEffect, useState } from "react";
import "../App.css";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import useRedirect from "../hooks/useRedirect";

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  return (
    <div className="container-fluid">
      <Card bg="dark" text="white">
        <Card.Body style={{ fontSize: "14vw", fontFamily: "Orbitron" }}>
          <Row>
            <Col>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</Col>
            <Col>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</Col>
            <Col>{("0" + ((time / 10) % 100)).slice(-2)}</Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          {!timerOn && time === 0 && (
            <Button variant="light" onClick={() => setTimerOn(true)}>
              Start
            </Button>
          )}
          {timerOn && (
            <Button variant="light" onClick={() => setTimerOn(false)}>
              Stop
            </Button>
          )}
          <ButtonGroup>
            {!timerOn && time > 0 && (
              <Button variant="light" onClick={() => setTime(0)}>
                Reset
              </Button>
            )}
            {!timerOn && time > 0 && (
              <Button variant="light" onClick={() => setTimerOn(true)}>
                Resume
              </Button>
            )}
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Stopwatch;
