import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataCreate } from "../../../redux/InputData/actions";

// inputData의 행 추가를 위해 사용하는 버튼 컴포넌트
const CreateRowButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 버튼 비활성화 조건, selOption 내 반, 과목, 연, 월 선택 안되어 있을시 비활성화
  const buttonDisabled =
    !selOption.cid || !selOption.subject || !selOption.year || !selOption.month;

  // onClick 함수
  // inputData의 행 추가
  const createData = () => dispatch(inputDataCreate("schedule"));

  return (
    <Button
      className="Growing-button"
      disabled={buttonDisabled}
      onClick={createData}
    >
      +
    </Button>
  );
};

export default CreateRowButton;
