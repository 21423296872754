import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { gameArrayClear } from "../../../redux/League_GameArray/actions";
import { useScoreSum } from "./function";

// 게임 초기화 버튼 컴포넌트
const GameClearButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // league 인원 reducer
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];
  // game 점수 reducer
  const gameArray = useSelector((state) => state.gameArrayReducer)[level];

  // onClick 함수
  // gameArray 내 모든 셀 0으로 초기화
  const gameClear = () => {
    dispatch(gameArrayClear(leagueRoster.length, level));
  };

  const isGamePlayed = useScoreSum(gameArray).reduce((a, b) => a + b) === 0;

  return (
    <Button
      variant="danger"
      style={{ float: "right" }}
      onClick={gameClear}
      disabled={loading || isGamePlayed}
    >
      게임 초기화
    </Button>
  );
};

export default GameClearButton;
