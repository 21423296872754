import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { selOptionKey } from "../../redux/selOption/actions";

const SelectYearMonth = (props) => {
  // props
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // require message 출력 조건, props로 required가 넘어오고, selOption의 cid가 null일 경우
  const returnRequire = required && (!selOption.year || !selOption.month);

  // onChange 함수
  // displaySchedule의 year 변경
  const changeYear = (e) => dispatch(selOptionKey("year", e.target.value));
  // onChange 함수
  // displaySchedule의 month 변경
  const changeMonth = (e) => dispatch(selOptionKey("month", e.target.value));

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <InputGroup>
        <Form.Control
          type="number"
          style={{ textAlign: "center" }}
          value={selOption.year || 2022}
          disabled={disabled}
          onChange={(e) => changeYear(e)}
          min={2022}
        />
        <Form.Control
          type="number"
          style={{ textAlign: "center" }}
          value={selOption.month || 1}
          disabled={disabled}
          onChange={(e) => changeMonth(e)}
          min={1}
          max={12}
        />
      </InputGroup>
      {returnRequire && <RequiredText />}
      {selOption.year < 2022 && (
        <RequiredText text="2022년 이상의 연도를 입력하세요." />
      )}
      {(selOption.month < 1 || selOption.month > 13) && (
        <RequiredText text="1월부터 12월 사이를 입력해야합니다." />
      )}
    </>
  );
};

export default SelectYearMonth;
