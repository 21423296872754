/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getWordQuizRankingByDateAPI } from "../../../../api/wordQuizApi";
import useRedirect from "../../../../hooks/useRedirect";
import { groupBy } from "../../../../lib/utils/getRank";
import { clearWordArray } from "../../../../redux/FirebaseData/Word/reducer";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

import * as CardStyles from "../../../../styles/CardStyles";

const DailyQuizResult = () => {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classesReducer);

  const [nowClass, setNowClass] = useState({ id: "MDE6", name: "E6" });
  const [rankingList, setRankingList] = useState([]);
  const [classRankingList, setClassRankingList] = useState([]);

  const changeNowClass = (e) => {
    setNowClass({ id: e.id, name: e.name });
    setClassRankingList(
      groupBy(
        rankingList.filter((data) => data.cid === e.id),
        "sid",
      ),
    );
  };

  useRedirect("learningcenter", "home");

  useEffect(async () => {
    dispatch(clearWordArray());
    getWordQuizRankingByDateAPI(moment(new Date()).format("YYYY-MM-DD"), setRankingList);
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left" style={{ marginTop: 24 }}>
            <Col>
              <h2>{moment(new Date()).format("YYYY-MM-DD")} Ranking</h2>
            </Col>
            <Col>
              <Select
                styles={{
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                placeholder="반"
                isSearchable
                options={classes}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.name}
                maxMenuHeight={150}
                onChange={changeNowClass}
                value={nowClass}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CardStyles.CardContainer more>
                <CardStyles.CardQuizResultRow quizResult>
                  <div>순위</div>
                  <div>이름</div>
                  <div>점수</div>
                  <div>정답 갯수</div>
                </CardStyles.CardQuizResultRow>
                {classRankingList.map((result, i) => (
                  <CardStyles.CardQuizResultRow quizResult key={i}>
                    <div>{i + 1}</div>
                    <div>{result.name}</div>
                    <div>{result.score}</div>
                    <div>{result.correct}</div>
                  </CardStyles.CardQuizResultRow>
                ))}
              </CardStyles.CardContainer>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};
export default DailyQuizResult;
