/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selOptionClear } from "../../../redux/selOption/actions";
import { loadingFalse } from "../../../redux/Loading/actions";
import SelectNumberForm from "../../../Component/Select/SelectNumberForm";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { updateOption } from "../../../api/optionApi";
import useRedirect from "../../../hooks/useRedirect";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";
import { InputText } from "components/blocks/forms";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import db from "../../../firebase";

const Setting = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const studentHomeBannerRef = useRef(null);
  const [studentHomeBanner, setStudentHomeBanner] = useState("");

  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  const settingData = useSelector((state) => state.settingReducer);

  const updateData = async () => {
    updateOption("만덕", selOption);
    await setDoc(doc(db, "StudentBanner", "fYhGvWTUdBgLSgQ11FVO"), { index: 0, text: studentHomeBanner });
  };

  const getBanners = useCallback(async () => {
    let banners = [];

    const bannerQuery = query(collection(db, "StudentBanner"));
    const querySnapshot = await getDocs(bannerQuery);

    querySnapshot.forEach((doc) => banners.push(doc.data()));

    if (!banners.length) {
      return;
    }

    setStudentHomeBanner(banners[0].text);
  }, []);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  useRedirect("teachingcenter", "home");

  // 컨테이너 마운트 시 최초 한번만 실행
  useEffect(() => {
    // select option reducer를 스케쥴 인풋에 맞게 재설정
    dispatch(selOptionClear("Set", settingData));
    dispatch(loadingFalse());
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Tab.Container id="left-tabs-example" defaultActiveKey="competitionExtra">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="competitionExtra">컴퍼티션 가산점</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="competitionProportion">컴퍼티션/퀴즈 비율</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="quizTime">퀴즈 시간</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="maxScore">테스트 최대 점수</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="studentHomeBanner">학생 화면 문구 변경</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="competitionExtra">
                    <SelectNumberForm title="컴퍼티션 가산점" objectKey="competitionExtra" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="competitionProportion">
                    <Row>
                      <Col md>
                        <SelectNumberForm title="컴퍼티션 순위" objectKey="rank" parent="competitionProportion" />
                      </Col>
                      <Col md>
                        <SelectNumberForm title="퀴즈 점수" objectKey="quiz" parent="competitionProportion" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="quizTime">
                    <SelectNumberForm title="퀴즈 시간" objectKey="quizTime" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="maxScore">
                    <Row>
                      <Col md>
                        <SelectNumberForm title="Listening" objectKey="listening" parent="maxScore" />
                      </Col>
                      <Col md>
                        <SelectNumberForm title="Grammar" objectKey="grammar" parent="maxScore" />
                      </Col>
                      <Col md>
                        <SelectNumberForm title="Reading" objectKey="reading" parent="maxScore" />
                      </Col>
                      <Col md>
                        <SelectNumberForm title="모의고사" objectKey="mock" parent="maxScore" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="studentHomeBanner">
                    <InputText
                      label="학생 화면 문구"
                      type="string"
                      ref={studentHomeBannerRef}
                      value={studentHomeBanner}
                      onChange={(e) => setStudentHomeBanner(e.target.value)}
                      onDelete={() => setStudentHomeBanner("")}
                      autoComplete="on"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Row className="mt-5">
            <Col md>
              <Button onClick={updateData}>업로드</Button>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default Setting;
