import { useSelector } from "react-redux";

// 학생의 퀴즈별 정답률 및 퀴즈별 정답률 Display
const AnswerCell = () => {
  // reducers
  // Input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 퀴즈별 정답률
  const qidRate = (qid) => {
    let rate = { correct: 0, count: 0 };
    quizInputData
      .filter((row) => row.qid === qid)
      .forEach((res) => {
        rate.correct += res.correct;
        rate.count += res.count;
      });
    return Math.round((rate.correct / rate.count) * 10000) / 100;
  };

  // qid 배열
  const qidArray = quizInputData
    .map(function (val, index) {
      return val["qid"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ qid: row, rate: qidRate(row) }))
    .sort(function (a, b) {
      return a.rate - b.rate;
    });

  // 학생의 퀴즈별 정답률
  const correctRate = (sid, qid) =>
    quizInputData.find((obj) => obj.sid === sid && obj.qid === qid) !==
    undefined
      ? Math.round(
          (quizInputData.find((obj) => obj.sid === sid && obj.qid === qid)
            .correct /
            quizInputData.find((obj) => obj.sid === sid && obj.qid === qid)
              .count) *
            10000
        ) / 100
      : "";

  // 퀴즈별 정답률에 따라 셀 스타일 변경
  const qidRateStyle = (qid) =>
    qidRate(qid) <= selOption.percentage
      ? { background: "Salmon", color: "white" }
      : { background: "SeaGreen", color: "white" };

  // 학생의 퀴즈별 정답률에 따라 셀 스타일 변경
  const correctRateStyle = (sid, qid) =>
    correctRate(sid, qid) !== ""
      ? correctRate(sid, qid) <= selOption.percentage
        ? { background: "DarkSalmon" }
        : { background: "DarkSeaGreen" }
      : { background: "grey" };

  return (
    <>
      {qidArray.map((row) => (
        <tr key={row.qid}>
          <td style={qidRateStyle(row.qid)}>
            <strong>{row.qid}</strong>
          </td>
          {inputData.map((res) => (
            <td key={res.sid} style={correctRateStyle(res.sid, row.qid)}>
              {correctRate(res.sid, row.qid) !== "" &&
                correctRate(res.sid, row.qid) + "%"}
            </td>
          ))}
          <td style={qidRateStyle(row.qid)}>
            <strong>{qidRate(row.qid)}%</strong>
          </td>
        </tr>
      ))}
    </>
  );
};

export default AnswerCell;
