import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setQuizUploadData } from "../../../redux/Quiz_UploadData/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { nullPlayer } from "./function";

// 퀴즈 파일 로드 시 UploadData로 데이터 계산하여 이동시키는 버튼 컴포넌트
const CompeleteButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 퀴즈 파일 로드 시 정오표 업로드 되는 reducer
  const inputData = useSelector((state) => state.quizInputDataReducer);

  // inputData 내 서버에 등록되지 않았거나 잘못 입력된 이름의 학생 리스트
  const nullStudent = nullPlayer(inputData);

  // 버튼 비활성화 조건, nullStudent 해당 학생이 있거나, inputData에 학생이 없거나, 로딩 중일 경우
  const buttonDisabled =
    nullStudent.length !== 0 || inputData.length === 0 || loading;

  // onClick 함수
  // inputData의 데이터를 퀴즈 방식에 따라 동일 인물 별로 합산하여 uploadData로 이동
  const setUploadData = () =>
    dispatch(setQuizUploadData(inputData, selOption.quiz));

  return (
    <>
      {/* inputData 내 인원이 있을 경우에만 Display */}
      {inputData.length !== 0 && (
        <Button
          variant="warning"
          onClick={setUploadData}
          disabled={buttonDisabled}
        >
          {loading ? <LoadingSpinner /> : "완료"}
        </Button>
      )}
    </>
  );
};

export default CompeleteButton;
