import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageType } from "types";
import usePageType from "hooks/usePageType";
import { login, signGoogle } from "api/authApi";
import useLoadingIndicator from "hooks/useLoadingIndicator";
import { Button } from "components/atoms";
import { InputText } from "components/blocks/forms";
import useGoToMainPage from "hooks/useGoToMainPage";
import BasicLayout from "components/templates/basic-layout";

import * as S from "./LoginPage.styled";
import logo from "../../assets/images/growing_symbol_sm.png";
import googleIcon from "../../assets/images/google_icon.png";
import { HrSect } from "components/blocks/hr-sect";

interface LoginPageProps {
  pageType: PageType;
}

type RefType = "email" | "password";

const defaultValues = {
  email: "",
  password: "",
};

const STUDENT_TITLE = `Learning
Center`;
const TEACHER_TITLE = `Teaching
Center`;

const LoginPage = ({ pageType }: LoginPageProps) => {
  const navigate = useNavigate();

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleChange = (refType: RefType) => {
    const ref = refType === "email" ? emailRef : passwordRef;
    if (!ref.current) {
      return;
    }

    const {
      current: { value },
    } = ref;

    setValues((prev) => ({
      ...prev,
      [refType]: value,
    }));
  };

  const handleDelete = (refType: RefType) => {
    setValues((prev) => ({
      ...prev,
      [refType]: "",
    }));
  };

  const handleError = (error: unknown) => {
    const { message } = error as { message: string };

    alert(message);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      if (!emailRef.current?.value || !passwordRef.current?.value) {
        throw new Error("이메일과 비밀번호를 입력해주세요.");
      }
      await login({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      }); // firebase login
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin();
  };

  const googleSignUp = async () => {
    setIsLoading(true);

    try {
      signGoogle();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const handleSignUpButton = () => {
    navigate("signup");
  };

  const handleFindPasswordButton = () => {
    navigate("findpassword");
  };

  usePageType("");

  useLoadingIndicator(isLoading);

  useGoToMainPage(pageType);

  useEffect(() => {
    if (!emailRef.current) {
      return;
    }

    emailRef.current.focus();
  }, []);

  return (
    <BasicLayout>
      <S.Container>
        <S.ContentContainer>
          <S.TitleSection>
            <S.Logo src={logo} />
            <S.Title>{pageType === "student" ? STUDENT_TITLE : TEACHER_TITLE}</S.Title>
          </S.TitleSection>
          <S.LoginForm onSubmit={handleSubmit}>
            <InputText
              label="이메일"
              type="email"
              ref={emailRef}
              value={values.email}
              onChange={() => handleChange("email")}
              onDelete={() => handleDelete("email")}
              tabIndex={1}
            />
            <InputText
              label="비밀번호"
              type="password"
              ref={passwordRef}
              value={values.password}
              onChange={() => handleChange("password")}
              onDelete={() => handleDelete("password")}
              autoComplete="on"
              tabIndex={2}
            />
            <Button type="submit">로그인</Button>
          </S.LoginForm>
          <S.FindSection>
            <S.FindText onClick={handleSignUpButton}>회원가입</S.FindText>
            <S.FindDivider>|</S.FindDivider>
            <S.FindText onClick={handleFindPasswordButton}>비밀번호 찾기</S.FindText>
          </S.FindSection>

          <HrSect>or</HrSect>
          <S.GoogleLoginSection>
            <S.GoogleButtonContainer onClick={googleSignUp}>
              <S.GoogleIcon src={googleIcon} />
              <S.GoogleButtonText>Sign in with Google</S.GoogleButtonText>
            </S.GoogleButtonContainer>
          </S.GoogleLoginSection>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default LoginPage;
