/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import WordCard from "../../../Component/TimeWordPage/WordCard/WordCard";
import WordTable from "../../../Component/TimeWordPage/WordTable";
import { useDispatch, useSelector } from "react-redux";
import { randomWord, setWordArray } from "../../../redux/FirebaseData/Word/reducer";
import { loadingFalse } from "../../../redux/Loading/actions";
import { wordOptionClear } from "../../../redux/wordOption/actions";
import { getFireStoreData } from "../../../redux/FirebaseData/DownloadController/reducer";
import useRedirect from "../../../hooks/useRedirect";
import { useLocation } from "react-router-dom";
import CountdownTimer from "../../../Component/TimeWordPage/CountdownTimer";
import { collection, getDocs, query, updateDoc, where, doc } from "firebase/firestore";
import db from "../../../firebase";
import { useNavigate } from "react-router-dom";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const TimeWordPage = () => {
  // redux dispatch
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/learningcenter");
  };

  const { storeInfo } = useSelector((state) => state.currentUserReducer);

  const [showModal, setShowModal] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);

  dispatch(setWordArray([{ scid: state.scid, set: state.part, start: 1, end: 25 }]));

  const getMyTimeWordList = async () => {
    let data = [];
    const timeWordCollection = query(collection(db, "TimeWord"), where("sid", "==", storeInfo.uid), where("scheduleId", "==", state.id));
    const timeWordList = await getDocs(timeWordCollection);
    timeWordList.forEach(async (timeWord) => {
      data.push({ id: timeWord.id, ...timeWord.data() });
    });
    if (data.filter((row) => row.isPlayed).length) {
      setShowModal(true);
      setIsPlayed(true);
    } else {
      data.forEach(async (timeWord) => {
        await updateDoc(doc(db, "TimeWord", timeWord.id), { isPlayed: true });
      });
    }
  };
  // reducer
  // playtime
  const { playTime } = useSelector((state) => state.wordOptionReducer);
  // loading reducer
  const { auto } = useSelector((state) => state.loadingReducer);
  // download controler reducer
  const downloadSchedule = useSelector((state) => state.downloadControlerReducer).schedule;

  const wordRand = () => dispatch(randomWord());

  useRedirect("learningcenter", "home");

  useEffect(() => {
    dispatch(wordOptionClear());
    // dispatch(clearWordArray());
    dispatch(setWordArray([{ scid: state.scid, set: state.part, start: 1, end: 25 }]));
    dispatch(loadingFalse());
    // 스케쥴 데이터 다운로드 토글 true로
    !downloadSchedule && dispatch(getFireStoreData("schedule"));
    getMyTimeWordList();
  }, []);

  useEffect(() => {
    let timer = null;
    if (auto) {
      timer = setInterval(() => {
        wordRand();
      }, playTime);
    } else if (!auto) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [auto]);

  // usePageBlock(!showModal, "지금 페이지를 나가면 다시 단어를 외울 수 없습니다. 그래도 나가시겠습니까?");

  return (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <CountdownTimer showModal={showModal} setShowModal={setShowModal} />
          <WordCard />
          <Accordion className="mt-5" defaultActiveKey="1">
            <WordTable wordSet={[{ scid: state.scid, set: state.part, start: 1, end: 25 }]} />
          </Accordion>
          <Modal show={showModal} centered>
            <Modal.Header>
              <Modal.Title>{isPlayed ? "단어 외우기를 이미 완료하셨습니다." : `Time's Up!`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{isPlayed ? "내일 또 봐요!" : `시간이 종료되었습니다.`}</Modal.Body>
            <Modal.Footer>
              <Button onClick={handleGoBack}>돌아가기</Button>
            </Modal.Footer>
          </Modal>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default TimeWordPage;
