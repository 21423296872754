import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  modalShowDisplay,
  modalShowLoad,
} from "../../redux/Modal_Show/actions";

const SaveModalButton = () => {
  const dispatch = useDispatch();
  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputData
  const inputData = useSelector((state) => state.inputDataReducer);

  const isSidNull = inputData
    .map((row) => (row.sid === null ? true : false))
    .some((e) => e === true);

  const setShow = () => {
    dispatch(modalShowDisplay(true));
    dispatch(modalShowLoad(false));
  };

  return (
    <Button
      disabled={inputData.length === 0 || loading || isSidNull}
      className="py-0 px-2"
      onClick={setShow}
    >
      설정 저장
    </Button>
  );
};

export default SaveModalButton;
