import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { quizTypes } from "../../../lib/utils/constants";
import { RequiredText } from "../../../Methods";
import { selOptionKey } from "../../../redux/selOption/actions";

// select option의 quiz state 변경을 위한 선택 컴포넌트
const SelectQuiz = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // onChange 함수
  // select option의 quiz state 변경
  const changeQuiz = (e) => {
    dispatch(selOptionKey("quiz", e.name));
  };

  return (
    <>
      <p className="Form-label"> 퀴즈 플랫폼 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="퀴즈 플랫폼"
        isSearchable
        options={quizTypes}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        onChange={(e) => changeQuiz(e)}
      />
      {!selOption.quiz && <RequiredText />}
    </>
  );
};

export default SelectQuiz;
