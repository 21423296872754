import {
  SET_ALLGAMEARRAY_CLEAR,
  SET_GAMEARRAY_ADD,
  SET_GAMEARRAY_CHANGE,
  SET_GAMEARRAY_CLEAR,
  SET_GAMEARRAY_REMOVE,
} from "./types";

const gameArray = [];

const gameArrayReducer = (state = gameArray, action) => {
  // state 의 초깃값을 gameArray 으로 지정했습니다.
  const newArray = [...state];
  switch (action.type) {
    // 초기화
    case SET_ALLGAMEARRAY_CLEAR:
      return action.payload;
    // 리그 개별 초기화
    case SET_GAMEARRAY_CLEAR:
      newArray[action.league] = Array.from(Array(action.payload), () =>
        Array(action.payload).fill(0)
      );
      return newArray;
    // GameArray 수정
    case SET_GAMEARRAY_CHANGE:
      newArray[action.league] = action.payload;
      return newArray;
    // GameArray 플레이어 추가
    case SET_GAMEARRAY_ADD:
      newArray[action.payload] = [
        ...newArray[action.payload].map((row) => [...row, 0]),
        Array(newArray[action.payload].length + 1).fill(0),
      ];
      return newArray;
    // GameArray 플레이어 제외
    case SET_GAMEARRAY_REMOVE:
      action.payload.forEach((obj) => {
        newArray[action.league].forEach((row) => row.splice(obj, 1));
        newArray[action.league].splice(obj, 1);
      });
      return newArray;
    default:
      return state;
  }
};

export default gameArrayReducer;
