import db from "../../firebase";
import { collectionGroup, onSnapshot, query, where } from "firebase/firestore";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { getAttendData } from "../../redux/FirebaseData/Attend/reducer";
import { loadingComponent } from "../../redux/Loading/actions";
import { selOptionKey } from "../../redux/selOption/actions";
import { getPaymentData } from "../../redux/FirebaseData/Payment/reducer";
import { CRUD } from "../../lib/utils/constants";

const SelectYearMonthLoadButton = ({ type }) => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // onChange 함수
  // select Option의 year 변경
  const changeYear = (e) =>
    dispatch(selOptionKey("year", Number(e.target.value)));
  // select Option의 month 변경
  const changeMonth = (e) =>
    dispatch(selOptionKey("month", Number(e.target.value)));

  const setAttend = () => {
    dispatch(selOptionKey("nowYear", selOption.year));
    dispatch(selOptionKey("nowMonth", selOption.month));
    dispatch(loadingComponent(true));
    const attends = query(
      collectionGroup(db, "attend"),
      where("year", "==", selOption.year),
      where("month", "==", selOption.month),
      where("attend", "==", true)
    );
    const attendQuery = onSnapshot(attends, (snapshot) => {
      dispatch(
        getAttendData(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
    });
    setTimeout(() => {
      dispatch(loadingComponent(false));
    }, 500);
    setTimeout(() => {
      attendQuery();
    }, 10000);
  };

  const setPayment = () => {
    dispatch(selOptionKey("nowYear", selOption.year));
    dispatch(loadingComponent(true));
    const payments = query(
      collectionGroup(db, "payment"),
      where("year", "==", selOption.nowYear || new Date().getFullYear())
    );

    const paymentQuery = onSnapshot(payments, (snapshot) => {
      dispatch(
        getPaymentData(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            crud: CRUD.R,
          }))
        )
      );
    });
    setTimeout(() => {
      dispatch(loadingComponent(false));
      paymentQuery();
    }, 500);
  };

  const getData = () => (type === "payment" ? setPayment() : setAttend());

  return (
    <Col lg>
      <InputGroup>
        <Form.Control
          type="number"
          style={{ textAlign: "center" }}
          value={selOption.year || 2022}
          onChange={changeYear}
          min={2022}
          max={new Date().getFullYear()}
        />
        <InputGroup.Text>년</InputGroup.Text>
        {type !== "payment" && (
          <>
            <Form.Control
              type="number"
              style={{ textAlign: "center" }}
              value={selOption.month || 3}
              onChange={changeMonth}
              min={selOption.year === 2022 ? 3 : 1}
              max={
                selOption.year === new Date().getFullYear()
                  ? new Date().getMonth() + 1
                  : 12
              }
            />
            <InputGroup.Text>월</InputGroup.Text>
          </>
        )}
        <Button
          disabled={
            selOption.year < 2022 ||
            selOption.year > new Date().getFullYear() ||
            (selOption.year === new Date().getFullYear() &&
              selOption.month > new Date().getMonth() + 1) ||
            selOption.month < 1 ||
            selOption.month > 12 ||
            (selOption.month < 2022 && selOption.month < 3)
          }
          onClick={getData}
        >
          조회
        </Button>
      </InputGroup>
      {(!selOption.year || (type !== "payment" && !selOption.month)) && (
        <RequiredText />
      )}
      {(selOption.year < 2022 ||
        (selOption.month < 2022 && selOption.month < 3)) && (
        <RequiredText text="2022년 3월부터 조회 가능합니다." />
      )}
      {selOption.year === new Date().getFullYear() &&
        selOption.month > new Date().getMonth() + 1 && (
          <RequiredText text="현재 날짜까지 조회 가능합니다." />
        )}
      {(selOption.month < 1 || selOption.month > 13) && (
        <RequiredText text="1월부터 12월 사이를 입력해야합니다." />
      )}
    </Col>
  );
};

export default SelectYearMonthLoadButton;
