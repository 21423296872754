/* eslint-disable react-hooks/exhaustive-deps */
import { Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { wordOptionClass } from "../../../redux/wordOption/actions";
import { useEffect } from "react";
import { getFireStoreData } from "../../../redux/FirebaseData/DownloadController/reducer";

const SelectWordClass = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const classes = useSelector((state) => state.classesReducer);
  const wordOption = useSelector((state) => state.wordOptionReducer);
  const schedule = useSelector((state) => state.scheduleReducer);
  // download controler reducer
  const downloadSchedules = useSelector((state) => state.downloadControlerReducer).schedule;

  // onClick 함수
  const changeClass = (e) => dispatch(wordOptionClass(e));

  const todaySchedule = schedule.filter(
    (row) =>
      row.date.toDate() >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) &&
      row.date.toDate() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4) &&
      row.cid !== "MDH1" &&
      row.cid !== "MDH2" &&
      row.cid !== "MDTE"
  );

  const todayCompetition = todaySchedule.filter((row) => row.subject === "Competition");

  useEffect(() => {
    // 스케쥴 리스트 데이터 다운로드 토글 true로
    !downloadSchedules && dispatch(getFireStoreData("schedule"));
  }, []);

  return (
    <Col md>
      <p className="Form-label"> 반 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="Class"
        isSearchable
        isClearable
        options={todayCompetition.filter((row) => row.date.toDate().toLocaleDateString() === wordOption.date)}
        getOptionLabel={(e) => classes.find((row) => row.id === e.cid).name}
        getOptionValue={(e) => e.cid}
        maxMenuHeight={150}
        value={wordOption.cid ? wordOption : null}
        onChange={(e) => changeClass(e)}
      />
    </Col>
  );
};

export default SelectWordClass;
