export const errMsg = (err) => {
  const isThisError = (message) => err.message.indexOf(message) !== -1;
  switch (true) {
    case isThisError("auth/user-not-found"):
      return "사용자를 찾을 수 없습니다.";
    case isThisError("auth/missing-email"):
      return "이메일을 입력하세요.";
    case isThisError("auth/invalid-email"):
      return "유효한 이메일 형식을 입력하세요.";
    default:
      return "예기치 못한 오류가 발생했습니다.\n오류가 계속될 경우 문의해주세요.";
  }
};

export const infoMsg = {
  resetPW: () =>
    "비밀번호 재설정 메일이 전송되었습니다. \n해당 링크를 통해 비밀번호를 재설정 해주세요. \n메일이 오지 않았을 시 스팸메일함을 확인해주세요.",
  uidCopy: (param) =>
    `복사되었습니다.\n복사된 인원: ${param.name}\n복사된 UID: ${param.id}`,
  editComplete: () => "수정되었습니다! 관리자의 승인을 기다려주세요.",
  uploading: () => "업로드 중입니다!",
};

export const errorMsg = {
  runFailed: () => "실행에 실패하였습니다.",
  default: () =>
    "알 수 없는 오류가 발생했습니다. 오류가 계속될 시 문의해주세요.",
};
