import { Col, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const PrintList = (props) => {
  // props
  const row = props.row;
  const obj = props.obj;

  // reducers
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // quiz Input Data reducer
  const qIDData = useSelector((state) => state.qIDDataReducer);

  // 학생의 퀴즈별 정답률
  const correctRate = (a, b) =>
    quizInputData.find((d) => d.sid === a && d.qid === b) !== undefined
      ? Math.round(
          (quizInputData.find((d) => d.sid === a && d.qid === b).correct /
            quizInputData.find((d) => d.sid === a && d.qid === b).count) *
            10000
        ) / 100
      : "";

  return (
    <div className="list">
      {row.map((res) => (
        <div className="mb-5 question" key={res.qid}>
          <Row>
            <Col>
              <h5>{res.qid}</h5>
            </Col>
            <Col style={{ textAlign: "right", fontSize: "10px" }}>
              <p>정답률: {correctRate(obj.sid, res.qid)}%</p>
            </Col>
          </Row>
          <p>
            {ReactHtmlParser(qIDData.find((d) => d.qid === res.qid).question)}
          </p>
          {qIDData.find((d) => d.qid === res.qid).num1 !== "" && (
            <p className="my-0">
              ① {qIDData.find((d) => d.qid === res.qid).num1}
            </p>
          )}
          {qIDData.find((d) => d.qid === res.qid).num2 !== "" && (
            <p className="my-0">
              ② {qIDData.find((d) => d.qid === res.qid).num2}
            </p>
          )}
          {qIDData.find((d) => d.qid === res.qid).num3 !== "" && (
            <p className="my-0">
              ③ {qIDData.find((d) => d.qid === res.qid).num3}
            </p>
          )}
          {qIDData.find((d) => d.qid === res.qid).num4 !== "" && (
            <p className="my-0">
              ④ {qIDData.find((d) => d.qid === res.qid).num4}
            </p>
          )}
          {qIDData.find((d) => d.qid === res.qid).num5 !== "" && (
            <p className="my-0">
              ⑤ {qIDData.find((d) => d.qid === res.qid).num5}
            </p>
          )}
          {qIDData.find((d) => d.qid === res.qid).type === "short" && (
            <p>A: </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PrintList;
