import { Form, ListGroupItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { displayDataSet } from "../../../redux/DisplayData/actions";
import { selOptionKey } from "../../../redux/selOption/actions";

const PassageDiv = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const passage = selOption.passage || [
    { id: 0, eng: "", kor: "", start: true },
  ];

  const sentenceClassName = (id) => {
    switch (true) {
      case id === selOption.number && selOption.eng:
        return "passage-activep";
      case id === selOption.number && !selOption.eng:
        return "passage-activep-noteng";
      default:
        return "passage-p";
    }
  };

  const changePassageSize = (e) =>
    dispatch(selOptionKey("passageSize", e.target.value));

  const changeNu = (row) => {
    dispatch(displayDataSet(row));
    dispatch(selOptionKey("number", row.id));
  };
  return (
    <>
      <Form.Range
        min={8}
        max={100}
        value={selOption.passageSize || 16}
        onChange={changePassageSize}
      />
      <ListGroupItem style={{ fontSize: selOption.passageSize + "px" }}>
        <div
          style={{
            resize: "vertical",
            width: "100%",
            height: "500px",
            overflow: "scroll",
            textAlign: "left",
          }}
        >
          {passage.map((row) => (
            <p
              // style={sentenceStyle(row.id)}
              key={row.id}
              onClick={() => changeNu(row)}
              className={sentenceClassName(row.id)}
            >
              {row.paragraph && row.id !== 0 && <br />}
              {(row.paragraph ? "\u00A0\u00A0\u00A0" : "") + row.eng + "\u00A0"}
            </p>
          ))}
        </div>
      </ListGroupItem>
    </>
  );
};

export default PassageDiv;
