import { Colors } from "styles/colors";

interface IconProps {
  color?: string;
}

export default function ErrorIcon({ color = Colors.black }: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8849 2.4905C12.5389 1.8365 11.4629 1.8365 11.1169 2.4905L2.11694 19.4905C2.03592 19.6429 1.99575 19.8136 2.00036 19.9861C2.00496 20.1586 2.05417 20.327 2.14319 20.4749C2.23221 20.6227 2.35801 20.7449 2.50833 20.8297C2.65865 20.9145 2.82837 20.9588 3.00094 20.9585H21.0009C21.1734 20.9589 21.343 20.9145 21.4932 20.8298C21.6434 20.7451 21.7691 20.6229 21.8581 20.4752C21.947 20.3274 21.9961 20.1591 22.0007 19.9867C22.0052 19.8144 21.965 19.6437 21.8839 19.4915L12.8849 2.4905ZM13.0009 17.9585H11.0009V15.9585H13.0009V17.9585ZM11.0009 13.9585V8.9585H13.0009L13.0019 13.9585H11.0009Z"
        fill={color}
      />
    </svg>
  );
}
