import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { wordOptionPlayTime } from "../../../redux/wordOption/actions";

const PlayTimeRange = ({ game }) => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const wordOption = useSelector((state) => state.wordOptionReducer);
  // loading reducer
  const auto = useSelector((state) => state.loadingReducer).auto;

  const changePlayTime = (e) => dispatch(wordOptionPlayTime(Math.round(e.target.value / 100) * 100));

  return <Form.Range min={500} max={10000} disabled={!game && auto} value={wordOption.playTime || 500} onChange={(e) => changePlayTime(e)} />;
};

export default PlayTimeRange;
