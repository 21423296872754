import "../../App.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import { useSelector, useDispatch } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";
import { RequiredText } from "../../Methods";

// 달력을 한국어로
registerLocale("ko", ko);

// select option의 날짜를 변경하기 위한 Select 컴포넌트
const SelectDate = (props) => {
  // props
  // require message 출력 여부
  const required = props.required;
  // require message 출력 여부
  const validText = props.validText;
  // require message 출력 여부
  const isValid = props.isValid;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  const unlimit = props.unlimit;

  const objectKey = props.objectKey;

  const isClearable = props.isClearable;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // require message 출력 조건, props로 required가 넘어오고, selOption의 date가 null일 경우
  const returnRequire = required && !selOption[objectKey ?? "date"];

  // onChange 함수
  // select option reducer의 date 변경 함수
  const changeDate = (e) => dispatch(selOptionKey(objectKey ?? "date", e || null));

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <DatePicker
        dateFormat="yyyy.MM.dd (eee)"
        selected={selOption[objectKey ?? "date"]}
        maxDate={unlimit ? new Date(9999, 11, 31) : new Date()}
        disabled={disabled}
        todayButton="today"
        onChange={changeDate}
        locale={ko}
        placeholderText="날짜"
        className="Date-picker-border"
        isClearable={!!isClearable}
      />
      {returnRequire && <RequiredText />}
      {isValid && <RequiredText text={validText} />}
    </>
  );
};

export default SelectDate;
