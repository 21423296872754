import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { displayDataAge } from "../../redux/DisplayData/actions";

const DisplayChangeAge = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const changeAge = (e) => dispatch(displayDataAge(Number(e.target.value)));

  return (
    <>
      <p className="Form-label"> 나이 </p>
      <Form.Control
        type="text"
        value={displayData.age || 0}
        onChange={(e) => changeAge(e)}
      />
      {!displayData.age && <RequiredText />}
    </>
  );
};

export default DisplayChangeAge;
