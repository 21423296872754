/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRedirect from "../../../hooks/useRedirect";
import * as CardStyles from "../../../styles/CardStyles";
import { calculateDate } from "../../../lib/utils/date";
import moment from "moment";
import { clearWordArray, getQuizWordList } from "../../../redux/FirebaseData/Word/reducer";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import db from "../../../firebase";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

export default function SelectTimeWord() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storeInfo } = useSelector((state) => state.currentUserReducer);
  const classList = useSelector((state) => state.classesReducer);
  const wordList = useSelector((state) => state.scheduleReducer)
    .map((row) => ({ ...row, date: moment(row.date.toDate()).format("YYYY-MM-DD") }))
    .filter(({ cid }) => cid === "MDH1" || cid === "MDH2" || cid === "MDTE")
    .filter(({ date }) => {
      return date === moment(calculateDate(2)).format("YYYY-MM-DD");
    });

  const scheduleList = useSelector((state) => state.scheduleListReducer);

  const [myTimeWordList, setMyTimeWordList] = useState([]);

  const getMyTimeWordList = async () => {
    let data = [];
    const timeWordCollection = query(collection(db, "TimeWord"), where("sid", "==", storeInfo.uid));
    const timeWordList = await getDocs(timeWordCollection);
    timeWordList.forEach((timeWord) => {
      data.push({ id: timeWord.id, ...timeWord.data() });
    });
    const myTimeWords = wordList.map((wordSet) => {
      const timeWordLog = data.find((timeWord) => timeWord.scheduleId === wordSet.id);
      return {
        ...wordSet,
        isCheck: false,
        isPlayed: timeWordLog ? "O" : "X",
      };
    });
    setMyTimeWordList(myTimeWords);
  };

  const clickMyTimeWord = ({ id, isPlayed }) => {
    if (isPlayed === "O") {
      return;
    }
    setMyTimeWordList((prev) => prev.map((row) => ({ ...row, isCheck: row.id === id })));
  };

  const quizOption = () => {
    const now = myTimeWordList.find(({ isCheck }) => isCheck);
    if (!now) {
      return;
    }
    return { scid: now.scid, part: now.part, scheduleList };
  };

  useRedirect("learningcenter", "home");

  const isCheck = myTimeWordList.filter(({ isCheck }) => isCheck).length;

  const goGame = async () => {
    const gameWord = myTimeWordList.find(({ isCheck }) => isCheck);
    dispatch(getQuizWordList(quizOption()));
    addDoc(collection(db, "TimeWord"), { sid: storeInfo.uid, cid: gameWord.cid, date: gameWord.date, scheduleId: gameWord.id });
    navigate("/learningcenter/timeword/play", {
      state: myTimeWordList.find(({ isCheck }) => isCheck),
    });
  };

  useEffect(() => {
    dispatch(clearWordArray());
  }, []);

  useEffect(() => {
    getMyTimeWordList();
  }, []);

  return (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left" style={{ marginTop: 24 }}>
            <Col>
              <h2>단어를 선택해주세요!</h2>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CardStyles.CardContainer more>
                {myTimeWordList.map((row, i) => {
                  const thisClass = classList.find((cls) => cls.id === row.cid);
                  return (
                    <CardStyles.CardTimeWordRow
                      i={i}
                      key={row.id}
                      isPlayed={row.isPlayed === "O"}
                      isCheck={row.isCheck}
                      onClick={() => clickMyTimeWord(row)}
                    >
                      <div>{thisClass.name}</div>
                      <div>
                        {thisClass !== "MDH1" || thisClass !== "MDH2" || thisClass !== "MDTE" ? row.date.slice(5) : `${row.scname}-${row.part}`}
                      </div>
                      <div>{row.isPlayed}</div>
                    </CardStyles.CardTimeWordRow>
                  );
                })}
              </CardStyles.CardContainer>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Button variant={isCheck ? "primary" : "outline-primary"} style={{ width: "100%" }} disabled={!isCheck} onClick={goGame}>
                단어 외우기 시작
              </Button>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}
