import {
  SET_LEAGUEROSTER_ADD,
  SET_LEAGUEROSTER_CLEAR,
  SET_LEAGUEROSTER_REMOVE,
  SET_LEAGUEROSTER_REMOVE_CHECK,
  SET_LEAGUEROSTER_ENTIRE_CLEAR,
} from "./types";

//

export const leagueRosterEntireClear = () => ({
  type: SET_LEAGUEROSTER_ENTIRE_CLEAR,
});

export const leagueRosterClear = (payload) => ({
  type: SET_LEAGUEROSTER_CLEAR,
  payload,
});

export const leagueRosterAdd = (payload, league) => ({
  type: SET_LEAGUEROSTER_ADD,
  payload,
  league,
});

export const leagueRosterRemoveCheck = (payload, league) => ({
  type: SET_LEAGUEROSTER_REMOVE_CHECK,
  payload,
  league,
});

export const leagueRosterRemove = (league) => ({
  type: SET_LEAGUEROSTER_REMOVE,
  league,
});
