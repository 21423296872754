import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowPopup } from "../../redux/Modal_Show/actions";
import { clearPopupModal } from "../../redux/PopupModal/reducer";
import LoadingSpinner from "./LoadingSpinner";

// 팝업을 띄워서 사용자가 재차 확인할 수 있도록 하는 Modal 컴포넌트
const PopupModal = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer).popup;
  const popupModal = useSelector((state) => state.popupModalReducer);

  // 제목
  const header = popupModal.header;
  // 본문
  const content = popupModal.content;
  // 버튼에 표시될 텍스트
  const btnText = popupModal.btnText;
  // 실행할 함수
  const run = popupModal.run;

  // 모달 닫기 함수
  const handleClose = () => {
    if (!loading) {
      dispatch(modalShowPopup(false));
      dispatch(clearPopupModal(false));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button disabled={loading} variant="light" onClick={handleClose}>
            취소
          </Button>
          <Button onClick={run} disabled={loading}>
            {loading ? <LoadingSpinner /> : btnText}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupModal;
