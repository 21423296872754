import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../Methods";
import { selOptionKey } from "../../../redux/selOption/actions";

const SelectPercentage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const changePercentage = (e) =>
    dispatch(selOptionKey("percentage", Number(e.target.value)));

  return (
    <>
      <p className="Form-label"> 정답률 기준 </p>
      <InputGroup>
        <Form.Control
          type="number"
          min={0}
          max={100}
          value={selOption.percentage || 0}
          style={{ textAlign: "center" }}
          onChange={(e) => changePercentage(e)}
        />
        <InputGroup.Text>%</InputGroup.Text>
        <Form.Range
          min={0}
          max={100}
          value={selOption.percentage || 0}
          onChange={(e) => changePercentage(e)}
        />
      </InputGroup>
      {selOption.percentage === null && <RequiredText />}
      {(selOption.percentage > 100 || selOption.percentage < 0) && (
        <RequiredText text="0~100 사이의 수를 입력하세요." />
      )}
    </>
  );
};

export default SelectPercentage;
