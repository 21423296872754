/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import SelectClass from "../../../../Component/Select/SelectClass";
import SelectSubject from "../../../../Component/Select/SelectSubject";
import FontSizeDropDown from "../../../../Component/Schedule/Main/FontSizeDropdown";
import ScheduleCalendar from "../../../../Component/Schedule/Main/ScheduleCalendar";
import { selOptionClear } from "../../../../redux/selOption/actions";
import { useDispatch, useSelector } from "react-redux";
import AddModifyButton from "../../../../Component/Schedule/Main/AddModifyButton";
import ScheduleModal from "../../../../Component/Schedule/Main/ScheduleModal";
import { displayDataScheduleClear } from "../../../../redux/DisplayData/actions";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";
import useRedirect from "../../../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

// 스케쥴 컨테이너
const Schedule = () => {
  // redux dispatch
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  // download controler reducer
  const downloadSchedule = useSelector((state) => state.downloadControlerReducer).schedule;

  // 마운트 시 최초 실행
  useEffect(() => {
    // select option reducer를 스케쥴에 맞게 재설정
    dispatch(selOptionClear("Schedule"));
    // display data reducer를 스케쥴에 맞게 재설정
    dispatch(displayDataScheduleClear());
    // 스케쥴 데이터 다운로드 토글 true로
    !downloadSchedule && dispatch(getFireStoreData("schedule"));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <div className="opt-area mb-3">
            <Row className="p-3">
              <Col md>
                <SelectClass title="반" clearable />
              </Col>
              <Col md>
                <SelectSubject title="과목" clearable competition />
              </Col>
            </Row>
          </div>
          <FontSizeDropDown />
          <hr></hr>
          <ScheduleCalendar />
          <ScheduleModal />
          <AddModifyButton />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default Schedule;
