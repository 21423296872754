import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../../redux/selOption/actions";

const LoadPassageBtn = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 본문 리스트 reducer
  const passageList = useSelector((state) => state.passageReducer).map(
    (row) => ({ ...row, modify: false, delete: false })
  );
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // select option 본문 리스트 reducer
  const selectPassageList =
    useSelector((state) => state.selOptionReducer).passageList || [];

  const filterPassageList = () => {
    const isNull = (object) => object === "" || object === null;
    switch (true) {
      case !isNull(selOption.type) && !isNull(selOption.textbook):
        return passageList.filter(
          (row) =>
            row.type === selOption.type && row.textbook === selOption.textbook
        );
      case !isNull(selOption.type) && isNull(selOption.textbook):
        return passageList.filter((row) => row.type === selOption.type);
      case isNull(selOption.type) && !isNull(selOption.textbook):
        return passageList.filter((row) => row.textbook === selOption.textbook);
      default:
        return passageList;
    }
  };

  // input data setting
  const setPassageInputData = () => {
    dispatch(selOptionKey("passageList", filterPassageList()));
  };

  return (
    <>
      {selectPassageList.length === 0 && (
        <Button onClick={setPassageInputData}>본문 목록 가져오기</Button>
      )}
    </>
  );
};

export default LoadPassageBtn;
