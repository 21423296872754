import { Colors } from "styles/colors";

interface IconProps {
  color?: string;
}

export default function HamburgerIcon({ color = Colors.gray1 }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5 7H19M5 12H19M5 17H19" stroke={color} />
    </svg>
  );
}
