import { doc, setDoc } from 'firebase/firestore';
import db from '../firebase';

export function getOption(myInfo) {
  return doc(db, 'Setting', '만덕');
}

export function updateOption(officeId, data) {
  const { id, ...updateData } = data;
  return setDoc(doc(db, 'Setting', officeId), updateData, { merge: true });
}
