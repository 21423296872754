import { Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { wordOptionDate } from "../../../redux/wordOption/actions";

const SelectWordDate = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const changeDate = (e) => dispatch(wordOptionDate(e));

  const schedule = useSelector((state) => state.scheduleReducer);

  const todaySchedule = schedule.filter(
    (row) =>
      row.date.toDate() >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) &&
      row.date.toDate() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4) &&
      row.cid !== "MDH1" &&
      row.cid !== "MDH2" &&
      row.cid !== "MDTE"
  );

  const uniqueDate = todaySchedule
    .map(function (val, index) {
      return val["date"].toDate().toLocaleDateString();
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ date: row }));

  return (
    <Col md>
      <p className="Form-label"> 날짜 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="Date"
        options={uniqueDate}
        getOptionLabel={(e) => e.date}
        getOptionValue={(e) => e.date}
        maxMenuHeight={150}
        onChange={(e) => changeDate(e)}
      />
    </Col>
  );
};

export default SelectWordDate;
