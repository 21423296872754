import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import db from "../../firebase";
import { holidays } from "../../lib/utils/constants";
import { dateControl } from "../../Methods";
import * as XLSX from "xlsx";
import moment from "moment";
import { getRank } from "../../lib/utils/getRank";

// Competition 관련 함수
// 각 행의 랭크로 계산하여 리그를 return
export const leagueLable = (i, league) => {
  if (!league) return "";
  switch (true) {
    case i <= league.A.player:
      return "A";
    case i <= league.A.player + league.B.player && i > league.A.player:
      return "B";
    case i <= league.A.player + league.B.player + league.C.player && i > league.A.player + league.B.player:
      return "C";
    case i <= league.A.player + league.B.player + league.C.player + league.D.player && i > league.A.player + league.B.player + league.C.player:
      return "D";
    case i <= league.A.player + league.B.player + league.C.player + league.D.player + league.E.player &&
      i > league.A.player + league.B.player + league.C.player + league.D.player:
      return "E";
    case i <= league.A.player + league.B.player + league.C.player + league.D.player + league.E.player + league.F.player &&
      i > league.A.player + league.B.player + league.C.player + league.D.player + league.E.player:
      return "F";
    case i <= league.A.player + league.B.player + league.C.player + league.D.player + league.E.player + league.F.player + league.G.player &&
      i > league.A.player + league.B.player + league.C.player + league.D.player + league.E.player + league.F.player:
      return "G";
    case i <=
      league.A.player + league.B.player + league.C.player + league.D.player + league.E.player + league.F.player + league.G.player + league.H.player &&
      i > league.A.player + league.B.player + league.C.player + league.D.player + league.E.player + league.F.player + league.G.player:
      return "H";
    default:
      return "";
  }
};
// 평균 계산
export const syncAverage = async (sid, cid, extra, proportion) => {
  const dateid = new Date().getFullYear().toString().slice(-2) + ("0" + (new Date().getMonth() + 1)).slice(-2);

  let result = await getDoc(doc(db, "Users", sid, "competitionStat", dateid));
  const wordQuizCollection = query(
    collection(db, "WordQuiz"),
    where("cid", "==", cid),
    where("date", "==", moment(new Date()).format("YYYY-MM-DD"))
    // where("date", "==", "2023-03-03")
  );
  const querySnapshot = await getDocs(wordQuizCollection);
  let wordQuizArray = [];
  querySnapshot.forEach((doc) =>
    wordQuizArray.push({
      ...doc.data(),
      id: doc.id,
    })
  );
  const quizRank = getRank(wordQuizArray, sid);

  const rankAverage = () => {
    const podium = result.exists() ? result.data()[cid + "podium"] || { first: 0, second: 0 } : { first: 0, second: 0 };
    if (result.exists() && !isNaN(result.data()[cid + "sum"])) {
      return Math.round(((result.data()[cid + "sum"] - (podium.first + podium.second) * extra) / result.data()[cid + "count"]) * 10) / 10;
    } else return 0;
  };
  const finalRank = rankAverage() === 0 ? 0 : rankAverage() * proportion.rank + quizRank * proportion.quiz;
  return { average: rankAverage(), quizRank, finalRank };
};
// 해당 날짜와 반에 맞는 데이터의 학생의 평균까지 load
export const getDatas = async (selOption, league, extra, proportion) => {
  const finalRank = (rank, quizRank) => rank * proportion.rank + quizRank * proportion.quiz;
  let returnArray = [];
  const competitionCollection = query(
    collection(db, "Competition"),
    where("cid", "==", selOption.cid),
    where("date", "==", new Date(selOption.date.toDateString())),
    orderBy("rank", "asc")
  );
  const querySnapshot = await getDocs(competitionCollection);
  querySnapshot.forEach((doc) => (returnArray = [...returnArray, doc.data()]));

  if (selOption.avgDate) {
    const avgCompetitionCollection = query(
      collection(db, "Competition"),
      where("cid", "==", selOption.cid),
      where("date", "==", new Date(selOption.avgDate.toDateString())),
      orderBy("rank", "asc")
    );
    const avgQuerySnapshot = await getDocs(avgCompetitionCollection);
    avgQuerySnapshot.forEach((doc) => {
      if (returnArray.map((row) => row.sid).includes(doc.data().sid)) {
        return;
      }
      returnArray = [...returnArray, { ...doc.data(), disabledRank: true }];
    });
  }
  return await Promise.all(
    returnArray.map(async (doc, i) => {
      const average = await syncAverage(doc.sid, selOption.cid, extra, proportion);
      return {
        id: i,
        sid: doc.sid,
        name: doc.name,
        rank: doc.rank,
        league: leagueLable(doc.rank, league),
        podium: doc.disabledRank ? { first: false, second: false } : doc.podium ?? { first: false, second: false },
        average: average.average,
        quizRank: average.quizRank,
        finalRank: doc.disabledRank ? average.finalRank : finalRank(doc.rank, average.quizRank),
        newPlayer: false,
      };
    })
  );
};

// Schedule
// 평일 Array 생성
export const dayArray = (selOption) => {
  const year = selOption.year;
  const month = selOption.month;
  const schedule = { scid: selOption.scid, scname: selOption.scname };
  const holiday = holidays.map((row) => ({
    date: new Date(row.year, row.month - 1, row.day),
    holiday: row.holiday,
  }));

  let newArray = [];
  for (let i = 1; i <= 31; i++) {
    if (
      new Date(year, month - 1, i).getDay() !== 0 &&
      new Date(year, month - 1, i).getDay() !== 6 &&
      !holiday.find((res) => res.date.toDateString() === new Date(year, month - 1, i).toDateString()) &&
      new Date(year, month - 1, i).getMonth() === month - 1
    ) {
      newArray.push(new Date(year, month - 1, i));
    }
  }
  return newArray.map((row, i) => ({ id: i, date: row, ...schedule }));
};
// 해당 조건에 맞는 스케쥴 불러오기
export const scheduleLoad = (scheduleData, selOption) => {
  const returnArray = scheduleData
    .filter(
      (row) =>
        row.cid === selOption.cid &&
        row.subject === selOption.subject &&
        row.date.toDate() >= new Date(selOption.year, selOption.month - 1, 1) &&
        row.date.toDate() <= new Date(selOption.year, selOption.month - 1, 31)
    )
    .map((res) => ({
      ...res,
      date: res.date && res.date.toDate(),
      add: false,
      modify: false,
      delete: false,
    }));
  return [...returnArray];
};

export const getTestData = async (selOption) => {
  let returnArray = [];
  const testCollection = query(
    collection(db, "TestScore"),
    where("date", ">=", selOption.dateRange[0] ? selOption.dateRange[0] : dateControl(-90)),
    where("date", "<=", selOption.dateRange[1] ? selOption.dateRange[1] : dateControl(1)),
    orderBy("date", "desc"),
    selOption.cid ? where("cid", "==", selOption.cid) : orderBy("cid", "asc"),
    selOption.sid ? where("sid", "==", selOption.sid) : orderBy("sid", "asc"),
    selOption.subject ? where("subject", "==", selOption.subject) : orderBy("subject", "asc"),
    limit(50)
  );
  const querySnapshot = await getDocs(testCollection);
  querySnapshot.forEach(
    (doc) =>
      (returnArray = [
        ...returnArray,
        {
          ...doc.data(),
          date: doc.data().date.toDate(),
          id: doc.id,
          original: { ...doc.data(), date: doc.data().date.toDate() },
        },
      ])
  );
  return await Promise.all(returnArray);
};

export const getCompetitionData = async (selOption) => {
  let returnArray = [];
  const competitionCollection = query(
    collection(db, "Competition"),
    where("date", ">=", selOption.dateRange[0] ? selOption.dateRange[0] : dateControl(-90)),
    where("date", "<=", selOption.dateRange[1] ? selOption.dateRange[1] : dateControl(1)),
    orderBy("date", "desc"),
    orderBy("rank", "asc"),
    selOption.cid ? where("cid", "==", selOption.cid) : orderBy("cid", "asc"),
    selOption.sid ? where("sid", "==", selOption.sid) : orderBy("sid", "asc"),
    limit(50)
  );
  const querySnapshot = await getDocs(competitionCollection);
  querySnapshot.forEach(
    (doc) =>
      (returnArray = [
        ...returnArray,
        {
          ...doc.data(),
          date: doc.data().date.toDate(),
          podium: doc.data().podium || { first: false, second: false },
          id: doc.id,
          original: {
            ...doc.data(),
            podium: doc.data().podium || { first: false, second: false },
            date: doc.data().date.toDate(),
          },
        },
      ])
  );
  return await Promise.all(returnArray);
};
export const getQuestionData = async (qid) => {
  const quizRef = doc(db, "Question", qid);
  const quizSnap = await getDoc(quizRef);
  if (quizSnap.exists()) {
    return [
      {
        ...quizSnap.data(),
        id: quizSnap.id,
        qid: quizSnap.id,
        answerArray: quizSnap.data().answerArray || {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
        },
      },
    ];
  } else return [{ error: true, qid: qid, id: "error" }];
};

const qidExists = async (qid) => {
  const qidSnap = await getDoc(doc(db, "Question", qid));

  if (qidSnap.exists()) {
    return { ...qidSnap.data(), exist: true };
  } else return { exist: false };
};

export const getQuizData = async (e, inputDataLength) => {
  const typeReturn = (row) => {
    switch (true) {
      case row.num1 === "":
        return "short";
      case row.num1 !== "" && row.answer.toString().length !== 1:
        return "checkBox";
      default:
        return "choice";
    }
  };

  const answerReturn = (row) => {
    switch (true) {
      case row.answer.toString() === "1":
        return row.num1.toString();
      case row.answer.toString() === "2":
        return row.num2.toString();
      case row.answer.toString() === "3":
        return row.num3.toString();
      case row.answer.toString() === "4":
        return row.num4.toString();
      case row.answer.toString() === "5":
        return row.num5.toString();
      default:
        return "error";
    }
  };

  const checkBoxAnswerReturn = (row) => {
    const answerArray = { 1: false, 2: false, 3: false, 4: false, 5: false };
    const answerReturn = (answer) => {
      switch (answer) {
        case "1":
          return row.num1.toString();
        case "2":
          return row.num2.toString();
        case "3":
          return row.num3.toString();
        case "4":
          return row.num4.toString();
        case "5":
          return row.num5.toString();
        default:
          return "error";
      }
    };
    let str = "";
    for (let i = 1; i < 6; i++) {
      if (row.answer.toString().indexOf(i.toString()) !== -1) {
        str = str + answerReturn(i.toString()) + ",";
        answerArray[i] = true;
      }
    }
    return { str: str.slice(0, -1), answerArray };
  };

  const answersReturn = (row) => {
    switch (typeReturn(row)) {
      case "short":
        return row.answer.toString();
      case "checkBox":
        return checkBoxAnswerReturn(row).str;
      default:
        return answerReturn(row);
    }
  };

  const file = e.target.files[0];
  const data = await file.arrayBuffer();
  // const workbook = XLSX.readFile(data, {sheetRows: 5});
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets["Input"];
  let range = XLSX.utils.decode_range(worksheet["!ref"]);
  range.e.r = 10000;
  worksheet["!ref"] = XLSX.utils.encode_range(range);
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
  return await Promise.all(
    jsonData.map(async (row, i) => {
      const exists = await qidExists(row.qid.toString());
      return {
        id: i + inputDataLength,
        qid: row.qid.toString(),
        question: row.question.toString(),
        num1: typeReturn(row) === "short" ? "" : row.num1.toString(),
        num2: typeReturn(row) === "short" ? "" : row.num2.toString(),
        num3: typeReturn(row) === "short" ? "" : row.num3.toString(),
        num4: typeReturn(row) === "short" ? "" : row.num4.toString(),
        num5: typeReturn(row) === "short" ? "" : row.num5.toString(),
        type: typeReturn(row),
        answer: answersReturn(row),
        numAnswer: typeReturn(row) === "short" ? "" : row.answer.toString(),
        label: row.label.toString(),
        answerArray: checkBoxAnswerReturn(row).answerArray || {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
        },
        exists: exists,
      };
    })
  );
};

export const getQuizRowData = async (question, modify) => {
  const answerArray = question.answerArray || {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };

  const exists = await qidExists(question.qid.toString());

  const returnAnswer = () => {
    let numAnswer = "";
    let answer = "";
    for (let i = 1; i < 6; i++) {
      if (answerArray[i]) {
        numAnswer += i + ",";
        answer += question["num" + i] + ",";
      }
    }
    return { answer: answer.slice(0, -1), num: numAnswer.slice(0, -1) };
  };
  if (question.type === "short") {
    return modify === "create"
      ? { ...question, exists: exists }
      : {
          ...question,
          num1: "",
          num2: "",
          num3: "",
          num4: "",
          num5: "",
          numAnswer: "",
          type: "short",
          exists: exists,
        };
  } else if (question.type === "choice") {
    return {
      ...question,
      answer: question["num" + question.numAnswer],
      exists: exists,
    };
  } else {
    return {
      ...question,
      numAnswer: returnAnswer().num,
      answer: returnAnswer().answer,
      exists: exists,
    };
  }
};

export const getWordsData = async (selOption) => {
  let returnArray = [];
  const wordsCollection = query(
    collection(db, "Words"),
    selOption.scid ? where("scid", "==", selOption.scid) : orderBy("scid", "asc"),
    selOption.isSet ? where("set", "==", selOption.set) : orderBy("set", "asc"),
    orderBy("number", "asc"),
    selOption.eng ? where("eng", "==", selOption.eng) : orderBy("eng", "asc"),
    selOption.kor ? where("kor", "==", selOption.kor) : orderBy("kor", "asc")
  );
  const querySnapshot = await getDocs(wordsCollection);
  querySnapshot.forEach((doc) => {
    returnArray = [
      ...returnArray,
      {
        ...doc.data(),
        id: doc.id,
        original: {
          ...doc.data(),
        },
      },
    ];
  });
  return await Promise.all(returnArray);
};
