import { call, put, takeEvery } from "redux-saga/effects";
import { sumObj } from "./function";
import {
  QUIZ_SETUPLOADDATA,
  QUIZ_CLEARUPLOADDATA,
  QUIZ_SETUPLOADDATA_ERROR,
  QUIZ_SETUPLOADDATA_SUCCESS,
} from "./types";

export const setQuizUploadData = (payload, quiz) => ({
  type: QUIZ_SETUPLOADDATA,
  payload,
  quiz,
});

function* setQuizUploadDataSaga(action) {
  const inputData = action.payload;
  const quiz = action.quiz;
  try {
    const uploadData = yield call(sumObj, inputData, quiz);
    yield put({
      type: QUIZ_SETUPLOADDATA_SUCCESS,
      uploadData,
    });
  } catch (e) {
    yield put({
      type: QUIZ_SETUPLOADDATA_ERROR,
      error: true,
      e,
    });
  }
}

export function* quizUploadDataSaga() {
  yield takeEvery(QUIZ_SETUPLOADDATA, setQuizUploadDataSaga);
}

export const clearQuizUploadData = () => ({
  type: QUIZ_CLEARUPLOADDATA,
});
