import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const DeleteTodoRowButton = ({ id, deleteRowTable, header }) => {
  // reducers
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;

  const className = header ? "py-0 px-2" : "";

  // onClick 함수
  // 해당 index에 해당하는 행을 삭제
  const deleteData = () => deleteRowTable(id);

  return (
    <Button className={className} variant="light" disabled={loading} onClick={deleteData}>
      -
    </Button>
  );
};

export default DeleteTodoRowButton;
