import db from "../../firebase";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { weekNumberByMonth } from "../../lib/utils/date";

// student stat load
export const statDataLoad = async (selOption, dateRange) => {
  // Competition Stat이 저장될 배열
  let comArray = [];
  // Test Stat이 저장될 배열
  let testArray = [];

  // competition & test Stat data state
  let com = { this: 0, last: 0 };
  let lis = { this: 0, last: 0 };
  let rea = { this: 0, last: 0 };
  let gra = { this: 0, last: 0 };
  let mock = { this: 0, last: 0 };
  // quiz Stat data state
  let graQ = [];
  let grbQ = [];
  let lisQ = [];
  let tepQ = [];

  // 평균 계산 함수
  const getAvg = (snap, label) => Math.round((snap.data()[label + "sum"] / snap.data()[label + "count"]) * 10) / 10;

  // 이번 달 시리얼
  const statMonth = (date) => date.getFullYear().toString().slice(-2) + (date.getMonth() + 1).toString().padStart(2, "0");
  const thisMonth = statMonth(new Date());
  // 저번 달 시리얼
  const lastMonth = statMonth(new Date(new Date().setMonth(new Date().getMonth() - 1)));

  // 날짜 지정 안 되어 있을 경우 기본값 (90일 전)
  let startFilter = dateRange[0] === null ? new Date(new Date().setDate(new Date().getDate() - 90)) : dateRange[0];
  // 날짜 지정 안 되어 있을 경우 기본값 (오늘)
  let endFilter = dateRange[1] === null ? new Date() : dateRange[1];

  // Competition Stat을 받아올 Collection Query
  const comCollection = query(collection(db, "Competition"), where("sid", "==", selOption.sid), where("date", ">=", startFilter), where("date", "<=", endFilter), orderBy("date", "asc"));
  // Test Stat을 받아올 Collection Query
  const testScoreCollection = query(collection(db, "TestScore"), where("sid", "==", selOption.sid), where("date", ">=", startFilter), where("date", "<=", endFilter), orderBy("date", "asc"));

  // quiz stat을 받아올 주차 레이블 데이터 배열
  const quizSnapArray = () => {
    let returnArray = [];
    for (let i = 0; i < 8; i++) {
      returnArray = [...returnArray, weekNumberByMonth(new Date(new Date().setDate(new Date().getDate() - i * 7)))];
    }
    return returnArray.reverse();
  };

  // Competition Query Snapshot
  const comQuerySnapshot = await getDocs(comCollection);
  // Test Query Snapshot
  const testQuerySnapshot = await getDocs(testScoreCollection);

  // Competition Array에 데이터 저장
  comQuerySnapshot.forEach((doc) => (comArray = [...comArray, { ...doc.data(), id: doc.id }]));
  // Test Array에 데이터 저장
  testQuerySnapshot.forEach((doc) => (testArray = [...testArray, { ...doc.data(), id: doc.id }]));

  // 이번 달 Competition Stat 받아오기
  const comThis = await getDoc(doc(db, "Users", selOption.sid, "competitionStat", thisMonth));
  // 지난 달 Competition Stat 받아오기
  const comLast = await getDoc(doc(db, "Users", selOption.sid, "competitionStat", lastMonth));

  // 이번 달 Test Stat 받아오기
  const testThis = await getDoc(doc(db, "Users", selOption.sid, "testScoreStat", thisMonth));
  // 지난 달 Test Stat 받아오기
  const testLast = await getDoc(doc(db, "Users", selOption.sid, "testScoreStat", lastMonth));

  // quiz Stat 받아오기
  const quizSnap = await Promise.all(quizSnapArray().map(async (row) => await getDoc(doc(db, "Users", selOption.sid, "quizStat", row))));

  // 이번 달 Competition Stat이 존재할 경우 data 입력
  if (comThis.exists()) {
    com = { ...com, this: { ...comThis.data() } };
  }
  // 지난 달 Competition Stat이 존재할 경우 data 입력
  if (comLast.exists()) {
    com = { ...com, last: { ...comLast.data() } };
  }
  // 이번 달 Test Stat이 존재할 경우 data 입력
  if (testThis.exists()) {
    if (!isNaN(testThis.data().liscount)) {
      lis = {
        ...lis,
        this: getAvg(testThis, "lis"),
      };
    }
    if (!isNaN(testThis.data().reacount)) {
      rea = {
        ...rea,
        this: getAvg(testThis, "rea"),
      };
    }
    if (!isNaN(testThis.data().gracount)) {
      gra = {
        ...gra,
        this: getAvg(testThis, "gra"),
      };
    }
    if (!isNaN(testThis.data().mockcount)) {
      mock = {
        ...mock,
        this: getAvg(testThis, "mock"),
      };
    }
  }
  // 지난 달 Test Stat이 존재할 경우 data 입력
  if (testLast.exists()) {
    if (!isNaN(testLast.data().liscount)) {
      lis = {
        ...lis,
        last: getAvg(testLast, "lis"),
      };
    }
    if (!isNaN(testLast.data().reacount)) {
      rea = {
        ...rea,
        last: getAvg(testLast, "rea"),
      };
    }
    if (!isNaN(testLast.data().gracount)) {
      gra = {
        ...gra,
        last: getAvg(testLast, "gra"),
      };
    }
    if (!isNaN(testLast.data().mockcount)) {
      mock = {
        ...mock,
        last: getAvg(testLast, "gra"),
      };
    }
  }
  quizSnap.forEach((obj, i) => {
    if (obj.exists()) {
      if (!isNaN(obj.data().gracount)) {
        graQ[i] = Math.round((obj.data().gracorrect / obj.data().gracount) * 1000) / 10;
      }
      if (!isNaN(obj.data().grbcount)) {
        grbQ[i] = Math.round((obj.data().grbcorrect / obj.data().grbcount) * 1000) / 10;
      }
      if (!isNaN(obj.data().liscount)) {
        lisQ[i] = Math.round((obj.data().liscorrect / obj.data().liscount) * 1000) / 10;
      }
      if (!isNaN(obj.data().tepcount)) {
        tepQ[i] = Math.round((obj.data().tepcorrect / obj.data().tepcount) * 1000) / 10;
      }
    }
  });
  return {
    comArray: comArray,
    testArray: testArray,
    stat: {
      com: com,
      test: {
        Listening: lis,
        Grammar: gra,
        Reading: rea,
        모의고사: mock,
      },
      quiz: {
        GRA: graQ,
        GRB: grbQ,
        LIS: lisQ,
        TEP: tepQ,
      },
    },
  };
};
