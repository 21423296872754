import db from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import gradeCalc from "../../lib/utils/gradeCalc";
import { holidays } from "../../lib/utils/constants";

const AttendTable = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  const nowYear = useSelector((state) => state.selOptionReducer).nowYear;
  const nowMonth = useSelector((state) => state.selOptionReducer).nowMonth;

  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // user list reducer
  const users = useSelector((state) => state.usersReducer);
  // user list reducer
  const attendList = useSelector((state) => state.attendReducer);

  let student = users.filter(
    (v) => v.teacher === false && v.state === true && v.office === myInfo.office
  );

  let studentList = () => {
    switch (true) {
      case selOption.grade !== null && selOption.school !== null:
        return student.filter(
          (row) =>
            gradeCalc(row.age) === selOption.grade &&
            row.school === selOption.school
        );
      case !selOption.grade && selOption.school !== null:
        return student.filter((row) => row.school === selOption.school);
      case selOption.grade !== null && !selOption.school:
        return student.filter((row) => gradeCalc(row.age) === selOption.grade);
      default:
        return student;
    }
  };

  const holiday = holidays.map((row) => ({
    date: new Date(row.year, row.month - 1, row.day),
    holiday: row.holiday,
  }));

  let dateArray = (year, month) => {
    let newArray = [];
    let i = 1;
    while (
      i === 1 ||
      new Date(year, month - 1, i - 1).getMonth() ===
        new Date(year, month - 1, i).getMonth()
    ) {
      newArray.push(new Date(year, month - 1, i));
      i++;
    }
    return newArray;
  };

  const dateFormat = (date) => {
    let day = "";
    switch (date.getDay()) {
      case 1:
        day = " (월)";
        break;
      case 2:
        day = " (화)";
        break;
      case 3:
        day = " (수)";
        break;
      case 4:
        day = " (목)";
        break;
      case 5:
        day = " (금)";
        break;
      case 6:
        day = " (토)";
        break;
      default:
        day = " (일)";
        break;
    }
    return day;
  };

  const isHoliday = (res) =>
    res.getDay() === 0 ||
    res.getDay() === 6 ||
    holiday.find(
      (element) => element.date.toDateString() === res.toDateString()
    );

  const isAttend = (row, res) =>
    attendList.find(
      (d) => d.sid === row.id && res.toString() === d.date.toDate().toString()
    )
      ? attendList.find(
          (d) =>
            d.sid === row.id && res.toString() === d.date.toDate().toString()
        ).attend
      : false;

  const updateAttend = (row, res) => {
    const attendId =
      // res.getFullYear().toString() +
      // (res.getMonth() + 1).toString().padStart(2, "0") +
      // res.getDate().toString().padStart(2, "0");
      res.getFullYear().toString() +
      (res.getMonth() + 1).toString() +
      res.getDate().toString();
    myInfo.admin &&
      !(
        res > new Date() ||
        (row.registrationDate
          ? res < row.registrationDate.toDate()
          : res < new Date(1900, 0, 1))
      ) &&
      setDoc(doc(db, "Users", row.id, "attend", attendId), {
        year: res.getFullYear(),
        month: res.getMonth() + 1,
        date: new Date(res.toDateString()),
        attend: !isAttend(row, res),
        sid: row.id,
      });
  };

  return (
    <Table striped hover bordered size="sm" style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th width="5%">학생</th>
          <th width={95 / (dateArray(nowYear, nowMonth).length + 1) + "%"}>
            출석
          </th>
          {dateArray(nowYear, nowMonth).map((row, i) => (
            <th
              width={95 / (dateArray(nowYear, nowMonth).length + 1) + "%"}
              key={i}
              style={
                row.getDay() === 0 ||
                holiday.find(
                  (element) =>
                    element.date.toDateString() === row.toDateString()
                )
                  ? { color: "red", background: "whitesmoke" }
                  : row.getDay() === 6
                  ? { color: "blue", background: "whitesmoke" }
                  : { color: "black" }
              }
            >
              {row.getDate()} <br /> {dateFormat(row)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {studentList().map((row) => (
          <tr key={row.id}>
            <th>{row.name}</th>
            <th>
              {attendList.filter((d) => d.sid === row.id && d.attend).length}
            </th>
            {dateArray(selOption.nowYear, selOption.nowMonth).map((res, i) => (
              <td
                key={i}
                onClick={() => updateAttend(row, res)}
                style={
                  myInfo.admin
                    ? isHoliday(res) &&
                      (res > new Date() ||
                        (row.registrationDate
                          ? res <
                            row.registrationDate
                              .toDate()
                              .setDate(
                                row.registrationDate.toDate().getDate() - 1
                              )
                          : res < new Date(1900, 0, 1)))
                      ? { background: "lightgrey" }
                      : res > new Date() ||
                        (row.registrationDate
                          ? res <
                            row.registrationDate
                              .toDate()
                              .setDate(
                                row.registrationDate.toDate().getDate() - 1
                              )
                          : res < new Date(1900, 0, 1))
                      ? { background: "whitesmoke" }
                      : isHoliday(res) && isAttend(row, res)
                      ? { background: "DarkSeaGreen", cursor: "pointer" }
                      : isHoliday(res)
                      ? { background: "lightgrey", cursor: "pointer" }
                      : isAttend(row, res)
                      ? { background: "DarkSeaGreen", cursor: "pointer" }
                      : { background: "DarkSalmon", cursor: "pointer" }
                    : isHoliday(res) &&
                      (res > new Date() ||
                        (row.registrationDate
                          ? res < row.registrationDate.toDate()
                          : res < new Date(1900, 0, 1)))
                    ? { background: "lightgrey" }
                    : res > new Date() ||
                      (row.registrationDate
                        ? res < row.registrationDate.toDate()
                        : res < new Date(1900, 0, 1))
                    ? { background: "whitesmoke" }
                    : isHoliday(res) && isAttend(row, res)
                    ? { background: "DarkSeaGreen" }
                    : isHoliday(res)
                    ? { background: "lightgrey" }
                    : isAttend(row, res)
                    ? { background: "DarkSeaGreen" }
                    : { background: "DarkSalmon" }
                }
              />
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AttendTable;
