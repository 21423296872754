import {
  STATARRAY_CLEAR,
  STATARRAY_LOAD,
  STATARRAY_LOAD_ERROR,
  STATARRAY_LOAD_SUCCESS,
} from "./types";

const statData = {
  comArray: [],
  testArray: [],
  stat: {
    com: { this: 0, last: 0 },
    test: {
      Listening: { this: 0, last: 0 },
      Grammar: { this: 0, last: 0 },
      Reading: { this: 0, last: 0 },
      모의고사: { this: 0, last: 0 },
    },
    quiz: {
      GRA: [0, 0, 0, 0, 0, 0, 0, 0],
      GRB: [0, 0, 0, 0, 0, 0, 0, 0],
      LIS: [0, 0, 0, 0, 0, 0, 0, 0],
      TEP: [0, 0, 0, 0, 0, 0, 0, 0],
    },
  },
};

const statArrayReducer = (state = statData, action) => {
  switch (action.type) {
    // STUDENT STAT
    // stat 초기화
    case STATARRAY_CLEAR:
      return statData;
    // stat data load
    case STATARRAY_LOAD:
      return state;
    // 해당 조건에 맞는 stat 불러오기 성공 시
    case STATARRAY_LOAD_SUCCESS:
      return action.payload;
    // 해당 조건에 맞는 stat 불러오기 실패 시
    case STATARRAY_LOAD_ERROR:
      return state;
    default:
      return state;
  }
};

export default statArrayReducer;
