import { Card, Col } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

const TitleCard = (props) => {
  // props
  // HomeCarousel에서 넘어온 cardData props
  const cardData = props.cardData;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(cardData.cardLink);
  };

  return (
    <Col xs>
      <Card onClick={handleClick} className="card-light" style={{ cursor: "pointer", height: "8rem" }}>
        <Card.Body>
          <Card.Title className="App-left"> {cardData.title} </Card.Title>
          <p className="App-left"> {cardData.content} </p>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TitleCard;
