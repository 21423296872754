import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectPageSingle from "../../Select/SelectPageSingle";
import ChangeTextForm from "../../SelectTable/ChangeTextForm";
import DeleteRowButton from "../../SelectTable/DeleteRowButton";

const InputTable = () => {
  const selOption = useSelector((state) => state.selOptionReducer);

  // inputData reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const setRange =
    inputData.length !== 0
      ? [inputData[0].set, inputData[inputData.length - 1].set]
      : [0, 0];

  return (
    <>
      {inputData.length !== 0 && (
        <Table hover striped size="sm" bordered>
          <thead>
            <tr>
              <th colSpan={4}>
                <SelectPageSingle min={setRange[0]} max={setRange[1]} />
              </th>
            </tr>
            <tr>
              <th width="10%">No.</th>
              <th width="40%">eng</th>
              <th width="40%">kor</th>
              <th width="10%">제거</th>
            </tr>
          </thead>
          <tbody>
            {inputData
              .filter((obj) => obj.set === selOption.displayPage)
              .map((row) => (
                <tr key={row.id}>
                  <td style={{ verticalAlign: "middle" }}>{row.number}</td>
                  <td>
                    <ChangeTextForm
                      objectKey="eng"
                      row={row}
                      placeholder="단어"
                    />
                  </td>
                  <td>
                    <ChangeTextForm
                      objectKey="kor"
                      row={row}
                      placeholder="해석"
                    />
                  </td>
                  <td>
                    <DeleteRowButton row={row} />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default InputTable;
