import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { selOptionKey } from "../../../redux/selOption/actions";

const InputWord = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // onChange 함수
  // passageObject reducer의 passage 변경 함수
  const changeRawWord = (e) =>
    dispatch(selOptionKey("rawWord", e.target.value));

  const codeInputTabHandler = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      changeRawWord(e);
      return false;
    }
  };

  return (
    <Row className="g-2 mb-3">
      <Col md>
        <p className="Form-label">
          단어 Raw Data <br />
          <strong>
            (탭을 기준으로 단어/해석 구분, 엔터를 기준으로 한 단어 구분)
          </strong>
        </p>
        <Form.Control
          as="textarea"
          rows={5}
          style={{ fontSize: "12px" }}
          onKeyDown={codeInputTabHandler}
          onChange={changeRawWord}
          value={selOption.rawWord || ""}
        />
      </Col>
    </Row>
  );
};

export default InputWord;
