/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";
import { Col, Row } from "react-bootstrap";
import SelectType from "./SelectType";
import SelectTextBook from "./SelectTextBook";

// select option의 반을 변경하기 위한 Select 컴포넌트
const SelectPassage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // download controler reducer
  const downloadPassageList = useSelector(
    (state) => state.downloadControlerReducer
  ).passage;

  useEffect(() => {
    // 본문 데이터 다운로드 토글 true로
    !downloadPassageList && dispatch(getFireStoreData("passage"));
  }, []);

  return (
    <Row className="g-2 mb-3">
      <Col md>
        <SelectType />
      </Col>
      <Col md>
        <SelectTextBook />
      </Col>
    </Row>
  );
};

export default SelectPassage;
