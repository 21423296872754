/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";

export const dateControl = (date) =>
  new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + date
  );

// 상황에 맞는 행 배경색 리턴
export const rowColor = (row) => {
  switch (true) {
    case row.modify && row.add:
      return "add";
    case !row.modify && row.add:
      return "notadd";
    case row.delete:
      return "delete";
    case row.modify:
      return "modify";
    default:
      return "none";
  }
};

export function RequiredText(props) {
  return (
    <p className="Required-Text">
      {props.text ? props.text : "필수 입력 사항입니다."}
    </p>
  );
}

export const splitPage = (string) => {
  const returnArr = string.split(",").map((row) => {
    if (row.indexOf("-") !== -1) {
      let emptyArr = [];
      const divideArr = row.split("-");
      for (let i = divideArr[0]; i <= divideArr[1]; i++) {
        emptyArr.push(i);
      }
      return emptyArr;
    } else return row;
  });

  return returnArr.flat().map((row) => Number(row));
};
