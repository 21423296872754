import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataPresetLoad } from "../../../redux/InputData/actions";
import { modalShowClear } from "../../../redux/Modal_Show/actions";

const LoadPresetButton = (props) => {
  const row = props.row;

  const dispatch = useDispatch();

  const loadPreset = () => {
    dispatch(inputDataPresetLoad(row.studentList));
    dispatch(modalShowClear());
  };
  return (
    <Button onClick={loadPreset} className="Growing-button py-0">
      불러오기
    </Button>
  );
};

export default LoadPresetButton;
