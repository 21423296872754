import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getPlayerNum, getQIDNum } from "./function";

// 데이터가 보여질 정오표 테이블 컴포넌트
const ResultTable = () => {
  // reducers
  // 서버에 등록될 데이터 reducer
  const uploadData = useSelector((state) => state.quizUploadDataReducer);

  // uploadData 에서 학생의 중복을 제거한 Array
  const playerNum = getPlayerNum(uploadData);
  // uploadData 에서 퀴즈의 중복을 제거한 Array
  const qidNum = getQIDNum(uploadData);

  // uploadData에서 학생별 정답률 계산
  const percent = (row) => {
    // num이 1: 정답, num이 0: 오답
    const count = (row, num) =>
      uploadData.filter((res) => res.name === row.name && res.correct === num)
        .length;
    return (
      Math.round((count(row, 1) / (count(row, 1) + count(row, 0))) * 1000) / 10
    );
  };

  // uploadData에서 answer를 return
  const returnAnswer = (row, res) => {
    const returnArray = uploadData.filter(
      (obj) => obj.qid === res.qid && obj.sid === row.sid
    );
    const length = returnArray ? returnArray.length : 0;
    switch (length) {
      case 0:
        return { answer: "", undefined: true, correct: false };
      case 1:
        return {
          answer: returnArray[0].answer,
          undefined: false,
          correct: returnArray[0].correct,
        };
      default:
        return "";
    }
  };

  // returnAnswer에 따라서 셀의 배경색 지정
  const answerBackground = (row, res) => {
    return returnAnswer(row, res).undefined
      ? { background: "grey" }
      : returnAnswer(row, res).correct
      ? { background: "DarkSeaGreen" }
      : { background: "DarkSalmon" };
  };

  // 정답률(50% 기준)에 따라 색 변경
  const rateBackGround = (row) =>
    percent(row) >= 50
      ? { background: "DarkSeaGreen" }
      : { background: "DarkSalmon" };

  return (
    <>
      {/* nullStudent 내 인원이 있을 경우에만 Display */}
      {uploadData.length !== 0 && (
        <Table striped bordered hover responsive size="sm">
          {/* 헤더 */}
          <thead>
            <tr>
              {/* 학생 이름 헤더 */}
              <th>Student</th>
              {/* 퀴즈 수에 따라 반복 */}
              {qidNum.map((row) => {
                // qid Display
                return <th key={row.qid}>{row.qid}</th>;
              })}
              {/* 정답률 헤더 */}
              <th>Rate</th>
            </tr>
          </thead>
          {/* 바디 */}
          <tbody>
            {/* 학생 수에 따라 반복 */}
            {playerNum.map((row, i) => {
              return (
                <tr key={i}>
                  {/* 학생 이름 */}
                  <td> {row.name} </td>
                  {/* 퀴즈 수에 따라 반복 */}
                  {qidNum.map((res) => {
                    // 정답 Display
                    return (
                      <td
                        key={row.sid + res.qid}
                        style={answerBackground(row, res)}
                      >
                        {returnAnswer(row, res).answer}
                      </td>
                    );
                  })}
                  {/* 정답률 Display */}
                  <td style={rateBackGround(row)}>{percent(row) + "%"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ResultTable;
