/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";
import { RequiredText } from "../../Methods";
import { useEffect } from "react";
import { getFireStoreData } from "../../redux/FirebaseData/DownloadController/reducer";

// select option의 반을 변경하기 위한 Select 컴포넌트
const SelectClass = (props) => {
  // props
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 반 리스트 reducer
  const classes = useSelector((state) => state.classesReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // download controler reducer
  const downloadClass = useSelector(
    (state) => state.downloadControlerReducer
  ).classes;

  const defaultValue = selOption.cid
    ? {
        id: selOption.cid,
        name: selOption.class,
      }
    : null;

  // require message 출력 조건, props로 required가 넘어오고, selOption의 cid가 null일 경우
  const returnRequire = required && !selOption.cid;

  // onChange 함수
  // select option reducer의 cid와 class 변경 함수
  const changeCID = (e) => {
    const returnValue = e ? e : { id: null, name: null };
    dispatch(selOptionKey("cid", returnValue.id));
    dispatch(selOptionKey("class", returnValue.name));
  };

  useEffect(() => {
    // 반 데이터 다운로드 토글 true로
    !downloadClass && dispatch(getFireStoreData("classes"));
  }, []);

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="반"
        isSearchable
        isClearable={clearable}
        options={classes}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        onChange={changeCID}
        isDisabled={disabled}
        value={defaultValue}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectClass;
