import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import {
  wordOptionEnd,
  wordOptionStart,
} from "../../../../redux/wordOption/actions";

const ChangeNumber = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const changeStart = (e) =>
    dispatch(wordOptionStart(row.id, Number(e.target.value)));
  const changeEnd = (e) =>
    dispatch(wordOptionEnd(row.id, Number(e.target.value)));

  return (
    <>
      <td>
        <Form.Control
          style={{ textAlign: "center" }}
          min={1}
          max={25}
          type="number"
          disabled={active}
          onChange={(e) => changeStart(e)}
          placeholder="시작"
          value={row.start || ""}
        />
        {(row.start > 25 || row.start < 1) && (
          <RequiredText text={"1~25 사이의 숫자를 입력하세요."} />
        )}
        {row.start > row.end && (
          <RequiredText text={"시작 번호는 끝 번호보다 작아야 합니다."} />
        )}
      </td>
      <td>
        <Form.Control
          style={{ textAlign: "center" }}
          min={1}
          max={25}
          type="number"
          disabled={active}
          onChange={(e) => changeEnd(e)}
          placeholder="끝"
          value={row.end || ""}
        />
        {(row.end > 25 || row.end < 1) && (
          <RequiredText text={"1~25 사이의 숫자를 입력하세요."} />
        )}
        {row.start > row.end && (
          <RequiredText text={"끝 번호는 시작 번호보다 커야 합니다."} />
        )}
      </td>
    </>
  );
};

export default ChangeNumber;
