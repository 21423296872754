import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { selOptionKey } from "../../redux/selOption/actions";

// inputData 내의 개별 행 본문 변경을 위해 사용하는 Select 컴포넌트
const SelectNumberForm = (props) => {
  // props
  // 제목 출력 여부
  const title = props.title;
  // selOption 내 변경할 key의 parent
  const parent = props.parent;
  // selOption 내 변경할 key
  const key = props.objectKey;
  // placeholder
  const placeholder = props.placeholder;
  // 비활성화 조건
  const disabled = props.disabled;
  // require message 출력 여부
  const required = props.required;

  // redux dispatch
  const dispatch = useDispatch();

  const selOption = useSelector((state) => state.selOptionReducer);

  // onClick 함수
  // inputData 내 타이틀 변경
  const changeKey = (e) => {
    if (parent) {
      dispatch(
        selOptionKey(parent, {
          ...selOption[parent],
          [key]: Number(e.target.value),
        })
      );
    } else dispatch(selOptionKey(key, Number(e.target.value)));
  };

  const value = () => {
    if (parent) {
      return selOption[parent] ? selOption[parent][key] : 0;
    } else return selOption[key] ?? 0;
  };

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Form.Control
        type="number"
        style={{ textAlign: "center" }}
        value={value()}
        onChange={changeKey}
        placeholder={placeholder || "값을 입력하세요"}
        disabled={disabled}
      />
      {required && !selOption[key] && <RequiredText />}
    </>
  );
};

export default SelectNumberForm;
