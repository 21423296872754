import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataPodium } from "../../redux/InputData/actions";

const ChangePodium = (props) => {
  // props
  // inputData 내 index
  const row = props.row;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  const podiumText = () => {
    const podium = row.podium || { first: false, second: false };
    switch (true) {
      case podium.first:
        return { variant: "primary", text: "1" };
      case podium.second:
        return { variant: "warning", text: "2" };
      default:
        return { variant: "light", text: " " };
    }
  };

  // onClick 함수
  // inputData 내 타이틀 변경
  const changeNumber = () =>
    dispatch(inputDataPodium(row.id, podiumText().text));

  return (
    <Button
      variant={podiumText().variant}
      onClick={changeNumber}
      disabled={disabled}
    >
      {podiumText().text}
    </Button>
  );
};

export default ChangePodium;
