import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import moment from "moment";
import db from "../firebase";

/**
 * @typedef todoType
 * @property
 */
/**
 * @typedef todo
 * @property {string} id
 * @property {string} todoType - 타입
 * @property {string} scheduleId - 스케쥴 연동 시 스케쥴 id
 * @property {boolean} check - 체크 여부
 * @property {string} sid - 학생의 id
 * @property {string} title - todo title
 * @property {string} content - todo content
 * @property {string} date - todo 등록일
 * @property {string} deadline - 마감기한
 * @property {string} crud - crud
 */

/**
 * @description 전체 todo list 불러오기
 * @returns {todo[]}
 */
export async function getTodoListAPI() {
  const todoCollection = query(collection(db, "Todo"));
  const querySnapshot = await getDocs(todoCollection);
  /** @type {todo[]} */
  let todoArray = [];
  querySnapshot.forEach((doc) =>
    todoArray.push({
      ...doc.data(),
      date: typeof doc.data().date === "object" ? moment(doc.data().date.toDate()).format("YYYY-MM-DD") : doc.data().date,
      id: doc.id,
    })
  );
  return todoArray;
}

/**
 * @description 사용자의 sid를 이용해 todo list 불러오기
 * @param {string} sid
 * @returns {todo[]}
 */
export async function getMyTodoListAPI(sid) {
  const todoCollection = query(collection(db, "Todo"), where("sid", "==", sid));
  const querySnapshot = await getDocs(todoCollection);

  /** @type {todo[]} */
  let todoArray = [];
  querySnapshot.forEach((doc) =>
    todoArray.push({
      ...doc.data(),
      date: typeof doc.data().date === "object" ? moment(doc.data().date.toDate()).format("YYYY-MM-DD") : doc.data().date,
      id: doc.id,
    })
  );
  return todoArray;
}

/**
 * @description 사용자의 sid를 이용해 todo list 불러오기
 * @param {string} deadline
 * @returns {todo[]}
 */
export async function getTodoListBySidAndDeadlineAPI(sid, deadline) {
  const todoCollection = query(collection(db, "Todo"), where("sid", "==", sid), where("deadline", "==", deadline));
  const querySnapshot = await getDocs(todoCollection);

  /** @type {todo[]} */
  let todoArray = [];
  querySnapshot.forEach((doc) =>
    todoArray.push({
      ...doc.data(),
      date: typeof doc.data().date === "object" ? moment(doc.data().date.toDate()).format("YYYY-MM-DD") : doc.data().date,
      id: doc.id,
    })
  );
  return todoArray;
}

/**
 * @description todo 생성
 * @param {todo} data
 * @returns {void}
 */
export async function createTodoAPI(data) {
  await addDoc(collection(db, "Todo"), data);
}

/**
 * @description change todo check toggle
 * @param {string} todoId
 * @param {boolean} check
 * @returns {void}
 */
export async function checkTodoAPI(todoId, check) {
  await setDoc(doc(db, "Todo", todoId), { check }, { merge: true });
}

/**
 * @description update todo
 * @param {string} todoId
 * @param {todo} data
 * @returns {void}
 */
export async function updateTodoAPI(todoId, data) {
  const { id, ...updateData } = data;
  await setDoc(doc(db, "Todo", todoId), updateData, { merge: true });
}

/**
 * @description delete todo
 * @param {string} todoId
 * @returns {void}
 */
export async function deleteTodoAPI(todoId) {
  await deleteDoc(doc(db, "Todo", todoId));
}
