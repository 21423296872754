import { Accordion, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { splitPage } from "../../Methods";
import SelectPage from "./SelectPage";

const WordTable = ({ wordSet }) => {
  // reducers
  // word reducer
  // 게임에 사용될 word array
  const wordArray = useSelector((state) => state.wordReducer).wordArray;
  // inputData reducer
  const wordOption = useSelector((state) => state.wordOptionReducer);

  const wordSetList =
    wordSet.length !== 0
      ? wordSet
          .map((row) => splitPage(row.set).map((obj) => ({ scid: row.scid, set: obj })))
          .flat()
          .map((d, i) => ({ id: i, ...d }))
      : [];

  return (
    <>
      {wordArray.length !== 0 && (
        <Accordion.Item eventKey="2">
          <Accordion.Header>단어 리스트 보기</Accordion.Header>
          <Accordion.Body>
            <SelectPage wordSet={wordSet} />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>English</th>
                  <th style={{ width: "50%" }}>Korean</th>
                </tr>
              </thead>
              <tbody>
                {wordArray
                  .filter((row) => row?.scid === wordSetList[wordOption.displayPage]?.scid && row?.set === wordSetList[wordOption.displayPage]?.set)
                  .map((a, i) => {
                    return (
                      <tr key={i}>
                        <td>{a.eng}</td>
                        <td>{a.kor}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      )}
    </>
  );
};

export default WordTable;
