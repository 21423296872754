const SET_CURRENTINFO = "SET_CURRENTINFO";
const UPDATE_CURRENTINFO = "UPDATE_CURRENTINFO";
const CLEAR_CURRENTINFO = "CLEAR_CURRENTINFO";

const defaultState = {
  accountInfo: {},
  storeInfo: {},
};

export const CURRENTUSER_KEY = {
  ACCOUNT_INFO: "accountInfo",
  STORE_INFO: "storeInfo",
};

export const setCurrentInfo = (payload, key) => ({
  type: SET_CURRENTINFO,
  payload,
  key,
});

export const updateCurrentInfo = (payload, key) => ({
  type: UPDATE_CURRENTINFO,
  payload,
  key,
});

export const clearCurrentInfo = (key) => ({
  type: CLEAR_CURRENTINFO,
  key,
});

const currentUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENTINFO: {
      return { ...state, [action.key]: action.payload };
    }
    case UPDATE_CURRENTINFO: {
      return {
        ...state,
        [action.key]: { ...state[action.key], ...action.payload },
      };
    }
    case CLEAR_CURRENTINFO: {
      return !action.key
        ? defaultState
        : { ...state, [action.key]: defaultState[action.key] };
    }
    default:
      return state;
  }
};

export default currentUserReducer;
