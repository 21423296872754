import db from "../../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPassageObj,
  setPassage,
} from "../../../../redux/PassageObject/reducer";
import { loadingComponent } from "../../../../redux/Loading/actions";
import { modalShowPopup } from "../../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../../redux/PopupModal/reducer";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // select option reducer
  const passage = useSelector((state) => state.passageObjectReducer);

  const replacePassage = (text) => {
    const returnText = text
      .replace(/“|”/g, '"')
      .replace(/\."/g, "대화끝마침표")
      .replace(/\?"/g, "대화끝물음표")
      .replace(/!"/g, "대화끝느낌표")
      .replace(/Dr./g, "박사님마침표")
      .replace(/Mr./g, "남자마침표")
      .replace(/Ms./g, "여자마침표")
      .replace(/Dr./g, "박사님마침표")
      .replace(/Ph./g, "학위마침표")
      .replace(/U.K./g, "영국마침표")
      .replace(/U.S./g, "미국마침표")
      .replace(/\./g, ".쩜")
      .replace(/\?/g, "물음표옹쩜")
      .replace(/!/g, "!쩜");
    return returnText;
  };

  const displayText = (text) => {
    const returnText = text
      .replace("\n", "")
      .replace(/대화끝마침표/g, '."')
      .replace(/대화끝물음표/g, '?"')
      .replace(/대화끝느낌표/g, '!"')
      .replace(/박사님마침표/g, "Dr.")
      .replace(/남자마침표/g, "Mr.")
      .replace(/여자마침표/g, "Ms.")
      .replace(/학위마침표/g, "Ph.")
      .replace(/영국마침표/g, "U.K.")
      .replace(/미국마침표/g, "U.S.")
      .replace(/물음표옹/g, "?");
    return returnText;
  };

  const passageEng = replacePassage(passage.eng);
  const passageKor = replacePassage(passage.kor);

  let engArray = passageEng.split(/[?=쩜]/);
  let korArray = passageKor.split(/[?=쩜]/);

  const arrayLength = Math.max(engArray.length, korArray.length);

  const inputDataArray = new Array(arrayLength)
    .fill(0)
    .map((row, i) => {
      const returnEng = engArray[i] || "";
      const returnKor = korArray[i] || "";
      return {
        id: i,
        eng: displayText(returnEng).trim(),
        kor: displayText(returnKor).trim(),
        paragraph: returnEng.indexOf("\n") !== -1 || i === 0,
      };
    })
    .filter((row) => row.id !== arrayLength - 1);

  // onChange 함수
  // passageObject reducer의 passage 변경 함수
  const changePassage = () => dispatch(setPassage("inputData", inputDataArray));

  const dataUpload = () => {
    dispatch(loadingComponent(true));
    addDoc(collection(db, "Passage"), {
      type: selOption.type,
      textbook: selOption.textbook,
      lesson: selOption.lesson,
      passage: passage.inputData.map((row) => ({
        id: row.id,
        eng: row.eng,
        kor: row.kor,
        paragraph: row.paragraph,
      })),
    });
    setTimeout(() => {
      dispatch(loadingComponent(false));
      dispatch(clearPassageObj());
    }, 500);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  const clearData = () => dispatch(clearPassageObj());

  const passageDisabled = !(
    selOption.type &&
    selOption.textbook &&
    selOption.lesson &&
    passage.eng &&
    passage.kor
  );

  const uploadDisabled = (passage.inputData || []).length === 0;

  return (
    <Row className="g-2 mb-3">
      <Col>
        <ButtonGroup>
          <Button disabled={passageDisabled} onClick={changePassage}>
            문장 나누기
          </Button>
          <Button
            disabled={uploadDisabled}
            variant="warning"
            onClick={clearData}
          >
            초기화
          </Button>
        </ButtonGroup>
        <Button className="mx-3" disabled={uploadDisabled} onClick={openPopup}>
          본문 업로드
        </Button>
      </Col>
    </Row>
  );
};

export default ControlButton;
