import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  inputDataClear,
  inputDataSetting,
} from "../../../../redux/InputData/actions";
import { selOptionKey } from "../../../../redux/selOption/actions";

const PassageControlBtn = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const inputData = useSelector((state) => state.inputDataReducer);

  const passageList = selOption.passageList || [];

  const originalData = passageList.find(
    (row) => row.id === selOption.nowPassage.id
  );

  const modifyComplete = () => {
    const returnArray = inputData
      .filter((row) => !row.delete)
      .map((row) => {
        const returnRow = { ...row };
        delete returnRow.modify;
        delete returnRow.delete;
        return returnRow;
      });

    dispatch(
      selOptionKey(
        "passageList",
        passageList.map((row) =>
          row.id === selOption.nowPassage.id
            ? {
                ...row,
                ...selOption.nowPassage,
                modify: true,
                passage: returnArray,
              }
            : row
        )
      )
    );
    dispatch(
      selOptionKey("nowPassage", { id: "", type: "", passage: "", lesson: "" })
    );
    dispatch(inputDataClear());
  };

  const backOriginal = () => {
    const returnRow = { ...originalData };
    const passage = returnRow.passage;
    delete returnRow.passage;
    dispatch(selOptionKey("nowPassage", returnRow));
    dispatch(inputDataSetting(passage));
  };

  return (
    <ButtonGroup>
      {inputData.length !== 0 && (
        <>
          <Button onClick={modifyComplete}>수정 완료</Button>
          <Button variant="warning" onClick={backOriginal}>
            원래대로
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};

export default PassageControlBtn;
