const SET_QUESTION_OPT = "SET_QUESTION_OPT";
const KEY_QUESTION_OPT = "KEY_QUESTION_OPT";
const KEY_QUESTION_OPT_ANSWER_ARRAY = "KEY_QUESTION_OPT_ANSWER_ARRAY";
const CLEAR_QUESTION_OPT_ANSWER_ARRAY = "CLEAR_QUESTION_OPT_ANSWER_ARRAY";
const CLEAR_QUESTION_OPT = "CLEAR_QUESTION_OPT";

export const setQuestionOption = (payload) => ({
  type: SET_QUESTION_OPT,
  payload,
});
export const keyQuestionOption = (key, payload) => ({
  type: KEY_QUESTION_OPT,
  key,
  payload,
});
export const keyQuestionOptionAnswerArray = (key) => ({
  type: KEY_QUESTION_OPT_ANSWER_ARRAY,
  key,
});
export const clearQuestionOptionAnswerArray = () => ({
  type: CLEAR_QUESTION_OPT_ANSWER_ARRAY,
});
export const clearQuestionOption = () => ({
  type: CLEAR_QUESTION_OPT,
});

const answerArray = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
};

const questionOption = {
  id: "",
  qid: "",
  question: "",
  num1: "",
  num2: "",
  num3: "",
  num4: "",
  num5: "",
  answer: "",
  numAnswer: "",
  label: "",
  type: "choice",
  answerArray: answerArray,
};

const questionOptionReducer = (state = questionOption, action) => {
  switch (action.type) {
    case SET_QUESTION_OPT:
      return action.payload;
    case KEY_QUESTION_OPT:
      return { ...state, [action.key]: action.payload };
    case KEY_QUESTION_OPT_ANSWER_ARRAY:
      return {
        ...state,
        answerArray: {
          ...state.answerArray,
          [action.key]: !state.answerArray[action.key],
        },
      };
    case CLEAR_QUESTION_OPT_ANSWER_ARRAY:
      return { ...state, answerArray: answerArray };
    case CLEAR_QUESTION_OPT:
      return questionOption;
    default:
      return state;
  }
};

export default questionOptionReducer;
