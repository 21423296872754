import { collection, getDocs, query } from "firebase/firestore";
import { call, put, takeEvery } from "redux-saga/effects";
import db from "../../../firebase";

const GET_QID_PART = "GET_QID_PART";
const GET_QID_PART_SUCCESS = "GET_QID_PART_SUCCESS";
const GET_QID_PART_ERROR = "GET_QID_PART_ERROR";

const getDatas = async () => {
  let returnArray = [];
  const qidPartCollection = query(collection(db, "QIDPart"));
  const querySnapshot = await getDocs(qidPartCollection);
  querySnapshot.forEach(
    (doc) => (returnArray = [...returnArray, { ...doc.data(), id: doc.id }])
  );
  return returnArray;
};

export const getQIDPartData = (payload) => ({
  type: GET_QID_PART,
  payload,
});

function* getQIDPartDataSaga() {
  try {
    const payload = yield call(getDatas);
    yield put({
      type: GET_QID_PART_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_QID_PART_ERROR,
      error: true,
      e,
    });
  }
}

export function* qidPartSaga() {
  yield takeEvery(GET_QID_PART, getQIDPartDataSaga);
}

const qidPartReducer = (state = [], action) => {
  switch (action.type) {
    case GET_QID_PART:
      return state;
    case GET_QID_PART_SUCCESS:
      return action.payload;
    case GET_QID_PART_ERROR:
      return state;
    default:
      return state;
  }
};

export default qidPartReducer;
