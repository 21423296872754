import * as selOpt from "./types";
import * as selFn from "./function";
import { defaultSelectOption } from "./objects";

const selOption = {
  sid: null,
  name: null,
  cid: null,
  class: null,
  date: null,
};

const selOptionL = defaultSelectOption;

const selOptionReducer = (state = selOption, action) => {
  // state 의 초깃값을 selOption 으로 지정했습니다.
  switch (action.type) {
    // 공용
    // 초기화
    case selOpt.SEL_CLEAR:
      return selFn.clearState(state, action);
    // 키
    case selOpt.SEL_KEY:
      return {
        ...state,
        [action.key]: action.payload,
      };
    // selOption의 array 중 id 행의 입력받은 Key의 값을 payload로 변경
    case selOpt.SEL_ARRAY_KEY:
      return {
        ...state,
        [action.array]: state[action.array].map((row) => {
          if (row.id === action.id) {
            return { ...row, [action.key]: action.payload };
          } else return row;
        }),
      };
    // selOption의 array의 행 추가
    case selOpt.SEL_ARRAY_CREATE:
      return {
        ...state,
        [action.array]: [
          ...state[action.array],
          {
            id:
              new Date().toLocaleDateString() + new Date().toLocaleTimeString(),
          },
        ],
      };
    // selOption의 array의 행 삭제
    case selOpt.SEL_ARRAY_DELETE:
      return {
        ...state,
        [action.array]: state[action.array].filter(
          (row) => row.id !== action.id
        ),
      };

    // Board
    // File 하나 삭제
    case selOpt.SEL_FILE_REMOVE:
      let fileArray = [...state[action.array]];
      fileArray.splice(action.payload, 1);
      return {
        ...state,
        [action.array]: fileArray,
      };

    default:
      return state;
  }
};

export default selOptionReducer;
