import styled from "@emotion/styled";
import { Colors } from "styles/colors";
import { Body1, H1 } from "styles/typography";

export const Container = styled.div`
  display: flex;
  width: 100vw;

  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1440px;
  flex-direction: column;

  padding: 40px 24px;
`;

export const ProfileSection = styled.div`
  display: flex;

  justify-content: space-between;

  gap: 16px;
`;

export const NameSection = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;

  flex: 1;

  gap: 8px;
`;

export const Label = styled.span`
  ${Body1};
  color: ${Colors.black};
`;

export const Name = styled.span`
  ${H1};
  color: ${Colors.black};
`;

export const ToDoNumber = styled.span`
  ${H1};
  color: ${Colors.blue1};
`;

export const ToDoNubmerBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  flex: 1;

  border: 1px solid ${Colors.blue1};
  border-radius: 8px;
`;
