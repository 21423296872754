/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { PageType } from "types";
import useRedirect from "hooks/useRedirect";
import BasicLayout from "components/templates/basic-layout";
import * as S from "./NotAllowedPage.styled";
import { InputText } from "components/blocks/forms";
import { Button } from "components/atoms";
import useGetMemberInfo from "api/useGetMemberInfo";
import usePutMemberInfo from "api/usePutMemberInfo";

interface NotAllowedPageProps {
  pageType: PageType;
}

type RefType = "name" | "phone";

const HELPER_TEXT = {
  PHONE: "숫자 11자리를 입력해주세요.",
};

const DESCRIPTION = `계정이 관리자의 승인을 기다리고 있습니다.
아래 정보가 다른 경우 아래에서 수정해주세요!
올바른 값이 아닐 시 승인되지 않을 수 있습니다.`;

const DEFAULT_VALUES = {
  name: "",
  phone: "",
};

const NotAllowedPage = ({ pageType }: NotAllowedPageProps) => {
  const { data: memberInfo } = useGetMemberInfo();
  const { mutate: putMemberInfo } = usePutMemberInfo();

  const [values, setValues] = useState(DEFAULT_VALUES);

  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  const isErrorValue = {
    phone: values.phone.length !== 11,
  };

  const isButtonDisabled = values.name.length < 2 || isErrorValue.phone;

  const handleChange = (refType: RefType) => {
    const ref = () => {
      switch (refType) {
        case "name":
          return nameRef;
        case "phone":
        default:
          return phoneRef;
      }
    };
    const refCurrent = ref()?.current;
    if (!refCurrent) {
      return;
    }

    const { value } = refCurrent;

    setValues((prev) => ({
      ...prev,
      [refType]: value,
    }));
  };

  const handleDelete = (refType: RefType) => {
    setValues((prev) => ({
      ...prev,
      [refType]: "",
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isButtonDisabled) {
      return;
    }

    putMemberInfo(values);
  };

  useRedirect(pageType === "student" ? "learningcenter" : "teachingcenter", "notallow");

  useEffect(() => {
    if (!memberInfo) {
      return;
    }
    setValues({
      name: memberInfo.name,
      phone: memberInfo.phone,
    });
  }, [memberInfo]);

  if (!memberInfo) {
    return null;
  }

  return (
    <BasicLayout>
      <S.Container>
        <S.ContentContainer>
          <S.TitleSection>
            <S.Title>{`${memberInfo.name} ${memberInfo.teacher && "선생"}님, 안녕하세요!`}</S.Title>
            <S.Description>{DESCRIPTION}</S.Description>
          </S.TitleSection>
          <S.LoginForm onSubmit={handleSubmit}>
            <InputText
              label="이름"
              type="text"
              ref={nameRef}
              value={values.name}
              onChange={() => handleChange("name")}
              onDelete={() => handleDelete("name")}
              autoComplete="on"
              tabIndex={1}
            />
            <InputText
              label="전화번호"
              type="text"
              ref={phoneRef}
              value={values.phone}
              onChange={() => handleChange("phone")}
              onDelete={() => handleDelete("phone")}
              autoComplete="on"
              tabIndex={2}
              helperText={HELPER_TEXT.PHONE}
              errorText={HELPER_TEXT.PHONE}
              isError={!!values.phone.length && isErrorValue.phone}
            />
            <Button disabled={isButtonDisabled} tabIndex={3}>
              수정 완료
            </Button>
          </S.LoginForm>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default NotAllowedPage;
