import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as msg from "../../../lib/utils/msg";

// 업로드 정보 알려주는 컴포넌트
const AlertCard = () => {
  // reducers
  // loading reducer의 componet state
  const loading = useSelector((state) => state.loadingReducer).component;

  return <>{loading && <Alert children={msg.infoMsg.uploading()} />}</>;
};

export default AlertCard;
