import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingFalse } from "../../../redux/Loading/actions";
import useRedirect from "../../../hooks/useRedirect";
import * as CardStyles from "../../../styles/CardStyles";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown from "../../../Component/Countdown";
import WordGameQuizArea from "../../../Component/WordGameQuizArea";
import QuizTimerBox from "../../../Component/QuizTimerBox";
import { randomQuizIndexArray } from "../../../lib/utils/random";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const WordGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const quizTime = useSelector((state) => state.settingReducer).quizTime;

  const [countdownNumber, setCountdownNumber] = useState(3);
  const [quizResultList, setQuizResultList] = useState([]);
  const [quizTimer, setQuizTimer] = useState({ second: Math.floor(quizTime), milli: (quizTime % 1) * 10, start: false });
  const [quizWordList, setQuizWordList] = useState([]);

  const { wordArray } = useSelector((state) => state.wordReducer);

  useRedirect("learningcenter", "home", !location.state);

  useEffect(() => {
    dispatch(loadingFalse());
  }, [dispatch]);

  useEffect(() => {
    if (!location.state || !wordArray.length) {
      return;
    }
    const quizIndexArray = randomQuizIndexArray(wordArray.length);
    setQuizWordList(
      quizIndexArray.map((q) => {
        const [quizLanguage, optionLanguage] = [q.language, q.language === "eng" ? "kor" : "eng"];
        return {
          question: wordArray[q.questionIndex][quizLanguage],
          optionList: q.optionIndex.map((option) => wordArray[option][optionLanguage]),
          answerIndex: q.optionIndex.indexOf(q.questionIndex),
        };
      }),
    );
  }, [location.state, wordArray]);

  useEffect(() => {
    if (countdownNumber === 0) {
      !wordArray.length && navigate("/learningcenter/wordgame/main");
      setQuizTimer((prev) => ({ ...prev, start: true }));
    }
  }, [countdownNumber, navigate, wordArray.length]);

  return countdownNumber ? (
    <Countdown countdownNumber={countdownNumber} setCountdownNumber={setCountdownNumber} />
  ) : (
    <BasicLayout headerType="student">
      <S.Container>
        <S.ContentContainer>
          <Row className="App-left">
            <Col>
              <QuizTimerBox quizTimer={quizTimer} setQuizTimer={setQuizTimer} />
            </Col>
            <Col>
              <CardStyles.CorrectBox>
                <p>Correct</p>
                <h1>{quizResultList.filter((result) => result.correct).length}</h1>
              </CardStyles.CorrectBox>
            </Col>
            <Col>
              <CardStyles.ScoreBox>
                <p>Score</p>
                <h1>{quizResultList.map((result) => result.score).reduce((stack, el) => stack + el, 0)}</h1>
              </CardStyles.ScoreBox>
            </Col>
          </Row>
          <WordGameQuizArea
            state={location.state}
            quizWordList={quizWordList}
            quizResultList={quizResultList}
            setQuizResultList={setQuizResultList}
            quizTimer={quizTimer}
            setQuizTimer={setQuizTimer}
          />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default WordGame;
