import db from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { MemberInfo } from "types";

export default function useGetMemberInfo() {
  const [memberInfo, setMemberInfo] = useState<MemberInfo>();

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo.uid as string;

  const getMemberInfo = useCallback(async () => {
    if (!userUid) {
      return;
    }

    const docRef = doc(db, "Users", userUid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return;
    }

    const memberInfo = { ...docSnap.data(), id: userUid } as MemberInfo;

    setMemberInfo(memberInfo);
  }, [userUid]);

  useEffect(() => {
    getMemberInfo();
  }, [getMemberInfo]);

  return { data: memberInfo };
}
