import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useStudentList } from "../../../firestoreData";
import { setLeaguePlayerAdd } from "../../../redux/league/actions";
import { gameArrayAdd } from "../../../redux/League_GameArray/actions";
import { leagueRosterAdd } from "../../../redux/League_Roster/actions";

// 해당 리그에 인원을 추가할 경우에 사용하는 컴포넌트
const AddPlayerButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // state
  // 추가할 학생 정보 state
  const [addPlayer, setAddPlayer] = useState({});

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);
  // league 인원 reducer
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);

  // firestoreData.js의 데이터
  // 사용자 지점의 전체 학생 데이터
  const student = useStudentList();

  // addPlayer 변경 함수
  const optionChange = (e) => {
    e
      ? setAddPlayer({ sid: e.id, name: e.name })
      : setAddPlayer({ sid: null, name: null });
  };

  // 버튼 클릭 시 addPlayer의 인원 추가
  const onClick = () => {
    // league reducer의 해당하는 리그의 플레이어 수 1 추가
    dispatch(setLeaguePlayerAdd(leagueLable[level]));
    // leagueRoster의 해당하는 리그에 인원 추가
    dispatch(
      leagueRosterAdd(
        {
          ...addPlayer,
          id: leagueRoster.length,
          rank: leagueRoster.length + 1,
          league: leagueLable[level],
        },
        level
      )
    );
    // 학생 수에 맞게 gameArray 재조정
    dispatch(gameArrayAdd(level));
    setAddPlayer({});
  };

  return (
    <>
      <ButtonGroup className="mb-3" style={{ float: "left" }}>
        <Select
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Player"
          value={addPlayer.sid ? addPlayer : null}
          isSearchable
          isClearable
          options={student}
          isDisabled={loading}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e.name}
          maxMenuHeight={150}
          onChange={(e) => optionChange(e)}
        />
        <Button
          onClick={onClick}
          disabled={!addPlayer.sid || loading} // sid가 null일 경우 disabled
          style={{
            backgroundColor: "#0b3885",
            color: "whitesmoke",
            border: "#0b3885 solid 1px",
          }}
        >
          추가
        </Button>
      </ButtonGroup>
    </>
  );
};

export default AddPlayerButton;
