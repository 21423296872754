import { Accordion, Row } from "react-bootstrap";
import DisplayScidPart from "./DisplayScidPart";
import SelectWordClass from "./SelectWordClass";
import SelectWordDate from "./SelectWordDate";

const TodaySchedule = () => {
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>오늘 스케쥴 보기</Accordion.Header>
      <Accordion.Body>
        <Row>
          <SelectWordDate />
          <SelectWordClass />
          <DisplayScidPart />
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TodaySchedule;
