import db from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingActive,
  loadingComponent,
} from "../../../redux/Loading/actions";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import { setQIDData } from "../../../redux/QIDData/reducers";
import { Button } from "react-bootstrap";

const LoadStatButton = () => {
  // redux dispatch
  const dispatch = useDispatch();
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // loading reducer
  const loading = useSelector((state) => state.loadingReducer).component;
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const qidRate = (a) => {
    let rate = { correct: 0, count: 0 };
    quizInputData
      .filter((row) => row.qid === a)
      .forEach((res) => {
        rate.correct += res.correct;
        rate.count += res.count;
      });
    return Math.round((rate.correct / rate.count) * 10000) / 100;
  };

  const qidArray = quizInputData
    .map(function (val, index) {
      return val["qid"];
    })
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ qid: row, rate: qidRate(row) }))
    .sort(function (a, b) {
      return a.rate - b.rate;
    });

  const qidDoc = async (qid) => await getDoc(doc(db, "Question", qid));

  const getQidDoc = async () => {
    dispatch(loadingComponent(true));
    dispatch(
      setQIDData(
        await Promise.all(
          qidArray.map(async (row) => {
            let result = await qidDoc(row.qid);
            return { ...result.data(), qid: row.qid };
          })
        )
      )
    );
    dispatch(loadingActive(false));
  };

  return (
    <>
      {quizInputData.length !== 0 && active && (
        <Button
          className="mt-3 Growing-button"
          disabled={!active}
          onClick={getQidDoc}
        >
          {loading ? <LoadingSpinner /> : "통계 불러오기"}
        </Button>
      )}
    </>
  );
};

export default LoadStatButton;
