/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useStudentList } from "../../firestoreData";
import { getFireStoreData } from "../../redux/FirebaseData/DownloadController/reducer";
import { inputDataKey } from "../../redux/InputData/actions";

// test Input 인원 테이블에서 인원을 선택하는 컴포넌트
const ChangeStudent = (props) => {
  // props
  // InputTable.js에서 넘어온 표의 index
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // test Input 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // download controler reducer
  const downloadUser = useSelector(
    (state) => state.downloadControlerReducer
  ).users;

  // useFirestoreData.js 의 해당 지점 전체 학생 데이터
  const student = useStudentList();

  // 선택할 수 있는 학생 리스트 (중복 선택 방지 위함)
  const canSelectStudentList = () => {
    // inputData 내 학생 리스트
    const inputDataStudent = inputData.map((row) => row.sid);
    // return 할 Array
    return student.filter(
      (row) => !inputDataStudent.find((obj) => obj === row.id)
    );
  };

  // onClick 함수
  // 해당 index의 행에 sid, 이름 변경
  const inputSid = (e) => {
    dispatch(inputDataKey("sid", row.id, e.id));
    dispatch(inputDataKey("name", row.id, e.name));
  };

  useEffect(() => {
    // 유저 데이터 다운로드 토글 true로
    !downloadUser && dispatch(getFireStoreData("users"));
  }, []);

  return (
    <Select
      placeholder="학생"
      isSearchable
      options={canSelectStudentList()}
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.name}
      maxMenuHeight={300}
      value={row.sid !== null ? row : { sid: null, name: null }}
      onChange={inputSid}
    />
  );
};

export default ChangeStudent;
