import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setLeaguePlayerRemove,
  setLeagueRemove,
} from "../../../redux/league/actions";
import { gameArrayRemove } from "../../../redux/League_GameArray/actions";
import { leagueRosterRemove } from "../../../redux/League_Roster/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

// 해당 리그에 인원을 제외할 경우에 사용하는 컴포넌트
const ReducePlayerButton = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);
  // 해당 리그 참가 인원
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);
  // 해당 리그 내 인원 제거 모드
  const remove = league[leagueLable[level]].remove;

  // 해당 리그의 remove state 변경 함수
  const removeNow = () => {
    // remove가 true이고 해당 리그 인원 수가 0명 이상일 경우 인원 삭제
    if (remove && leagueRoster.filter((row) => row.remove).length !== 0) {
      // 삭제할 인원의 leagueRoster 내 index 추출
      const removeIndex = leagueRoster
        .map((row, i) => (row.remove ? i : null))
        .filter((res) => res !== null)
        .reverse();
      // 해당 리그의 gameArray에서 index를 이용하여 삭제
      dispatch(gameArrayRemove(removeIndex, level));
      // 해당 리그의 인원에서 remove가 true인 인원을 삭제
      dispatch(leagueRosterRemove(level));
      // 해당 리그의 플레이어 수 재설정
      dispatch(
        setLeaguePlayerRemove(
          leagueLable[level],
          leagueRoster.filter((row) => row.remove).length
        )
      );
    }
    // 해당 리그의 remove state 변경
    dispatch(setLeagueRemove(leagueLable[level]));
    dispatch(modalShowPopup(false));
  };

  // onClick 함수
  // 팝업 띄우기
  const openPopup = () => {
    if (remove && leagueRoster.filter((row) => row.remove).length !== 0) {
      dispatch(modalShowPopup(true));
      dispatch(
        setPopupModal(
          "인원을 제외하시겠습니까?",
          "이 작업은 되돌릴 수 없습니다.",
          removeNow,
          "제외"
        )
      );
    } else return dispatch(setLeagueRemove(leagueLable[level]));
  };

  return (
    <>
      <Button
        className="mx-3"
        variant="danger"
        style={{ float: "left" }}
        onClick={openPopup}
        disabled={loading}
      >
        {remove ? "완료" : "제외"}
      </Button>
    </>
  );
};

export default ReducePlayerButton;
