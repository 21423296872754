import { Button, Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setNameOptionAfter, setNameOptionBefore, setNameOptionClear } from "../../../redux/Quiz_NameOption/actions";
import { setNameChange } from "../../../redux/Quiz_InputData/actions";
import { nullPlayer } from "./function";
import { useStudentList } from "../../../firestoreData";

// 잘못 입력된 학생 정보 고치기 위한 Select 컴포넌트
const NameChange = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 퀴즈 파일 로드 시 정오표 업로드 되는 reducer
  const inputData = useSelector((state) => state.quizInputDataReducer);
  // 잘못된 이름을 정확한 이름으로 고치기 위한 정보를 저장하는 reducer
  const nameOption = useSelector((state) => state.quizNameOptionReducer);

  // useFirestoreData.js 의 해당 지점 전체 학생 데이터
  const student = useStudentList();

  // inputData 내 서버에 등록되지 않았거나 잘못 입력된 이름의 학생 리스트
  const nullStudent = nullPlayer(inputData);
  // 바꿀 이름 nameOption으로 전송
  const setNameBefore = (e) => dispatch(setNameOptionBefore(e));
  const setNameAfter = (e) => dispatch(setNameOptionAfter(e));

  // 이름 변경 함수
  const nameChange = () => {
    dispatch(setNameOptionClear());
    dispatch(setNameChange(nameOption));
  };

  return (
    <>
      {/* nullStudent 내 인원이 있을 경우에만 Display */}
      {nullStudent.length !== 0 && (
        <Col>
          {/* 제목 */}
          <p className="Form-label">이름 변경 (총 {nullStudent.length}명의 데이터 수정 필요)</p>
          {/* 이름을 변경해야 할 인원이 Display되는 선택 컴포넌트 */}
          <Select
            placeholder="변경 전"
            isSearchable
            options={nullStudent}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.name}
            maxMenuHeight={300}
            onChange={(e) => setNameBefore(e)}
          />
          {/* 해당 이름으로 변경 될 학생을 선택할 컴포넌트 */}
          <Select
            placeholder="변경 후"
            isSearchable
            options={student}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.name}
            maxMenuHeight={300}
            onChange={(e) => setNameAfter(e)}
          />
          {/* 변경 버튼 컴포넌트 */}
          <Button className="Growing-button mt-2" onClick={nameChange} disabled={nullStudent.length === 0}>
            변경
          </Button>
        </Col>
      )}
    </>
  );
};

export default NameChange;
