import { useSelector } from "react-redux";

// 학생의 이름 표시
const NameHeader = () => {
  // reducers
  // Input Data reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // 학생별 정답률
  const sidRate = (a) => {
    let rate = { correct: 0, count: 0 };
    quizInputData
      .filter((row) => row.sid === a)
      .forEach((res) => {
        rate.correct += res.correct;
        rate.count += res.count;
      });
    return Math.round((rate.correct / rate.count) * 10000) / 100;
  };

  // 학생별 정답률에 따라 셀 스타일 변경
  const sidRateStyle = (sid) =>
    sidRate(sid) <= selOption.percentage
      ? { background: "Salmon", color: "white" }
      : { background: "SeaGreen", color: "white" };

  // 학생별 오답노트 해당 문제의 갯수
  const incorrectQuestion = (row) =>
    row.name +
    " (" +
    quizInputData.filter(
      (res) =>
        res.sid === row.sid &&
        (res.correct / res.count) * 100 <= selOption.percentage
    ).length +
    ")";

  return (
    <>
      {inputData.map((row) => (
        <th key={row.name} style={sidRateStyle(row.sid)}>
          {incorrectQuestion(row)}
        </th>
      ))}
    </>
  );
};

export default NameHeader;
