import {
  SET_LEAGUEROSTER_ADD,
  SET_LEAGUEROSTER_CLEAR,
  SET_LEAGUEROSTER_REMOVE,
  SET_LEAGUEROSTER_REMOVE_CHECK,
  SET_LEAGUEROSTER_ENTIRE_CLEAR,
} from "./types";

const leagueRoster = [];

const leagueRosterReducer = (state = leagueRoster, action) => {
  // state 의 초깃값을 leagueRoster 으로 지정했습니다.

  // 변경용 복사 배열
  const newArray = [...state];

  switch (action.type) {
    // 초기화
    case SET_LEAGUEROSTER_CLEAR:
      return action.payload;
    // 플레이어 추가
    case SET_LEAGUEROSTER_ADD:
      newArray[action.league] = [...newArray[action.league], action.payload];
      return newArray;
    // 플레이어 제외 체크
    case SET_LEAGUEROSTER_REMOVE_CHECK:
      newArray[action.league] = newArray[action.league].map((row) => {
        if (row.sid === action.payload) {
          return { ...row, remove: !row.remove };
        } else return row;
      });
      return newArray;
    // 플레이어 제외 적용
    case SET_LEAGUEROSTER_REMOVE:
      newArray[action.league] = newArray[action.league].filter(
        (row) => row.remove === false || row.remove === undefined
      );
      return newArray;
    // 초기화
    case SET_LEAGUEROSTER_ENTIRE_CLEAR:
      return leagueRoster;
    default:
      return state;
  }
};

export default leagueRosterReducer;
