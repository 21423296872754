import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { call, put, takeEvery } from "redux-saga/effects";
import db from "../../../firebase";

const GET_SCHEDULELIST = "GET_SCHEDULELIST";
const GET_SCHEDULELIST_SUCCESS = "GET_SCHEDULELIST_SUCCESS";
const GET_SCHEDULELIST_ERROR = "GET_SCHEDULELIST_ERROR";

const getDatas = async (office) => {
  let returnArray = [];
  const scheduleListCollection =
    office &&
    query(
      collection(db, "Schedule", office, "List"),
      orderBy("subject", "asc")
    );
  const querySnapshot = await getDocs(scheduleListCollection);
  scheduleListCollection &&
    querySnapshot.forEach(
      (doc) => (returnArray = [...returnArray, { ...doc.data(), id: doc.id }])
    );
  return returnArray;
};

export const getScheduleListData = (payload) => ({
  type: GET_SCHEDULELIST,
  payload,
});

function* getScheduleListDataSaga(action) {
  const office = action.payload;
  try {
    const payload = yield call(getDatas, office);
    yield put({
      type: GET_SCHEDULELIST_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_SCHEDULELIST_ERROR,
      error: true,
      e,
    });
  }
}

export function* scheduleListSaga() {
  yield takeEvery(GET_SCHEDULELIST, getScheduleListDataSaga);
}

const scheduleListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SCHEDULELIST:
      return state;
    case GET_SCHEDULELIST_SUCCESS:
      return action.payload;
    case GET_SCHEDULELIST_ERROR:
      return state;
    default:
      return state;
  }
};

export default scheduleListReducer;
