import { ButtonGroup, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadPresetButton from "../../StudentPreset/LoadModalButton";
import SavePresetButton from "../../StudentPreset/SaveModalButton";
import CreateRowButton from "./Table/CreateRowButton";
import DeleteRowButton from "./Table/DeleteRowButton";
import SelectInputScore from "./Table/SelectInputScore";
import ChangeStudent from "../../SelectTable/ChangeStudent";

const InputTable = () => {
  // Reducer
  // test Input 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  return (
    <Table striped bordered hover size="sm" className="mb-5">
      <thead>
        <tr>
          <th>
            <ButtonGroup className="mx-3">
              <CreateRowButton />
              <DeleteRowButton />
            </ButtonGroup>
            <ButtonGroup>
              <SavePresetButton />
              <LoadPresetButton />
            </ButtonGroup>
          </th>
          <th style={{ width: "25%" }}>Score</th>
          <th style={{ width: "5%" }}></th>
        </tr>
      </thead>
      <tbody>
        {inputData.map((row) => (
          <tr key={row.id}>
            <td>
              <ChangeStudent row={row} />
            </td>
            <td>
              <SelectInputScore row={row} />
            </td>
            <td>
              <DeleteRowButton row={row} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InputTable;
