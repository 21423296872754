import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RequiredText } from "../../Methods";
import { displayDataSchool } from "../../redux/DisplayData/actions";
import { schools } from "../../lib/utils/constants";

const DisplayChangeSchool = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const sortSchoolList = schools.sort((a, b) => {
    if (a.school < b.school) {
      return -1;
    }
    if (a.school > b.school) {
      return 1;
    }
    return 0;
  });

  const changeSchool = (e) => dispatch(displayDataSchool(e));

  return (
    <>
      <p className="Form-label"> 학교 </p>
      <Select
        placeholder="학교"
        isSearchable
        options={sortSchoolList}
        getOptionLabel={(e) => e.school}
        getOptionValue={(e) => e.school}
        value={displayData.school ? displayData : null}
        maxMenuHeight={150}
        onChange={(e) => changeSchool(e)}
      />
      {!displayData.school && <RequiredText />}
    </>
  );
};

export default DisplayChangeSchool;
