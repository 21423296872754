import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { inputDataSetting } from "../../../../redux/InputData/actions";
import { selOptionKey } from "../../../../redux/selOption/actions";
import CheckDeleteBtn from "./CheckDeleteBtn";

const PassageListTable = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  const passageList =
    useSelector((state) => state.selOptionReducer).passageList || [];

  const setInputData = (row) => {
    const returnRow = { ...row };
    const passage = returnRow.passage;
    delete returnRow.passage;
    dispatch(selOptionKey("nowPassage", returnRow));
    dispatch(inputDataSetting(passage));
  };

  const background = (row) => {
    switch (true) {
      case row.delete:
        return "salmon";
      case row.id === selOption.nowPassage.id:
        return "yellowgreen";
      case row.modify:
        return "darkkhaki";
      default:
        return "none";
    }
  };

  return (
    <>
      {passageList.length !== 0 && (
        <Table bordered hover striped size="sm" className="InputTable">
          <thead>
            <tr>
              <th width="25%">타입</th>
              <th width="35%">교과서</th>
              <th width="30%">레슨</th>
              <CheckDeleteBtn isAll />
            </tr>
          </thead>
          <tbody>
            {passageList.map((row) => (
              <tr
                style={{
                  cursor: "pointer",
                  background: background(row),
                }}
                key={row.id}
                onClick={() => setInputData(row)}
              >
                <td>{row.type}</td>
                <td>{row.textbook}</td>
                <td>{row.lesson}</td>
                <CheckDeleteBtn row={row} />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default PassageListTable;
