import useRedirect from "../../hooks/useRedirect";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";
import WordStudy from "./components/word-study";

const WordPage = ({ pageType }) => {
  useRedirect(pageType === "student" ? "learningcenter" : "teachingcenter", "home");

  return (
    <BasicLayout headerType={pageType}>
      <S.Container>
        <S.ContentContainer>
          <WordStudy />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default WordPage;
