import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  modalShowAdd,
  modalShowDisplay,
  modalShowModify,
} from "../../../redux/Modal_Show/actions";
import ModifyButton from "./ModifyButton";
import SelectDisplayClass from "./SelectDisplay/SelectDisplayClass";
import SelectDisplayDate from "./SelectDisplay/SelectDisplayDate";
import SelectDisplayPart from "./SelectDisplay/SelectDisplayPart";
import SelectDisplaySchedule from "./SelectDisplay/SelectDisplaySchedule";
import SelectDisplaySubject from "./SelectDisplay/SelectDisplaySubject";
import UploadButton from "./UploadButton";

// ScheduleCalendar에서 스케쥴 클릭 시 Display되는 Modal 컴포넌트
const ScheduleModal = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer);
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);
  // 반 리스트 reducer
  const classes = useSelector((state) => state.classesReducer);

  // 헤더에 표시될 텍스트
  const title = () => {
    if (show.add) return "스케쥴 추가";
    else if (show.modify) return "수정";
    else return displaySchedule.scname + "-" + displaySchedule.part;
  };

  // onHide 함수
  // 모달 창 닫기
  const handleClose = () => {
    dispatch(modalShowDisplay(false));
    dispatch(modalShowAdd(false));
    dispatch(modalShowModify(false));
  };

  return (
    <Modal show={show.display} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title()}</Modal.Title>
      </Modal.Header>
      {/* show.modify가 false일 때, 단순 Display 목적의 Body */}
      {show.display && !show.modify && (
        <Modal.Body>
          과목: {displaySchedule.subject} <br />
          반: {classes.find((row) => row.id === displaySchedule.cid).name}
        </Modal.Body>
      )}
      {/* show.modify가 true일 때 수정 및 추가 목적의 Body */}
      {show.display && show.modify && (
        <Modal.Body>
          <Form>
            <SelectDisplaySubject />
            <SelectDisplaySchedule />
            <SelectDisplayPart />
            <SelectDisplayClass />
            <SelectDisplayDate />
          </Form>
        </Modal.Body>
      )}
      <ModifyButton />
      <UploadButton />
    </Modal>
  );
};

export default ScheduleModal;
