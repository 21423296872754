import db from "../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowClear } from "../../../redux/Modal_Show/actions";

const SavePresetButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // test Input 인원 테이블 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // Modal 창을 보여주는 조건 reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  const savePreset = () => {
    addDoc(collection(db, "Users", myInfo.uid, "studentSetting"), {
      title: selOption.title,
      comment: selOption.comment === null ? "" : selOption.comment,
      studentList: inputData.map((row) => ({
        sid: row.sid,
        name: row.name,
      })),
    });
    dispatch(modalShowClear());
  };
  return (
    <Button onClick={savePreset} className="Growing-button">
      저장
    </Button>
  );
};

export default SavePresetButton;
