export const Colors = {
  // Primary
  blue1: "#1155CC",
  blue2: "#6BA9F9",
  blue3: "#003EAB",

  // Secondary
  lime: "#BFFF00",

  // Grayscale
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F9F9F9",

  // System
  green: "#14AE5C",
  yellow: "#EBC812",
  red: "#F24822",

  // Monochrome
  white: "#FFFFFF",
  black: "#1B1B1B",
};

export const OpacityColors = {
  blue1Opacity20: "#1155CC33",
  blue2Opacity20: "#6BA9F933",

  gray1Opacity30: "#3333334D",
  gray4Opacity20: "#BDBDBD33",

  whiteOpacity20: "#FFFFFF33",
  blackOpacity20: "#1B1B1B33",
};
