import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import { displayDataName } from "../../redux/DisplayData/actions";

const DisplayChangeName = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const changeName = (e) => dispatch(displayDataName(e.target.value));

  return (
    <>
      <p className="Form-label"> 이름 </p>
      <Form.Control
        type="text"
        value={displayData.name || ""}
        onChange={(e) => changeName(e)}
      />
      {!displayData.name && <RequiredText />}
    </>
  );
};

export default DisplayChangeName;
