import { useEffect, useRef, useState } from "react";
import usePageType from "hooks/usePageType";
import { InputText } from "components/blocks/forms";
import { PageType } from "types";
import { resetPassword } from "api/authApi";
import useLoadingIndicator from "hooks/useLoadingIndicator";
import useGoToMainPage from "hooks/useGoToMainPage";
import * as S from "./FindPassword.styled";
import logo from "../../assets/images/growing_symbol_sm.png";
import BasicLayout from "components/templates/basic-layout";
import { Button } from "components/atoms";
import { testEmail } from "utils/regExp";

interface FindPasswordPageProps {
  pageType: PageType;
}

export default function FindPasswordPage({ pageType }: FindPasswordPageProps) {
  const emailRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = () => {
    if (!emailRef.current) {
      return;
    }

    const {
      current: { value },
    } = emailRef;

    setValue(value);
  };

  const handleDelete = () => {
    setValue("");
  };

  const handleFindPassword = async () => {
    setIsLoading(true);

    if (!emailRef?.current) {
      return;
    }

    await resetPassword(emailRef.current.value);

    setIsLoading(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleFindPassword();
  };

  // navbar 컨트롤 함수
  usePageType("");

  useLoadingIndicator(isLoading);

  useGoToMainPage(pageType);

  useEffect(() => {
    if (!emailRef.current) {
      return;
    }

    emailRef.current.focus();
  }, []);

  return (
    <BasicLayout>
      <S.Container>
        <S.ContentContainer>
          <S.TitleSection>
            <S.Logo src={logo} />
            <S.Title>비밀번호 찾기</S.Title>
          </S.TitleSection>
          <S.LoginForm onSubmit={handleSubmit}>
            <InputText
              label="이메일"
              type="email"
              ref={emailRef}
              value={value}
              onChange={handleChange}
              onDelete={handleDelete}
              errorText={"올바른 이메일 형식을 입력해주세요."}
              isError={!!value.length && !testEmail(value)}
              tabIndex={1}
            />
            <Button disabled={!testEmail(value)} type="submit">
              비밀번호 재설정 이메일 보내기
            </Button>
          </S.LoginForm>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
}
