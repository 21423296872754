import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { gameArrayChange } from "../../../redux/League_GameArray/actions";
import { leagueRosterRemoveCheck } from "../../../redux/League_Roster/actions";
import {
  rank,
  leagueType,
  useScoreSum,
  useTotalRank,
  useMajor,
  useMinor,
} from "./function";

// 게임판 컴포넌트
const GameBoard = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // redux dispatch
  const dispatch = useDispatch();

  // Reducers
  // loading reducer의 component loading state
  const loading = useSelector((state) => state.loadingReducer).component;
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // league 정보 reducer
  const league = useSelector((state) => state.leagueReducer);
  // league 인원 reducer
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];
  // game 점수 reducer
  const gameArray = useSelector((state) => state.gameArrayReducer)[level];

  // league의 라벨 ("A", "B", "C", "D")
  const leagueLable = Object.keys(league);
  // 클릭 시 해당 리그의 점수 변동이 minus인지 plus인지
  const minus = league[leagueLable[level]].minus;
  // 클릭 시 해당 리그의 점수 변동이 1인지 0.1인지
  const score = league[leagueLable[level]].score;
  // 클릭 시 해당 리그의 인원 제외 가능
  const remove = league[leagueLable[level]].remove;

  // function.js에서 사용한 함수
  // 상위 리그 인원의 합
  const major = useMajor(level);
  // 바로 아래 리그의 인원 수
  const minor = useMinor(level);
  // 리그의 타입, (major, minor)에 의해 결정
  const type = leagueType(major, minor);
  // 각 인원의 점수의 합 Array
  const scoreSum = useScoreSum(gameArray);
  // 각 인원의 해당 리그 내 순위
  const nowRank = scoreSum.map((obj) => rank(obj, scoreSum));
  // 각 인원의 게임 전채 순위
  const totalRank = useTotalRank(scoreSum, selOption.promotion, major, type);

  // onClick 함수
  // 클릭 시 해당 칸의 점수 변동, minus(-1, 1), score(1, 0.1)에 따라 계산 (gameArray 셀에 사용)
  const gameWin = (i, j) => {
    if (
      i !== j &&
      !loading &&
      (minus === 1 || (minus === -1 && gameArray[i][j] >= score))
    ) {
      return () => {
        const newArray = [...gameArray];
        newArray[i][j] += score * minus;
        dispatch(gameArrayChange(newArray, level));
      };
    }
  };
  // 클릭 시 해당 칸의 점수를 0으로 (gameArray 셀에 사용)
  const scoreClear = (i, j) => {
    if (i !== j && !loading) {
      return () => {
        const newArray = [...gameArray];
        newArray[i][j] = 0;
        dispatch(gameArrayChange(newArray, level));
      };
    }
  };
  // 클릭 시 해당 인원을 게임에서 제외한다고 저장 (헤더 셀에 사용)
  const removeCheck = (sid) =>
    remove && dispatch(leagueRosterRemoveCheck(sid, level));

  // 셀 스타일
  // label 셀 스타일
  const cellColor = {
    backgroundColor: "#0b3885",
    color: "whitesmoke",
  };
  // Header 이름 셀 스타일
  const headerCellColor = (isRemove) => {
    const cellStyle = {
      backgroundColor: "#454648",
      color: "whitesmoke",
      cursor: "pointer",
    };
    if (isRemove && remove) {
      return {
        ...cellStyle,
        textDecoration: "line-through",
      };
    } else if (remove) {
      return cellStyle;
    } else return cellColor;
  };
  // scoreSumCell, statCell 셀 스타일
  const statCellColor = { backgroundColor: "Indigo", color: "whitesmoke" };

  // scoreSumCell, statCell 셀 스타일
  const statScoreCellColor = (i) => {
    let returnArray = [...scoreSum];
    returnArray.splice(i, 1);
    return returnArray.findIndex((obj) => obj === scoreSum[i]) >= 0
      ? { backgroundColor: "indianred", color: "whitesmoke" }
      : { backgroundColor: "Indigo", color: "whitesmoke" };
  };

  // gameArray 셀 스타일
  const gameCellColor = (i, j) => {
    if (i === j) {
      return { backgroundColor: "white" };
    } else if (gameArray[j][i] > gameArray[i][j]) {
      return {
        backgroundColor: "#da6a12",
        color: "whitesmoke",
        cursor: "pointer",
      };
    } else if (gameArray[j][i] < gameArray[i][j]) {
      return {
        backgroundColor: "#337700",
        color: "whitesmoke",
        cursor: "pointer",
      };
    } else {
      return { backgroundColor: "#e6ebf1", cursor: "pointer" };
    }
  };

  // 셀 컴포넌트
  // 헤더 이름 셀 컴포넌트
  const nameHeaderCell = (obj, i) => (
    <th
      key={i}
      style={{
        ...headerCellColor(obj.remove),
        width: (100 - 15) / leagueRoster.length + "%",
      }}
      onClick={() => removeCheck(obj.sid)}
    >
      {obj.name}
    </th>
  );
  // 왼쪽 이름 셀 컴포넌트
  const nameRowCell = (obj) => (
    <td key={"student" + obj.name} style={cellColor}>
      <strong>{obj.name}</strong>
    </td>
  );
  // gameArray 셀 컴포넌트
  const gameArrayCell = (row, i, j) => {
    // 셀에 표시되는 숫자
    const score = () => {
      if (i === j) {
        return "";
      } else if (gameArray[i][j].toFixed(1) % 1 > 0) {
        return (
          parseInt(gameArray[i][j].toFixed(1) / 1) +
          " (연장 " +
          (gameArray[i][j] % 1).toFixed(1) * 10 +
          ")"
        );
      } else return parseInt(gameArray[i][j].toFixed(1) / 1);
    };
    // 리턴할 컴포넌트
    return (
      <td
        key={"gameArray" + row.name + i + j}
        style={gameCellColor(i, j)}
        onClick={gameWin(i, j)}
        onDoubleClick={scoreClear(i, j)}
      >
        {score()}
      </td>
    );
  };
  // 점수 합 셀 컴포넌트
  const scoreSumCell = (row, i) => (
    <td key={"sum" + leagueRoster[i].name} style={statScoreCellColor(i)}>
      <strong>
        {parseInt(row.toFixed(1) / 1) +
          " " +
          (row.toFixed(1) % 1 > 0
            ? " (연장 " + (row % 1).toFixed(1) * 10 + ")"
            : "")}
      </strong>
    </td>
  );
  // 순위 셀 컴포넌트, nowRank, totalRank에 사용
  const statCell = (text, row, i) => (
    <td key={text + leagueRoster[i].name} style={statScoreCellColor(i)}>
      <strong>{row}</strong>
    </td>
  );

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th
            style={{
              ...cellColor,
              width: "15%",
            }}
          >
            Game
          </th>
          {leagueRoster.map((obj, i) => nameHeaderCell(obj, i))}
        </tr>
      </thead>
      <tbody>
        {leagueRoster.map((obj, i) => {
          return (
            <tr key={obj.sid}>
              {nameRowCell(obj)}
              {gameArray.map((row, j) => gameArrayCell(row, i, j))}
            </tr>
          );
        })}
        <tr>
          <td style={statCellColor}>
            <strong>Sum</strong>
          </td>
          {scoreSum.map((row, i) => scoreSumCell(row, i))}
        </tr>
        <tr>
          <td style={statCellColor}>
            <strong>Rank</strong>
          </td>
          {nowRank.map((row, i) => statCell("rank", row, i))}
        </tr>
        <tr>
          <td style={statCellColor}>
            <strong>Total Rank</strong>
          </td>
          {totalRank.map((row, i) => statCell("totalRank", row, i))}
        </tr>
      </tbody>
    </Table>
  );
};

export default GameBoard;
