import { Card, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { colors } from "../../../lib/utils/constants";
import { weekLable, weekNumberByMonth } from "../../../lib/utils/date";

const ChartQuiz = () => {
  // stat reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // stat reducer
  const stat = useSelector((state) => state.statArrayReducer).stat.quiz;

  const sumArray = (acc, cur) => acc + cur;

  const sumQuizStat =
    stat.GRA.reduce(sumArray, 0) +
      stat.GRB.reduce(sumArray, 0) +
      stat.LIS.reduce(sumArray, 0) +
      stat.TEP.reduce(sumArray, 0) !==
    0;

  let quizDataArray = Object.keys(stat).map((a, i) => {
    return {
      label: a,
      data: stat[a],
      backgroundColor: colors.main[i],
      borderColor: colors.main[i],
    };
  });

  // quiz stat을 받아올 주차 레이블 배열
  const weekArray = () => {
    let returnArray = [];
    for (let i = 0; i < 8; i++) {
      returnArray = [
        ...returnArray,
        weekLable(
          weekNumberByMonth(
            new Date(new Date().setDate(new Date().getDate() - i * 7))
          )
        ),
      ];
    }
    return returnArray.reverse();
  };

  const quizData = {
    labels: weekArray(),
    datasets: quizDataArray,
  };

  const quizOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          min: 0,
          max: 100,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              tooltipItem.dataset.label +
              ": " +
              Number(tooltipItem.formattedValue) +
              "%"
            );
          },
        },
      },
    },
  };

  return (
    <>
      {sumQuizStat && selOption.toggleQuiz && (
        <Col xxl>
          <Card>
            <Card.Body>
              <Card.Title className="App-left">
                {`퀴즈 정답률 - ${selOption.nowStudent} `}
              </Card.Title>
              <Bar
                data={quizData}
                options={quizOptions}
                width={10}
                height={6}
              />
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ChartQuiz;
