import { useEffect } from "react";
import useLoadingIndicatorStore from "stores/useLoadingIndicatorStore";

export default function useLoadingIndicator(isShow = false) {
  const { setIsShow } = useLoadingIndicatorStore();

  useEffect(() => {
    setIsShow(isShow);
    document.body.style.overflow = isShow ? "hidden" : "unset";
  }, [isShow, setIsShow]);
}
