import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectTextForm from "../../Select/SelectTextForm";
import SelectSchedule from "./SelectSchedule";
import SelectSet from "./SelectSet";

const DisplayWordProperty = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  return (
    <Row className="g-2 mx-2">
      <Col>
        <SelectSchedule title="카테고리 선택" />
      </Col>
      <Col md>
        <SelectSet disabled={!selOption.isSet} />
      </Col>
      <Col md>
        <p className="Form-label"> 단어 </p>
        <SelectTextForm objectKey="eng" placeholder="단어" />
      </Col>
      <Col md>
        <p className="Form-label"> 해석 </p>
        <SelectTextForm objectKey="kor" placeholder="해석" />
      </Col>
    </Row>
  );
};

export default DisplayWordProperty;
