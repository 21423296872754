import styled from "@emotion/styled";

export const Container = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`