import { useEffect } from "react";
import styled from "styled-components";
import { GrowingTheme } from "../styles/styleProperties";

const Countdown = ({ countdownNumber, setCountdownNumber }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (countdownNumber === 0) {
        clearInterval(timer);
        return;
      }
      setCountdownNumber((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  });

  return (
    <Styles.Container>
      <Styles.Spinner>
        <Styles.Number children={countdownNumber} />
      </Styles.Spinner>
    </Styles.Container>
  );
};

const Styles = {
  Container: styled.div`
    width: 100%;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Spinner: styled.div`
    width: 30vh;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: ${GrowingTheme.color.primary};
    border-bottom-color: ${GrowingTheme.color.primary};
    animation: spin 1s ease-in-out infinite;
    animation-iteration-count: 3;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `,
  Number: styled.h1`
    margin: 0;
    font-size: 20vh;
    color: ${GrowingTheme.color.primary};
  `,
};

export default Countdown;
