const GET_BPM = "GET_BPM";

export const getBPMData = (payload) => ({
  type: GET_BPM,
  payload,
});

const bpmReducer = (state = [], action) => {
  switch (action.type) {
    case GET_BPM:
      return action.payload;
    default:
      return state;
  }
};

export default bpmReducer;
