import * as displayType from "./types";

// 초기화
export const displayDataClear = () => ({
  type: displayType.DISPLAY_CLEAR,
});
// 기본 Row Setting
export const displayDataSet = (payload) => ({
  type: displayType.DISPLAY_SET,
  payload,
});
// office
export const displayDataOffice = (payload) => ({
  type: displayType.DISPLAY_OFFICE,
  payload,
});
// subject
export const displayDataSubject = (payload) => ({
  type: displayType.DISPLAY_SUBJECT,
  payload,
});
// scid, scname
export const displayDataSchedule = (payload) => ({
  type: displayType.DISPLAY_SCHEDULE,
  payload,
});
// part
export const displayDataPart = (payload) => ({
  type: displayType.DISPLAY_PART,
  payload,
});
// class
export const displayDataClass = (payload) => ({
  type: displayType.DISPLAY_CLASS,
  payload,
});
// date
export const displayDataDate = (payload) => ({
  type: displayType.DISPLAY_DATE,
  payload,
});
// name
export const displayDataName = (payload) => ({
  type: displayType.DISPLAY_NAME,
  payload,
});
// age
export const displayDataAge = (payload) => ({
  type: displayType.DISPLAY_AGE,
  payload,
});
// school
export const displayDataSchool = (payload) => ({
  type: displayType.DISPLAY_SCHOOL,
  payload,
});
// phone
export const displayDataPhone = (payload) => ({
  type: displayType.DISPLAY_PHONE,
  payload,
});
// registrationDate
export const displayDataRegistrationDate = (payload) => ({
  type: displayType.DISPLAY_DATE_REGI,
  payload,
});

// Schedule
// Schedule Row CLEAR
export const displayDataScheduleClear = () => ({
  type: displayType.DISPLAY_SCHEDULE_CLEAR,
});
// Schedule Row Setting
export const displayDataScheduleSet = (payload) => ({
  type: displayType.DISPLAY_SCHEDULE_SET,
  payload,
});

// Board
// Board Row CLEAR
export const displayDataBoardClear = () => ({
  type: displayType.DISPLAY_BOARD_CLEAR,
});
// Board Row Setting
export const displayDataBoardSet = (payload) => ({
  type: displayType.DISPLAY_BOARD_SET,
  payload,
});

// StudentList
// StudentList Row Clear
// StudentList Row CLEAR
export const displayDataStudentListClear = () => ({
  type: displayType.DISPLAY_STUDENTLIST_CLEAR,
});
