import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "react-bootstrap";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import {
  modalShowAdd,
  modalShowDisplay,
} from "../../../redux/Modal_Show/actions";

const AddQuizButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // loading reducer
  const loading = useSelector((state) => state.loadingReducer).component;
  // data load 시 reducer
  const loadData = useSelector((state) => state.loadingReducer).alert;

  const onClick = () => {
    dispatch(modalShowDisplay(true));
    dispatch(modalShowAdd(true));
  };
  return (
    <Row className="g-4 mb-5 px-1 mx-4">
      <Button variant="warning" onClick={onClick} disabled={loading}>
        {loadData || loading ? <LoadingSpinner /> : "문제 추가"}
      </Button>
    </Row>
  );
};

export default AddQuizButton;
