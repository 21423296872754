import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { keyQuestionOption } from "../../../../redux/QuestionOption/reducer";

const ChangeQID = () => {
  // redux dispatch
  const dispatch = useDispatch();

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const regQid = /^[A-Z]{2}[0-9]{4}$/;

  const changeQID = (e) => dispatch(keyQuestionOption("qid", e.target.value));

  return (
    <>
      <h4>QID</h4>
      <Form.Control
        className="App"
        type="text"
        value={questionOption.qid}
        onChange={(e) => changeQID(e)}
      />
      {!regQid.test(questionOption.qid) && (
        <RequiredText text="영어 대문자 2자리 + 숫자 4자리 조합으로 구성해주세요." />
      )}
    </>
  );
};

export default ChangeQID;
