/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PassageDiv from "../../Component/Test/Reading/PassageDiv";
import SelectPassage from "../../Component/Test/Reading/SelectPassage";
import SentenceDiv from "../../Component/Test/Reading/SentenceDiv";
import useRedirect from "../../hooks/useRedirect";
import { displayDataClear } from "../../redux/DisplayData/actions";
import { selOptionClear } from "../../redux/selOption/actions";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const Reading = ({ pageType }) => {
  // redux dispatch
  const dispatch = useDispatch();

  useRedirect(pageType === "student" ? "learningcenter" : "teachingcenter", "home");

  useEffect(() => {
    dispatch(displayDataClear());
    dispatch(selOptionClear("Reading"));
  }, []);

  return (
    <BasicLayout headerType={pageType}>
      <S.Container>
        <S.ContentContainer>
          <SelectPassage />
          <Card>
            <ListGroup className="list-group-flush">
              <SentenceDiv />
              <Card.Header>
                <strong>Passage</strong>
              </Card.Header>
              <PassageDiv />
            </ListGroup>
          </Card>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default Reading;
