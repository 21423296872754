import { useEffect, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { signup } from "api/authApi";
import usePageType from "hooks/usePageType";
import { PageType } from "types";
import useLoadingIndicator from "hooks/useLoadingIndicator";
import { Button } from "components/atoms";
import { InputText } from "components/blocks/forms";
import useGoToMainPage from "hooks/useGoToMainPage";
import BasicLayout from "components/templates/basic-layout";
import logo from "../../assets/images/growing_symbol_sm.png";

import * as S from "./SignupPage.styled";
import { testEmail } from "utils/regExp";

interface SignUpPageProps {
  pageType: PageType;
}

type RefType = "email" | "password" | "name" | "phone";

const HELPER_TEXT = {
  EMAIL: "올바른 이메일 형식을 입력해주세요.",
  PASSWORD: "6자리 이상의 비밀번호를 입력해주세요.",
  PHONE: "숫자 11자리를 입력해주세요.",
};

const defaultValues = {
  email: "",
  password: "",
  name: "",
  phone: "",
};

const SignUpPage = ({ pageType }: SignUpPageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState(defaultValues);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  const isErrorValue = {
    email: !testEmail(values.email),
    password: values.password.length < 6,
    phone: values.phone.length !== 11,
  };

  // firebase 로그인 정보 reducer
  // TODO: zustand로 변경
  const currentUser = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo.uid;

  const handleChange = (refType: RefType) => {
    const ref = () => {
      switch (refType) {
        case "email":
          return emailRef;
        case "password":
          return passwordRef;
        case "name":
          return nameRef;
        case "phone":
        default:
          return phoneRef;
      }
    };
    const refCurrent = ref()?.current;
    if (!refCurrent) {
      return;
    }

    const { value } = refCurrent;

    setValues((prev) => ({
      ...prev,
      [refType]: value,
    }));
  };

  const handleDelete = (refType: RefType) => {
    setValues((prev) => ({
      ...prev,
      [refType]: "",
    }));
  };

  // 일반 이메일 회원가입 function
  const handleSignup = async () => {
    setIsLoading(true);
    // firebase signup
    if (!emailRef?.current || !passwordRef?.current || !nameRef?.current || !phoneRef?.current) {
      return;
    }
    await signup({
      email: emailRef.current.value,
      password: passwordRef.current.value,
      name: nameRef.current.value,
      phone: phoneRef.current.value,
      pageType,
    });
    setIsLoading(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSignup();
  };

  usePageType("");

  useLoadingIndicator(isLoading);

  useGoToMainPage(pageType);

  useEffect(() => {
    if (!emailRef.current) {
      return;
    }

    emailRef.current.focus();
  }, []);

  return (
    <BasicLayout>
      <S.Container>
        <S.ContentContainer>
          <S.TitleSection>
            <S.Logo src={logo} />
            <S.Title>회원가입</S.Title>
          </S.TitleSection>
          <S.LoginForm onSubmit={handleSubmit}>
            <InputText
              label="이메일"
              type="email"
              ref={emailRef}
              value={values.email}
              onChange={() => handleChange("email")}
              onDelete={() => handleDelete("email")}
              tabIndex={1}
              errorText={HELPER_TEXT.EMAIL}
              isError={!!values.email.length && isErrorValue.email}
            />
            <InputText
              label="비밀번호"
              type="password"
              ref={passwordRef}
              value={values.password}
              onChange={() => handleChange("password")}
              onDelete={() => handleDelete("password")}
              autoComplete="on"
              tabIndex={2}
              helperText={HELPER_TEXT.PASSWORD}
              errorText={HELPER_TEXT.PASSWORD}
              isError={!!values.password.length && isErrorValue.password}
            />
            <InputText
              label="이름"
              type="text"
              ref={nameRef}
              value={values.name}
              onChange={() => handleChange("name")}
              onDelete={() => handleDelete("name")}
              autoComplete="on"
              tabIndex={3}
            />
            <InputText
              label="전화번호"
              type="text"
              ref={phoneRef}
              value={values.phone}
              onChange={() => handleChange("phone")}
              onDelete={() => handleDelete("phone")}
              autoComplete="on"
              tabIndex={4}
              helperText={HELPER_TEXT.PHONE}
              errorText={HELPER_TEXT.PHONE}
              isError={!!values.phone.length && isErrorValue.phone}
            />
            <Button disabled={currentUser} type="submit">
              회원가입
            </Button>
          </S.LoginForm>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default SignUpPage;
