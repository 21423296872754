import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setWordArray } from "../../../redux/FirebaseData/Word/reducer";
import { loadingActive } from "../../../redux/Loading/actions";

const LoadWordButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const inputData = useSelector((state) => state.wordOptionReducer).inputData;
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const isNull =
    inputData.length === 0 ||
    inputData
      .map((row) =>
        row.category === null ||
        row.set === null ||
        row.set > row.maxset ||
        row.set < row.minset ||
        row.start === null ||
        row.end === null ||
        row.start > 25 ||
        row.start < 1 ||
        row.end > 25 ||
        row.end < 1 ||
        row.start > row.end
          ? true
          : false
      )
      .some((e) => e === true);

  const setGameWord = () => {
    dispatch(setWordArray(inputData));
    dispatch(loadingActive(true));
  };

  return (
    <>
      {!active && (
        <Button disabled={isNull} onClick={setGameWord} variant="dark">
          단어 불러오기
        </Button>
      )}
    </>
  );
};

export default LoadWordButton;
