/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useDispatch } from "react-redux";
import { selOptionKey } from "../../../redux/selOption/actions";
import { weekNumberByMonth } from "../../../lib/utils/date";

// select option의 start, week를 변경하기 위한 Select 컴포넌트
const SelectDateRange = (props) => {
  // props
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  const dateDay = new Date().getDay();

  // dateArray
  const dateArray = [
    new Date(
      new Date().setDate(
        new Date().getDate() - dateDay + (dateDay === 0 ? -6 : 1)
      )
    ),
    new Date(
      new Date().setDate(
        new Date().getDate() - 7 - dateDay + (dateDay === 0 ? -6 : 1)
      )
    ),
    new Date(
      new Date().setDate(
        new Date().getDate() - 14 - dateDay + (dateDay === 0 ? -6 : 1)
      )
    ),
    new Date(
      new Date().setDate(
        new Date().getDate() - 21 - dateDay + (dateDay === 0 ? -6 : 1)
      )
    ),
  ];

  const weekArray = [
    [weekNumberByMonth(dateArray[0])],
    [weekNumberByMonth(dateArray[0]), weekNumberByMonth(dateArray[1])],
    [
      weekNumberByMonth(dateArray[0]),
      weekNumberByMonth(dateArray[1]),
      weekNumberByMonth(dateArray[2]),
    ],
    [
      weekNumberByMonth(dateArray[0]),
      weekNumberByMonth(dateArray[1]),
      weekNumberByMonth(dateArray[2]),
      weekNumberByMonth(dateArray[3]),
    ],
  ];

  // dateRange
  const dateRange = [
    { name: "이번주", start: dateArray[0], week: weekArray[0] },
    { name: "최근 2주", start: dateArray[1], week: weekArray[1] },
    { name: "최근 3주", start: dateArray[2], week: weekArray[2] },
    { name: "최근 4주", start: dateArray[3], week: weekArray[3] },
  ];

  // onChange 함수
  // select option reducer의 part 변경 함수
  const changeStartWeek = (e) => {
    dispatch(selOptionKey("start", e.start));
    dispatch(selOptionKey("week", e.week));
  };

  return (
    <>
      <p className="Form-label"> 기간 설정 </p>
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="기간 설정"
        isSearchable
        options={dateRange}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        defaultValue={dateRange[0]}
        isDisabled={disabled}
        maxMenuHeight={150}
        onChange={(e) => changeStartWeek(e)}
      />
    </>
  );
};

export default SelectDateRange;
