import CardCompetition from "./CardCompetition.js/CardCompetition";
import CardTest from "./CardTest.js/CardTest";

const DisplayStatCard = () => {
  return (
    <>
      <CardCompetition />
      <CardTest />
    </>
  );
};

export default DisplayStatCard;
