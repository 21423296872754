import styled from "@emotion/styled";
import { Colors } from "styles/colors";
import { H2 } from "styles/typography";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;

  justify-content: center;
  align-items: center;

  position: fixed;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1024px;
  flex-direction: column;

  padding: 40px 24px;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 12px;

  margin-bottom: 42px;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export const Title = styled.span`
  ${H2};
  color: ${Colors.black};

  white-space: pre-wrap;
  text-align: left;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  gap: 24px;

  margin-bottom: 20px;

  button {
    margin-top: 12px;
  }
`;
