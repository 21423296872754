import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { checkTodoAPI } from "../api/todoApi";
import { checkTodo } from "../redux/FirebaseData/Todo/studentTodoReducer";
import * as CardStyles from "../styles/CardStyles";

const TodoRow = ({ row, i, admin }) => {
  const dispatch = useDispatch();
  const checkThisTodo = (id) => {
    if (admin) return;
    checkTodoAPI(id, !row.check);
    dispatch(checkTodo(id));
  };

  return (
    <CardStyles.CardRow i={i} key={row.id} isCheck={row.check} admin={!!admin}>
      <div onClick={() => checkThisTodo(row.id)}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <div>
        <p>{row.title}</p>
      </div>
      <div>{row.deadline.slice(5)}</div>
      <div>{row.teacherName}</div>
    </CardStyles.CardRow>
  );
};

export default TodoRow;
