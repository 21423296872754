import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { wordOptionCreate } from "../../../redux/wordOption/actions";

const CreateButton = () => {
  // redux dispatch
  const dispatch = useDispatch();
  // reducers
  // 스케쥴 라벨 리스트 reducer
  const scheduleList = useSelector((state) => state.scheduleListReducer);

  const inputData = useSelector((state) => state.wordOptionReducer).inputData;
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const createData = () => dispatch(wordOptionCreate(scheduleList));

  return (
    <Button onClick={createData} disabled={active || inputData.length >= 20}>
      +
    </Button>
  );
};

export default CreateButton;
