import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useStudentList } from "../../firestoreData";
import { RequiredText } from "../../Methods";
import { selOptionKey } from "../../redux/selOption/actions";
import gradeCalc from "../../lib/utils/gradeCalc";

const SelectExistGrade = (props) => {
  // props
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  const growingGrade = useStudentList()
    .map(function (val, index) {
      return val["age"];
    })
    .sort((a, b) => a - b)
    .filter(function (val, index, arr) {
      return arr.indexOf(val) === index;
    })
    .map((row) => ({ grade: gradeCalc(row) }));

  // require message 출력 조건, props로 required가 넘어오고, selOption의 grade가 null일 경우
  const returnRequire = required && !selOption.grade;

  // onClick 함수
  const changeGrade = (e) => {
    const returnValue = e || { grade: null };
    dispatch(selOptionKey("grade", returnValue.grade));
  };

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="학년"
        isSearchable
        isClearable={clearable}
        isDisabled={disabled}
        value={selOption.grade ? selOption : null}
        options={growingGrade}
        getOptionLabel={(e) => e.grade}
        getOptionValue={(e) => e.grade}
        maxMenuHeight={150}
        onChange={changeGrade}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectExistGrade;
