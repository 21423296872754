import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText, splitPage } from "../../../../Methods";
import { wordOptionSet } from "../../../../redux/wordOption/actions";

const ChangeSet = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const changeSet = (e) => {
    const value = e.target.value.replace(/[^0-9|\-|,]/g, "");
    dispatch(wordOptionSet(row.id, value));
  };

  const setArr = splitPage(row.set);

  const isValid = () => {
    const returnValid =
      [...setArr.map((obj) => obj > row.maxset || obj < row.minset)].indexOf(
        true
      ) !== -1;
    return returnValid || setArr.length === 0;
  };

  return (
    <td>
      <Form.Control
        style={{ textAlign: "center" }}
        type="text"
        onChange={changeSet}
        placeholder="회차"
        disabled={active}
        value={row.set || ""}
      />
      {isValid() && (
        <RequiredText
          text={row.minset + "~" + row.maxset + " 사이의 범위를 입력하세요."}
        />
      )}
    </td>
  );
};

export default ChangeSet;
