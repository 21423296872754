import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowClear } from "../../redux/Modal_Show/actions";
import DisplayChangeAge from "../DisplayChange/DisplayChangeAge";
import DisplayChangeName from "../DisplayChange/DisplayChangeName";
import DisplayChangePhone from "../DisplayChange/DisplayChangePhone";
import DisplayChangeRegistrationDate from "../DisplayChange/DisplayChangeRegistrationDate";
import DisplayChangeSchool from "../DisplayChange/DisplayChangeSchool";
import UpdateButton from "./UpdateButton";

const StudentListModal = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;
  // modal show reducer
  const show = useSelector((state) => state.modalShowReducer).display;

  // modal 닫기
  const handleClose = () => dispatch(modalShowClear());

  return (
    <>
      {myInfo.admin && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>수정</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <DisplayChangeName />
              <DisplayChangeAge />
              <DisplayChangeSchool />
              <DisplayChangePhone />
              <DisplayChangeRegistrationDate />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <UpdateButton />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default StudentListModal;
