const GET_ATTEND = "GET_ATTEND";

export const getAttendData = (payload) => ({
  type: GET_ATTEND,
  payload,
});

const attendReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ATTEND:
      return action.payload;
    default:
      return state;
  }
};

export default attendReducer;
