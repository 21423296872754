import db from "../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";
import { inputDataClear } from "../../../redux/InputData/actions";
import { loadingComponent } from "../../../redux/Loading/actions";

// inputData를 업로드 하기 위한 버튼 컴포넌트
const UploadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // 버튼 비활성화 조건, inputData 내 null 값이 하나라도 존재 시 비활성화
  const isNull = inputData
    .map((row) =>
      row.date === null || row.scid === null || row.part === null ? true : false
    )
    .some((e) => e === true);

  // onClick 함수
  // inputData를 스케쥴로 업로드
  const dataUpload = () => {
    dispatch(loadingComponent(true));
    inputData.forEach((row) => {
      addDoc(collection(db, "Schedule", myInfo.office, "Plan"), {
        date: row.date,
        scid: row.scid,
        scname: row.scname,
        part: row.part,
        cid: selOption.cid,
        subject: selOption.subject,
        office: myInfo.office,
      });
    });
    setTimeout(() => {
      dispatch(modalShowPopup(false));
      dispatch(loadingComponent(false));
      dispatch(inputDataClear());
    }, 1000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  return (
    <>
      {inputData.length !== 0 && (
        <Button disabled={isNull} onClick={openPopup}>
          스케쥴 업로드
        </Button>
      )}
    </>
  );
};

export default UploadButton;
