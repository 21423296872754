import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearWordArray } from "../../../redux/FirebaseData/Word/reducer";
import { loadingFalse } from "../../../redux/Loading/actions";
import { wordOptionInputClear } from "../../../redux/wordOption/actions";

const ClearButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // Reducer
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  const setGameClear = () => {
    dispatch(wordOptionInputClear());
    dispatch(loadingFalse());
    dispatch(clearWordArray());
  };

  return (
    <>
      {active && (
        <Button onClick={setGameClear} variant="warning">
          초기화
        </Button>
      )}
    </>
  );
};

export default ClearButton;
