import useLoadingIndicatorStore from "stores/useLoadingIndicatorStore";
import loadingLottie from "../../../assets/lottie/loading.json";

import * as S from "./LoadingIndicator.styled";

export default function LoadingIndicator() {
  const { isShow } = useLoadingIndicatorStore();

  if (!isShow) {
    return null;
  }
  return (
    <S.Container>
      <S.Indicator animationData={loadingLottie} loop autoPlay />
    </S.Container>
  );
}
