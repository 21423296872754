import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../../../Methods";
import { displayDataDate } from "../../../../redux/DisplayData/actions";

// 날짜를 한국어로
registerLocale("ko", ko);

// Display될 스케쥴의 날짜를 선택하는 Select 컴포넌트
const SelectDisplayDate = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // Modal 창에 Display될 스케쥴 reducer
  const displaySchedule = useSelector((state) => state.displayDataReducer);

  // onChange 함수
  // displaySchedule의 date 변경
  const changeDate = (e) => dispatch(displayDataDate(e));

  // 기본값
  const defaultValue = displaySchedule.date ? displaySchedule.date : new Date();

  return (
    <>
      <p className="Form-label"> 날짜 </p>
      <DatePicker
        dateFormat="yyyy.MM.dd"
        todayButton="today"
        selected={defaultValue}
        onChange={(e) => changeDate(e)}
        locale={ko}
        className="Date-picker-border"
      />
      {!displaySchedule.date && <RequiredText />}
    </>
  );
};

export default SelectDisplayDate;
