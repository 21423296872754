import { call, put, takeEvery } from "redux-saga/effects";
import { getOffice } from "../../../api/officeApi";

const GET_OFFICE = "GET_OFFICE";
const GET_OFFICE_SUCCESS = "GET_OFFICE_SUCCESS";
const GET_OFFICE_ERROR = "GET_OFFICE_ERROR";

export const getOfficeData = () => ({ type: GET_OFFICE });

function* getOfficeDataSaga() {
  try {
    const payload = yield call(getOffice);
    yield put({
      type: GET_OFFICE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_OFFICE_ERROR,
      error: true,
      e,
    });
  }
}

export function* officeSaga() {
  yield takeEvery(GET_OFFICE, getOfficeDataSaga);
}

const officeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_OFFICE:
      return state;
    case GET_OFFICE_SUCCESS:
      return action.payload;
    case GET_OFFICE_ERROR:
      return state;
    default:
      return state;
  }
};

export default officeReducer;
