import {
  SET_LEAGUE_ACTIVE,
  SET_LEAGUE_CLEAR,
  SET_LEAGUE_MINUS,
  SET_LEAGUE_PLAYER,
  SET_LEAGUE_PLAYER_ADD,
  SET_LEAGUE_PLAYER_REMOVE,
  SET_LEAGUE_ROUND,
  SET_LEAGUE_REMOVE,
} from "./types";

//
export const setLeaguePlayer = (payload, league) => ({
  type: SET_LEAGUE_PLAYER,
  league,
  player: payload,
});

export const setLeaguePlayerAdd = (league) => ({
  type: SET_LEAGUE_PLAYER_ADD,
  league,
});

export const setLeaguePlayerRemove = (league, payload) => ({
  type: SET_LEAGUE_PLAYER_REMOVE,
  league,
  payload: payload ? payload : 1,
});

export const setLeagueActive = (league) => ({
  type: SET_LEAGUE_ACTIVE,
  league,
});

export const setLeagueMinus = (league) => ({
  type: SET_LEAGUE_MINUS,
  league,
});

export const setLeagueRound = (league) => ({
  type: SET_LEAGUE_ROUND,
  league,
});

export const setLeagueRemove = (league) => ({
  type: SET_LEAGUE_REMOVE,
  league,
});

export const setLeagueClear = () => ({
  type: SET_LEAGUE_CLEAR,
});
