import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showWord } from "../../../redux/FirebaseData/Word/reducer";

const DisplayAnswer = () => {
  // redux dispatch
  const dispatch = useDispatch();
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;

  // reducers
  // word reducer
  /**
   * 게임에 사용될 단어 Object
   */
  const randomWord = useSelector((state) => state.wordReducer).randomWord;
  /**
   * 0: 한국어, 1: 영어
   * @type {0|1}
   */
  const nu = useSelector((state) => state.wordReducer).nu;
  /**
   * true: 정답 공개 / false: 정답 비공개
   * @type {boolean}
   */
  const show = useSelector((state) => state.wordReducer).show;

  /**
   * 정답 공개/비공개 함수
   */
  const showAnswer = () => active && dispatch(showWord());

  return (
    <Col className="display-answer" onClick={showAnswer}>
      <div>
        <h3
          className="center-wrap2"
          style={{ fontSize: "3vh", fontWeight: show ? "bold" : "normal" }}
        >
          {show
            ? nu === 0
              ? randomWord.kor
              : randomWord.eng
            : "클릭 시 정답 공개"}
        </h3>
      </div>
    </Col>
  );
};

export default DisplayAnswer;
