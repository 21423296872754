import { leagueLable } from "./function";

// 컴퍼티션 행 추가 시 사용할 기본값
export const newRowCompetition = (state, payload) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  sid: null,
  name: null,
  rank: state.length + 1,
  average: 0,
  quizRank: 0,
  finalRank: 0,
  realRank: 0,
  league: leagueLable(state.length + 1, payload),
  newPlayer: false,
});

// 스케쥴 행 추가 시 사용할 기본값
export const newRowSchedule = (state) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  date: new Date(),
  scid: null,
  scname: null,
  part: null,
});

// 스케쥴 수정 페이지에서 행 추가 시 사용할 기본값
export const newRowScheduleUdt = (state, payload) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  date: new Date(),
  scid: "",
  scname: "",
  part: "",
  cid: payload.cid,
  office: payload.office,
  subject: payload.subject,
  add: true,
  modify: true,
  delete: false,
});

// Test 행 추가 시 사용할 기본값
export const newRowTest = (state) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  sid: null,
  name: null,
  score: 0,
});

// Carousel 행 추가 시 사용할 기본값
export const newRowCarousel = (state, payload) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  number: state.length + 1,
  title: "",
  content: "",
  teacher: payload.teacher,
  office: payload.office,
  add: true,
  modify: true,
  delete: false,
});

// Carousel 행 추가 시 사용할 기본값
export const newRowTodo = (state) => ({
  id: state[0] === undefined ? 0 : state[state.length - 1].id + 1,
  sid: null,
  name: null,
});

// 행 삭제 시 사용할 기본값
export const deleteRowDefault = (row, i) => ({
  ...row,
  id: i,
});

// Competition 행 삭제 시 사용할 기본값
export const deleteRowCompetition = ({ state, payload }) => {
  return state.map((row, i) => ({
    ...row,
    id: i,
    realRank: i + 1,
    league: leagueLable(i + 1, payload),
  }));
};

// 데이터 수정 후 inputdata 배열의 수정/삭제 여부 초기화, original 업데이트
export const modifyArrayDefault = (arr) => {
  // original 업데이트
  const newOriginal = (obj) => {
    const original = { ...obj };
    delete original.original;
    return original;
  };

  // inputdata 배열의 수정/삭제 여부 초기화, original 업데이트
  const returnArray = arr
    .filter((row) => !row.delete)
    .map((obj) =>
      obj.modify
        ? {
            ...obj,
            modify: false,
            delete: false,
            original: newOriginal(obj),
          }
        : obj,
    );

  return returnArray;
};

// 데이터 수정 후 inputdata 배열의 수정/삭제 여부 초기화, original 업데이트
export const modifyArrayCompetition = (arr) => {
  // original 업데이트
  const newOriginal = (obj) => {
    const original = { ...obj };
    delete original.original;
    return original;
  };

  // inputdata 배열의 수정/삭제 여부 초기화, original 업데이트
  const returnArray = arr
    .filter((row) => !row.delete)
    .map((obj) =>
      obj.modify
        ? {
            ...obj,
            modify: false,
            delete: false,
            original: newOriginal(obj),
          }
        : obj,
    );

  // 날짜 내림차순, 순위 오름차순으로 정렬
  return returnArray.sort((a, b) => {
    if (a.date > b.date) return -1;
    else if (a.date < b.date) return 1;
    else if (a.rank > b.rank) return 1;
    else if (a.rank < b.rank) return -1;
    else return 0;
  });
};

// 순위권 리턴
export const returnPodium = (payload) => {
  switch (payload) {
    case "1":
      return { first: false, second: true };
    case "2":
      return { first: false, second: false };
    default:
      return { first: true, second: true };
  }
};

// competition 테이블 평균으로 정렬
export const sortAvgCompetition = (state, payload) =>
  state
    .sort((a, b) => {
      if (a.average === 0) return 100;
      else if (b.average === 0) return -100;
      else return a.average - b.average;
    })
    .map((row, i) => ({
      ...row,
      id: i,
      realRank: i + 1,
      league: leagueLable(i + 1, payload),
    }));

// quiz 순위로 정렬
export const sortQuizCompetition = (state, payload) =>
  state
    .sort((a, b) => {
      return a.quiz - b.quiz;
    })
    .map((row, i) => ({
      ...row,
      id: i,
      realRank: i + 1,
      league: leagueLable(i + 1, payload),
    }));

// competition 테이블 순위로 정렬
export const sortRankCompetition = (state, payload) => {
  return state
    .sort((a, b) => {
      if (a.finalRank === 0) return 100;
      if (b.finalRank === 0) return -100;
      return a.finalRank - b.finalRank;
    })
    .map((row, i) => ({
      ...row,
      id: i,
      realRank: i + 1,
      league: leagueLable(i + 1, payload),
    }));
};

// competition 테이블 새로운 플레이어는 평균으로, 기존 플레이어는 순위로 정렬
export const sortNPCompetition = (state, payload) =>
  state
    .sort((a, b) => {
      const aScore = a.newPlayer ? a.average : a.finalRank;
      const bScore = b.newPlayer ? b.average : b.finalRank;
      if (aScore === 0) return 100;
      else if (bScore === 0) return -100;
      else return aScore - bScore;
    })
    .map((row, i) => ({
      ...row,
      id: i,
      realRank: i + 1,
      league: leagueLable(i + 1, payload),
    }));

export const changeRankArray = (state, id, rank, league) => {
  return state.map((row) => {
    if (row.id === id) {
      return {
        ...row,
        realRank: rank,
        league: leagueLable(rank, league),
      };
    } else return { ...row, league: leagueLable(row.rank, league) };
  });
};
