import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowClear } from "../../../redux/Modal_Show/actions";
import { selOptionClear } from "../../../redux/selOption/actions";
import SelectTextForm from "../../Select/SelectTextForm";
import SavePresetButton from "./SavePresetButton";

const SavePresetModal = () => {
  const dispatch = useDispatch();
  // Modal 창을 보여주는 조건 reducer
  const show = useSelector((state) => state.modalShowReducer);

  const onHide = () => {
    dispatch(selOptionClear("PresetStudent"));
    dispatch(modalShowClear());
  };

  return (
    <Modal show={show.display} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>설정 저장</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <SelectTextForm
            title="제목"
            objectKey="title"
            placeholder="제목"
            required
          />
          <SelectTextForm title="설명" objectKey="comment" placeholder="설명" />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SavePresetButton />
      </Modal.Footer>
    </Modal>
  );
};

export default SavePresetModal;
