import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

const PrintButton = (props) => {
  // props
  // print할 컨텐츠
  const printRef = props.printRef;

  // state
  // quiz Input Data reducer
  const quizInputData = useSelector((state) => state.quizInputDataReducer);
  // quiz Input Data reducer
  const qIDData = useSelector((state) => state.qIDDataReducer);

  const trigger = () => (
    <Button
      disabled={quizInputData.length === 0}
      className="mt-3 Growing-button"
    >
      프린트 하기
    </Button>
  );

  const content = () => printRef.current;

  return (
    <>
      {qIDData.length !== 0 && (
        <ReactToPrint trigger={trigger} content={content} />
      )}
    </>
  );
};

export default PrintButton;
