import { Col, Row } from "react-bootstrap";
import SelectDate from "../../Select/SelectDate";
import CompeleteButton from "./CompeleteButton";
import NameChange from "./NameChange";
import SelectFile from "./SelectFile";
import SelectQuiz from "./SelectQuiz";

const OptionAccordion = (props) => {
  const fileRef = props.fileRef;

  return (
    <div className="m-3 opt-area">
      <Row className="g-4 p-3">
        <Col>
          <SelectQuiz />
        </Col>
        <Col>
          <SelectDate title="날짜" required />
        </Col>
      </Row>
      <Row className="g-4 mb-3 p-3">
        <Col>
          <SelectFile fileRef={fileRef} />
        </Col>
        <NameChange />
      </Row>
      <Row className="g-4 mb-3 mx-3">
        <Col>
          <CompeleteButton />
        </Col>
      </Row>
    </div>
  );
};

export default OptionAccordion;
