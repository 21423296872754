import db from "./firebase";
import "./App.css";
import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { createUserInfo, getUserInfo, updateUserInfo } from "./api/usersApi";

export const MoveUserDatas = async (uID, changeUID) => {
  const originalSnapshot = await getUserInfo(uID);
  const changeSnapshot = await getUserInfo(changeUID);
  if (!(originalSnapshot.id && changeSnapshot.id)) {
    alert("정확한 uid를 입력해주세요.");
    return;
  }
  const originalData = () => {
    const { data, id } = originalSnapshot;
    return data;
  };
  const changeData = () => {
    const { data, id } = changeSnapshot;
    return data;
  };

  await createUserInfo(changeUID, {
    ...originalData(),
    email: changeData().email,
  });
  await updateUserInfo(uID, { allow: false, state: false });
};

export const MoveTestScore = async (uID, changeUID) => {
  const original = query(collection(db, "TestScore"), where("sid", "==", uID));
  const querySnapshot = await getDocs(original);

  querySnapshot.forEach((row) =>
    updateDoc(doc(db, "TestScore", row.id), {
      sid: changeUID,
    })
  );
};

export const MoveCompetitionScore = async (uID, changeUID) => {
  const original = query(collection(db, "Competition"), where("sid", "==", uID));
  const querySnapshot = await getDocs(original);

  querySnapshot.forEach(
    async (row) =>
      await updateDoc(doc(db, "Competition", row.id), {
        sid: changeUID,
      })
  );
};

export const MoveUserQuizStat = async (uID, changeUID) => {
  const originalQuizStat = query(collection(db, "Users", uID, "quizStat"));
  const quizQuerySnapshot = await getDocs(originalQuizStat);

  quizQuerySnapshot.forEach(async (row) => {
    await setDoc(doc(db, "Users", changeUID, "quizStat", row.id), row.data());
    const originalQIDStat = query(collection(db, "Users", uID, "quizStat", row.id, "qidStat"));
    const qidQuerySnapshot = await getDocs(originalQIDStat);
    qidQuerySnapshot.forEach(async (obj) => await setDoc(doc(db, "Users", changeUID, "quizStat", row.id, "qidStat", obj.id), obj.data()));
  });
};

export const MoveUserCompetitionStat = async (uID, changeUID) => {
  const original = query(collection(db, "Users", uID, "competitionStat"));
  const querySnapshot = await getDocs(original);

  querySnapshot.forEach(async (row) => {
    await setDoc(doc(db, "Users", changeUID, "competitionStat", row.id), row.data());
  });
};

export const MoveUserTestScoreStat = async (uID, changeUID) => {
  const original = query(collection(db, "Users", uID, "testScoreStat"));
  const querySnapshot = await getDocs(original);

  querySnapshot.forEach(async (row) => {
    await setDoc(doc(db, "Users", changeUID, "testScoreStat", row.id), row.data());
  });
};

export const MoveUserAttendStat = async (uID, changeUID) => {
  const original = query(collection(db, "Users", uID, "attend"));
  const querySnapshot = await getDocs(original);

  querySnapshot.forEach(async (row) => {
    await setDoc(doc(db, "Users", changeUID, "attend", row.id), {
      ...row.data(),
      sid: changeUID,
    });
  });
};

export const MoveStudentSetting = async (uID, changeUID) => {
  const teacher = query(collection(db, "Users"), where("teacher", "==", true));
  const teacherQuerySnapshot = await getDocs(teacher);
  teacherQuerySnapshot.forEach(async (teacher) => {
    const original = query(collection(db, "Users", teacher.id, "studentSetting"));
    const querySnapshot = await getDocs(original);
    querySnapshot.forEach((userSetting) => {
      if (
        userSetting
          .data()
          .studentList.map((student) => student.sid)
          .indexOf(uID) !== -1
      ) {
        updateDoc(doc(db, "Users", teacher.id, "studentSetting", userSetting.id), {
          studentList: userSetting.data().studentList.map((student) => (student.sid === uID ? { ...student, sid: changeUID } : student)),
        });
      }
    });
  });
};

export const DeleteWords = async (category) => {
  const querySnapshot = await getDocs(query(collection(db, "Words"), where("category", "==", category)));

  querySnapshot.forEach(async (d) => {
    await deleteDoc(doc(db, "Words", d.id));
  });
};
