import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalShowClear } from "../../../../redux/Modal_Show/actions";
import { clearQuestionOption } from "../../../../redux/QuestionOption/reducer";
import ChangeLabel from "./ChangeLabel";
import ChangeQID from "./ChangeQID";
import ChangeQuestion from "./ChangeQuestion";
import ControlButton from "./ControlButton";
import InputNumberAnswer from "./InputNumberAnswer";
import InputShortAnswer from "./InputShortAnswer";
import SelectQuestionType from "./SelectQuestionType";

const QuestionModal = (props) => {
  // props
  const isModify = props.modify;

  // redux dispatch
  const dispatch = useDispatch();

  const show = useSelector((state) => state.modalShowReducer).display;

  const questionOption = useSelector((state) => state.questionOptionReducer);

  const onHide = () => {
    dispatch(modalShowClear());
    dispatch(clearQuestionOption());
  };

  return (
    <Modal show={show} fullscreen onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Question</Modal.Title>
        <SelectQuestionType />
      </Modal.Header>
      <Modal.Body>
        <ChangeQID />
        <Form>
          <ChangeLabel />
          <ChangeQuestion />
          {questionOption.type !== "short" ? (
            <>
              <InputNumberAnswer number={1} />
              <InputNumberAnswer number={2} />
              <InputNumberAnswer number={3} />
              <InputNumberAnswer number={4} />
              <InputNumberAnswer number={5} />
            </>
          ) : (
            <InputShortAnswer />
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ControlButton isModify={isModify} />
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionModal;
