import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { call, put, takeEvery } from "redux-saga/effects";
import db from "../../../firebase";

const GET_PASSAGE = "GET_PASSAGE";
const GET_PASSAGE_SUCCESS = "GET_PASSAGE_SUCCESS";
const GET_PASSAGE_ERROR = "GET_PASSAGE_ERROR";
const SET_PASSAGE = "SET_PASSAGE";

const getDatas = async () => {
  let returnArray = [];
  const passageCollection = query(
    collection(db, "Passage"),
    orderBy("type", "asc"),
    orderBy("textbook", "asc"),
    orderBy("lesson", "asc")
  );
  const querySnapshot = await getDocs(passageCollection);
  querySnapshot.forEach(
    (doc) => (returnArray = [...returnArray, { ...doc.data(), id: doc.id }])
  );
  return returnArray;
};

export const getPassageData = () => ({
  type: GET_PASSAGE,
});

function* getPassageDataSaga() {
  try {
    const payload = yield call(getDatas);
    yield put({
      type: GET_PASSAGE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_PASSAGE_ERROR,
      error: true,
      e,
    });
  }
}

export const setPassage = (payload) => ({
  type: SET_PASSAGE,
  payload,
});

export function* passageSaga() {
  yield takeEvery(GET_PASSAGE, getPassageDataSaga);
}

const passageReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PASSAGE:
      return state;
    case GET_PASSAGE_SUCCESS:
      return action.payload;
    case GET_PASSAGE_ERROR:
      return state;
    case SET_PASSAGE:
      return action.payload;
    default:
      return state;
  }
};

export default passageReducer;
