import { Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

const DisplayScidPart = () => {
  const wordOption = useSelector((state) => state.wordOptionReducer);

  return (
    <Col md>
      <p className="Form-label"> Word </p>
      <InputGroup>
        <Form.Control
          style={{ textAlign: "center" }}
          type="text"
          readOnly
          value={wordOption.cid ? wordOption.scname : ""}
        />
        <Form.Control
          style={{ textAlign: "center" }}
          type="text"
          readOnly
          value={wordOption.cid ? wordOption.part : ""}
        />
      </InputGroup>
    </Col>
  );
};

export default DisplayScidPart;
