// 초기화
export const DISPLAY_CLEAR = "DISPLAY_CLEAR";
// 기본 Row Setting
export const DISPLAY_SET = "DISPLAY_SET";
// office
export const DISPLAY_OFFICE = "DISPLAY_OFFICE";
// subject
export const DISPLAY_SUBJECT = "DISPLAY_SUBJECT";
// scid, scname
export const DISPLAY_SCHEDULE = "DISPLAY_SCHEDULE";
// part
export const DISPLAY_PART = "DISPLAY_PART";
// class
export const DISPLAY_CLASS = "DISPLAY_CLASS";
// date
export const DISPLAY_DATE = "DISPLAY_DATE";
// name
export const DISPLAY_NAME = "DISPLAY_NAME";
// age
export const DISPLAY_AGE = "DISPLAY_AGE";
// school
export const DISPLAY_SCHOOL = "DISPLAY_SCHOOL";
// phone
export const DISPLAY_PHONE = "DISPLAY_PHONE";
// registrationDate
export const DISPLAY_DATE_REGI = "DISPLAY_DATE_REGI";

// Schedule
// Schedule Row Clear
export const DISPLAY_SCHEDULE_CLEAR = "DISPLAY_SCHEDULE_CLEAR";
// Schedule Row Setting
export const DISPLAY_SCHEDULE_SET = "DISPLAY_SCHEDULE_SET";

// Board
// Board Row Clear
export const DISPLAY_BOARD_CLEAR = "DISPLAY_BOARD_CLEAR";
// Board Row Setting
export const DISPLAY_BOARD_SET = "DISPLAY_BOARD_SET";

// StudentList
// StudentList Row Clear
export const DISPLAY_STUDENTLIST_CLEAR = "DISPLAY_STUDENTLIST_CLEAR";
