import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectClass from "../../Select/SelectClass";
import SelectSubject from "../../Select/SelectSubject";
import SelectYearMonth from "../../Select/SelectYearMonth";

// select option을 선택하기 위한 아코디언 컴포넌트
const OptionAccordion = () => {
  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  // 선택창 비활성화 조건, inputData 내에 데이터가 있으면 비활성화
  const selectDisabled = inputData.length !== 0;

  return (
    <div className="opt-area m-3">
      <Row className="p-3">
        <Col lg>
          <SelectClass title="반" required disabled={selectDisabled} />
        </Col>
        <Col lg>
          <SelectSubject
            title="과목"
            required
            competition
            disabled={selectDisabled}
          />
        </Col>
        <Col lg>
          <SelectYearMonth title="연/월" required disabled={selectDisabled} />
        </Col>
      </Row>
    </div>
  );
};

export default OptionAccordion;
