const GET_SCHEDULE = "GET_SCHEDULE";

export const getScheduleData = (payload) => ({
  type: GET_SCHEDULE,
  payload,
});

const scheduleReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SCHEDULE:
      return action.payload;
    default:
      return state;
  }
};

export default scheduleReducer;
