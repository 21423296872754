import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import db from "../../../../firebase";
import { setPassage } from "../../../../redux/FirebaseData/Passage/reducer";
import {
  loadingComponent,
  loadingFalse,
} from "../../../../redux/Loading/actions";
import { modalShowPopup } from "../../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../../redux/PopupModal/reducer";
import { selOptionKey } from "../../../../redux/selOption/actions";

const UploadPassageBtn = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // 본문 리스트 reducer
  const passageList = useSelector((state) => state.passageReducer);
  // select option 본문 리스트 reducer
  const selectPassageList =
    useSelector((state) => state.selOptionReducer).passageList || [];

  // 데이터 업로드 함수
  const dataUpload = () => {
    const modifyList = selectPassageList.filter(
      (row) => row.modify || row.delete
    );

    const returnSelectPassageList = [...selectPassageList]
      .filter((row) => !row.delete)
      .map((obj) => ({ ...obj, modify: false, delete: false }));

    const returnPassageList = [...passageList];

    const modifyPassage = (obj) => {
      updateDoc(doc(db, "Passage", obj.id), {
        type: obj.type,
        textbook: obj.textbook,
        lesson: obj.lesson,
        passage: obj.passage,
      });
    };
    const deletePassage = (obj) => deleteDoc(doc(db, "Passage", obj.id));

    dispatch(loadingComponent(true));
    modifyList.forEach(async (obj) => {
      const index = returnPassageList.findIndex((row) => row.id === obj.id);
      switch (true) {
        case obj.delete:
          returnPassageList.splice(index, 1);
          await deletePassage(obj);
          break;
        default:
          returnPassageList[index] = {
            type: obj.type,
            textbook: obj.textbook,
            lesson: obj.lesson,
            passage: obj.passage,
          };
          modifyPassage(obj);
          break;
      }
    });
    // console.log(returnSelectPassageList, returnPassageList);
    dispatch(
      selOptionKey("nowPassage", { type: "", textbook: "", lesson: "" })
    );
    dispatch(selOptionKey("passageList", returnSelectPassageList));
    dispatch(setPassage(returnPassageList));
    setTimeout(() => {
      dispatch(loadingFalse());
      dispatch(modalShowPopup(false));
    }, 1000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "수정하시겠습니까?", dataUpload, "수정"));
  };

  return (
    <>
      {selectPassageList.length !== 0 && (
        <Button
          onClick={openPopup}
          disabled={
            selectPassageList.filter((row) => row.modify || row.delete)
              .length === 0
          }
        >
          본문 수정 완료
        </Button>
      )}
    </>
  );
};

export default UploadPassageBtn;
