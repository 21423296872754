/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { wordOptionSchedule } from "../../../../redux/wordOption/actions";
import { useEffect } from "react";
import { getFireStoreData } from "../../../../redux/FirebaseData/DownloadController/reducer";

// inputData 내의 개별 행 스케쥴 변경을 위해 사용하는 Select 컴포넌트
const ChangeComSchedule = (props) => {
  // props
  // InputTable에서 넘어온 row props
  const row = props.row;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 스케쥴 라벨 리스트 reducer
  const scheduleList = useSelector((state) => state.scheduleListReducer);
  // loading reducer
  const active = useSelector((state) => state.loadingReducer).active;
  // download controler reducer
  const downloadList = useSelector((state) => state.downloadControlerReducer).scheduleList;

  // 기본값
  const defaultValue = row.scid ? { id: row.scid, name: row.category } : null;

  // 과목에 해당하는 스케쥴 라벨 리스트
  const subjectScheduleList = scheduleList.filter((res) => res.subject === "Competition" && res.id !== "MDC09");

  // onChange 함수
  // 해당 행의 scid, scname을 바꾸는 함수
  const changeSchedule = (row, e) => dispatch(wordOptionSchedule(row.id, e));

  useEffect(() => {
    // 스케쥴 리스트 데이터 다운로드 토글 true로
    !downloadList && dispatch(getFireStoreData("scheduleList"));
  }, []);

  return (
    <td>
      <Select
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isSearchable
        options={subjectScheduleList}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        value={defaultValue}
        onChange={(e) => changeSchedule(row, e)}
        placeholder="스케쥴"
        isDisabled={active}
      />
    </td>
  );
};

export default ChangeComSchedule;
