import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getPlayerNum, getQIDNum } from "./function";

// 학생 수와 퀴즈 수를 Display할 컴포넌트
const SummaryData = () => {
  // 서버에 등록될 데이터 컴포넌트
  const uploadData = useSelector((state) => state.quizUploadDataReducer);

  // uploadData 에서 학생의 중복을 제거한 Array
  const playerNum = getPlayerNum(uploadData);
  // uploadData 에서 퀴즈의 중복을 제거한 Array
  const qidNum = getQIDNum(uploadData);

  return (
    <Button variant="outline-dark" disabled>
      {/* 각 length를 이용하여 학생 및 퀴즈의 갯수 표시 */}
      {playerNum.length} Student(s), {qidNum.length} Quiz(zes)
    </Button>
  );
};

export default SummaryData;
