/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { selOptionKey } from "../../redux/selOption/actions";
import { RequiredText } from "../../Methods";

// select option의 지점을 변경하기 위한 Select 컴포넌트
const SelectOffice = (props) => {
  // props
  // clearable 가능 여부
  const clearable = props.clearable;
  // require message 출력 여부
  const required = props.required;
  // 제목 출력 여부
  const title = props.title;
  // 비활성화 조건
  const disabled = props.disabled;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 지점 목록 reducer
  const office = useSelector((state) => state.officeReducer);
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  // require message 출력 조건, props로 required가 넘어오고, selOption의 office가 null일 경우
  const returnRequire = required && !selOption.office;

  // onChange 함수
  // select option reducer의 office 변경 함수
  const changeOffice = (e) => {
    const returnValue = e ? e : { name: null };
    dispatch(selOptionKey("office", returnValue.name));
  };

  return (
    <>
      {title && <p className="Form-label"> {title} </p>}
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        placeholder="지점"
        isSearchable
        isClearable={clearable}
        options={office}
        value={selOption.office ? { name: selOption.office } : null}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        maxMenuHeight={150}
        onChange={changeOffice}
        isDisabled={disabled}
      />
      {returnRequire && <RequiredText />}
    </>
  );
};

export default SelectOffice;
