const SET_QIDDATA = "SET_QIDDATA";
const CLEAR_QIDDATA = "CLEAR_QIDDATA";

export const setQIDData = (payload) => ({
  type: SET_QIDDATA,
  payload,
});

export const clearQIDData = () => ({
  type: CLEAR_QIDDATA,
});

const qIDDataReducer = (state = [], action) => {
  switch (action.type) {
    case SET_QIDDATA:
      return action.payload;
    case CLEAR_QIDDATA:
      return [];
    default:
      return state;
  }
};

export default qIDDataReducer;
