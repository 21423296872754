import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { inputDataClear } from "../../../../redux/InputData/actions";
import { selOptionClear } from "../../../../redux/selOption/actions";
import LoadPassageBtn from "./LoadPassageBtn";
import UploadPassageBtn from "./UploadPassageBtn";

const ControlBtnGroup = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // option reducer clear
  const optionClear = () => {
    dispatch(selOptionClear("ReadingInput"));
    dispatch(inputDataClear());
  };

  return (
    <Row className="mb-3">
      <Col md>
        <ButtonGroup>
          <LoadPassageBtn />
          <UploadPassageBtn />
          <Button variant="warning" onClick={optionClear}>
            초기화
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default ControlBtnGroup;
