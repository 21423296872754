import { useDispatch, useSelector } from "react-redux";
import { RequiredText } from "../../Methods";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import { displayDataRegistrationDate } from "../../redux/DisplayData/actions";

registerLocale("ko", ko);

const DisplayChangeRegistrationDate = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  const displayData = useSelector((state) => state.displayDataReducer);

  const changeRegistrationDate = (e) => dispatch(displayDataRegistrationDate(e));

  return (
    <>
      <p className="Form-label"> 등록일 </p>
      <DatePicker
        dateFormat="yyyy.MM.dd (eee)"
        selected={displayData.registrationDate && displayData.registrationDate}
        maxDate={new Date()}
        todayButton="today"
        onChange={changeRegistrationDate}
        locale={ko}
        placeholderText="등록일"
        className="Date-picker-border"
      />
      {!displayData.registrationDate && <RequiredText />}
    </>
  );
};

export default DisplayChangeRegistrationDate;
