import { useSelector } from "react-redux";

// 함수 행<->열 바꾸기
export const transposeArray = (array) => {
  return array[0].map(function (col, i) {
    return array.map(function (row) {
      return row[i];
    });
  });
};

// gameArray의 합 표시
export const useScoreSum = (gameArray) => {
  return transposeArray(gameArray).map((row) =>
    row.reduce((acc, current) => acc + current, 0)
  );
};

// 상위 리그 인원의 합 계산
export const useMajor = (level) => {
  const league = useSelector((state) => state.leagueReducer);
  const leagueLable = Object.keys(league);
  const leaguePlayer = leagueLable.map((obj) => league[obj].player);
  if (level === 0) {
    return 0;
  } else {
    let majorMember = 0;
    for (let i = 1; i <= level; i++) {
      majorMember += leaguePlayer[i - 1];
    }
    return majorMember;
  }
};

// 바로 아래 리그의 인원 수
export const useMinor = (level) => {
  const league = useSelector((state) => state.leagueReducer);
  const leagueLable = Object.keys(league);
  const leaguePlayer = leagueLable.map((obj) => league[obj].player);

  if (level === 3) return 0;
  else return leaguePlayer[level + 1];
};

// 리그의 타입
export const leagueType = (major, minor) => {
  switch (true) {
    case major === 0 && minor === 0:
      return "single";
    case major !== 0 && minor === 0:
      return "bottom";
    case major === 0 && minor !== 0:
      return "top";
    default:
      return "middle";
  }
};

// 리그 내 순위
export function rank(value, arr) {
  const sorted = arr.slice().sort(function (a, b) {
    return b - a;
  });
  const rank = sorted.indexOf(value);
  if (rank > -1) return rank + 1;
  return null;
}

// 전체 순위 계산
export const useTotalRank = (scoreSumArray, promotion, major, type) => {
  // 전체 순위 계산 함수
  const competitionRank = (value, arr, extra, major, type) => {
    const sorted = arr.slice().sort(function (a, b) {
      return b - a;
    });
    const rank = sorted.indexOf(value);

    switch (type) {
      case "single":
        if (rank > -1) return rank + 1;
        break;
      case "bottom":
        if (rank > -1) {
          if (rank < extra) {
            return rank - extra + 1 + major;
          } else {
            return rank + 1 + major;
          }
        }
        break;
      case "top":
        if (rank > -1) {
          if (rank > arr.length - extra - 1) {
            return rank + extra + 1 + major;
          } else {
            return rank + 1 + major;
          }
        }
        break;
      case "middle":
        if (rank > -1) {
          if (rank > arr.length - extra - 1) {
            return rank + extra + 1 + major;
          } else if (rank < extra) {
            return rank - extra + 1 + major;
          } else {
            return rank + 1 + major;
          }
        }
        break;
      default:
        return "error!";
    }
  };
  // 리턴할 Array
  return scoreSumArray.map((obj) => {
    return competitionRank(obj, scoreSumArray, promotion, major, type);
  });
};
