import { Alert, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useScoreSum } from "./function";

// 게임 시 업로드 정보 or 게임 점수가 부족할 시 알려주는 컴포넌트
const AlertCard = (props) => {
  // props
  // GameTable에서 넘어온 league 번호 props
  const level = props.level;

  // reducers
  // loading reducer 의 alert
  const alertClick = useSelector((state) => state.loadingReducer).alert;
  // 해당 리그 참가 인원
  const leagueRoster = useSelector((state) => state.leagueRosterReducer)[level];
  // 해당 리그 게임판
  const gameArray = useSelector((state) => state.gameArrayReducer)[level];

  // function.js 에서 넘어온 함수
  // 각 인원의 점수 합 Array
  const scoreSum = useScoreSum(gameArray);

  // 동점자가 있는지
  const isDuplicate = scoreSum.some((x) => {
    return scoreSum.indexOf(x) !== scoreSum.lastIndexOf(x);
  });

  // 게임이 전체 완료 되었는지 알림
  const allGameEnd =
    scoreSum.reduce((a, b) => a + b) >=
    (leagueRoster.length * (leagueRoster.length - 1)) / 2;

  return (
    <>
      {alertClick && (
        <Alert>
          업로드를 하고 있습니다.. 게임판은 업로드 후 자동으로 초기화됩니다.
        </Alert>
      )}
      {!allGameEnd && (
        <Card.Body className="card-mango">
          <strong>모든 게임이 완료되지 않은 것 같습니다.</strong>
        </Card.Body>
      )}
      {isDuplicate && (
        <Card.Body className="card-guaba">
          <strong>동점자가 있습니다.</strong>
        </Card.Body>
      )}
    </>
  );
};

export default AlertCard;
