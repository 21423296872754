import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "./ToggleSwitch";
import * as msg from "../../../lib/utils/msg";
import { updateUserInfo } from "../../../api/usersApi";
import { updateUserData } from "../../../redux/FirebaseData/Users/reducer";

const UserManageTable = (props) => {
  const isTeacher = props.isTeacher;

  const dispatch = useDispatch();

  const [isAllow, setIsAllow] = useState(0);
  const [isState, setIsState] = useState(0);

  // 사용자를 기준에 맞춰 정렬한 Array
  const userData = useSelector((state) => state.usersReducer)
    .sort((x, y) => {
      // 반환될 Array
      if (x.allow === y.allow) {
        if (x.teacher === y.teacher) {
          if (x.office > y.office) return -1;
          else if (x.office < y.office) return 1;
          else if (x.admin === y.admin) return 0;
          else if (x.admin) return -1;
          else return 1;
        } else if (x.teacher) return -1;
        else return 1;
      } else if (x.allow) return 1;
      else return -1;
    })
    .filter((row) => (isTeacher ? row.teacher : !row.teacher))
    .filter((row) => (isAllow === 0 ? true : isAllow === 1 ? row.allow : !row.allow))
    .filter((row) => (isState === 0 ? true : isState === 1 ? row.state : !row.state));

  // 해당 행의 사용자가 강사일 경우 하늘색, 아닐 경우 베이지색
  const trColor = (row) => (row.teacher ? "teacher" : "student");

  // 해당 행의 사용자가 강사일 경우 선생님, 아닐 경우 학생, 없을 경우 빈 칸 반환
  const teacherLabel = (row) => (row.teacher ? "선생님" : "학생");

  // 해당 행의 사용자 속성이 true일 경우 O, 아닐 경우 X 반환
  const toggleLabel = (row) => (row ? "O" : "X");

  // 해당 행의 사용자 속성이 true일 경우 연두색, 아닐 경우 분홍색 반환
  const toggleStyle = (row) => (row ? "allow" : "not-allow");

  // onClick 함수, 속성 변환
  const handleEdit = (row, name) => {
    const data = row.teacher === undefined ? { teacher: false, [name]: !row[name] } : { [name]: !row[name] };
    updateUserInfo(row.id, data);
    dispatch(updateUserData("id", row.id, data));
  };

  // 강사/학생 구분 td 컴포넌트
  const teacherCell = (row) => (
    <td className="toggle-pointer" onClick={() => handleEdit(row, "teacher")}>
      {teacherLabel(row)}
    </td>
  );

  // 사용자 속성 구분 td 컴포넌트
  const toggleCell = (row, object) => (
    <td className={toggleStyle(row[object])} onClick={() => handleEdit(row, object)}>
      {toggleLabel(row[object])}
    </td>
  );

  // 클립보드에 uid 복사
  const handleCopyClipBoard = async (row) => {
    try {
      await navigator.clipboard.writeText(row.id);
      alert(msg.infoMsg.uidCopy(row));
    } catch (error) {
      alert(msg.errorMsg.runFailed());
    }
  };

  const copyCell = (row) => (
    <td className="copy" onClick={() => handleCopyClipBoard(row)}>
      <FontAwesomeIcon icon={faCopy} />
    </td>
  );

  return (
    <div style={{ userSelect: "none" }}>
      <ToggleSwitch isAllow={isAllow} isState={isState} setIsAllow={setIsAllow} setIsState={setIsState} />
      <Row className="g-5">
        <Col>
          <Table bordered hover responsive size="sm" className="ManageTable">
            <thead>
              <tr>
                <th width="10%">구분</th>
                <th width="20%">이름</th>
                <th width="20%">이메일</th>
                <th width="22%">전화번호</th>
                <th width="7%">state</th>
                {isTeacher && <th width="7%">admin</th>}
                <th width="7%">allow</th>
                <th width="7%">UID</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((row) => (
                <tr key={row.id} className={trColor(row)}>
                  {teacherCell(row)}
                  <td>{row.name}</td>
                  <td>{row.email.split("@")[0]}</td>
                  <td>{row.phone}</td>
                  {toggleCell(row, "state")}
                  {isTeacher && toggleCell(row, "admin")}
                  {toggleCell(row, "allow")}
                  {copyCell(row)}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default UserManageTable;
