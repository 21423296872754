import {
  Badge,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  modalShowAdd,
  modalShowDisplay,
} from "../../../../redux/Modal_Show/actions";
import DisplayAnswer from "./DisplayAnswer";
import QuizHeader from "./QuizHeader";
import UploadAlert from "./UploadAlert";
import ReactHtmlParser from "react-html-parser";
import { setQuestionOption } from "../../../../redux/QuestionOption/reducer";

const DisplayQuiz = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // inputdata reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const ifErrorStyle = (row) => {
    const cardStyle = { textAlign: "left", cursor: "pointer" };
    return row.answer === "error" || row.error
      ? { ...cardStyle, background: "salmon" }
      : cardStyle;
  };

  const onClick = (row) => {
    dispatch(setQuestionOption(row));
    dispatch(modalShowDisplay(true));
    dispatch(modalShowAdd(false));
  };

  const badgeForm = (row, already) => {
    const alreadyText = already ? "기존: " : "변경: ";
    const bg = (row) => {
      switch (row.type) {
        case "short":
          return "dark";
        case "choice":
          return "primary";
        case "checkBox":
          return "success";
        default:
          return "danger";
      }
    };

    return <Badge bg={bg(row)}>{alreadyText + row.type} type</Badge>;
  };

  const questionForm = (row) => (
    <>
      <Card.Text className="my-1">{ReactHtmlParser(row.question)}</Card.Text>
      <ListGroup className="mt-2">
        {row.type === "short" ? (
          <ListGroupItem
            className="py-1"
            style={{ background: "lightblue", fontSize: "15px" }}
          >
            Answer : {row.answer}
          </ListGroupItem>
        ) : (
          <>
            <DisplayAnswer row={row} number={1} />
            <DisplayAnswer row={row} number={2} />
            <DisplayAnswer row={row} number={3} />
            <DisplayAnswer row={row} number={4} />
            <DisplayAnswer row={row} number={5} />
          </>
        )}
      </ListGroup>
    </>
  );

  const errorMessage = (
    <Card.Body style={{ textAlign: "center", verticalAlign: "middle" }}>
      해당하는 문제가 없습니다. 다시 시도해주세요.
    </Card.Body>
  );

  const existsForm = (row) => (
    <>
      <QuizHeader row={row} />
      <Card.Body onClick={() => onClick(row)}>
        <Row className="g-2">
          <Col>
            {badgeForm(row.exists, true)}
            {questionForm(row.exists)}
          </Col>
          <Col>
            {badgeForm(row)}
            {questionForm(row)}
          </Col>
        </Row>
      </Card.Body>
    </>
  );

  const normalForm = (row) => (
    <>
      <QuizHeader row={row} />
      <Card.Body onClick={() => onClick(row)}>{questionForm(row)}</Card.Body>
    </>
  );

  const displayForm = (row) => {
    switch (true) {
      case row.error:
        return errorMessage;
      case (row.exists || { exist: false }).exist:
        return existsForm(row);
      default:
        return normalForm(row);
    }
  };

  return (
    <Row className="g-4 mb-5 pb-2 mx-3">
      <Col>
        {inputData.length !== 0 &&
          inputData.map((row) => (
            <Card className="my-1" key={row.qid} style={ifErrorStyle(row)}>
              {displayForm(row)}
            </Card>
          ))}
        <UploadAlert />
      </Col>
    </Row>
  );
};

export default DisplayQuiz;
