/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selOptionClear } from "../../../../redux/selOption/actions";
import { inputDataClear } from "../../../../redux/InputData/actions";
import SelectPassage from "../../../../Component/Test/Reading/Modify/SelectPassage";
import PassageListTable from "../../../../Component/Test/Reading/Modify/PassageListTable";
import { Col, Row } from "react-bootstrap";
import InputTable from "../../../../Component/Test/Reading/Modify/InputTable";
import ChangePassageType from "../../../../Component/Test/Reading/Modify/ChangePassageType";
import PassageControlBtn from "../../../../Component/Test/Reading/Modify/PassageControlBtn";
import ControlBtnGroup from "../../../../Component/Test/Reading/Modify/ControlBtnGroup";
import useRedirect from "../../../../hooks/useRedirect";
import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const ModifyPassage = () => {
  // redux dispatch
  const dispatch = useDispatch();

  useRedirect("teachingcenter", "home");

  useEffect(() => {
    dispatch(inputDataClear());
    dispatch(selOptionClear("Reading"));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <SelectPassage />
          <ControlBtnGroup />
          <Row className="g-3">
            <Col md>
              <PassageListTable />
            </Col>
            <Col md>
              <ChangePassageType />
              <InputTable />
              <PassageControlBtn />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ModifyPassage;
