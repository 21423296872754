/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import GrowingCarousel from "../../../Component/Title/GrowingCarousel";
import TitleCard from "../../../Component/Title/TitleCard";
import { cards } from "../../../lib/utils/constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useRedirect from "../../../hooks/useRedirect";
import NotAllowedPage from "../../not-allowed";
import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

const HomeCarousel = ({ pageType }) => {
  const navigate = useNavigate();

  // redux reducers
  const currentUser = useSelector((state) => state.currentUserReducer).accountInfo; // 사용자 정보
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo; // 사용자 정보

  // HomeCarousel을 보여줄 조건
  const displayCondition = pageType === "teacher" ? myInfo.allow && myInfo.teacher : myInfo.allow;

  // 숏컷이 제공될 데이터
  const cardData = pageType === "teacher" ? cards.teachingHome : cards.learningHome;

  useRedirect(pageType === "student" ? "learningcenter" : "teachingcenter", "home");

  useEffect(() => {
    !currentUser.uid && (pageType === "teacher" ? navigate("/teachingcenter") : navigate("/learningcenter"));
  }, []);

  return (
    <>
      {displayCondition ? (
        <BasicLayout headerType="teacher">
          <S.Container>
            <S.ContentContainer>
              <GrowingCarousel pageType={pageType} />
              <Row md className="g-4 mx-2">
                {cardData.map((obj, i) => {
                  return (
                    <Col md key={i}>
                      <TitleCard cardData={obj} />
                    </Col>
                  );
                })}
              </Row>
            </S.ContentContainer>
          </S.Container>
        </BasicLayout>
      ) : (
        <NotAllowedPage pageType={pageType} />
      )}
    </>
  );
};

export default HomeCarousel;
