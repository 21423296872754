import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  modalShowAdd,
  modalShowDisplay,
  modalShowModify,
} from "../../../redux/Modal_Show/actions";
import { displayDataOffice } from "../../../redux/DisplayData/actions";

// 관리자가 스케쥴을 추가하거나 수정할 때 사용하는 버튼 컴포넌트
const AddModifyButton = () => {
  const navigate = useNavigate();
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // onClick 함수
  // 스케쥴 추가 시 사용하는 함수
  const addSchedule = () => {
    // ScheduleModal 창에서 사용할 옵션 값 중 office 값을 사용자의 지점으로 설정
    dispatch(displayDataOffice(myInfo.office));
    // modal show 설정
    dispatch(modalShowDisplay(true));
    dispatch(modalShowAdd(true));
    dispatch(modalShowModify(true));
  };
  // 일괄 추가 가능한 페이지로 이동
  const historyAdd = () => navigate("/teachingcenter/schedule/input");
  // 일괄 수정 가능한 페이지로 이동
  const historyModify = () => navigate("/teachingcenter/schedule/update");

  // 버튼 컴포넌트
  const buttons = myInfo.admin && (
    <>
      <Button className="Growing-button" onClick={addSchedule}>
        스케쥴 추가
      </Button>
      <Button onClick={historyAdd}>일괄 추가</Button>
      <Button onClick={historyModify} variant="warning">
        일괄 수정
      </Button>
    </>
  );

  return <ButtonGroup className="mt-3">{buttons}</ButtonGroup>;
};

export default AddModifyButton;
