import db from "../../../firebase";
import { addDoc, collection, doc, increment, setDoc } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../PageComponent/LoadingSpinner";
import {
  loadingAlert,
  loadingComponent,
  loadingFalse,
} from "../../../redux/Loading/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { inputDataClear } from "../../../redux/InputData/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

const UploadButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // loading reducer의 component state
  const loading = useSelector((state) => state.loadingReducer).component;
  // inputData
  const inputData = useSelector((state) => state.inputDataReducer);
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  // inputData 안의 sid나 score가 비어있는지 체크
  const isNull = inputData
    .map((row) => (!row.sid || !row.score ? true : false))
    .some((e) => e === true);

  // 버튼 비활성화 조건
  const buttonDisabled =
    loading ||
    selOption.cid === null ||
    selOption.subject === null ||
    selOption.date === null ||
    inputData.length === 0 ||
    isNull;

  // 데이터 업로드
  const dataUpload = () => {
    const dateid =
      new Date().getFullYear().toString().slice(-2) +
      ("0" + (new Date().getMonth() + 1)).slice(-2);

    const attendId =
      selOption.date.getFullYear().toString() +
      (selOption.date.getMonth() + 1).toString() +
      selOption.date.getDate().toString();

    dispatch(loadingComponent(true));
    dispatch(loadingAlert(true));
    inputData.forEach((row) => {
      const uploadData = {
        sid: row.sid,
        name: row.name,
        date: new Date(selOption.date.toDateString()),
        office: myInfo.office,
        cid: selOption.cid,
        score: row.score,
        subject: selOption.subject,
        log: new Date(),
        tid: myInfo.uid,
      };
      if (selOption.subject === "Listening") {
        setDoc(
          doc(db, "Users", row.sid, "testScoreStat", dateid),
          { lissum: increment(row.score), liscount: increment(1) },
          { merge: true }
        );
      } else if (selOption.subject === "Reading") {
        setDoc(
          doc(db, "Users", row.sid, "testScoreStat", dateid),
          { reasum: increment(row.score), reacount: increment(1) },
          { merge: true }
        );
      } else if (selOption.subject === "Grammar") {
        setDoc(
          doc(db, "Users", row.sid, "testScoreStat", dateid),
          { grasum: increment(row.score), gracount: increment(1) },
          { merge: true }
        );
      } else if (selOption.subject === "모의고사") {
        setDoc(
          doc(db, "Users", row.sid, "testScoreStat", dateid),
          { mocksum: increment(row.score), mockcount: increment(1) },
          { merge: true }
        );
      }
      setDoc(doc(db, "Users", row.sid, "attend", attendId), {
        year: selOption.date.getFullYear(),
        month: selOption.date.getMonth() + 1,
        date: new Date(selOption.date.toDateString()),
        attend: true,
        sid: row.sid,
      });
      addDoc(collection(db, "TestScore"), uploadData);
    });
    setTimeout(() => {
      dispatch(inputDataClear());
      dispatch(loadingFalse());
      dispatch(modalShowPopup(false));
    }, 3000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(
      setPopupModal("Upload?", "업로드 하시겠습니까?", dataUpload, "업로드")
    );
  };

  return (
    <>
      <Button
        disabled={buttonDisabled}
        className="Growing-button mb-1"
        style={{ float: "right" }}
        onClick={openPopup}
      >
        {loading ? <LoadingSpinner /> : "성적 입력하기"}
      </Button>
    </>
  );
};

export default UploadButton;
