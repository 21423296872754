import {
  LOADING_MAIN,
  LOADING_COMPONENT,
  LOADING_ALERT,
  LOADING_ACTIVE,
  LOADING_WARNING,
  LOADING_DANGER,
  LOADING_FALSE,
  LOADING_AUTO,
  LOADING_WRITE,
} from "./types";

const loading = {
  main: true,
  component: false,
  alert: false,
  active: false,
  auto: false,
  write: false,
  warning: false,
  danger: false,
};

const loadingReducer = (state = loading, action) => {
  switch (action.type) {
    case LOADING_MAIN:
      return { ...state, main: action.payload };
    case LOADING_COMPONENT:
      return {
        ...state,
        component:
          action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_ALERT:
      return {
        ...state,
        alert: action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_ACTIVE:
      return {
        ...state,
        active: action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_AUTO:
      return {
        ...state,
        auto: action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_WRITE:
      return {
        ...state,
        write: action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_WARNING:
      return {
        ...state,
        warning:
          action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_DANGER:
      return {
        ...state,
        danger: action.payload === undefined ? !action.payload : action.payload,
      };
    case LOADING_FALSE:
      return { ...loading, main: false };
    default:
      return state;
  }
};

export default loadingReducer;
