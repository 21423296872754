import styled from "@emotion/styled";
import { collection, getDocs, query } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import db from "../firebase";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #4b82fa;
  border-radius: 8px;
  margin-top: 16px;
  padding: 0px 8px 16px;
  overflow: hidden;

  .swiper {
    margin-top: 16px;
    position: relative;
  }
  .swiper .swiper-pagination {
    position: absolute;
    bottom: -4px;
  }
`;

export default function NoticeCard() {
  const [bannerData, setBannerData] = useState([]);

  const getBanners = useCallback(async () => {
    let banners = [];

    const bannerQuery = query(collection(db, "StudentBanner"));
    const querySnapshot = await getDocs(bannerQuery);

    querySnapshot.forEach((doc) => banners.push(doc.data()));

    setBannerData(banners.sort((a, b) => a.index - b.index));
  }, []);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <Container>
      <Swiper
        modules={[Autoplay]}
        centeredSlides
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
      >
        {bannerData.map(({ text: bannerText, index: bannerIndex }) => (
          <SwiperSlide key={bannerIndex}>{bannerText ?? "sample"}</SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}
