export const CRUD = {
  C: "C",
  R: "R",
  U: "U",
  D: "D",
};

export const TODO_TYPE = [
  { id: "Competition", value: "Competition" },
  { id: "etc", value: "etc" },
];

export const DATEDATA = {
  monthArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export const SAGA = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const holidays = [
  {
    year: 2022,
    month: 1,
    day: 1,
    holiday: "신정",
  },
  {
    year: 2022,
    month: 1,
    day: 31,
    holiday: "설날",
  },
  {
    year: 2022,
    month: 2,
    day: 1,
    holiday: "설날",
  },
  {
    year: 2022,
    month: 2,
    day: 2,
    holiday: "설날",
  },
  {
    year: 2022,
    month: 3,
    day: 1,
    holiday: "삼일절",
  },
  {
    year: 2022,
    month: 3,
    day: 9,
    holiday: "대통령선거일",
  },
  {
    year: 2022,
    month: 5,
    day: 5,
    holiday: "어린이날",
  },
  {
    year: 2022,
    month: 5,
    day: 8,
    holiday: "부처님오신날",
  },
  {
    year: 2022,
    month: 6,
    day: 1,
    holiday: "전국동시지방선거",
  },
  {
    year: 2022,
    month: 6,
    day: 6,
    holiday: "현충일",
  },
  {
    year: 2022,
    month: 8,
    day: 15,
    holiday: "광복절",
  },
  {
    year: 2022,
    month: 9,
    day: 9,
    holiday: "추석",
  },
  {
    year: 2022,
    month: 9,
    day: 10,
    holiday: "추석",
  },
  {
    year: 2022,
    month: 9,
    day: 11,
    holiday: "추석",
  },
  {
    year: 2022,
    month: 9,
    day: 12,
    holiday: "대체공휴일",
  },
  {
    year: 2022,
    month: 10,
    day: 3,
    holiday: "개천절",
  },
  {
    year: 2022,
    month: 10,
    day: 9,
    holiday: "한글날",
  },
  {
    year: 2022,
    month: 10,
    day: 10,
    holiday: "대체공휴일",
  },
  {
    year: 2022,
    month: 12,
    day: 25,
    holiday: "크리스마스",
  },
  {
    year: 2023,
    month: 1,
    day: 1,
    holiday: "신정",
  },
  {
    year: 2023,
    month: 1,
    day: 21,
    holiday: "설날",
  },
  {
    year: 2023,
    month: 2,
    day: 22,
    holiday: "설날",
  },
  {
    year: 2023,
    month: 1,
    day: 23,
    holiday: "설날",
  },
  {
    year: 2023,
    month: 1,
    day: 24,
    holiday: "대체공휴일",
  },
  {
    year: 2023,
    month: 3,
    day: 1,
    holiday: "삼일절",
  },
  {
    year: 2023,
    month: 5,
    day: 5,
    holiday: "어린이날",
  },
  {
    year: 2023,
    month: 5,
    day: 27,
    holiday: "부처님오신날",
  },
  {
    year: 2023,
    month: 6,
    day: 6,
    holiday: "현충일",
  },
  {
    year: 2023,
    month: 8,
    day: 15,
    holiday: "광복절",
  },
  {
    year: 2023,
    month: 9,
    day: 28,
    holiday: "추석",
  },
  {
    year: 2023,
    month: 9,
    day: 29,
    holiday: "추석",
  },
  {
    year: 2023,
    month: 9,
    day: 30,
    holiday: "추석",
  },
  {
    year: 2023,
    month: 10,
    day: 3,
    holiday: "개천절",
  },
  {
    year: 2023,
    month: 10,
    day: 9,
    holiday: "한글날",
  },
  {
    year: 2023,
    month: 12,
    day: 25,
    holiday: "크리스마스",
  },
];

export const subjects = [{ subject: "Listening" }, { subject: "Reading" }, { subject: "Grammar" }, { subject: "모의고사" }];

export const subjectsWithCompetition = [...subjects, { subject: "Competition" }];

export const quizTypes = [{ name: "Kahoot" }, { name: "Quizizz" }];

export const passages = {
  types: [
    { type: "교과서", name: "영어" },
    { type: "교과서", name: "영어I" },
    { type: "교과서", name: "영어II" },
    { type: "교과서", name: "중1" },
    { type: "교과서", name: "중2" },
    { type: "교과서", name: "중3" },
    { type: "모의고사", name: "모의고사" },
    { type: "부교재", name: "부교재" },
    { type: "부교재", name: "문장연습" },
  ],
  textbooks: [
    {
      type: ["중1", "중2", "중3", "영어", "영어I", "영어II"],
      name: "능률(김)",
    },
    { type: ["중1", "중2", "중3", "영어"], name: "능률(양)" },
    { type: ["중1", "중2", "중3", "영어", "영어I", "영어II"], name: "YBM(박)" },
    { type: ["중1", "중2", "중3"], name: "YBM(송)" },
    { type: ["영어", "영어II"], name: "YBM(한)" },
    { type: ["영어I"], name: "교학사(강)" },
    {
      type: ["중1", "중2", "중3", "영어", "영어I", "영어II"],
      name: "금성(최)",
    },
    { type: ["영어I", "영어II"], name: "동아(권)" },
    { type: ["중1", "중2", "중3"], name: "동아(윤)" },
    { type: ["중1", "중2", "중3", "영어"], name: "동아(이)" },
    { type: ["중1", "중2", "중3"], name: "미래엔(최)" },
    { type: ["중1", "중2", "중3"], name: "비상(김)" },
    { type: ["영어", "영어I", "영어II"], name: "비상(홍)" },
    {
      type: ["중1", "중2", "중3", "영어", "영어I", "영어II"],
      name: "지학사(민)",
    },
    { type: ["영어"], name: "천재(김)" },
    {
      type: ["중1", "중2", "중3", "영어", "영어I", "영어II"],
      name: "천재(이)",
    },
    { type: ["중1", "중2", "중3"], name: "천재(정)" },
    { type: ["모의고사"], name: "고1" },
    { type: ["모의고사"], name: "고2" },
    { type: ["모의고사"], name: "고3" },
    { type: ["부교재"], name: "수능라이트" },
    { type: ["부교재"], name: "수능특강 (2023)" },
    { type: ["부교재"], name: "수능특강 영어독해 (2023)" },
    { type: ["부교재"], name: "신사고" },
    { type: ["부교재"], name: "올림포스" },
  ],
};

export const colors = {
  main: ["#4b82fa", "#f8633e", "#f89602", "DarkGoldenRod", "DeepPink", "Indigo", "SlateGray", "Aquamarine", "BlueViolet", "Coral"],
  sub: ["DarkBlue", "DarkRed", "DarkOrange", "DarkGoldenRod", "DeepPink", "Indigo", "SlateGray", "Aquamarine", "BlueViolet", "Coral"],
};

export const cards = {
  tool: [
    {
      id: 0,
      row: 0,
      title: "Stopwatch",
      content: "시간을 측정합니다.",
      cardLink: "/teachingcenter/tool/stopwatch",
    },
    {
      id: 1,
      row: 0,
      title: "Vocabulary",
      content: "단어를 공부합니다.",
      cardLink: "/teachingcenter/tool/voca",
    },
    {
      id: 2,
      row: 1,
      title: "Todo List",
      content: "학생들의 Todo List를 설정합니다",
      cardLink: "/teachingcenter/tool/todo",
    },
    {
      id: 3,
      row: 1,
      title: "Todo List Check",
      content: "학생들의 Todo List를 확인합니다",
      cardLink: "/teachingcenter/tool/todocheck",
    },
  ],

  admin: [
    {
      id: 0,
      row: 0,
      title: "Setting",
      content: "각종 수치를 조정합니다.",
      cardLink: "/teachingcenter/admin/setting",
    },
    {
      id: 1,
      row: 0,
      title: "Daily Quiz Result",
      content: "오늘 학생들이 실시한 단어 퀴즈의 결과를 확인합니다.",
      cardLink: "/teachingcenter/admin/dailyquizresult",
    },
    {
      id: 2,
      row: 1,
      title: "Todo List",
      content: "학생들의 Todo List를 설정합니다",
      cardLink: "/teachingcenter/admin/todo",
    },
    {
      id: 3,
      row: 1,
      title: "Todo List Check",
      content: "학생들의 Todo List를 확인합니다",
      cardLink: "/teachingcenter/admin/todocheck",
    },
    {
      id: 4,
      row: 2,
      title: "Upload Question",
      content: "퀴즈를 업로드합니다.",
      cardLink: "/teachingcenter/admin/uploadquestion",
    },
    {
      id: 5,
      row: 2,
      title: "Modify Question",
      content: "퀴즈를 수정합니다.",
      cardLink: "/teachingcenter/admin/modifyquestion",
    },
    {
      id: 6,
      row: 3,
      title: "Upload Passage",
      content: "본문을 업로드합니다.",
      cardLink: "/teachingcenter/admin/uploadpassage",
    },
    {
      id: 7,
      row: 3,
      title: "Modify Passage",
      content: "본문을 수정합니다.",
      cardLink: "/teachingcenter/admin/modifypassage",
    },
    {
      id: 8,
      row: 4,
      title: "Upload Word",
      content: "단어를 업로드합니다.",
      cardLink: "/teachingcenter/admin/uploadword",
    },
    {
      id: 9,
      row: 4,
      title: "Modify Word",
      content: "단어를 수정합니다.",
      cardLink: "/teachingcenter/admin/modifyword",
    },
    {
      id: 10,
      row: 5,
      title: "Payment",
      content: "납부 기록을 관리합니다",
      cardLink: "/teachingcenter/admin/payment",
    },
  ],
  learningHome: [
    {
      id: 0,
      title: "Competition Voca",
      content: "단어를 학습합니다.",
      cardLink: "/learningcenter/voca",
    },
    {
      id: 1,
      title: "Reading",
      content: "지문을 읽고 해석합니다.",
      cardLink: "/learningcenter/reading",
    },
    {
      id: 2,
      title: "Notice",
      content: "자료 및 공지사항을 확인합니다.",
      cardLink: "/learningcenter/board/notice",
    },
  ],
  teachingHome: [
    {
      id: 0,
      title: "Score",
      content: "매일 실시하는 테스트의 점수를 조회하고 입력합니다.",
      cardLink: "/teachingcenter/test/input",
    },
    {
      id: 1,
      title: "Competition",
      content: "Competition 게임을 진행하고 순위를 입력합니다.",
      cardLink: "/teachingcenter/competition",
    },

    {
      id: 2,
      title: "Input Quiz Result",
      content: "퀴즈 결과 파일을 업로드합니다.",
      cardLink: "/teachingcenter/quiz/input",
    },
  ],
};

export const grades = [
  { id: 0, grade: "유치부" },
  { id: 1, grade: "초1" },
  { id: 2, grade: "초2" },
  { id: 3, grade: "초3" },
  { id: 4, grade: "초4" },
  { id: 5, grade: "초5" },
  { id: 6, grade: "초6" },
  { id: 7, grade: "중1" },
  { id: 8, grade: "중2" },
  { id: 9, grade: "중3" },
  { id: 10, grade: "고1" },
  { id: 11, grade: "고2" },
  { id: 12, grade: "고3" },
  { id: 13, grade: "성인" },
];

export const schools = [
  {
    id: 1,
    type: "공립",
    establush: "서부교육지원청",
    school: "감천초등학교",
    address: "부산광역시 사하구 원양로 368-2",
    phone: "051-206-9482",
    fax: "051-206- 9484",
    zipCode: 49451,
    homepage: "http://www.gam.es.kr",
  },
  {
    id: 2,
    type: "공립",
    establush: "서부교육지원청",
    school: "송도초등학교",
    address: "부산광역시 서구 충무대로 25",
    phone: "051-250-5700",
    fax: "051-245-9023",
    zipCode: 49270,
    homepage: "http://www.songdo.es.kr",
  },
  {
    id: 3,
    type: "공립",
    establush: "서부교육지원청",
    school: "승학초등학교",
    address: "부산광역시 사하구 낙동대로135번길 55",
    phone: "051-208-2052",
    fax: "051-208-2053",
    zipCode: 49347,
    homepage: "http://www.seunghak.es.kr",
  },
  {
    id: 4,
    type: "공립",
    establush: "서부교육지원청",
    school: "신남초등학교",
    address: "부산광역시 사하구 비봉로 88",
    phone: "051-990-7300",
    fax: "051-990-7399",
    zipCode: 49419,
    homepage: "http://www.sinnam.es.kr",
  },
  {
    id: 5,
    type: "공립",
    establush: "서부교육지원청",
    school: "신선초등학교",
    address: "부산광역시 영도구 산정길 17",
    phone: "051-719-1300",
    fax: "051-418-5159",
    zipCode: 49075,
    homepage: "http://www.sinseon.es.kr",
  },
  {
    id: 6,
    type: "공립",
    establush: "서부교육지원청",
    school: "신촌초등학교",
    address: "부산광역시 사하구 다대로135번길 30-21",
    phone: "051-220-3700",
    fax: "051-207-5987",
    zipCode: 49393,
    homepage: "http://www.shinchon.es.kr",
  },
  {
    id: 7,
    type: "공립",
    establush: "서부교육지원청",
    school: "신평초등학교",
    address: "부산광역시 사하구 장평로299번안길 19",
    phone: "051-200-5000",
    fax: "051-203-5381",
    zipCode: 49418,
    homepage: "http://www.sinpyeong.es.kr",
  },
  {
    id: 8,
    type: "공립",
    establush: "서부교육지원청",
    school: "아미초등학교",
    address: "부산광역시 서구 해돋이로 241",
    phone: "051-240-6000",
    fax: "051-240-6009",
    zipCode: 49249,
    homepage: "http://www.ami.es.kr",
  },
  {
    id: 9,
    type: "공립",
    establush: "서부교육지원청",
    school: "영도초등학교",
    address: "부산광역시 영도구 꿈나무길 41",
    phone: "051-604-0100",
    fax: "051-604-0199",
    zipCode: 49061,
    homepage: "http://www.yeongdo.es.kr",
  },
  {
    id: 10,
    type: "공립",
    establush: "서부교육지원청",
    school: "옥천초등학교",
    address: "부산광역시 사하구 사하로 134",
    phone: "051-200-0600",
    fax: "051-200-0610",
    zipCode: 49361,
    homepage: "http://www.okchun.es.kr",
  },
  {
    id: 11,
    type: "공립",
    establush: "서부교육지원청",
    school: "을숙도초등학교",
    address: "부산광역시 사하구 낙동대로 581",
    phone: "051-200-2800",
    fax: "051-205-2918",
    zipCode: 49302,
    homepage: "http://www.eulsukdo.es.kr",
  },
  {
    id: 12,
    type: "공립",
    establush: "서부교육지원청",
    school: "응봉초등학교",
    address: "부산광역시 사하구 다대낙조2길 26",
    phone: "051-265-6773",
    fax: "051-263-4358",
    zipCode: 49501,
    homepage: "http://www.eungbong.es.kr",
  },
  {
    id: 13,
    type: "공립",
    establush: "서부교육지원청",
    school: "장림초등학교",
    address: "부산광역시 사하구 장림번영로103번길 10",
    phone: "051-290-2700",
    fax: "051-261-4334",
    zipCode: 49464,
    homepage: "http://www.jangrim.es.kr",
  },
  {
    id: 14,
    type: "공립",
    establush: "서부교육지원청",
    school: "절영초등학교",
    address: "부산광역시 영도구 함지로 37",
    phone: "051-403-5648",
    fax: "051-403-5646",
    zipCode: 49113,
    homepage: "http://www.jeolyeong.es.kr",
  },
  {
    id: 15,
    type: "공립",
    establush: "서부교육지원청",
    school: "중리초등학교",
    address: "부산광역시 영도구 와치로 286",
    phone: "051-400-2500",
    fax: "051-400-2590",
    zipCode: 49116,
    homepage: "http://www.joonglee.es.kr",
  },
  {
    id: 16,
    type: "공립",
    establush: "서부교육지원청",
    school: "중현초등학교",
    address: "부산광역시 사하구 다대낙조2길 8",
    phone: "051-260-0503",
    fax: "051-266-6532",
    zipCode: 49503,
    homepage: "http://www.junghyun.es.kr",
  },
  {
    id: 17,
    type: "공립",
    establush: "서부교육지원청",
    school: "천마초등학교",
    address: "부산광역시 서구 천해로7번길 38",
    phone: "051-250-6701",
    fax: "051-253-0102",
    zipCode: 49265,
    homepage: "http://www.chunma.es.kr",
  },
  {
    id: 18,
    type: "공립",
    establush: "서부교육지원청",
    school: "청동초등학교",
    address: "부산광역시 영도구 태종로422번길 51",
    phone: "051-400-2808",
    fax: "051-400-2899",
    zipCode: 49088,
    homepage: "http://www.chungdong.es.kr",
  },
  {
    id: 19,
    type: "공립",
    establush: "서부교육지원청",
    school: "청학초등학교",
    address: "부산광역시 영도구 청학로 49",
    phone: "051-416-0974",
    fax: "051-416-3484",
    zipCode: 49031,
    homepage: "http://www.cheonghak.es.kr",
  },
  {
    id: 20,
    type: "공립",
    establush: "서부교육지원청",
    school: "태종대초등학교",
    address: "부산광역시 영도구 태종로802번길 38",
    phone: "051-405-4861",
    fax: "051-400-2180",
    zipCode: 49126,
    homepage: "http://www.taejongdae.es.kr",
  },
  {
    id: 21,
    type: "공립",
    establush: "서부교육지원청",
    school: "토성초등학교",
    address: "부산광역시 서구 구덕로134번길 45",
    phone: "051-250-0800",
    fax: "051-253-0110",
    zipCode: 49246,
    homepage: "http://www.toseong.es.kr",
  },
  {
    id: 22,
    type: "공립",
    establush: "서부교육지원청",
    school: "하남초등학교",
    address: "부산광역시 사하구 하신번영로 186",
    phone: "051-200-3600",
    fax: "051-200-3698",
    zipCode: 49429,
    homepage: "http://www.hanam.es.kr",
  },
  {
    id: 23,
    type: "공립",
    establush: "서부교육지원청",
    school: "하단초등학교",
    address: "부산광역시 사하구 하신중앙로 321",
    phone: "051-201-3461",
    fax: "051-201-3453",
    zipCode: 49426,
    homepage: "http://www.hadan.es.kr",
  },
  {
    id: 24,
    type: "공립",
    establush: "서부교육지원청",
    school: "화랑초등학교",
    address: "부산광역시 서구 대영로86번길 56",
    phone: "051-793-7300",
    fax: "051-793-7388",
    zipCode: 49222,
    homepage: "http://www.hwarang.es.kr",
  },
  {
    id: 25,
    type: "공립",
    establush: "서부교육지원청",
    school: "효림초등학교",
    address: "부산광역시 사하구 장평로 106",
    phone: "051-261-5220",
    fax: "051-266-5220",
    zipCode: 49468,
    homepage: "http://www.hyorim.es.kr",
  },
  {
    id: 26,
    type: "공립",
    establush: "남부교육지원청",
    school: "가남초등학교",
    address: "부산광역시 부산진구 엄광로 325",
    phone: "051-897-1712",
    fax: "051-896-7595",
    zipCode: 47332,
    homepage: "http://ga-nam.es.kr",
  },
  {
    id: 27,
    type: "공립",
    establush: "남부교육지원청",
    school: "가산초등학교",
    address: "부산광역시 부산진구 엄광로182번길 49",
    phone: "051-894-9484",
    fax: "051-891-5979",
    zipCode: 47339,
    homepage: "http://pusangasan.es.kr",
  },
  {
    id: 28,
    type: "공립",
    establush: "남부교육지원청",
    school: "가야초등학교",
    address: "부산광역시 부산진구 대학로 22",
    phone: "051-892-3515",
    fax: "051-891-0859",
    zipCode: 47330,
    homepage: "http://ka-ya.es.kr",
  },
  {
    id: 29,
    type: "공립",
    establush: "서부교육지원청",
    school: "서천초등학교",
    address: "부산광역시 사하구 감천로105번길 10",
    phone: "051-291-4698",
    fax: "051-291-4697",
    zipCode: 49374,
    homepage: "http://www.seocheon.es.kr",
  },
  {
    id: 30,
    type: "공립",
    establush: "서부교육지원청",
    school: "상리초등학교",
    address: "부산광역시 영도구 상리로 38",
    phone: "051-719-5800",
    fax: "051-719-5899",
    zipCode: 49092,
    homepage: "http://www.ses.es.kr",
  },
  {
    id: 31,
    type: "공립",
    establush: "서부교육지원청",
    school: "사하초등학교",
    address: "부산광역시 사하구 낙동대로 266",
    phone: "051-793-7200",
    fax: "051-793-7271",
    zipCode: 49343,
    homepage: "http://www.saha.es.kr",
  },
  {
    id: 32,
    type: "공립",
    establush: "서부교육지원청",
    school: "광일초등학교",
    address: "부산광역시 중구 중구로 74",
    phone: "051-603-5000",
    fax: "051-603- 5119",
    zipCode: 48933,
    homepage: "http://www.gwangil.es.kr",
  },
  {
    id: 33,
    type: "공립",
    establush: "서부교육지원청",
    school: "괴정초등학교",
    address: "부산광역시 사하구 낙동대로 152",
    phone: "051-220-0500",
    fax: "051-220- 0599",
    zipCode: 49319,
    homepage: "http://www.koejeong.es.kr",
  },
  {
    id: 34,
    type: "공립",
    establush: "서부교육지원청",
    school: "구덕초등학교",
    address: "부산광역시 서구 보수대로 312",
    phone: "051-242-6600",
    fax: "051-254- 6660",
    zipCode: 49202,
    homepage: "http://www.gudeok.es.kr",
  },
  {
    id: 35,
    type: "공립",
    establush: "서부교육지원청",
    school: "구평초등학교",
    address: "부산광역시 사하구 사하로 9",
    phone: "051-220-2500",
    fax: "051-262-0048",
    zipCode: 49396,
    homepage: "http://www.gupyong.es.kr",
  },
  {
    id: 36,
    type: "공립",
    establush: "서부교육지원청",
    school: "낙동초등학교",
    address: "부산광역시 사하구 제석로 41",
    phone: "051-207-7631",
    fax: "051-207-7634",
    zipCode: 49326,
    homepage: "http://www.nagdong.es.kr",
  },
  {
    id: 37,
    type: "공립",
    establush: "서부교육지원청",
    school: "남부민초등학교",
    address: "부산광역시 서구 천마로 153",
    phone: "051-244-3988",
    fax: "051-256-2545",
    zipCode: 49262,
    homepage: "http://www.nambumin.es.kr",
  },
  {
    id: 38,
    type: "사립",
    establush: "서부교육지원청",
    school: "남성초등학교",
    address: "부산광역시 중구 샘길 14",
    phone: "051-469-7963",
    fax: "051-469-7965",
    zipCode: 48926,
    homepage: "http://www.nsworld.net",
  },
  {
    id: 39,
    type: "공립",
    establush: "서부교육지원청",
    school: "남항초등학교",
    address: "부산광역시 영도구 남항새싹길 55",
    phone: "051-417-4142",
    fax: "051-417-4143",
    zipCode: 49078,
    homepage: "http://www.namhang.es.kr",
  },
  {
    id: 40,
    type: "공립",
    establush: "서부교육지원청",
    school: "다대초등학교",
    address: "부산광역시 사하구 다대로 499",
    phone: "051-263-0881",
    fax: "051-263-3563",
    zipCode: 49522,
    homepage: "http://www.dadae.es.kr",
  },
  {
    id: 41,
    type: "공립",
    establush: "서부교육지원청",
    school: "다선초등학교",
    address: "부산광역시 사하구 다송로 57",
    phone: "051-262-0884",
    fax: "051-266-0665",
    zipCode: 49516,
    homepage: "http://www.dasun.es.kr",
  },
  {
    id: 42,
    type: "공립",
    establush: "서부교육지원청",
    school: "다송초등학교",
    address: "부산광역시 사하구 다대로381번길 17",
    phone: "051-260-5800",
    fax: "051-264-4688",
    zipCode: 49513,
    homepage: "http://www.dasong.es.kr",
  },
  {
    id: 43,
    type: "공립",
    establush: "서부교육지원청",
    school: "당리초등학교",
    address: "부산광역시 사하구 다대로22번길 12",
    phone: "051-200-3700",
    fax: "051-208-4603",
    zipCode: 49416,
    homepage: "http://www.dangni.es.kr",
  },
  {
    id: 44,
    type: "공립",
    establush: "서부교육지원청",
    school: "대교초등학교",
    address: "부산광역시 영도구 봉래나루로246번길 19",
    phone: "051-410-2500",
    fax: "051-412-8957",
    zipCode: 49006,
    homepage: "http://www.daegyo.es.kr",
  },
  {
    id: 45,
    type: "공립",
    establush: "서부교육지원청",
    school: "대신초등학교",
    address: "부산광역시 서구 대신로 63",
    phone: "051-240-0800",
    fax: "051-240-0899",
    zipCode: 49209,
    homepage: "http://www.daesin.es.kr",
  },
  {
    id: 46,
    type: "공립",
    establush: "서부교육지원청",
    school: "대평초등학교",
    address: "부산광역시 영도구 절영로 82",
    phone: "051-410-0503",
    fax: "051-412-4359",
    zipCode: 49047,
    homepage: "http://www.daepyung.es.kr",
  },
  {
    id: 47,
    type: "공립",
    establush: "서부교육지원청",
    school: "사동초등학교",
    address: "부산광역시 사하구 승학로 129-2",
    phone: "051-200-3500",
    fax: "051-200-3599",
    zipCode: 49322,
    homepage: "http://www.psadong.es.kr",
  },
  {
    id: 48,
    type: "공립",
    establush: "서부교육지원청",
    school: "사남초등학교",
    address: "부산광역시 사하구 장평로 390",
    phone: "051-220-0400",
    fax: "051-220-0499",
    zipCode: 49392,
    homepage: "http://www.sanam.es.kr",
  },
  {
    id: 49,
    type: "사립",
    establush: "서부교육지원청",
    school: "부산알로이시오초등학교",
    address: "부산광역시 서구 천해로13번길 16-9",
    phone: "051-231-6580",
    fax: "051-231-6376",
    zipCode: 49260,
    homepage: "http://aloysius-bs.es.kr",
  },
  {
    id: 50,
    type: "공립",
    establush: "서부교육지원청",
    school: "부민초등학교",
    address: "부산광역시 서구 고운들로12",
    phone: "051-603-2100",
    fax: "051-248-2132",
    zipCode: 49233,
    homepage: "http://www.bumin.es.kr",
  },
  {
    id: 51,
    type: "공립",
    establush: "서부교육지원청",
    school: "봉학초등학교",
    address: "부산광역시 영도구 태종로 242",
    phone: "051-410-0683",
    fax: "051-413-9726",
    zipCode: 49025,
    homepage: "http://www.bonghak.es.kr",
  },
  {
    id: 52,
    type: "공립",
    establush: "서부교육지원청",
    school: "봉삼초등학교",
    address: "부산광역시 영도구 함지로79번길 39",
    phone: "051-405-0916",
    fax: "051-405-4222",
    zipCode: 49113,
    homepage: "http://www.bongsam.es.kr",
  },
  {
    id: 53,
    type: "공립",
    establush: "서부교육지원청",
    school: "봉래초등학교",
    address: "부산광역시 중구 대영로226번길 15",
    phone: "051-719-1500",
    fax: "051-719-1599",
    zipCode: 48922,
    homepage: "http://www.pusanbongrae.es.kr",
  },
  {
    id: 54,
    type: "공립",
    establush: "서부교육지원청",
    school: "보수초등학교",
    address: "부산광역시 중구 흑교로 74",
    phone: "051-255-7975",
    fax: "051-248-1151",
    zipCode: 48962,
    homepage: "http://www.bosu.es.kr",
  },
  {
    id: 55,
    type: "공립",
    establush: "서부교육지원청",
    school: "보림초등학교",
    address: "부산광역시 사하구 장림로161번길 20",
    phone: "051-260-1903",
    fax: "051-260-1917",
    zipCode: 49473,
    homepage: "http://www.borim.es.kr",
  },
  {
    id: 56,
    type: "공립",
    establush: "서부교육지원청",
    school: "몰운대초등학교",
    address: "부산광역시 사하구 다대낙조2길 110",
    phone: "051-290-3300",
    fax: "051-290-3397",
    zipCode: 49497,
    homepage: "http://www.morundae.es.kr",
  },
  {
    id: 57,
    type: "공립",
    establush: "서부교육지원청",
    school: "동신초등학교",
    address: "부산광역시 서구 대영로85번길 81-19",
    phone: "051-240-0700",
    fax: "051-245-1795",
    zipCode: 49212,
    homepage: "http://www.dongsin.es.kr",
  },
  {
    id: 58,
    type: "공립",
    establush: "서부교육지원청",
    school: "동삼초등학교",
    address: "부산광역시 영도구 태종로 610",
    phone: "051-403-9555",
    fax: "051-403-6088",
    zipCode: 49100,
    homepage: "http://www.dongsam.es.kr",
  },
  {
    id: 59,
    type: "공립",
    establush: "동래교육지원청",
    school: "연신초등학교",
    address: "부산광역시 연제구 신금로 23번길 30",
    phone: "051-502-5537",
    fax: "051-790-6767",
    zipCode: 47560,
    homepage: "http://yonsin.es.kr/",
  },
  {
    id: 60,
    type: "공립",
    establush: "동래교육지원청",
    school: "연서초등학교",
    address: "부산광역시 연제구 거제천로 270번길 30",
    phone: "051-790-5403",
    fax: "051-864-5464",
    zipCode: 47518,
    homepage: "http://yeonseo.es.kr/",
  },
  {
    id: 61,
    type: "공립",
    establush: "동래교육지원청",
    school: "연산초등학교",
    address: "부산광역시 연제구 월드컵대로 41",
    phone: "051-850-1901",
    fax: "051-867-8503",
    zipCode: 47602,
    homepage: "http://yonsan.es.kr/",
  },
  {
    id: 62,
    type: "공립",
    establush: "동래교육지원청",
    school: "연미초등학교",
    address: "부산광역시 연제구 금련로 38번길 84",
    phone: "051-852-0539",
    fax: "051-860-8309",
    zipCode: 47613,
    homepage: "http://yeunmi.es.kr/",
  },
  {
    id: 63,
    type: "공립",
    establush: "동래교육지원청",
    school: "연동초등학교",
    address: "부산광역시 연제구 고분로 56번길 40",
    phone: "051-853-7172",
    fax: "051-867-4698",
    zipCode: 47579,
    homepage: "http://yondong.es.kr/",
  },
  {
    id: 64,
    type: "공립",
    establush: "동래교육지원청",
    school: "여고초등학교",
    address: "부산광역시 동래구 여고로 63번 가길 12",
    phone: "051-590-0500",
    fax: "051-590-0598",
    zipCode: 47843,
    homepage: "http://yeogo.es.kr/",
  },
  {
    id: 65,
    type: "공립",
    establush: "동래교육지원청",
    school: "안진초등학교",
    address: "부산광역시 동래구 안남로 63",
    phone: "051-520-1600",
    fax: "051-520-1604",
    zipCode: 47894,
    homepage: "http://anjin.es.kr/",
  },
  {
    id: 66,
    type: "공립",
    establush: "동래교육지원청",
    school: "안민초등학교",
    address: "부산광역시 동래구 온천천로 381번길 19",
    phone: "051-580-4500",
    fax: "051-580-4599",
    zipCode: 47903,
    homepage: "http://anmin.es.kr/",
  },
  {
    id: 67,
    type: "공립",
    establush: "동래교육지원청",
    school: "안락초등학교",
    address: "부산광역시 동래구 명안로 53번길 22",
    phone: "051-713-8500",
    fax: "051-526-7708",
    zipCode: 47779,
    homepage: "http://anrak.es.kr/",
  },
  {
    id: 68,
    type: "공립",
    establush: "동래교육지원청",
    school: "안남초등학교",
    address: "부산광역시 동래구 연안로 46",
    phone: "051-520-6600",
    fax: "051-520-6670",
    zipCode: 47905,
    homepage: "http://annam.es.kr/",
  },
  {
    id: 69,
    type: "공립",
    establush: "동래교육지원청",
    school: "수안초등학교",
    address: "부산광역시 동래구 온천천로 215",
    phone: "051-550-8700",
    fax: "051-554-3365",
    zipCode: 47819,
    homepage: "http://suan.es.kr/",
  },
  {
    id: 70,
    type: "공립",
    establush: "동래교육지원청",
    school: "서명초등학교",
    address: "부산광역시 금정구 명서로 48",
    phone: "051-529-6595",
    fax: "051-529-6606",
    zipCode: 46324,
    homepage: "http://seomyeong.es.kr/",
  },
  {
    id: 71,
    type: "공립",
    establush: "동래교육지원청",
    school: "서동초등학교",
    address: "부산광역시 금정구 동현로 147",
    phone: "051-523-9247",
    fax: "051-985-3233",
    zipCode: 46319,
    homepage: "http://seodong.es.kr/",
  },
  {
    id: 72,
    type: "공립",
    establush: "동래교육지원청",
    school: "연일초등학교",
    address: "부산광역시 연제구 고분로 126-4",
    phone: "051-853-8436",
    fax: "051-790-0610",
    zipCode: 47583,
    homepage: "http://yonil.es.kr/",
  },
  {
    id: 73,
    type: "공립",
    establush: "동래교육지원청",
    school: "연제초등학교",
    address: "부산광역시 연제구 중앙대로 1065번길 14",
    phone: "051-867-8211",
    fax: "051-790-7692",
    zipCode: 47541,
    homepage: "http://yeonje.es.kr/",
  },
  {
    id: 74,
    type: "공립",
    establush: "동래교육지원청",
    school: "연천초등학교",
    address: "부산광역시 연제구 과정로225번길 46",
    phone: "051-850-1770",
    fax: "051-850-1795",
    zipCode: 47557,
    homepage: "http://yonchon.es.kr/",
  },
  {
    id: 75,
    type: "공립",
    establush: "동래교육지원청",
    school: "예원초등학교",
    address: "부산광역시 동래구 사직로 78",
    phone: "051-500-0400",
    fax: "051-500-0404",
    zipCode: 47863,
    homepage: "http://yewon.es.kr/",
  },
  {
    id: 76,
    type: "공립",
    establush: "동래교육지원청",
    school: "온샘초등학교(신설)",
    address: "부산광역시 동래구 충렬대로 75번길 25",
    phone: "미정",
    fax: "미정",
    zipCode: 47724,
    homepage: "미정",
  },
  {
    id: 77,
    type: "공립",
    establush: "동래교육지원청",
    school: "온천초등학교",
    address: "부산광역시 동래구 충렬대로 107번길 124",
    phone: "051-550-7800",
    fax: "051-550-7809",
    zipCode: 47725,
    homepage: "http://onchun.es.kr/",
  },
  {
    id: 78,
    type: "공립",
    establush: "동래교육지원청",
    school: "장서초등학교",
    address: "부산광역시 금정구 금강로 453번길 26-7",
    phone: "051-515-3606",
    fax: "051-515-3962",
    zipCode: 46242,
    homepage: "http://jangseo.es.kr/",
  },
  {
    id: 79,
    type: "공립",
    establush: "동래교육지원청",
    school: "장전초등학교",
    address: "부산광역시 금정구 수림로 90",
    phone: "051-797-9207",
    fax: "051-797-9299",
    zipCode: 46247,
    homepage: "http://jangjeon.es.kr/",
  },
  {
    id: 80,
    type: "공립",
    establush: "동래교육지원청",
    school: "창신초등학교",
    address: "부산광역시 연제구 종합운동장로 12번길 15",
    phone: "051-506-0393",
    fax: "051-506-0392",
    zipCode: 47501,
    homepage: "http://changshin.es.kr/",
  },
  {
    id: 81,
    type: "공립",
    establush: "동래교육지원청",
    school: "청룡초등학교",
    address: "부산광역시 금정구 팔송로 6",
    phone: "051-508-8242",
    fax: "051-580-9309",
    zipCode: 46218,
    homepage: "http://cheongryong.es.kr/",
  },
  {
    id: 82,
    type: "공립",
    establush: "동래교육지원청",
    school: "충렬초등학교",
    address: "부산광역시 동래구 안락로 125번길 22",
    phone: "051-997-4100",
    fax: "051-997-4104",
    zipCode: 47785,
    homepage: "http://pscr.es.kr/",
  },
  {
    id: 83,
    type: "공립",
    establush: "동래교육지원청",
    school: "토현초등학교",
    address: "부산광역시 연제구 토현로 48",
    phone: "051-759-1216",
    fax: "051-759-6225",
    zipCode: 47572,
    homepage: "http://tohyeon.es.kr/",
  },
  {
    id: 84,
    type: "공립",
    establush: "동래교육지원청",
    school: "현곡초등학교",
    address: "부산광역시 금정구 동부곡로 27번라길 20",
    phone: "051-512-0711",
    fax: "051-515-0482",
    zipCode: 46270,
    homepage: "http://hyungok.es.kr/",
  },
  {
    id: 85,
    type: "사립",
    establush: "동래교육지원청",
    school: "혜화초등학교",
    address: "부산광역시 동래구 명장로 99번길 25",
    phone: "051-531-1664",
    fax: "051-524-5371",
    zipCode: 47769,
    homepage: "http://hyehwa.es.kr/",
  },
  {
    id: 86,
    type: "공립",
    establush: "해운대교육지원청",
    school: "가동초등학교",
    address: "부산광역시 기장군 정관읍 정관5로 100",
    phone: "051-780-3700",
    fax: "051-727-9558",
    zipCode: 46013,
    homepage: "http://ga-dong.es.kr/",
  },
  {
    id: 87,
    type: "사립",
    establush: "동래교육지원청",
    school: "부산삼육초등학교",
    address: "부산광역시 금정구 금정도서관로 17",
    phone: "051-442-3691",
    fax: "051-442-3696",
    zipCode: 46213,
    homepage: "http://bs36.com/",
  },
  {
    id: 88,
    type: "공립",
    establush: "동래교육지원청",
    school: "사직초등학교",
    address: "부산광역시 동래구 쇠미로 81번길 7",
    phone: "051-501-4267",
    fax: "051-506-0929",
    zipCode: 47872,
    homepage: "http://sajik.es.kr/",
  },
  {
    id: 89,
    type: "국립",
    establush: "동래교육지원청",
    school: "부산교육대학교부설초등학교",
    address: "부산광역시 연제구 교대로 24",
    phone: "051-505-9123",
    fax: "051-505-9121",
    zipCode: 47503,
    homepage: "http://es.bnue.ac.kr/",
  },
  {
    id: 90,
    type: "공립",
    establush: "동래교육지원청",
    school: "공덕초등학교",
    address: "부산광역시 금정구 대두로 2",
    phone: "051-508-2362",
    fax: "051-508-4761",
    zipCode: 46206,
    homepage: "http://kongduk.es.kr/",
  },
  {
    id: 91,
    type: "공립",
    establush: "동래교육지원청",
    school: "과정초등학교",
    address: "부산광역시 연제구 과정로 164번길 37",
    phone: "051-758-8941",
    fax: "051-758-8944",
    zipCode: 47569,
    homepage: "http://kwajung.es.kr/",
  },
  {
    id: 92,
    type: "공립",
    establush: "동래교육지원청",
    school: "교동초등학교",
    address: "부산광역시 동래구 시실로 73-13",
    phone: "051-620-0104",
    fax: "051-6200-170",
    zipCode: 47756,
    homepage: "http://kd.es.kr/",
  },
  {
    id: 93,
    type: "공립",
    establush: "동래교육지원청",
    school: "구서초등학교",
    address: "부산광역시 금정구 구서중앙로 35",
    phone: "051-516-2994",
    fax: "051-516-2996",
    zipCode: 46231,
    homepage: "http://kuseo.es.kr/",
  },
  {
    id: 94,
    type: "공립",
    establush: "동래교육지원청",
    school: "금강초등학교",
    address: "부산광역시 동래구 사직북로 28번길 125",
    phone: "051-590-0600",
    fax: "051-505-9874",
    zipCode: 47852,
    homepage: "http://geumgang.es.kr/",
  },
  {
    id: 95,
    type: "공립",
    establush: "동래교육지원청",
    school: "금빛초등학교",
    address: "부산광역시 금정구 소정로 31",
    phone: "051-719-8374",
    fax: "051-719-8377",
    zipCode: 46296,
    homepage: "http://gbit.es.kr/",
  },
  {
    id: 96,
    type: "공립",
    establush: "동래교육지원청",
    school: "금사초등학교",
    address: "부산광역시 금정구 금사로 57번길 24",
    phone: "051-531-3594",
    fax: "051-523-8455",
    zipCode: 46314,
    homepage: "http://kumsa.es.kr/",
  },
  {
    id: 97,
    type: "공립",
    establush: "동래교육지원청",
    school: "금샘초등학교",
    address: "부산광역시 금정구 금샘로 525",
    phone: "051-519-0800",
    fax: "051-518-0948",
    zipCode: 46234,
    homepage: "http://geumsaem.es.kr/",
  },
  {
    id: 98,
    type: "공립",
    establush: "동래교육지원청",
    school: "금성초등학교",
    address: "부산광역시 금정구 중리1길 15",
    phone: "051-517-5400",
    fax: "051-517-8690",
    zipCode: 46237,
    homepage: "http://gumseang.es.kr/",
  },
  {
    id: 99,
    type: "공립",
    establush: "동래교육지원청",
    school: "금양초등학교",
    address: "부산광역시 금정구 중앙대로 1755",
    phone: "051-583-5884",
    fax: "051-550-0404",
    zipCode: 46275,
    homepage: "http://kumyang.es.kr/",
  },
  {
    id: 100,
    type: "공립",
    establush: "동래교육지원청",
    school: "금정초등학교",
    address: "부산광역시 금정구 식물원로44번길 46",
    phone: "051-719-5405",
    fax: "051-719-5490",
    zipCode: 46297,
    homepage: "http://gumjeong.es.kr/",
  },
  {
    id: 101,
    type: "공립",
    establush: "동래교육지원청",
    school: "낙민초등학교",
    address: "부산광역시 동래구 충렬대로 272번길 34",
    phone: "051-712-5205",
    fax: "051-553-9896",
    zipCode: 47878,
    homepage: "http://nakmin.es.kr/",
  },
  {
    id: 102,
    type: "공립",
    establush: "동래교육지원청",
    school: "남문초등학교",
    address: "부산광역시 연제구 법원북로 23",
    phone: "051-507-0521",
    fax: "051-507-0525",
    zipCode: 47502,
    homepage: "http://nammun.es.kr/",
  },
  {
    id: 103,
    type: "공립",
    establush: "동래교육지원청",
    school: "남산초등학교",
    address: "부산광역시 금정구 금강로 614",
    phone: "051-550-5100",
    fax: "051-513-7451",
    zipCode: 46227,
    homepage: "http://pusannamsan.es.kr/",
  },
  {
    id: 104,
    type: "공립",
    establush: "동래교육지원청",
    school: "내산초등학교",
    address: "부산광역시 동래구 중앙대로 1335번길 38",
    phone: "051-552-9886",
    fax: "051-792-0498",
    zipCode: 47732,
    homepage: "http://naesan.es.kr/",
  },
  {
    id: 105,
    type: "공립",
    establush: "동래교육지원청",
    school: "내성초등학교",
    address: "부산광역시 동래구 동래로 173",
    phone: "051-550-8600",
    fax: "051-550-8686",
    zipCode: 47804,
    homepage: "http://naesung.es.kr/",
  },
  {
    id: 106,
    type: "공립",
    establush: "동래교육지원청",
    school: "달북초등학교",
    address: "부산광역시 동래구 쇠미로 131",
    phone: "051-790-0500",
    fax: "051-502-7981",
    zipCode: 47869,
    homepage: "http://dalbuk.es.kr/",
  },
  {
    id: 107,
    type: "사립",
    establush: "동래교육지원청",
    school: "동래초등학교",
    address: "부산광역시 금정구 체육공원로 44번길 26",
    phone: "051-581-0901",
    fax: "051-581-0903",
    zipCode: 46251,
    homepage: "http://dongrae.es.kr/",
  },
  {
    id: 108,
    type: "공립",
    establush: "동래교육지원청",
    school: "동명초등학교",
    address: "부산광역시 연제구 쌍미천로 44번길 17",
    phone: "051-867-1459",
    fax: "051-863-2966",
    zipCode: 47589,
    homepage: "http://dongmyung.es.kr/",
  },
  {
    id: 109,
    type: "공립",
    establush: "동래교육지원청",
    school: "동상초등학교",
    address: "부산광역시 금정구 옥봉로 5",
    phone: "051-523-7892",
    fax: "051-528-5583",
    zipCode: 46335,
    homepage: "http://dongsang.es.kr/",
  },
  {
    id: 110,
    type: "공립",
    establush: "동래교육지원청",
    school: "동현초등학교",
    address: "부산광역시 금정구 동현로 36",
    phone: "070-8620-7004",
    fax: "051-518-5381",
    zipCode: 46305,
    homepage: "http://bs-donghyun.es.kr/",
  },
  {
    id: 111,
    type: "공립",
    establush: "동래교육지원청",
    school: "두실초등학교",
    address: "부산광역시 금정구 중앙대로 1893번길 33",
    phone: "051-580-0400",
    fax: "051-580-0498",
    zipCode: 46233,
    homepage: "http://dusil.es.kr/",
  },
  {
    id: 112,
    type: "공립",
    establush: "동래교육지원청",
    school: "명동초등학교",
    address: "부산광역시 동래구 명장로 63번길 60-5",
    phone: "051-526-7221",
    fax: "051-527-8120",
    zipCode: 47767,
    homepage: "http://mdong.es.kr/",
  },
  {
    id: 113,
    type: "공립",
    establush: "동래교육지원청",
    school: "명륜초등학교",
    address: "부산광역시 동래구 동래로 91",
    phone: "051-713-6801",
    fax: "051-713-6819",
    zipCode: 47748,
    homepage: "http://myeongryun.es.kr/",
  },
  {
    id: 114,
    type: "공립",
    establush: "동래교육지원청",
    school: "명서초등학교",
    address: "부산광역시 동래구 명서로 121",
    phone: "051-531-0749",
    fax: "051-526-4767",
    zipCode: 47760,
    homepage: "http://myongseo.es.kr/",
  },
  {
    id: 115,
    type: "공립",
    establush: "동래교육지원청",
    school: "명장초등학교",
    address: "부산광역시 동래구 복천로 121",
    phone: "051-529-0643",
    fax: "051-529-0646",
    zipCode: 47749,
    homepage: "http://myungjang.es.kr/",
  },
  {
    id: 116,
    type: "공립",
    establush: "동래교육지원청",
    school: "미남초등학교",
    address: "부산광역시 동래구 충렬대로 100번길 83",
    phone: "051-506-1343",
    fax: "051-503-3854",
    zipCode: 47826,
    homepage: "http://minam.es.kr/",
  },
  {
    id: 117,
    type: "공립",
    establush: "동래교육지원청",
    school: "부곡초등학교",
    address: "부산광역시 금정구 기찰로 22번길 15",
    phone: "051-580-4800",
    fax: "051-580-4899",
    zipCode: 46269,
    homepage: "http://pugok.es.kr/",
  },
  {
    id: 118,
    type: "공립",
    establush: "해운대교육지원청",
    school: "강동초등학교",
    address: "부산광역시 해운대구 해운대로349번길 10",
    phone: "051-740-2400",
    fax: "051-744-2008",
    zipCode: 48062,
    homepage: "http://pusangangdong.es.kr/",
  },
  {
    id: 119,
    type: "공립",
    establush: "해운대교육지원청",
    school: "광남초등학교",
    address: "부산광역시 수영구 광안해변로 115",
    phone: "051-610-6600",
    fax: "051-623-2826",
    zipCode: 48305,
    homepage: "http://www.gwangnam.es.kr/",
  },
  {
    id: 120,
    type: "공립",
    establush: "해운대교육지원청",
    school: "광안초등학교",
    address: "부산광역시 수영구 광안로35번길 42",
    phone: "051-790-2200",
    fax: "051-790-2299",
    zipCode: 48296,
    homepage: "http://kwangan.es.kr/",
  },
  {
    id: 121,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신정초등학교",
    address: "부산광역시 기장군 정관읍 정관로 460",
    phone: "051-745-3500",
    fax: "051-727-2614",
    zipCode: 46011,
    homepage: "http://sinjeong.kr/",
  },
  {
    id: 122,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신진초등학교",
    address: "부산광역시 기장군 철마면 고촌로28번길 10",
    phone: "051-720-8500",
    fax: "051-720-8599",
    zipCode: 46051,
    homepage: "http://www.sinjin.es.kr/",
  },
  {
    id: 123,
    type: "공립",
    establush: "해운대교육지원청",
    school: "양운초등학교",
    address: "부산광역시 해운대구 대천로67번길 36",
    phone: "051-709-6800",
    fax: "051-709-6897",
    zipCode: 48080,
    homepage: "http://www.yangun.es.kr/",
  },
  {
    id: 124,
    type: "공립",
    establush: "해운대교육지원청",
    school: "용암초등학교",
    address: "부산광역시 기장군 기장읍 기장해안로 577",
    phone: "051-721-1302",
    fax: "051-721-1307",
    zipCode: 46079,
    homepage: "http://yongam.es.kr/",
  },
  {
    id: 125,
    type: "공립",
    establush: "해운대교육지원청",
    school: "운봉초등학교",
    address: "부산광역시 해운대구 신반송로 11-20",
    phone: "051-542-8272",
    fax: "051-540-5549",
    zipCode: 48009,
    homepage: "http://www.unbong.es.kr/",
  },
  {
    id: 126,
    type: "공립",
    establush: "해운대교육지원청",
    school: "운송초등학교",
    address: "부산광역시 해운대구 반송로 839",
    phone: "051-540-5300",
    fax: "051-544-7091",
    zipCode: 48000,
    homepage: "http://www.woonsong.es.kr/",
  },
  {
    id: 127,
    type: "공립",
    establush: "해운대교육지원청",
    school: "월내초등학교",
    address: "부산광역시 기장군 장안읍 길천2길 25",
    phone: "051-719-9700",
    fax: "051-719-9710",
    zipCode: 46036,
    homepage: "http://www.wollae.es.kr/",
  },
  {
    id: 128,
    type: "공립",
    establush: "해운대교육지원청",
    school: "월평초등학교",
    address: "부산광역시 기장군 정관읍 월평1길 21",
    phone: "051-508-8352",
    fax: "051-508-8354",
    zipCode: 46000,
    homepage: "http://www.bswp.es.kr/",
  },
  {
    id: 129,
    type: "공립",
    establush: "해운대교육지원청",
    school: "인지초등학교",
    address: "부산광역시 해운대구 반여로 128",
    phone: "051-520-0100",
    fax: "051-528-0840",
    zipCode: 48036,
    homepage: "http://ij.es.kr/",
  },
  {
    id: 130,
    type: "공립",
    establush: "해운대교육지원청",
    school: "일광초등학교",
    address: "부산광역시 기장군 일광면 해빛로 46",
    phone: "051-930-7770",
    fax: "051-930-7798",
    zipCode: 46048,
    homepage: "http://www.ilgwang.es.kr",
  },
  {
    id: 131,
    type: "공립",
    establush: "해운대교육지원청",
    school: "장산초등학교",
    address: "부산광역시 해운대구 선수촌로 68",
    phone: "051-520-1500",
    fax: "051-531-5342",
    zipCode: 48039,
    homepage: "http://www.jangsan.es.kr/",
  },
  {
    id: 132,
    type: "공립",
    establush: "해운대교육지원청",
    school: "장안초등학교",
    address: "부산광역시 기장군 장안읍 장안로 67-4",
    phone: "051-727-1000",
    fax: "051-727-1075",
    zipCode: 46032,
    homepage: "http://www.jangancho.es.kr/",
  },
  {
    id: 133,
    type: "공립",
    establush: "해운대교육지원청",
    school: "재송초등학교",
    address: "부산광역시 해운대구 재반로64번길 33",
    phone: "051-780-3500",
    fax: "051-780-3598",
    zipCode: 48053,
    homepage: "http://www.jaesong.es.kr/",
  },
  {
    id: 134,
    type: "공립",
    establush: "해운대교육지원청",
    school: "정관초등학교",
    address: "부산광역시 기장군 정관읍 정관로 530",
    phone: "051-720-1500",
    fax: "051-720-1548",
    zipCode: 46016,
    homepage: "http://www.jungkwan.es.kr/",
  },
  {
    id: 135,
    type: "공립",
    establush: "해운대교육지원청",
    school: "정원초등학교",
    address: "부산광역시 기장군 정관읍 정관로 423",
    phone: "051-750-9100",
    fax: "051-750-9188",
    zipCode: 46009,
    homepage: "http://www.jeongwon.es.kr/",
  },
  {
    id: 136,
    type: "공립",
    establush: "해운대교육지원청",
    school: "좌동초등학교",
    address: "부산광역시 해운대구 세실로 127",
    phone: "051-701-4734",
    fax: "051-702-4771",
    zipCode: 48078,
    homepage: "http://www.jwadong.es.kr/",
  },
  {
    id: 137,
    type: "공립",
    establush: "해운대교육지원청",
    school: "좌산초등학교",
    address: "부산광역시 해운대구 좌동순환로311번길 7",
    phone: "051-709-1700",
    fax: "051-709-1770",
    zipCode: 48113,
    homepage: "http://jwasan.es.kr/",
  },
  {
    id: 138,
    type: "공립",
    establush: "해운대교육지원청",
    school: "좌천초등학교",
    address: "부산광역시 기장군 장안읍 좌천로 13-66",
    phone: "051-720-4101",
    fax: "051-727-6803",
    zipCode: 46033,
    homepage: "http://www.jwacheon.es.kr/",
  },
  {
    id: 139,
    type: "공립",
    establush: "해운대교육지원청",
    school: "죽성초등학교",
    address: "부산광역시 기장군 기장읍 죽성로 270",
    phone: "051-721-6470",
    fax: "051-721-6462",
    zipCode: 46078,
    homepage: "http://www.jukseong.es.kr/",
  },
  {
    id: 140,
    type: "공립",
    establush: "해운대교육지원청",
    school: "철마초등학교",
    address: "부산광역시 기장군 철마면 철마로 493",
    phone: "051-780-7500",
    fax: "051-721-9030",
    zipCode: 46049,
    homepage: "http://www.chulma.es.kr/",
  },
  {
    id: 141,
    type: "공립",
    establush: "해운대교육지원청",
    school: "칠암초등학교",
    address: "부산광역시 기장군 일광면 박영준길 19",
    phone: "051-727-0526",
    fax: "051-727-5957",
    zipCode: 46040,
    homepage: "http://www.chilam.es.kr/",
  },
  {
    id: 142,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해강초등학교",
    address: "부산광역시 해운대구 해운대해변로 73",
    phone: "051-745-4500",
    fax: "051-745-4506",
    zipCode: 48091,
    homepage: "http://www.haegang.es.kr/",
  },
  {
    id: 143,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해동초등학교",
    address: "부산광역시 해운대구 우동1로 104",
    phone: "051-740-7201",
    fax: "051-740-7292",
    zipCode: 48085,
    homepage: "http://haedong.es.kr",
  },
  {
    id: 144,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해림초등학교",
    address: "부산광역시 해운대구 해운대로469번길 42",
    phone: "051-740-1500",
    fax: "051-740-1590",
    zipCode: 48064,
    homepage: "http://www.haerim.es.kr/",
  },
  {
    id: 145,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해빛초등학교",
    address: "부산광역시 기장군 일광면 해빛6로 9",
    phone: "051-790-8300",
    fax: "051-790-8305",
    zipCode: 46048,
    homepage: "http://haebit.es.kr",
  },
  {
    id: 146,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해송초등학교",
    address: "부산광역시 해운대구 좌동순환로402번길 32",
    phone: "051-709-6900",
    fax: "051-731-3949",
    zipCode: 48104,
    homepage: "http://haesong.es.kr/",
  },
  {
    id: 147,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해운대초등학교",
    address: "부산광역시 해운대구 중동2로24번길 30",
    phone: "051-746-0045",
    fax: "051-743-6227",
    zipCode: 48096,
    homepage: "http://www.haeundae.es.kr/",
  },
  {
    id: 148,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해원초등학교",
    address: "부산광역시 해운대구 마린시티2로 10",
    phone: "051-740-9900",
    fax: "051-744-8228",
    zipCode: 48092,
    homepage: "http://haewon.es.kr/",
  },
  {
    id: 149,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신재초등학교",
    address: "부산광역시 해운대구 해운대로81번길 55",
    phone: "051-780-3800",
    fax: "051-784-9790",
    zipCode: 48051,
    homepage: "http://www.sinjae.es.kr/",
  },
  {
    id: 150,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신도초등학교",
    address: "부산광역시 해운대구 좌동순환로 34",
    phone: "051-750-0910",
    fax: "051-731-0249",
    zipCode: 48083,
    homepage: "http://sin-do.es.kr/",
  },
  {
    id: 151,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신곡초등학교",
    address: "부산광역시 해운대구 좌동순환로 290",
    phone: "051-702-6266",
    fax: "051-702-6270",
    zipCode: 48110,
    homepage: "http://www.singok.es.kr/",
  },
  {
    id: 152,
    type: "공립",
    establush: "해운대교육지원청",
    school: "교리초등학교",
    address: "부산광역시 기장군 기장읍 차성로 427",
    phone: "051-720-0503",
    fax: "051-720-0573",
    zipCode: 46054,
    homepage: "http://gyori.es.kr/",
  },
  {
    id: 153,
    type: "공립",
    establush: "해운대교육지원청",
    school: "기장초등학교",
    address: "부산광역시 기장군 기장읍 읍내로 69",
    phone: "051-722-8600",
    fax: "051-720-8649",
    zipCode: 46060,
    homepage: "http://www.gijang.es.kr/",
  },
  {
    id: 154,
    type: "공립",
    establush: "해운대교육지원청",
    school: "남천초등학교",
    address: "부산광역시 수영구 남천서로 14",
    phone: "051-610-3702",
    fax: "051-610-3799",
    zipCode: 48316,
    homepage: "http://www.namcheon.es.kr/",
  },
  {
    id: 155,
    type: "공립",
    establush: "해운대교육지원청",
    school: "내리초등학교",
    address: "부산광역시 기장군 기장읍 소정안길 50",
    phone: "051-745-3100",
    fax: "051-723-3198",
    zipCode: 46076,
    homepage: "http://naeri.es.kr",
  },
  {
    id: 156,
    type: "공립",
    establush: "해운대교육지원청",
    school: "달산초등학교",
    address: "부산광역시 기장군 정관읍 정관로 674",
    phone: "051-750-9900",
    fax: "051-728-8008",
    zipCode: 46025,
    homepage: "http://www.bsdalsan.es.kr/",
  },
  {
    id: 157,
    type: "공립",
    establush: "해운대교육지원청",
    school: "대청초등학교",
    address: "부산광역시 기장군 기장읍 차성서로 31",
    phone: "051-723-0923",
    fax: "051-723-0927",
    zipCode: 46068,
    homepage: "http://www.daechung.es.kr/",
  },
  {
    id: 158,
    type: "공립",
    establush: "해운대교육지원청",
    school: "동백초등학교",
    address: "부산광역시 해운대구 좌동순환로357번길 6",
    phone: "051-746-7367",
    fax: "051-746-7397",
    zipCode: 48114,
    homepage: "http://www.dongbaek.es.kr/",
  },
  {
    id: 159,
    type: "공립",
    establush: "해운대교육지원청",
    school: "망미초등학교",
    address: "부산광역시 수영구 수미로35번길 27",
    phone: "051-796-5501",
    fax: "051-796-5569",
    zipCode: 48216,
    homepage: "http://www.mangmi.es.kr/",
  },
  {
    id: 160,
    type: "공립",
    establush: "해운대교육지원청",
    school: "모전초등학교",
    address: "부산광역시 기장군 정관읍 정관1로 17",
    phone: "051-720-6701",
    fax: "051-728-7386",
    zipCode: 46008,
    homepage: "http://bsmojeon.es.kr/",
  },
  {
    id: 161,
    type: "공립",
    establush: "해운대교육지원청",
    school: "무정초등학교",
    address: "부산광역시 해운대구 반여로 68-11",
    phone: "051-520-1301",
    fax: "051-520-1339",
    zipCode: 48040,
    homepage: "http://www.bsmj.es.kr/",
  },
  {
    id: 162,
    type: "공립",
    establush: "해운대교육지원청",
    school: "민락초등학교",
    address: "부산광역시 수영구 광남로257번길 12",
    phone: "051-752-8694",
    fax: "051-753-5702",
    zipCode: 48275,
    homepage: "http://www.minlak.es.kr/",
  },
  {
    id: 163,
    type: "공립",
    establush: "해운대교육지원청",
    school: "민안초등학교",
    address: "부산광역시 수영구 감포로38번길 33",
    phone: "051-751-3931",
    fax: "051-751-3950",
    zipCode: 48274,
    homepage: "http://minan.es.kr/",
  },
  {
    id: 164,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반산초등학교",
    address: "부산광역시 해운대구 재반로 171",
    phone: "051-780-2000",
    fax: "051-780-2005",
    zipCode: 48051,
    homepage: "http://www.bansan.es.kr/",
  },
  {
    id: 165,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반석초등학교",
    address: "부산광역시 해운대구 선수촌로207번길 14",
    phone: "051-523-8171",
    fax: "051-523-0183",
    zipCode: 48032,
    homepage: "http://www.bansuk.es.kr",
  },
  {
    id: 166,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반송초등학교",
    address: "부산광역시 해운대구 아랫반송로30번길 37-16",
    phone: "051-540-5800",
    fax: "051-544-1120",
    zipCode: 48018,
    homepage: "http://www.bansong.es.kr/",
  },
  {
    id: 167,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반안초등학교",
    address: "부산광역시 해운대구 명장로67번길 40-42",
    phone: "051-520-0801",
    fax: "051-521-1694",
    zipCode: 48046,
    homepage: "http://www.banan.es.kr",
  },
  {
    id: 168,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반여초등학교",
    address: "부산광역시 해운대구 재반로 301",
    phone: "051-520-0400",
    fax: "051-520-0404",
    zipCode: 48036,
    homepage: "http://banyeo.es.kr/",
  },
  {
    id: 169,
    type: "공립",
    establush: "해운대교육지원청",
    school: "방곡초등학교",
    address: "부산광역시 기장군 정관읍 정관덕산길 61",
    phone: "051-780-7600",
    fax: "051-727-7941",
    zipCode: 46022,
    homepage: "http://banggok.es.kr/",
  },
  {
    id: 170,
    type: "공립",
    establush: "해운대교육지원청",
    school: "배산초등학교",
    address: "부산광역시 수영구 망미배산로48번길 85-15",
    phone: "051-999-7004",
    fax: "051-754-5140",
    zipCode: 48201,
    homepage: "http://www.baesan.es.kr/",
  },
  {
    id: 171,
    type: "공립",
    establush: "해운대교육지원청",
    school: "부흥초등학교",
    address: "부산광역시 해운대구 좌동순환로 216",
    phone: "051-703-0093",
    fax: "051-703-0993",
    zipCode: 48077,
    homepage: "http://www.buhung.es.kr/",
  },
  {
    id: 172,
    type: "공립",
    establush: "해운대교육지원청",
    school: "삼어초등학교",
    address: "부산광역시 해운대구 삼어로 91번길 73",
    phone: "051-520-6500",
    fax: "051-522-0294",
    zipCode: 48045,
    homepage: "http://www.sameo.es.kr/",
  },
  {
    id: 173,
    type: "공립",
    establush: "해운대교육지원청",
    school: "상당초등학교",
    address: "부산광역시 해운대구 세실로 173",
    phone: "051-709-1000",
    fax: "051-709-1009",
    zipCode: 48075,
    homepage: "http://www.sangdang.es.kr/",
  },
  {
    id: 174,
    type: "공립",
    establush: "해운대교육지원청",
    school: "센텀초등학교",
    address: "부산광역시 해운대구 센텀중앙로 166",
    phone: "051-781-2600",
    fax: "051-781-2698",
    zipCode: 48050,
    homepage: "http://centum.es.kr/",
  },
  {
    id: 175,
    type: "공립",
    establush: "해운대교육지원청",
    school: "송수초등학교",
    address: "부산광역시 해운대구 해운대로76번길 35",
    phone: "051-780-2700",
    fax: "051-780-2792",
    zipCode: 48048,
    homepage: "http://www.songsu.es.kr/",
  },
  {
    id: 176,
    type: "공립",
    establush: "해운대교육지원청",
    school: "송운초등학교",
    address: "부산광역시 해운대구 신반송로 20",
    phone: "051-540-2600",
    fax: "051-540-2610",
    zipCode: 48008,
    homepage: "http://www.songun.es.kr/",
  },
  {
    id: 177,
    type: "공립",
    establush: "해운대교육지원청",
    school: "송정초등학교",
    address: "부산광역시 해운대구 해운대로 1164-9",
    phone: "051-709-6700",
    fax: "051-709-6799",
    zipCode: 48069,
    homepage: "http://www.hudsj.es.kr/",
  },
  {
    id: 178,
    type: "공립",
    establush: "해운대교육지원청",
    school: "수미초등학교",
    address: "부산광역시 수영구 연수로310번길 91",
    phone: "051-791-4700",
    fax: "051-791-4726",
    zipCode: 48237,
    homepage: "http://www.sumi.es.kr/",
  },
  {
    id: 179,
    type: "공립",
    establush: "해운대교육지원청",
    school: "수영초등학교",
    address: "부산광역시 수영구 광서로16번길 33",
    phone: "051-792-2500",
    fax: "051-792-2509",
    zipCode: 48246,
    homepage: "http://www.suyoung.es.kr/",
  },
  {
    id: 180,
    type: "공립",
    establush: "동래교육지원청",
    school: "거학초등학교",
    address: "부산광역시 연제구 교대로 24번길 36",
    phone: "051-502-8876",
    fax: "051-503-8031",
    zipCode: 47504,
    homepage: "http://geohak.es.kr/",
  },
  {
    id: 181,
    type: "공립",
    establush: "동래교육지원청",
    school: "거제초등학교",
    address: "부산광역시 연제구 해맞이로 39",
    phone: "051-506-0371",
    fax: "051-505-1265",
    zipCode: 47535,
    homepage: "http://gue-je.es.kr/",
  },
  {
    id: 182,
    type: "공립",
    establush: "북부교육지원청",
    school: "화정초등학교",
    address: "부산광역시 북구 의성로 23",
    phone: "051-330-4100",
    fax: "051-330-4197",
    zipCode: 46539,
    homepage: "http://www.bshj.es.kr/",
  },
  {
    id: 183,
    type: "공립",
    establush: "남부교육지원청",
    school: "성전초등학교",
    address: "부산광역시 부산진구 전포대로298번길 15",
    phone: "051-719-5701",
    fax: "051-719-5799",
    zipCode: 47236,
    homepage: "http://sungjeun.es.kr",
  },
  {
    id: 184,
    type: "공립",
    establush: "남부교육지원청",
    school: "성지초등학교",
    address: "부산광역시 부산진구 시민공원로 62",
    phone: "051-816-0243",
    fax: "051-816-0246",
    zipCode: 47205,
    homepage: "http://sungji.es.kr",
  },
  {
    id: 185,
    type: "공립",
    establush: "남부교육지원청",
    school: "성천초등학교",
    address: "부산광역시 남구 장고개로 64-41",
    phone: "051-645-8098",
    fax: "051-642-3337",
    zipCode: 48467,
    homepage: "http://sungchun.es.kr",
  },
  {
    id: 186,
    type: "공립",
    establush: "남부교육지원청",
    school: "수성초등학교",
    address: "부산광역시 동구 망양로 807",
    phone: "051-466-1837",
    fax: "051-466-9284",
    zipCode: 48706,
    homepage: "http://suseong.es.kr",
  },
  {
    id: 187,
    type: "공립",
    establush: "남부교육지원청",
    school: "수정초등학교",
    address: "부산광역시 동구 진성로 29",
    phone: "051-719-1805",
    fax: "051-466-1776",
    zipCode: 48780,
    homepage: "http://bsujeong.es.kr",
  },
  {
    id: 188,
    type: "공립",
    establush: "남부교육지원청",
    school: "신연초등학교",
    address: "부산광역시 남구 유엔로25번길 66",
    phone: "051-630-8802",
    fax: "051-642-3364",
    zipCode: 48471,
    homepage: "http://sinyoun.es.kr",
  },
  {
    id: 189,
    type: "공립",
    establush: "남부교육지원청",
    school: "양동초등학교",
    address: "부산광역시 부산진구 황령산로605번길 35-10",
    phone: "051-250-1382",
    fax: "051-863-1660",
    zipCode: 47227,
    homepage: "http://yangdong.es.kr",
  },
  {
    id: 190,
    type: "공립",
    establush: "남부교육지원청",
    school: "양성초등학교",
    address: "부산광역시 부산진구 양성로 34",
    phone: "051-868-7312",
    fax: "051-863-0987",
    zipCode: 47208,
    homepage: "http://yangsung.es.kr",
  },
  {
    id: 191,
    type: "공립",
    establush: "남부교육지원청",
    school: "양정초등학교",
    address: "부산광역시 부산진구 중앙대로941번길 24",
    phone: "051-866-6439",
    fax: "051-852-2893",
    zipCode: 47210,
    homepage: "http://yj.es.kr",
  },
  {
    id: 192,
    type: "공립",
    establush: "남부교육지원청",
    school: "연지초등학교",
    address: "부산광역시 부산진구 연지로 29",
    phone: "051-816-0543",
    fax: "051-816-0544",
    zipCode: 47131,
    homepage: "http://yeonji.es.kr",
  },
  {
    id: 193,
    type: "공립",
    establush: "남부교육지원청",
    school: "연포초등학교",
    address: "부산광역시 남구 못골로 17",
    phone: "051-645-7464",
    fax: "051-630-1089",
    zipCode: 48454,
    homepage: "https://school.busanedu.net/yeonpo-e",
  },
  {
    id: 194,
    type: "공립",
    establush: "남부교육지원청",
    school: "연학초등학교",
    address: "부산광역시 부산진구 초읍천로43번길 10",
    phone: "051-718-2802",
    fax: "051-718-2809",
    zipCode: 47106,
    homepage: "http://yeonhak.es.kr",
  },
  {
    id: 195,
    type: "공립",
    establush: "남부교육지원청",
    school: "오륙도초등학교",
    address: "부산광역시 남구 오륙도로 57",
    phone: "051-610-6801",
    fax: "051-610-6807",
    zipCode: 48594,
    homepage: "http://oryukdo.es.kr",
  },
  {
    id: 196,
    type: "공립",
    establush: "남부교육지원청",
    school: "용당초등학교",
    address: "부산광역시 남구 신선로 341",
    phone: "051-611-4176",
    fax: "051-622-1624",
    zipCode: 48548,
    homepage: "http://yongdang.es.kr",
  },
  {
    id: 197,
    type: "공립",
    establush: "남부교육지원청",
    school: "용문초등학교",
    address: "부산광역시 남구 신선로 542",
    phone: "051-610-7406",
    fax: "051-610-7405",
    zipCode: 48516,
    homepage: "http://yong-mun.es.kr",
  },
  {
    id: 198,
    type: "공립",
    establush: "남부교육지원청",
    school: "용산초등학교",
    address: "부산광역시 남구 동명로176번길 55",
    phone: "051-610-7302",
    fax: "051-626-8307",
    zipCode: 48582,
    homepage: "http://yong-san.es.kr",
  },
  {
    id: 199,
    type: "공립",
    establush: "남부교육지원청",
    school: "용소초등학교",
    address: "부산광역시 남구 수영로325번길 114",
    phone: "051-750-9702",
    fax: "051-750-9799",
    zipCode: 48432,
    homepage: "http://yong-so.es.kr",
  },
  {
    id: 200,
    type: "공립",
    establush: "남부교육지원청",
    school: "용호초등학교",
    address: "부산광역시 남구 용호로42번길 94",
    phone: "051-623-3808",
    fax: "051-623-3214",
    zipCode: 48525,
    homepage: "http://yh.es.kr",
  },
  {
    id: 201,
    type: "공립",
    establush: "남부교육지원청",
    school: "우암초등학교",
    address: "부산광역시 남구 우암로 190-19",
    phone: "070-8709-2101",
    fax: "051-632-2928",
    zipCode: 48474,
    homepage: "http://uambusan.es.kr",
  },
  {
    id: 202,
    type: "공립",
    establush: "남부교육지원청",
    school: "운산초등학교",
    address: "부산광역시 남구 용호로269번길 89",
    phone: "051-626-6302",
    fax: "051-624-4975",
    zipCode: 48589,
    homepage: "http://unsan.es.kr",
  },
  {
    id: 203,
    type: "공립",
    establush: "남부교육지원청",
    school: "전포초등학교",
    address: "부산광역시 부산진구 전포대로255번길 38",
    phone: "051-809-7862",
    fax: "051-808-4574",
    zipCode: 47243,
    homepage: "http://jeonpo.es.kr",
  },
  {
    id: 204,
    type: "공립",
    establush: "남부교육지원청",
    school: "주례초등학교",
    address: "부산광역시 부산진구 진사로61번길 28-7",
    phone: "051-890-5502",
    fax: "051-895-3004",
    zipCode: 47393,
    homepage: "http://jure.es.kr",
  },
  {
    id: 205,
    type: "공립",
    establush: "남부교육지원청",
    school: "주원초등학교",
    address: "부산광역시 부산진구 복지로 71",
    phone: "051-893-2912",
    fax: "051-896-8350",
    zipCode: 47388,
    homepage: "http://juwon.es.kr",
  },
  {
    id: 206,
    type: "공립",
    establush: "남부교육지원청",
    school: "초량초등학교",
    address: "부산광역시 동구 초량상로 49",
    phone: "051-924-8586",
    fax: "051-466-3358",
    zipCode: 48811,
    homepage: "http://chogyo.es.kr",
  },
  {
    id: 207,
    type: "공립",
    establush: "남부교육지원청",
    school: "초읍초등학교",
    address: "부산광역시 부산진구 성지로104번길 26",
    phone: "051-797-9400",
    fax: "051-797-9499",
    zipCode: 47114,
    homepage: "http://choup.es.kr",
  },
  {
    id: 208,
    type: "공립",
    establush: "북부교육지원청",
    school: "가락초등학교",
    address: "부산광역시 강서구 호계로 41",
    phone: "051-972-8055",
    fax: "051-972-1375",
    zipCode: 46708,
    homepage: "http://www.garak.es.kr/",
  },
  {
    id: 209,
    type: "공립",
    establush: "북부교육지원청",
    school: "감전초등학교",
    address: "부산광역시 사상구 괘감로 132",
    phone: "051-310-8300",
    fax: "051-327-8543",
    zipCode: 46984,
    homepage: "http://gamjeon.es.kr/",
  },
  {
    id: 210,
    type: "공립",
    establush: "북부교육지원청",
    school: "괘법초등학교",
    address: "부산광역시 사상구 사상로 161번길 8",
    phone: "051-315-4345",
    fax: "051-315-4348",
    zipCode: 46974,
    homepage: "http://gwaebeop.es.kr/",
  },
  {
    id: 211,
    type: "공립",
    establush: "남부교육지원청",
    school: "성서초등학교",
    address: "부산광역시 부산진구 중앙번영로 25",
    phone: "051-631-0482",
    fax: "051-647-3399",
    zipCode: 47335,
    homepage: "http://sungso.es.kr",
  },
  {
    id: 212,
    type: "공립",
    establush: "남부교육지원청",
    school: "성북초등학교",
    address: "부산광역시 부산진구 황령대로 79",
    phone: "051-805-7476",
    fax: "051-805-7536",
    zipCode: 47306,
    homepage: "http://seongbuk.es.kr",
  },
  {
    id: 213,
    type: "공립",
    establush: "남부교육지원청",
    school: "성동초등학교",
    address: "부산광역시 남구 전포대로68번길 27",
    phone: "051-950-1000",
    fax: "051-635-8444",
    zipCode: 48410,
    homepage: "http://esungdong.es.kr",
  },
  {
    id: 214,
    type: "공립",
    establush: "남부교육지원청",
    school: "가평초등학교",
    address: "부산광역시 부산진구 가야대로 482",
    phone: "051-898-3946",
    fax: "051-895-1353",
    zipCode: 47385,
    homepage: "http://busangapyeong.es.kr",
  },
  {
    id: 215,
    type: "공립",
    establush: "남부교육지원청",
    school: "개금초등학교",
    address: "부산광역시 부산진구 가야대로443번길 25-10",
    phone: "051-892-1653",
    fax: "051-893-0422",
    zipCode: 47266,
    homepage: "http://kaegum.es.kr",
  },
  {
    id: 216,
    type: "공립",
    establush: "남부교육지원청",
    school: "개림초등학교",
    address: "부산광역시 부산진구 백양대로320번길 40",
    phone: "051-607-7010",
    fax: "051-607-7080",
    zipCode: 47175,
    homepage: "http://gaerim.es.kr",
  },
  {
    id: 217,
    type: "공립",
    establush: "남부교육지원청",
    school: "개원초등학교",
    address: "부산광역시 부산진구 백양관문로77번길 17",
    phone: "051-927-9630",
    fax: "051-893-8776",
    zipCode: 47164,
    homepage: "http://kaewone.es.kr",
  },
  {
    id: 218,
    type: "공립",
    establush: "남부교육지원청",
    school: "개포초등학교",
    address: "부산광역시 부산진구 백양대로208번길 9",
    phone: "051-930-8001",
    fax: "051-930-8099",
    zipCode: 47169,
    homepage: "http://gaepo.es.kr",
  },
  {
    id: 219,
    type: "공립",
    establush: "남부교육지원청",
    school: "개화초등학교",
    address: "부산광역시 부산진구 개금본동로66번길 12-12",
    phone: "051-890-0101",
    fax: "051-890-0199",
    zipCode: 47167,
    homepage: "http://kae-hwa.es.kr",
  },
  {
    id: 220,
    type: "공립",
    establush: "남부교육지원청",
    school: "당감초등학교",
    address: "부산광역시 부산진구 당감로 22-5",
    phone: "051-896-0082",
    fax: "051-896-1114",
    zipCode: 47181,
    homepage: "http://danggam.es.kr",
  },
  {
    id: 221,
    type: "공립",
    establush: "남부교육지원청",
    school: "당평초등학교",
    address: "부산광역시 부산진구 백양대로136번길 26",
    phone: "051-890-8502",
    fax: "051-897-0416",
    zipCode: 47178,
    homepage: "http://dangpyung.es.kr",
  },
  {
    id: 222,
    type: "공립",
    establush: "남부교육지원청",
    school: "대남초등학교",
    address: "부산광역시 남구 황령대로353번길 9-55",
    phone: "051-622-9433",
    fax: "051-622-9436",
    zipCode: 48428,
    homepage: "http://daenam.es.kr",
  },
  {
    id: 223,
    type: "공립",
    establush: "남부교육지원청",
    school: "대연초등학교",
    address: "부산광역시 남구 진남로18번길 11",
    phone: "051-610-7602",
    fax: "051-627-6494",
    zipCode: 48441,
    homepage: "https://school.busanedu.net/daeyon-e",
  },
  {
    id: 224,
    type: "공립",
    establush: "남부교육지원청",
    school: "대천초등학교",
    address: "부산광역시 남구 조각공원로 19",
    phone: "051-622-2496",
    fax: "051-625-8291",
    zipCode: 48529,
    homepage: "http://daechon.es.kr",
  },
  {
    id: 225,
    type: "사립",
    establush: "남부교육지원청",
    school: "동성초등학교",
    address: "부산광역시 부산진구 동성로 26",
    phone: "051-802-7985",
    fax: "051-802-7986",
    zipCode: 47310,
    homepage: "http://ds.es.kr",
  },
  {
    id: 226,
    type: "공립",
    establush: "남부교육지원청",
    school: "동양초등학교",
    address: "부산광역시 부산진구 백양산로 99",
    phone: "051-801-5218",
    fax: "051-819-2727",
    zipCode: 47148,
    homepage: "http://dong-yang.es.kr",
  },
  {
    id: 227,
    type: "공립",
    establush: "남부교육지원청",
    school: "동원초등학교",
    address: "부산광역시 부산진구 백양산로 43",
    phone: "051-898-0917",
    fax: "051-896-6764",
    zipCode: 47154,
    homepage: "http://pdw.es.kr",
  },
  {
    id: 228,
    type: "공립",
    establush: "남부교육지원청",
    school: "동일중앙초등학교",
    address: "부산광역시 동구 구봉로 46",
    phone: "051-460-0102",
    fax: "051-460-0117",
    zipCode: 48799,
    homepage: "http://dongiljungang.es.kr",
  },
  {
    id: 229,
    type: "공립",
    establush: "남부교육지원청",
    school: "동천초등학교",
    address: "부산광역시 남구 석포로26번길 22",
    phone: "051-644-9426",
    fax: "051-644-4414",
    zipCode: 48542,
    homepage: "http://dong-cheon.es.kr",
  },
  {
    id: 230,
    type: "공립",
    establush: "남부교육지원청",
    school: "동평초등학교",
    address: "부산광역시 부산진구 당감서로65번길 31",
    phone: "051-718-0102",
    fax: "051-718-0110",
    zipCode: 47146,
    homepage: "http://dongpyeong.es.kr",
  },
  {
    id: 231,
    type: "공립",
    establush: "남부교육지원청",
    school: "동항초등학교",
    address: "부산광역시 남구 우암로 42",
    phone: "051-646-0296",
    fax: "051-647-7993",
    zipCode: 48551,
    homepage: "http://donghang.es.kr",
  },
  {
    id: 232,
    type: "공립",
    establush: "남부교육지원청",
    school: "문현초등학교",
    address: "부산광역시 남구 고동골로 86-13",
    phone: "051-646-8555",
    fax: "051-647-4707",
    zipCode: 48409,
    homepage: "http://moonhyun.es.kr",
  },
  {
    id: 233,
    type: "공립",
    establush: "남부교육지원청",
    school: "백운초등학교",
    address: "부산광역시 남구 동명로 76",
    phone: "051-790-8701",
    fax: "051-622-5271",
    zipCode: 48570,
    homepage: "http://baekwoon.es.kr",
  },
  {
    id: 234,
    type: "공립",
    establush: "남부교육지원청",
    school: "범일초등학교",
    address: "부산광역시 동구 증산서로 55",
    phone: "051-647-9592",
    fax: "051-647-9593",
    zipCode: 48761,
    homepage: "http://beom-il.es.kr",
  },
  {
    id: 235,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산진초등학교",
    address: "부산광역시 부산진구 범천로 30",
    phone: "051-801-2802",
    fax: "051-801-2828",
    zipCode: 47280,
    homepage: "http://pusanjin.es.kr",
  },
  {
    id: 236,
    type: "공립",
    establush: "남부교육지원청",
    school: "부암초등학교",
    address: "부산광역시 부산진구 신천대로263번길 26",
    phone: "051-816-2679",
    fax: "051-806-2587",
    zipCode: 47185,
    homepage: "http://buam.es.kr",
  },
  {
    id: 237,
    type: "공립",
    establush: "남부교육지원청",
    school: "부전초등학교",
    address: "부산광역시 부산진구 부전로65번길 25",
    phone: "051-811-7801",
    fax: "051-811-7859",
    zipCode: 47282,
    homepage: "http://pspujun.es.kr",
  },
  {
    id: 238,
    type: "공립",
    establush: "남부교육지원청",
    school: "분포초등학교",
    address: "부산광역시 남구 분포로 41",
    phone: "051-624-8902",
    fax: "051-624-5186",
    zipCode: 48516,
    homepage: "http://bunpo.es.kr",
  },
  {
    id: 239,
    type: "공립",
    establush: "남부교육지원청",
    school: "석포초등학교",
    address: "부산광역시 남구 석포로86번길 27",
    phone: "051-629-9201",
    fax: "051-624-3646",
    zipCode: 48532,
    homepage: "http://sukpo.es.kr",
  },
  {
    id: 240,
    type: "공립",
    establush: "남부교육지원청",
    school: "선암초등학교",
    address: "부산광역시 부산진구 엄광로 412",
    phone: "051-642-7214",
    fax: "051-631-1442",
    zipCode: 47347,
    homepage: "http://sunam.es.kr",
  },
  {
    id: 241,
    type: "공립",
    establush: "남부교육지원청",
    school: "성남초등학교",
    address: "부산광역시 동구 범일로53번길 14",
    phone: "051-646-5359",
    fax: "051-646-9755",
    zipCode: 48737,
    homepage: "https://school.busanedu.net/pusansungnam-e",
  },
  {
    id: 242,
    type: "공립",
    establush: "북부교육지원청",
    school: "구남초등학교",
    address: "부산광역시 북구 구남로 14번가길 23",
    phone: "051-336-6602",
    fax: "051-337-6369",
    zipCode: 46647,
    homepage: "http://kunam.es.kr/",
  },
  {
    id: 243,
    type: "공립",
    establush: "북부교육지원청",
    school: "구포초등학교",
    address: "부산광역시 북구 백양대로 1124번길 20",
    phone: "051-330-5903",
    fax: "051-330-5999",
    zipCode: 46628,
    homepage: "http://www.gupo.es.kr/",
  },
  {
    id: 244,
    type: "공립",
    establush: "북부교육지원청",
    school: "구학초등학교",
    address: "부산광역시 사상구 학감대로 49",
    phone: "051-313-3892",
    fax: "051-322-4132",
    zipCode: 47053,
    homepage: "http://guhag.es.kr/",
  },
  {
    id: 245,
    type: "공립",
    establush: "북부교육지원청",
    school: "백양초등학교",
    address: "부산광역시 북구 상리로 18번길 12",
    phone: "051-310-5500",
    fax: "051-343-3139",
    zipCode: 46611,
    homepage: "http://www.baekyang.es.kr/",
  },
  {
    id: 246,
    type: "공립",
    establush: "북부교육지원청",
    school: "사상초등학교",
    address: "부산광역시 사상구 운산로 65",
    phone: "051-330-5300",
    fax: "051-330-5388",
    zipCode: 46954,
    homepage: "http://www.sasang.es.kr/",
  },
  {
    id: 247,
    type: "공립",
    establush: "북부교육지원청",
    school: "삼덕초등학교",
    address: "부산광역시 사상구 삼덕로 24",
    phone: "051-303-0255",
    fax: "051-303-0244",
    zipCode: 46912,
    homepage: "http://samdok.es.kr/",
  },
  {
    id: 248,
    type: "공립",
    establush: "북부교육지원청",
    school: "상학초등학교",
    address: "부산광역시 북구 상학산복길 191",
    phone: "051-330-4301",
    fax: "051-335-4307",
    zipCode: 46556,
    homepage: "http://sanghak.es.kr/",
  },
  {
    id: 249,
    type: "공립",
    establush: "북부교육지원청",
    school: "서감초등학교",
    address: "부산광역시 사상구 괘감로 64",
    phone: "051-320-5802",
    fax: "051-312-7292",
    zipCode: 46980,
    homepage: "http://seogam.es.kr/",
  },
  {
    id: 250,
    type: "공립",
    establush: "북부교육지원청",
    school: "송정초등학교",
    address: "부산광역시 강서구 신호산단4로 10",
    phone: "051-979-6300",
    fax: "051-979-6373",
    zipCode: 46760,
    homepage: "http://pssongjung.es.kr/",
  },
  {
    id: 251,
    type: "공립",
    establush: "북부교육지원청",
    school: "신금초등학교",
    address: "부산광역시 북구 효열로 42번길",
    phone: "051-330-6400",
    fax: "051-330-6474",
    zipCode: 46514,
    homepage: "http://sinkum.es.kr/",
  },
  {
    id: 252,
    type: "공립",
    establush: "북부교육지원청",
    school: "신덕초등학교",
    address: "부산광역시 북구 함박벙로 140번가길",
    phone: "051-330-2901",
    fax: "051-336-5755",
    zipCode: 46616,
    homepage: "http://sinduk.es.kr/",
  },
  {
    id: 253,
    type: "공립",
    establush: "북부교육지원청",
    school: "신명초등학교",
    address: "부산광역시 강서구 명지국제7로 178",
    phone: "051-792-1202",
    fax: "051-792-1288",
    zipCode: 46726,
    homepage: "http://bssm.es.kr/",
  },
  {
    id: 254,
    type: "공립",
    establush: "북부교육지원청",
    school: "신천초등학교",
    address: "부산광역시 북구 모분재로 83번길",
    phone: "051-341-6471",
    fax: "051-341-7307",
    zipCode: 46619,
    homepage: "http://psc.es.kr/",
  },
  {
    id: 255,
    type: "공립",
    establush: "북부교육지원청",
    school: "신호초등학교",
    address: "부산광역시 강서구 신호산단2로 75번길 12",
    phone: "051-974-6802",
    fax: "051-9746-810",
    zipCode: 46759,
    homepage: "http://shinho.es.kr/",
  },
  {
    id: 256,
    type: "공립",
    establush: "북부교육지원청",
    school: "양덕초등학교",
    address: "부산광역시 북구 팽나무로 58",
    phone: "051-719-8600",
    fax: "051-719-8688",
    zipCode: 46594,
    homepage: "http://yangduk.es.kr/",
  },
  {
    id: 257,
    type: "공립",
    establush: "북부교육지원청",
    school: "양천초등학교",
    address: "부산광역시 북구 덕천로 132번길",
    phone: "051-797-6500",
    fax: "051-797-6598",
    zipCode: 46590,
    homepage: "http://busanyangchun.es.kr/",
  },
  {
    id: 258,
    type: "공립",
    establush: "북부교육지원청",
    school: "엄궁초등학교",
    address: "부산광역시 사상구 엄궁로 191번길 23",
    phone: "051-320-6900",
    fax: "051-327-7278",
    zipCode: 47039,
    homepage: "http://omgung.es.kr/",
  },
  {
    id: 259,
    type: "공립",
    establush: "북부교육지원청",
    school: "오션초등학교",
    address: "부산광역시 강서구 명지오션시티6로 72",
    phone: "051-220-7200",
    fax: "051-220-7299",
    zipCode: 46764,
    homepage: "http://ocean.es.kr/",
  },
  {
    id: 260,
    type: "공립",
    establush: "북부교육지원청",
    school: "와석초등학교",
    address: "부산광역시 북구 화명대로 94번길 83",
    phone: "051-791-5200",
    fax: "051-791-5209",
    zipCode: 46534,
    homepage: "http://waseok.es.kr/",
  },
  {
    id: 261,
    type: "공립",
    establush: "북부교육지원청",
    school: "용수초등학교",
    address: "부산광역시 북구 화명신도시로 53번길",
    phone: "051-330-3700",
    fax: "051-363-8944",
    zipCode: 46540,
    homepage: "http://yongsu.es.kr/",
  },
  {
    id: 262,
    type: "공립",
    establush: "북부교육지원청",
    school: "주감초등학교",
    address: "부산광역시 사상구 양지로 48",
    phone: "051-320-3901",
    fax: "051-320-3909",
    zipCode: 46993,
    homepage: "http://jugam.es.kr/",
  },
  {
    id: 263,
    type: "공립",
    establush: "북부교육지원청",
    school: "주양초등학교",
    address: "부산광역시 사상구 백양대로 372-6번길",
    phone: "051-329-1503",
    fax: "051-329-1599",
    zipCode: 46997,
    homepage: "http://www.juyang.es.kr/",
  },
  {
    id: 264,
    type: "공립",
    establush: "북부교육지원청",
    school: "주학초등학교",
    address: "부산광역시 사상구 가야대로 366번길 120",
    phone: "051-327-7471",
    fax: "051-324-9880",
    zipCode: 47010,
    homepage: "http://juhak.es.kr/",
  },
  {
    id: 265,
    type: "공립",
    establush: "북부교육지원청",
    school: "창진초등학교",
    address: "부산광역시 사상구 사상로 223번길 100",
    phone: "051-310-3402",
    fax: "051-328-6757",
    zipCode: 46962,
    homepage: "http://changjin.es.kr/",
  },
  {
    id: 266,
    type: "공립",
    establush: "북부교육지원청",
    school: "천가초등학교",
    address: "부산광역시 강서구 동선길 135",
    phone: "051-972-7008",
    fax: "051-971-4031",
    zipCode: 46769,
    homepage: "http://chunga.es.kr/",
  },
  {
    id: 267,
    type: "공립",
    establush: "북부교육지원청",
    school: "포천초등학교",
    address: "부산광역시 북구 시랑로 115",
    phone: "051-366-3300",
    fax: "051-337-2867",
    zipCode: 46623,
    homepage: "http://www.pochen.es.kr/",
  },
  {
    id: 268,
    type: "공립",
    establush: "북부교육지원청",
    school: "학사초등학교",
    address: "부산광역시 북구 용당로 15번길 20",
    phone: "051-330-4401",
    fax: "051-330-4407",
    zipCode: 46527,
    homepage: "http://school.busanedu.net/haksa-e/intro.do",
  },
  {
    id: 269,
    type: "공립",
    establush: "북부교육지원청",
    school: "학장초등학교",
    address: "부산광역시 사상구 대동로 205",
    phone: "051-320-5701",
    fax: "051-311-9970",
    zipCode: 47024,
    homepage: "http://www.hakjang.es.kr/",
  },
  {
    id: 270,
    type: "공립",
    establush: "북부교육지원청",
    school: "학진초등학교",
    address: "부산광역시 사상구 엄궁동 대동로 34-16",
    phone: "051-982-5001",
    fax: "051-323-3921",
    zipCode: 47035,
    homepage: "http://hakjin.es.kr/",
  },
  {
    id: 271,
    type: "공립",
    establush: "북부교육지원청",
    school: "화명초등학교",
    address: "부산광역시 북구 대천천길 65",
    phone: "051-366-2500",
    fax: "051-343-2343",
    zipCode: 46517,
    homepage: "http://hwamyong.es.kr/",
  },
  {
    id: 272,
    type: "공립",
    establush: "북부교육지원청",
    school: "화잠초등학교",
    address: "부산광역시 북구 화명대로 97-25",
    phone: "051-330-4200",
    fax: "051-361-7038",
    zipCode: 46532,
    homepage: "http://www.hwajam.es.kr/",
  },
  {
    id: 273,
    type: "공립",
    establush: "북부교육지원청",
    school: "백산초등학교",
    address: "부산광역시 북구 덕천로 259번길 23",
    phone: "051-341-1180",
    fax: "051-338-4503",
    zipCode: 46563,
    homepage: "http://baeksan.es.kr/",
  },
  {
    id: 274,
    type: "공립",
    establush: "북부교육지원청",
    school: "배영초등학교",
    address: "부산광역시 강서구 공항로 339번길 260",
    phone: "051-971-4428",
    fax: "051-971-4430",
    zipCode: 46723,
    homepage: "http://www.baeyeong.es.kr/",
  },
  {
    id: 275,
    type: "공립",
    establush: "북부교육지원청",
    school: "모산초등학교",
    address: "부산광역시 사상구 모라로 110번길 93",
    phone: "051-329-4002",
    fax: "051-312-2048",
    zipCode: 46934,
    homepage: "http://www.mosan.es.kr/",
  },
  {
    id: 276,
    type: "공립",
    establush: "북부교육지원청",
    school: "금곡초등학교",
    address: "부산광역시 북구 효열로 174번길",
    phone: "051-330-5504",
    fax: "051-361-0036",
    zipCode: 46512,
    homepage: "http://www.geumgok.es.kr/",
  },
  {
    id: 277,
    type: "공립",
    establush: "북부교육지원청",
    school: "금명초등학교",
    address: "부산광역시 북구 화명신도시로 197",
    phone: "051-364-1838",
    fax: "051-364-1859",
    zipCode: 46522,
    homepage: "http://www.geummyeong.es.kr/",
  },
  {
    id: 278,
    type: "공립",
    establush: "북부교육지원청",
    school: "금창초등학교",
    address: "부산광역시 북구 효열로 235번길 14",
    phone: "051-362-2181",
    fax: "051-362-2183",
    zipCode: 46505,
    homepage: "http://geumchang.es.kr/",
  },
  {
    id: 279,
    type: "공립",
    establush: "북부교육지원청",
    school: "남명초등학교",
    address: "부산광역시 강서구 명지오션시티11로 132",
    phone: "051-260-0200",
    fax: "051-2600-290",
    zipCode: 46764,
    homepage: "http://nam-myeong.es.kr/",
  },
  {
    id: 280,
    type: "공립",
    establush: "북부교육지원청",
    school: "녹명초등학교",
    address: "부산광역시 강서구 낙동남로 617",
    phone: "051-971-5144",
    fax: "051-972-1353",
    zipCode: 46728,
    homepage: "http://nokmng.es.kr/",
  },
  {
    id: 281,
    type: "공립",
    establush: "북부교육지원청",
    school: "녹산초등학교",
    address: "부산광역시 강서구 과학산단2로20번길 55",
    phone: "051-979-1000",
    fax: "051-979-1090",
    zipCode: 46743,
    homepage: "http://noksan.es.kr/",
  },
  {
    id: 282,
    type: "공립",
    establush: "북부교육지원청",
    school: "대사초등학교",
    address: "부산광역시 강서구 낙동북로 73번길 29",
    phone: "051-970-0302",
    fax: "051-972-9589",
    zipCode: 46705,
    homepage: "http://psdaesa.es.kr/",
  },
  {
    id: 283,
    type: "공립",
    establush: "북부교육지원청",
    school: "대상초등학교",
    address: "부산광역시 강서구 대저로 249",
    phone: "051-971-6311",
    fax: "051-971-6313",
    zipCode: 46702,
    homepage: "http://www.daesang.es.kr/",
  },
  {
    id: 284,
    type: "공립",
    establush: "북부교육지원청",
    school: "대저초등학교",
    address: "부산광역시 강서구 대저로29번길 27",
    phone: "051-971-0615",
    fax: "051-971-9423",
    zipCode: 46700,
    homepage: "http://daejeo.es.kr/",
  },
  {
    id: 285,
    type: "공립",
    establush: "북부교육지원청",
    school: "대천리초등학교",
    address: "부산광역시 북구 산성로 87-10",
    phone: "051-366-2700",
    fax: "051-362-6624",
    zipCode: 46528,
    homepage: "http://www.daechunlee.es.kr/",
  },
  {
    id: 286,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕두초등학교",
    address: "부산광역시 강서구 공항로791번길 68",
    phone: "051-711-5120",
    fax: "051-971-8761",
    zipCode: 46720,
    homepage: "http://www.deokdu.es.kr/",
  },
  {
    id: 287,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕상초등학교",
    address: "부산광역시 사상구 덕상로 32",
    phone: "051-309-3400",
    fax: "051-305-4117",
    zipCode: 46941,
    homepage: "http://www.deoksang.es.kr/",
  },
  {
    id: 288,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕성초등학교",
    address: "부산광역시 북구 만덕대로 39번길",
    phone: "051-330-3600",
    fax: "051-342-9855",
    zipCode: 46546,
    homepage: "http://dsedu.es.kr/",
  },
  {
    id: 289,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕양초등학교",
    address: "부산광역시 북구 함박봉로 42",
    phone: "051-338-8364",
    fax: "051-338-8361",
    zipCode: 46591,
    homepage: "http://www.deokyang.es.kr/",
  },
  {
    id: 290,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕천초등학교",
    address: "부산광역시 북구 덕천2길 10",
    phone: "051-332-5198",
    fax: "051-331-9193",
    zipCode: 46579,
    homepage: "http://www.dugchun.es.kr/",
  },
  {
    id: 291,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕포초등학교",
    address: "부산광역시 사상구 삼덕로 83번길",
    phone: "051-320-0400",
    fax: "051-303-8514",
    zipCode: 46945,
    homepage: "http://www.deokpo.es.kr/",
  },
  {
    id: 292,
    type: "공립",
    establush: "북부교육지원청",
    school: "동궁초등학교",
    address: "부산광역시 사상구 엄궁로 70-7",
    phone: "051-310-9700",
    fax: "051-315-8279",
    zipCode: 47046,
    homepage: "http://www.donggung.es.kr/",
  },
  {
    id: 293,
    type: "공립",
    establush: "북부교육지원청",
    school: "동주초등학교",
    address: "부산광역시 사상구 가야대로 306번길 72",
    phone: "051-790-3701",
    fax: "051-790-3775",
    zipCode: 47014,
    homepage: "http://dongju.es.kr/",
  },
  {
    id: 294,
    type: "공립",
    establush: "북부교육지원청",
    school: "만덕초등학교",
    address: "부산광역시 북구 만덕1로 72",
    phone: "051-320-7600",
    fax: "051-320-7699",
    zipCode: 46558,
    homepage: "http://www.manduk.or.kr/",
  },
  {
    id: 295,
    type: "공립",
    establush: "북부교육지원청",
    school: "명덕초등학교",
    address: "부산광역시 북구 금곡대로 186번길 43",
    phone: "051-330-6800",
    fax: "051-332-0142",
    zipCode: 46538,
    homepage: "http://myd.es.kr/",
  },
  {
    id: 296,
    type: "공립",
    establush: "북부교육지원청",
    school: "명원초등학교",
    address: "부산광역시 강서구 명지국제6로 147",
    phone: "051-250-3300",
    fax: "051-250-3309",
    zipCode: 46726,
    homepage: "http://myoungwon.es.kr/",
  },
  {
    id: 297,
    type: "공립",
    establush: "북부교육지원청",
    school: "명일초등학교",
    address: "부산광역시 강서구 명지국제5로 170-5",
    phone: "051-290-1500",
    fax: "051-290-1590",
    zipCode: 46726,
    homepage: "http://myeong1.es.kr/",
  },
  {
    id: 298,
    type: "공립",
    establush: "북부교육지원청",
    school: "명지초등학교",
    address: "부산광역시 강서구 명지국제6로 296",
    phone: "051-220-1700",
    fax: "051-271-0631",
    zipCode: 46726,
    homepage: "http://myungji.es.kr/",
  },
  {
    id: 299,
    type: "공립",
    establush: "북부교육지원청",
    school: "명진초등학교",
    address: "부산광역시 북구 화명신도시로 159",
    phone: "051-330-4701",
    fax: "051-330-4794",
    zipCode: 46524,
    homepage: "http://myungjin.es.kr/",
  },
  {
    id: 300,
    type: "공립",
    establush: "북부교육지원청",
    school: "명호초등학교",
    address: "부산광역시 강서구 명지오션시티2로 41",
    phone: "051-220-1100",
    fax: "051-271-6103",
    zipCode: 46762,
    homepage: "http://myungho.es.kr/",
  },
  {
    id: 301,
    type: "공립",
    establush: "북부교육지원청",
    school: "모덕초등학교",
    address: "부산광역시 사상구 덕상로 120번길",
    phone: "051-320-0801",
    fax: "051-328-5697",
    zipCode: 46931,
    homepage: "http://www.modeok.es.kr/",
  },
  {
    id: 302,
    type: "공립",
    establush: "북부교육지원청",
    school: "모동초등학교",
    address: "부산광역시 사상구 모라로 192번길 37",
    phone: "051-792-3700",
    fax: "051-792-3777",
    zipCode: 46935,
    homepage: "http://modong2.es.kr/",
  },
  {
    id: 303,
    type: "공립",
    establush: "북부교육지원청",
    school: "모라초등학교",
    address: "부산광역시 사상구 모라로 64번길 46",
    phone: "051-310-7400",
    fax: "051-302-8145",
    zipCode: 49576,
    homepage: "http://www.mora.es.kr/",
  },
  {
    id: 304,
    type: "공립",
    establush: "해운대교육지원청",
    school: "호암초등학교",
    address: "부산광역시 수영구 호암로 57",
    phone: "051-712-3600",
    fax: "051-712-3678",
    zipCode: 48259,
    homepage: "http://www.hoam.es.kr/",
  },
  {
    id: 305,
    type: "공립",
    establush: "서부교육지원청",
    school: "감천중학교",
    address: "부산광역시 사하구 감천로73번길 60 (감천동, 감천중학교)",
    phone: 512083035,
    fax: "051-208-5194",
    zipCode: 49362,
    homepage: "http://gamcheon.ms.kr",
  },
  {
    id: 306,
    type: "공립",
    establush: "서부교육지원청",
    school: "경남중학교",
    address: "부산광역시 서구 까치고개로229번길 7 (토성동3가, 경남중학교)",
    phone: 512507574,
    fax: "051-256-0957",
    zipCode: 49224,
    homepage: "http://www.gyeongnam.ms.kr",
  },
  {
    id: 307,
    type: "공립",
    establush: "서부교육지원청",
    school: "다대중학교",
    address: "부산광역시 사하구 다대로554번길 33 , 다대중학교 (다대동)",
    phone: 512606540,
    fax: "051-262-5810",
    zipCode: 49494,
    homepage: "http://dadae.ms.kr",
  },
  {
    id: 308,
    type: "공립",
    establush: "서부교육지원청",
    school: "다선중학교",
    address: "부산광역시 사하구 다대낙조2길 36 (다대동, 다선중학교)",
    phone: 512090506,
    fax: "051-209-0518",
    zipCode: 49501,
    homepage: "http://dasun.ms.kr",
  },
  {
    id: 309,
    type: "공립",
    establush: "서부교육지원청",
    school: "다송중학교",
    address: "부산광역시 사하구 다송로 55 (다대동, 다송중학교)",
    phone: 512647650,
    fax: "051-265-1814",
    zipCode: 49516,
    homepage: "http://www.dasong.ms.kr",
  },
  {
    id: 310,
    type: "공립",
    establush: "서부교육지원청",
    school: "당리중학교",
    address: "부산광역시 사하구 낙동대로356번길 22 (당리동)",
    phone: 512002201,
    fax: "051-205-8808",
    zipCode: 49345,
    homepage: "http://dangni.ms.kr",
  },
  {
    id: 311,
    type: "공립",
    establush: "서부교육지원청",
    school: "두송중학교",
    address: "부산광역시 사하구 다송로 76 (다대동, 두송중학교)",
    phone: 512605303,
    fax: "051-260-5492",
    zipCode: 49521,
    homepage: "http://doosong.ms.kr",
  },
  {
    id: 312,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산남중학교",
    address: "부산광역시 영도구 절영로73번길 7 (남항동2가, 부산남중학교)",
    phone: 517921101,
    fax: "051-416-3117",
    zipCode: 49055,
    homepage: "http://www.namjung.ms.kr",
  },
  {
    id: 313,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산대신중학교",
    address: "부산광역시 서구 대신로109번길 10 (서대신동3가, 부산대신중학교)",
    phone: 512401400,
    fax: "051-244-0400",
    zipCode: 49203,
    homepage: "http://www.daesin.ms.kr",
  },
  {
    id: 314,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산여자중학교",
    address: "부산광역시 서구 보수대로 282-23 (서대신동3가, 부산여자중학교)",
    phone: 512400910,
    fax: "051-247-1915",
    zipCode: 49202,
    homepage: "http://busan-gm.ms.kr",
  },
  {
    id: 315,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산영선중학교",
    address: "부산광역시 영도구 영상길 83 , 부산영선중학교 (신선동3가)",
    phone: 514185608,
    fax: "051-415-0996",
    zipCode: 49072,
    homepage: "http://yeongseon.ms.kr",
  },
  {
    id: 316,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산중앙여자중학교",
    address: "부산광역시 서구 대신로 59-32 (서대신동3가, 중앙여자중학교)",
    phone: 512550750,
    fax: "051-254-6581",
    zipCode: 49209,
    homepage: "http://jungang-gm.ms.kr",
  },
  {
    id: 317,
    type: "공립",
    establush: "서부교육지원청",
    school: "부산체육중학교",
    address: "부산광역시 영도구 중리북로 21번길 5",
    phone: 514003090,
    fax: "051-403-5308",
    zipCode: 49118,
    homepage: "http://busport.ms.kr",
  },
  {
    id: 318,
    type: "공립",
    establush: "서부교육지원청",
    school: "사하중학교",
    address: "부산광역시 사하구 낙동대로302번길 35 (괴정동,사하중학교)",
    phone: 516020114,
    fax: "051-602-0115",
    zipCode: 49344,
    homepage: "http://saha.ms.kr",
  },
  {
    id: 319,
    type: "공립",
    establush: "서부교육지원청",
    school: "신선중학교",
    address: "부산광역시 영도구 복지관길 40 (신선동3가, 신선중학교)",
    phone: 516001500,
    fax: "051-600-1514",
    zipCode: 49082,
    homepage: "http://shinseon.ms.kr",
  },
  {
    id: 320,
    type: "공립",
    establush: "서부교육지원청",
    school: "영도제일중학교",
    address: "부산광역시 영도구 중리로 64 , 영도제일중학교 (동삼동, 영도제일중학교)",
    phone: 514032431,
    fax: "051-404-5492",
    zipCode: 49106,
    homepage: "http://www.yeongdo.ms.kr",
  },
  {
    id: 321,
    type: "공립",
    establush: "서부교육지원청",
    school: "장림여자중학교",
    address: "부산광역시 사하구 두송로 64 (장림동, 장림여자중학교)",
    phone: 512604203,
    fax: "051-263-1793",
    zipCode: 49510,
    homepage: "http://janglim.ms.kr",
  },
  {
    id: 322,
    type: "공립",
    establush: "서부교육지원청",
    school: "장평중학교",
    address: "부산광역시 사하구 사하로 134-1 (감천동)",
    phone: 512910617,
    fax: "051-207-4170",
    zipCode: 49361,
    homepage: "http://bsjp.ms.kr/",
  },
  {
    id: 323,
    type: "공립",
    establush: "서부교육지원청",
    school: "초장중학교",
    address: "부산광역시 서구 해돋이로 205 (초장동, 초장중학교)",
    phone: 512421412,
    fax: "051-253-2306",
    zipCode: 49250,
    homepage: "http://chojang.ms.kr/",
  },
  {
    id: 324,
    type: "공립",
    establush: "서부교육지원청",
    school: "태종대중학교",
    address: "부산광역시 영도구 함지로79번길 9 (동삼동,태종대중학교)",
    phone: 514002713,
    fax: "051-404-5303",
    zipCode: 49113,
    homepage: "http://www.taejongdae.ms.kr",
  },
  {
    id: 325,
    type: "공립",
    establush: "서부교육지원청",
    school: "하남중학교",
    address: "부산광역시 사하구 하신번영로 206 (하단동,하남중학교)",
    phone: 517908400,
    fax: "051-790-8408",
    zipCode: 49429,
    homepage: "http://www.hanam.ms.kr/",
  },
  {
    id: 326,
    type: "공립",
    establush: "서부교육지원청",
    school: "하단중학교",
    address: "부산광역시 사하구 하신번영로380번길 88 (하단동,하단중학교)",
    phone: 517196306,
    fax: "051-719-6378",
    zipCode: 49304,
    homepage: "http://www.hadan.ms.kr",
  },
  {
    id: 327,
    type: "사립",
    establush: "서부교육지원청",
    school: "대동중학교",
    address: "부산광역시 사하구 을숙도대로709번길 27 , 대동중학교 (신평동, 대동중학교)",
    phone: 512925120,
    fax: "051-207-1263",
    zipCode: 49397,
    homepage: "http://dd.ms.kr/",
  },
  {
    id: 328,
    type: "사립",
    establush: "서부교육지원청",
    school: "대신여자중학교",
    address: "부산광역시 서구 보동길 268 (동대신동2가,대신여자중학교)",
    phone: 512481812,
    fax: "051-242-4393",
    zipCode: 49215,
    homepage: "http://www.daesin-g.ms.kr",
  },
  {
    id: 329,
    type: "사립",
    establush: "서부교육지원청",
    school: "덕원중학교",
    address: "부산광역시 중구 망양로 356 (영주동, 덕원중학교) 덕원중학교",
    phone: 517908100,
    fax: "051-790-8104",
    zipCode: 48918,
    homepage: "http://www.deogweon.ms.kr",
  },
  {
    id: 330,
    type: "사립",
    establush: "서부교육지원청",
    school: "동주여자중학교",
    address: "부산광역시 사하구 사리로8번길 28 , 동주여자중학교 (괴정동)",
    phone: 512073965,
    fax: "051-207-3969",
    zipCode: 49352,
    homepage: "http://dong-ju.ms.kr",
  },
  {
    id: 331,
    type: "사립",
    establush: "서부교육지원청",
    school: "삼성중학교",
    address: "부산광역시 사하구 감천로43번길 45-2 (감천동, 삼성중학교)",
    phone: 517925573,
    fax: "051-208-5323",
    zipCode: 49362,
    homepage: "http://samsung.ms.kr",
  },
  {
    id: 332,
    type: "사립",
    establush: "서부교육지원청",
    school: "송도중학교",
    address: "부산광역시 서구 해양로 15 (남부민동)",
    phone: 512483726,
    fax: "051-248-3103",
    zipCode: 49263,
    homepage: "http://songdo.ms.co.kr",
  },
  {
    id: 333,
    type: "사립",
    establush: "서부교육지원청",
    school: "영남중학교",
    address: "부산광역시 사하구 다대로277번길 100 (장림동)",
    phone: 512654335,
    fax: "051-265-4305",
    zipCode: 49511,
    homepage: "http://www.yn.ms.kr/",
  },
  {
    id: 334,
    type: "사립",
    establush: "서부교육지원청",
    school: "해동중학교",
    address: "부산광역시 영도구 절영로490번길 8-22 , 해동중학교 (동삼동)",
    phone: 514002306,
    fax: "051-403-9810",
    zipCode: 49123,
    homepage: "http://www.haedong.ms.kr",
  },
  {
    id: 335,
    type: "공립",
    establush: "남부교육지원청",
    school: "가야여자중학교",
    address: "부산광역시 부산진구 엄광로182번길 15-25 (가야동,가야여자중학교)",
    phone: "051-890-5400",
    fax: "051-895-1256",
    zipCode: 47339,
    homepage: "http://gaya-gm.ms.kr",
  },
  {
    id: 336,
    type: "공립",
    establush: "남부교육지원청",
    school: "감만중학교",
    address: "부산광역시 남구 홍곡로 313-14 (감만동, 감만중학교)",
    phone: 516406460,
    fax: "051-631-6480",
    zipCode: 48539,
    homepage: "http://www.gamman.ms.kr",
  },
  {
    id: 337,
    type: "공립",
    establush: "남부교육지원청",
    school: "개금여자중학교",
    address: "부산광역시 부산진구 백양대로136번길 75 , 개금여자중학교 (당감동, 개금여자중학교)",
    phone: 516073072,
    fax: "051-607-3099",
    zipCode: 47176,
    homepage: "http://www.gaegum.ms.kr",
  },
  {
    id: 338,
    type: "공립",
    establush: "남부교육지원청",
    school: "개림중학교",
    address: "부산광역시 부산진구 백양대로300번길 46 (개금동,개림중학교)",
    phone: 518900801,
    fax: "051-896-7717",
    zipCode: 47174,
    homepage: "http://www.gaerim.ms.kr",
  },
  {
    id: 339,
    type: "공립",
    establush: "남부교육지원청",
    school: "경남여자중학교",
    address: "부산광역시 동구 초량중로 142 (수정동)",
    phone: 514600700,
    fax: "051-466-2414",
    zipCode: 48795,
    homepage: "http://gyeongnam-gm.ms.kr",
  },
  {
    id: 340,
    type: "공립",
    establush: "남부교육지원청",
    school: "광무여자중학교",
    address: "부산광역시 부산진구 백양관문로 31 (개금동)",
    phone: 516051704,
    fax: "051-896-1668",
    zipCode: 47170,
    homepage: "http://www.kwangmu-g.ms.kr/",
  },
  {
    id: 341,
    type: "공립",
    establush: "남부교육지원청",
    school: "남천중학교",
    address: "부산광역시 남구 황령대로492번길 71 (대연동, 남천중학교)",
    phone: 516245309,
    fax: "051-625-8879",
    zipCode: 48510,
    homepage: "http://www.namcheon.ms.kr",
  },
  {
    id: 342,
    type: "공립",
    establush: "남부교육지원청",
    school: "대연중학교",
    address: "부산광역시 남구 천제등로16번길 81 (대연동,대연중학교)",
    phone: 516067706,
    fax: "051-647-9403",
    zipCode: 48461,
    homepage: "http://www.daeyeon.ms.kr",
  },
  {
    id: 343,
    type: "공립",
    establush: "남부교육지원청",
    school: "대천중학교",
    address: "부산광역시 남구 신선로 491 (대연동,대천중학교)",
    phone: 516227180,
    fax: "051-621-1666",
    zipCode: 48530,
    homepage: "http://www.daecheon.ms.kr",
  },
  {
    id: 344,
    type: "공립",
    establush: "남부교육지원청",
    school: "동양중학교",
    address: "부산광역시 부산진구 백양산로 96(부암동) (부암동)",
    phone: 516062504,
    fax: "051-606-2505",
    zipCode: 47148,
    homepage: "http://dyj.ms.kr",
  },
  {
    id: 345,
    type: "사립",
    establush: "서부교육지원청",
    school: "건국중학교",
    address: "부산광역시 사하구 승학로17번길 76-8 , 건국중학교 (하단동, 건국중.고등학교)",
    phone: 512910067,
    fax: "051-291-0016",
    zipCode: 49315,
    homepage: "http://www.keonkuk.ms.kr",
  },
  {
    id: 346,
    type: "공립",
    establush: "남부교육지원청",
    school: "동평여자중학교",
    address: "부산광역시 부산진구 백양순환로119번길 12-39 (부암동)",
    phone: 518174603,
    fax: "051-818-9353",
    zipCode: 47137,
    homepage: "http://dong-pyung.ms.kr/",
  },
  {
    id: 347,
    type: "공립",
    establush: "남부교육지원청",
    school: "동평중학교",
    address: "부산광역시 부산진구 백양순환로119번길 12-31 (부암동, 동평중학교)",
    phone: 516051613,
    fax: "051-605-1619",
    zipCode: 47137,
    homepage: "http://www.dongpyeong.ms.kr",
  },
  {
    id: 348,
    type: "사립",
    establush: "남부교육지원청",
    school: "서면중학교",
    address: "부산광역시 부산진구 가야대로747번길 53 (부암동, 서면중학교)",
    phone: 518169016,
    fax: "051-816-7178",
    zipCode: 47194,
    homepage: "http://www.somyon.ms.kr",
  },
  {
    id: 349,
    type: "공립",
    establush: "남부교육지원청",
    school: "동항중학교",
    address: "부산광역시 남구 양지골로 248 동항중학교 (감만동)",
    phone: 516402573,
    fax: "051-635-7030",
    zipCode: 48546,
    homepage: "http://www.donghang.ms.kr",
  },
  {
    id: 350,
    type: "공립",
    establush: "남부교육지원청",
    school: "문현여자중학교",
    address: "부산광역시 남구 고동골로59번길 46 (문현동, 문현여자중학교)",
    phone: 516472363,
    fax: "051-645-5939",
    zipCode: 48410,
    homepage: "http://www.munhyeon-gm.ms.kr",
  },
  {
    id: 351,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산개성중학교",
    address: "부산광역시 부산진구 엄광로 176-12 (가야동,부산개성중학교)",
    phone: 518011154,
    fax: "051-898-1332",
    zipCode: 47341,
    homepage: "http://psksjung@hanmail.net",
  },
  {
    id: 352,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산국제중학교",
    address: "부산광역시 부산진구 백양관문로 105-70 , 부산국제중학교 (당감동)",
    phone: 518908458,
    fax: "051-892-1362",
    zipCode: 47162,
    homepage: "http://www.gukje.ms.kr",
  },
  {
    id: 353,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산서중학교",
    address: "부산광역시 동구 홍곡남로 29 (수정동, 부산서중학교)",
    phone: 514403805,
    fax: "051-441-7085",
    zipCode: 48799,
    homepage: "http://busanseo.ms.kr",
  },
  {
    id: 354,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산중학교",
    address: "부산광역시 동구 초량로40번길 29 (초량동)",
    phone: 519505705,
    fax: "051-950-5799",
    zipCode: 48802,
    homepage: "http://busan.ms.kr/",
  },
  {
    id: 355,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산진여자중학교",
    address: "부산광역시 부산진구 동성로 34 (전포동) 부산진여자중학교",
    phone: 516055601,
    fax: "051-605-5609",
    zipCode: 47309,
    homepage: "http://busanjingm.ms.kr",
  },
  {
    id: 356,
    type: "공립",
    establush: "남부교육지원청",
    school: "부산진중학교",
    address: "부산광역시 부산진구 시민공원로 50 (범전동)",
    phone: 518097365,
    fax: "051-819-7994",
    zipCode: 47205,
    homepage: "http://www.busanjin.ms.kr",
  },
  {
    id: 357,
    type: "공립",
    establush: "남부교육지원청",
    school: "분포중학교",
    address: "부산광역시 남구 분포로 51 , 용호동,분포중학교 (용호동, 분포중학교)",
    phone: 516244222,
    fax: "051-624-4447",
    zipCode: 48516,
    homepage: "http://www.boonpo.ms.kr/",
  },
  {
    id: 358,
    type: "공립",
    establush: "남부교육지원청",
    school: "석포여자중학교",
    address: "부산광역시 남구 유엔평화로126번길 63 (용당동)",
    phone: 516298900,
    fax: "051-621-8218",
    zipCode: 48536,
    homepage: "http://www.sokpo-gm.ms.kr/",
  },
  {
    id: 359,
    type: "공립",
    establush: "남부교육지원청",
    school: "성동중학교",
    address: "부산광역시 남구 남동천로 38 (문현동)",
    phone: 516605100,
    fax: "051-636-8159",
    zipCode: 48402,
    homepage: "http://seongdong.ms.kr/",
  },
  {
    id: 360,
    type: "공립",
    establush: "남부교육지원청",
    school: "양동여자중학교",
    address: "부산광역시 부산진구 황령산로605번길 35-32 , 양동여자중학교 (양정동, 양동여자중학교)",
    phone: 517135500,
    fax: "051-713-5519",
    zipCode: 47227,
    homepage: "http://www.yangdong-gm.ms.kr",
  },
  {
    id: 361,
    type: "공립",
    establush: "남부교육지원청",
    school: "오륙도중학교",
    address: "부산광역시 남구 오륙도로 60 (용호동,오륙도중학교)",
    phone: 516102811,
    fax: "051-610-2898",
    zipCode: 48594,
    homepage: "http://ord.ms.kr",
  },
  {
    id: 362,
    type: "공립",
    establush: "남부교육지원청",
    school: "용문중학교",
    address: "부산광역시 남구 동명로101번길 93 (용호동,용문중학교)",
    phone: 516231448,
    fax: "051-623-4441",
    zipCode: 48521,
    homepage: "http://www.yongmoon.ms.kr/",
  },
  {
    id: 363,
    type: "공립",
    establush: "남부교육지원청",
    school: "용호중학교",
    address: "부산광역시 남구 동명로 185-17 (용호동,용호중학교)",
    phone: 517905305,
    fax: "051-625-1275",
    zipCode: 48581,
    homepage: "http://yongho.ms.kr",
  },
  {
    id: 364,
    type: "공립",
    establush: "남부교육지원청",
    school: "초연중학교",
    address: "부산광역시 부산진구 초읍천로77번가길 9 (초읍동, 초연중학교)",
    phone: 516090301,
    fax: "051-609-0399",
    zipCode: 47105,
    homepage: "http://choyeon.ms.kr",
  },
  {
    id: 365,
    type: "공립",
    establush: "남부교육지원청",
    school: "초읍중학교",
    address: "부산광역시 부산진구 성지로94번길 71 (초읍동, 초읍중학교)",
    phone: 518176693,
    fax: "051-816-1581",
    zipCode: 47113,
    homepage: "http://www.choup.ms.kr/",
  },
  {
    id: 366,
    type: "사립",
    establush: "남부교육지원청",
    school: "덕명여자중학교",
    address: "부산광역시 부산진구 봉수로 13 (전포동)",
    phone: 518167990,
    fax: "051-816-7992",
    zipCode: 47310,
    homepage: "http://www.deokmyeong.ms.kr",
  },
  {
    id: 367,
    type: "공립",
    establush: "해운대교육지원청",
    school: "일광중학교",
    address: "부산광역시 기장군 일광면 해빛3로 99",
    phone: "051-750-3900",
    fax: "051-721-0080",
    zipCode: 46048,
    homepage: "http://school.busanedu.net/ilgwang-m",
  },
  {
    id: 368,
    type: "사립",
    establush: "남부교육지원청",
    school: "동의중학교",
    address: "부산광역시 부산진구 진남로462번길 16 (전포동, 동의중학교)",
    phone: 518098687,
    fax: "051-808-8214",
    zipCode: 47230,
    homepage: "http://dongeui.ms.kr",
  },
  {
    id: 369,
    type: "사립",
    establush: "남부교육지원청",
    school: "부산동여자중학교",
    address: "부산광역시 동구 망양로773번길 28 (수정동, 부산동여자중학교)",
    phone: 514678279,
    fax: "051-468-4252",
    zipCode: 48707,
    homepage: "http://busandong-gm.ms.kr/",
  },
  {
    id: 370,
    type: "사립",
    establush: "남부교육지원청",
    school: "선화여자중학교",
    address: "부산광역시 동구 초량로80번길 5 , 선화여자중학교 (초량동)",
    phone: 514600600,
    fax: "051-460-0650",
    zipCode: 48805,
    homepage: "http://htp://seonhwa-gm.ms.kr",
  },
  {
    id: 371,
    type: "사립",
    establush: "남부교육지원청",
    school: "항도중학교",
    address: "부산광역시 부산진구 동성로118번길 44 (전포동, 항도중학교)",
    phone: 518091910,
    fax: "051-807-6979",
    zipCode: 47237,
    homepage: "http://www.hangdo.ms.kr",
  },
  {
    id: 372,
    type: "사립",
    establush: "남부교육지원청",
    school: "해연중학교",
    address: "부산광역시 남구 진남로110번길 25 (대연동, 해연중학교)",
    phone: 516465004,
    fax: "051-644-6420",
    zipCode: 48449,
    homepage: "http://www.haeyeon.ms.kr",
  },
  {
    id: 373,
    type: "공립",
    establush: "동래교육지원청",
    school: "거제여자중학교",
    address: "부산광역시 연제구 금용로 43 (거제동, 거제여자중학교)",
    phone: 515038170,
    fax: "051-933-5999",
    zipCode: 47529,
    homepage: "http://www.keo-je.ms.kr",
  },
  {
    id: 374,
    type: "공립",
    establush: "동래교육지원청",
    school: "구서여자중학교",
    address: "부산광역시 금정구 금강로453번길 26-12 (구서동,구서여자중학교)",
    phone: 515805700,
    fax: "051-580-5750",
    zipCode: 46242,
    homepage: "http://www.guseo.ms.kr",
  },
  {
    id: 375,
    type: "공립",
    establush: "동래교육지원청",
    school: "금사중학교",
    address: "부산광역시 금정구 서금로 53-7 (서동)",
    phone: 515230952,
    fax: "051-523-0989",
    zipCode: 46312,
    homepage: "http://www.geumsa.ms.kr",
  },
  {
    id: 376,
    type: "공립",
    establush: "동래교육지원청",
    school: "금양중학교",
    address: "부산광역시 금정구 기찰로22번길 37 (부곡동, 금양중학교)",
    phone: 515800600,
    fax: "051-580-0607",
    zipCode: 46269,
    homepage: "http://geumyang.ms.kr",
  },
  {
    id: 377,
    type: "공립",
    establush: "동래교육지원청",
    school: "남산중학교",
    address: "부산광역시 금정구 금단로 114 (남산동, 남산중학교)",
    phone: 515800704,
    fax: "051-5800-740",
    zipCode: 46215,
    homepage: "http://www.p-namsan.ms.kr",
  },
  {
    id: 378,
    type: "공립",
    establush: "동래교육지원청",
    school: "남일중학교",
    address: "부산광역시 동래구 온천천로531번길 49 (안락동, 남일중학교)",
    phone: 517906304,
    fax: "051-790-6399",
    zipCode: 47905,
    homepage: "http://www.namil.ms.kr",
  },
  {
    id: 379,
    type: "공립",
    establush: "동래교육지원청",
    school: "동래중학교",
    address: "부산광역시 동래구 충렬대로181번길 109 (명륜동, 동래중학교)",
    phone: 517900806,
    fax: "051-790-0890",
    zipCode: 47735,
    homepage: "http://www.dongnae.ms.kr",
  },
  {
    id: 380,
    type: "공립",
    establush: "동래교육지원청",
    school: "동신중학교",
    address: "부산광역시 동래구 충렬사로51번길 38 (명장동)",
    phone: 515402500,
    fax: "051-540-2507",
    zipCode: 47750,
    homepage: "http://dongshin.ms.kr",
  },
  {
    id: 381,
    type: "공립",
    establush: "동래교육지원청",
    school: "동현중학교",
    address: "부산광역시 금정구 서동로 50 (부곡동, 동현중학교)",
    phone: 515800804,
    fax: "051-5800-899",
    zipCode: 46309,
    homepage: "http://www.donghyeon.ms.kr",
  },
  {
    id: 382,
    type: "공립",
    establush: "동래교육지원청",
    school: "부곡여자중학교",
    address: "부산광역시 금정구 가마실로 56 (부곡동,부곡여자중학교)",
    phone: 515105700,
    fax: "051-515-2150",
    zipCode: 46305,
    homepage: "http://bugok-g.ms.kr",
  },
  {
    id: 383,
    type: "공립",
    establush: "동래교육지원청",
    school: "부곡중학교",
    address: "부산광역시 금정구 동부곡로15번길 102-20 (부곡동, 부곡중학교)",
    phone: 515166026,
    fax: "051-515-5798",
    zipCode: 46270,
    homepage: "http://www.pugok.ms.kr/",
  },
  {
    id: 384,
    type: "공립",
    establush: "동래교육지원청",
    school: "부산내성중학교",
    address: "부산광역시 동래구 중앙대로 1297 (온천동,부산내성중학교)",
    phone: 515050814,
    fax: "051-504-8691",
    zipCode: 47824,
    homepage: "http://naesung.ms.kr",
  },
  {
    id: 385,
    type: "공립",
    establush: "동래교육지원청",
    school: "사직여자중학교",
    address: "부산광역시 동래구 사직로 91 (사직동, 사직여자중학교)",
    phone: 515900806,
    fax: "051-501-9712",
    zipCode: 47873,
    homepage: "http://www.sajik-g.ms.kr",
  },
  {
    id: 386,
    type: "공립",
    establush: "동래교육지원청",
    school: "사직중학교",
    address: "부산광역시 동래구 쇠미로51번길 30 (사직동)",
    phone: 515016602,
    fax: "051-505-3396",
    zipCode: 47872,
    homepage: "http://sajik.ms.kr",
  },
  {
    id: 387,
    type: "공립",
    establush: "동래교육지원청",
    school: "안락중학교",
    address: "부산광역시 동래구 온천천로 513 (안락2동)",
    phone: 517900700,
    fax: "051-7900-779",
    zipCode: 47905,
    homepage: "http://www.anrak.ms.kr",
  },
  {
    id: 388,
    type: "사립",
    establush: "남부교육지원청",
    school: "부산동중학교",
    address: "부산광역시 부산진구 동성로30번가길 36 , 부산동중학교 (전포동)",
    phone: 518020627,
    fax: "051-818-9166",
    zipCode: 47309,
    homepage: "http://www.tong.ms.kr",
  },
  {
    id: 389,
    type: "사립",
    establush: "서부교육지원청",
    school: "남도여자중학교",
    address: "부산광역시 영도구 남도여중길 130 (신선동2가,남도여자중학교)",
    phone: 514131619,
    fax: "051-416-3753",
    zipCode: 49071,
    homepage: "http://namdo.ms.kr",
  },
  {
    id: 390,
    type: "공립",
    establush: "동래교육지원청",
    school: "여명중학교",
    address: "부산광역시 동래구 여고로52번길 9 (사직동, 여명중학교)",
    phone: 515008793,
    fax: "051-500-8770",
    zipCode: 47845,
    homepage: "http://www.yomng.ms.kr",
  },
  {
    id: 391,
    type: "공립",
    establush: "동래교육지원청",
    school: "연산중학교",
    address: "부산광역시 연제구 고분로56번길 39 (연산동)",
    phone: 518019105,
    fax: "051-863-3932",
    zipCode: 47579,
    homepage: "http://www.yonsan.ms.kr",
  },
  {
    id: 392,
    type: "공립",
    establush: "동래교육지원청",
    school: "연제중학교",
    address: "부산광역시 연제구 쌍미천로 62 (연산동, 연제중학교)",
    phone: 518606377,
    fax: "051-864-8005",
    zipCode: 47589,
    homepage: "http://www.yeonje.ms.kr",
  },
  {
    id: 393,
    type: "공립",
    establush: "동래교육지원청",
    school: "연천중학교",
    address: "부산광역시 연제구 톳고개로 16 (연산동, 연천중학교)",
    phone: 7043300353,
    fax: "051-758-6440",
    zipCode: 47584,
    homepage: "http://www.yc.ms.kr",
  },
  {
    id: 394,
    type: "공립",
    establush: "동래교육지원청",
    school: "온천중학교",
    address: "부산광역시 동래구 쇠미로129번길 28 (온천동)",
    phone: 515001705,
    fax: "051-500-1707",
    zipCode: 47869,
    homepage: "http://onchun.ms.kr",
  },
  {
    id: 395,
    type: "공립",
    establush: "동래교육지원청",
    school: "유락여자중학교",
    address: "부산광역시 동래구 금강로 51 (온천동, 유락여자중학교)",
    phone: 519508405,
    fax: "051-9508-481",
    zipCode: 47707,
    homepage: "http://www.yurak.ms.kr/",
  },
  {
    id: 396,
    type: "공립",
    establush: "동래교육지원청",
    school: "장전중학교",
    address: "부산광역시 금정구 금샘로18번길 39 (장전동, 장전중학교)",
    phone: 515804300,
    fax: "051-581-4093",
    zipCode: 46295,
    homepage: "http://jangjeon.ms.kr",
  },
  {
    id: 397,
    type: "공립",
    establush: "동래교육지원청",
    school: "충렬중학교",
    address: "부산광역시 동래구 명장로64번길 45 (명장동,충렬중학교)",
    phone: 515203305,
    fax: "051-529-5755",
    zipCode: 47776,
    homepage: "http://www.choongryul.ms.kr",
  },
  {
    id: 398,
    type: "공립",
    establush: "동래교육지원청",
    school: "토현중학교",
    address: "부산광역시 연제구 토현로 47 (연산동, 토현중학교)",
    phone: 517503700,
    fax: "051-756-2324",
    zipCode: 47572,
    homepage: "http://tohyeon.ms.kr",
  },
  {
    id: 399,
    type: "사립",
    establush: "동래교육지원청",
    school: "거성중학교",
    address: "부산광역시 연제구 해맞이로109번길 61 , 거성중학교 (거제동)",
    phone: 515045376,
    fax: "051-502-5536",
    zipCode: 47531,
    homepage: "http://www.geosung.ms.kr",
  },
  {
    id: 400,
    type: "사립",
    establush: "동래교육지원청",
    school: "금정중학교",
    address: "부산광역시 금정구 중앙대로 2104 (남산동,금정중학교)",
    phone: 515173986,
    fax: "051-517-3982",
    zipCode: 46214,
    homepage: "http://kumjung.ms.kr",
  },
  {
    id: 401,
    type: "사립",
    establush: "동래교육지원청",
    school: "동래여자중학교",
    address: "부산광역시 금정구 체육공원로 20 (구서동, 동래여자중학교)",
    phone: 515141224,
    fax: "051-514-1225",
    zipCode: 46252,
    homepage: "http://dongnae-g.ms.kr",
  },
  {
    id: 402,
    type: "사립",
    establush: "동래교육지원청",
    school: "동해중학교",
    address: "부산광역시 동래구 시실로 19-39 (명륜동, 동해중학교)",
    phone: 515554458,
    fax: "051-553-9234",
    zipCode: 47756,
    homepage: "http://www.psdonghae.ms.kr",
  },
  {
    id: 403,
    type: "사립",
    establush: "동래교육지원청",
    school: "부산예술중학교",
    address: "부산광역시 금정구 체육공원로 20 , 부산예술중학교 (구서동, 학교법인동래학원)",
    phone: 515184032,
    fax: "051-518-4034",
    zipCode: 46252,
    homepage: "http://pusanarts.ms.kr",
  },
  {
    id: 404,
    type: "사립",
    establush: "동래교육지원청",
    school: "브니엘예술중학교",
    address: "부산광역시 금정구 체육공원로 154 , 브니엘예술중학교 (구서동)",
    phone: 515829122,
    fax: "051-582-9123",
    zipCode: 46251,
    homepage: "http://peniel.ms.kr",
  },
  {
    id: 405,
    type: "사립",
    establush: "동래교육지원청",
    school: "이사벨중학교",
    address: "부산광역시 연제구 중앙대로 1197 , 이사벨중학교 (거제동)",
    phone: 515902400,
    fax: "051-590-2583",
    zipCode: 47506,
    homepage: "http://www.isabel.ms.kr",
  },
  {
    id: 406,
    type: "사립",
    establush: "동래교육지원청",
    school: "학산여자중학교",
    address: "부산광역시 동래구 충렬사로51번길 61 (명장동,학산여자중학교)",
    phone: 517908900,
    fax: "051-790-8966",
    zipCode: 47750,
    homepage: "http://www.h-s.ms.kr",
  },
  {
    id: 407,
    type: "사립",
    establush: "동래교육지원청",
    school: "혜화여자중학교",
    address: "부산광역시 동래구 명장로67번길 14-22 , 혜화여자중학교 (명장동)",
    phone: 515274433,
    fax: "051-529-2983",
    zipCode: 47769,
    homepage: "http://www.hyehwa.ms.kr",
  },
  {
    id: 408,
    type: "공립",
    establush: "북부교육지원청",
    school: "가람중학교",
    address: "부산광역시 북구 낙동북로663번길 55 (구포동, 가람중학교)",
    phone: 513304813,
    fax: "051-3304-804",
    zipCode: 46585,
    homepage: "http://www.garam.ms.kr",
  },
  {
    id: 409,
    type: "공립",
    establush: "북부교육지원청",
    school: "구남중학교",
    address: "부산광역시 북구 백양대로1016번길 38 (구포동,구남중학교)",
    phone: 513305813,
    fax: "051-330-5829",
    zipCode: 46636,
    homepage: "http://www.gunam.ms.kr",
  },
  {
    id: 410,
    type: "공립",
    establush: "북부교육지원청",
    school: "주감중학교",
    address: "부산광역시 사상구 백양대로 530 (주례동, 주감중학교)",
    phone: 513144677,
    fax: "051-328-9040",
    zipCode: 46993,
    homepage: "http://www.jugam.ms.kr",
  },
  {
    id: 411,
    type: "공립",
    establush: "북부교육지원청",
    school: "구포중학교",
    address: "부산광역시 북구 백양대로1016번다길 31 (구포동)",
    phone: 513342202,
    fax: "051-331-8227",
    zipCode: 46637,
    homepage: "http://gupo.ms.kr",
  },
  {
    id: 412,
    type: "공립",
    establush: "북부교육지원청",
    school: "금곡중학교",
    address: "부산광역시 북구 효열로162번길 3 (금곡동, 금곡중학교)",
    phone: 513300800,
    fax: "051-330-0898",
    zipCode: 46512,
    homepage: "http://kumgok.ms.kr",
  },
  {
    id: 413,
    type: "공립",
    establush: "북부교육지원청",
    school: "낙동중학교",
    address: "부산광역시 강서구 대저로 115 (대저1동,낙동중학교)",
    phone: 519752812,
    fax: "051-973-4597",
    zipCode: 46700,
    homepage: "http://nak-dong.ms.kr",
  },
  {
    id: 414,
    type: "공립",
    establush: "북부교육지원청",
    school: "대천리중학교",
    address: "부산광역시 북구 산성로 87-15 (화명동)",
    phone: 513660705,
    fax: "051-362-6393",
    zipCode: 46528,
    homepage: "http://www.daechonli.ms.kr",
  },
  {
    id: 415,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕문중학교",
    address: "부산광역시 강서구 동선길 144 , 덕문중학교 (성북동)",
    phone: 519796170,
    fax: "051-971-2650",
    zipCode: 46769,
    homepage: "http://duckmun.ms.kr/",
  },
  {
    id: 416,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕천중학교",
    address: "부산광역시 북구 만덕대로65번길 50 (덕천동, 덕천중학교)",
    phone: 513663404,
    fax: "051-332-0313",
    zipCode: 46552,
    homepage: "http://deokcheon.ms.kr",
  },
  {
    id: 417,
    type: "공립",
    establush: "북부교육지원청",
    school: "덕포여자중학교",
    address: "부산광역시 사상구 사상로310번길 110 (덕포동)",
    phone: 513199922,
    fax: "051-304-7164",
    zipCode: 46952,
    homepage: "http://duckpo.ms.kr",
  },
  {
    id: 418,
    type: "공립",
    establush: "북부교육지원청",
    school: "동주중학교",
    address: "부산광역시 사상구 주례로 107 (주례동,동주중학교)",
    phone: 513220056,
    fax: "051-316-4175",
    zipCode: 47017,
    homepage: "http://www.dongju.ms.kr",
  },
  {
    id: 419,
    type: "공립",
    establush: "북부교육지원청",
    school: "만덕중학교",
    address: "부산광역시 북구 만덕1로 52 , 만덕중학교 (만덕동)",
    phone: 517969300,
    fax: "051-7969-390",
    zipCode: 46558,
    homepage: "http://www.mandeok.ms.kr/",
  },
  {
    id: 420,
    type: "공립",
    establush: "북부교육지원청",
    school: "명지중학교",
    address: "부산광역시 강서구 명지국제6로318번길 54 (명지동)",
    phone: 512201416,
    fax: "051-220-1498",
    zipCode: 46726,
    homepage: "http://myeongji.ms.kr",
  },
  {
    id: 421,
    type: "공립",
    establush: "북부교육지원청",
    school: "명진중학교",
    address: "부산광역시 북구 학사로 192 , 명진중학교 (화명동, 명진중학교)",
    phone: 513304606,
    fax: "051-330-4608",
    zipCode: 46524,
    homepage: "http://www.mjm.ms.kr/",
  },
  {
    id: 422,
    type: "공립",
    establush: "북부교육지원청",
    school: "명호중학교",
    address: "부산광역시 강서구 명지오션시티2로 100 , 명호중학교 (명지동, 명호중학교)",
    phone: 512201200,
    fax: "051-271-6306",
    zipCode: 46765,
    homepage: "http://myeong-ho.ms.kr/",
  },
  {
    id: 423,
    type: "공립",
    establush: "북부교육지원청",
    school: "모동중학교",
    address: "부산광역시 사상구 모라로192번길 51 , 모동중학교 (모라동, 모동중학교)",
    phone: 517909200,
    fax: "051-302-6137",
    zipCode: 46935,
    homepage: "http://modong.ms.kr",
  },
  {
    id: 424,
    type: "공립",
    establush: "북부교육지원청",
    school: "모라중학교",
    address: "부산광역시 사상구 백양대로 936 (모라동,모라중학교)",
    phone: 513273017,
    fax: "051-327-3229",
    zipCode: 46928,
    homepage: "http://mora.ms.kr",
  },
  {
    id: 425,
    type: "공립",
    establush: "북부교육지원청",
    school: "백양중학교",
    address: "부산광역시 북구 상리로 70 (만덕동)",
    phone: 513326789,
    fax: "051-331-0164",
    zipCode: 46614,
    homepage: "http://www.paekyang.ms.kr",
  },
  {
    id: 426,
    type: "공립",
    establush: "북부교육지원청",
    school: "신덕중학교",
    address: "부산광역시 북구 함박봉로140번가길 27 (만덕동, 신덕중학교)",
    phone: 513302707,
    fax: "051-331-4008",
    zipCode: 46616,
    homepage: "http://www.shindeok.ms.kr",
  },
  {
    id: 427,
    type: "공립",
    establush: "북부교육지원청",
    school: "신호중학교",
    address: "부산광역시 강서구 신호산단2로 76 , 신호중학교 (신호동)",
    phone: 513209304,
    fax: "051-320-9317",
    zipCode: 46760,
    homepage: "http://shinho.ms.kr/",
  },
  {
    id: 428,
    type: "공립",
    establush: "북부교육지원청",
    school: "양덕여자중학교",
    address: "부산광역시 북구 시랑로21번길 178 (구포동, 양덕여자중학교)",
    phone: 513300905,
    fax: "051-341-1190",
    zipCode: 46592,
    homepage: "http://www.yangduk.ms.kr",
  },
  {
    id: 429,
    type: "공립",
    establush: "북부교육지원청",
    school: "엄궁중학교",
    address: "부산광역시 사상구 대동로 56 (엄궁동, 엄궁중학교)",
    phone: 517136604,
    fax: "051-311-2622",
    zipCode: 47035,
    homepage: "http://www.eomgung.ms.kr",
  },
  {
    id: 430,
    type: "공립",
    establush: "북부교육지원청",
    school: "용수중학교",
    address: "부산광역시 북구 금곡대로229번길 88 (화명동)",
    phone: 513093002,
    fax: "051-309-3020",
    zipCode: 46527,
    homepage: "http://www.yongsu.ms.kr",
  },
  {
    id: 431,
    type: "공립",
    establush: "북부교육지원청",
    school: "금명중학교",
    address: "부산광역시 북구 화명신도시로 210-1 (금곡동, 금명중학교)",
    phone: 513304500,
    fax: "051-330-4523",
    zipCode: 46520,
    homepage: "http://geummyeong.ms.kr/",
  },
  {
    id: 432,
    type: "공립",
    establush: "북부교육지원청",
    school: "주례여자중학교",
    address: "부산광역시 사상구 가야대로306번길 55 , 주례여자중학교 (주례동, 주례여자중학교)",
    phone: 513103700,
    fax: "051-322-9108",
    zipCode: 47014,
    homepage: "http://www.juryegirls.ms.kr",
  },
  {
    id: 433,
    type: "공립",
    establush: "해운대교육지원청",
    school: "상당중학교",
    address: "부산광역시 해운대구 대천로103번길 48 (좌동, 상당중학교)",
    phone: 517090600,
    fax: "051-709-0604",
    zipCode: 48075,
    homepage: "http://www.sangdang.ms.kr",
  },
  {
    id: 434,
    type: "공립",
    establush: "해운대교육지원청",
    school: "센텀중학교",
    address: "부산광역시 해운대구 센텀북대로 32 (재송동, 센텀중학교)",
    phone: 517802500,
    fax: "051-780-2599",
    zipCode: 48058,
    homepage: "http://www.centum.ms.kr",
  },
  {
    id: 435,
    type: "공립",
    establush: "북부교육지원청",
    school: "지사중학교",
    address: "부산광역시 강서구 과학산단로306번길 33 (지사동)",
    phone: 519796203,
    fax: "051-979-6219",
    zipCode: 46743,
    homepage: "http://bsjisa.ms.kr",
  },
  {
    id: 436,
    type: "공립",
    establush: "북부교육지원청",
    school: "학장중학교",
    address: "부산광역시 사상구 대동로 140 , 60 (학장동)",
    phone: 513202500,
    fax: "051-320-2588",
    zipCode: 47051,
    homepage: "http://www.hakjang.ms.kr",
  },
  {
    id: 437,
    type: "공립",
    establush: "북부교육지원청",
    school: "화명중학교",
    address: "부산광역시 북구 금곡대로 186번길 , 13 (화명동)",
    phone: 513663552,
    fax: "051-337-9121",
    zipCode: 46538,
    homepage: "http://hwamyong.ms.kr/",
  },
  {
    id: 438,
    type: "공립",
    establush: "북부교육지원청",
    school: "화신중학교",
    address: "부산광역시 북구 산성로12번길 22 (화명동, 화신중학교)",
    phone: 513300573,
    fax: "051-341-1700",
    zipCode: 46531,
    homepage: "http://www.hwashin.ms.kr",
  },
  {
    id: 439,
    type: "공립",
    establush: "북부교육지원청",
    school: "경일중학교",
    address: "부산광역시 강서구 명지국제6로(명지동)",
    phone: 512503200,
    fax: "051-250-3215",
    zipCode: 46725,
    homepage: "http://gyeongil.ms.kr",
  },
  {
    id: 440,
    type: "사립",
    establush: "북부교육지원청",
    school: "녹산중학교",
    address: "부산광역시 강서구 낙동남로 659 , 녹산중학교 (녹산동)",
    phone: 519715042,
    fax: "051-971-5124",
    zipCode: 46728,
    homepage: "http://www.noksan.ms.kr",
  },
  {
    id: 441,
    type: "사립",
    establush: "북부교육지원청",
    school: "대저중학교",
    address: "부산광역시 강서구 공항로811번가길 46 (대저2동, 대저중고등학교)",
    phone: 519737702,
    fax: "051-972-7772",
    zipCode: 46720,
    homepage: "http://daejeo.ms.kr",
  },
  {
    id: 442,
    type: "사립",
    establush: "북부교육지원청",
    school: "신라중학교",
    address: "부산광역시 사상구 백양대로700번길 35-8 (덕포동)",
    phone: 519996000,
    fax: "051-999-6006",
    zipCode: 46957,
    homepage: "http://busansilla.ms.kr",
  },
  {
    id: 443,
    type: "공립",
    establush: "해운대교육지원청",
    school: "광안중학교",
    address: "부산광역시 수영구 연수로310번길 127 , 광안중학교 (광안동)",
    phone: 517503102,
    fax: "051-750-3103",
    zipCode: 48237,
    homepage: "http://gwangan.ms.kr",
  },
  {
    id: 444,
    type: "공립",
    establush: "해운대교육지원청",
    school: "기장중학교",
    address: "부산광역시 기장군 기장읍 읍내로 120 , 기장중학교 (기장읍)",
    phone: 517093806,
    fax: "051-722-4294",
    zipCode: 46065,
    homepage: "http://www.gijang.ms.kr",
  },
  {
    id: 445,
    type: "공립",
    establush: "해운대교육지원청",
    school: "대청중학교",
    address: "부산광역시 기장군 기장읍 차성서로 3 (기장읍, 대청중학교)",
    phone: 517200600,
    fax: "051-720-0699",
    zipCode: 46075,
    homepage: "http://www.daicheong.ms.kr",
  },
  {
    id: 446,
    type: "공립",
    establush: "해운대교육지원청",
    school: "동백중학교",
    address: "부산광역시 해운대구 좌동순환로468번가길 97 (중동, 동백중학교)",
    phone: 517402362,
    fax: "051-731-3205",
    zipCode: 48103,
    homepage: "http://tongbaek.ms.kr",
  },
  {
    id: 447,
    type: "공립",
    establush: "해운대교육지원청",
    school: "망미중학교",
    address: "부산광역시 수영구 망미배산로 102 (망미동,망미중학교)",
    phone: 517500700,
    fax: "051-750-0799",
    zipCode: 48200,
    homepage: "http://www.mangmi.ms.kr",
  },
  {
    id: 448,
    type: "공립",
    establush: "해운대교육지원청",
    school: "모전중학교",
    address: "부산광역시 기장군 정관읍 정관1로 9 (정관읍, 모전중학교)",
    phone: 517509304,
    fax: "051-727-9395",
    zipCode: 46008,
    homepage: "http://mojeon.ms.kr",
  },
  {
    id: 449,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반송여자중학교",
    address: "부산광역시 해운대구 운봉길 113 (반송동, 반송여자중학교)",
    phone: 515405400,
    fax: "051-543-0997",
    zipCode: 48000,
    homepage: "http://www.bansongg.ms.kr",
  },
  {
    id: 450,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반송중학교",
    address: "부산광역시 해운대구 아랫반송로40번길 37-18 (반송동)",
    phone: 515400300,
    fax: "051-544-1622",
    zipCode: 48018,
    homepage: "http://bansong.ms.kr",
  },
  {
    id: 451,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반안중학교",
    address: "부산광역시 해운대구 명장로67번길 40-32 (반여동, 반안중학교)",
    phone: 515200954,
    fax: "051-521-1764",
    zipCode: 48046,
    homepage: "http://www.banan.ms.kr/",
  },
  {
    id: 452,
    type: "공립",
    establush: "해운대교육지원청",
    school: "반여중학교",
    address: "부산광역시 해운대구 재반로256번길 57-12 (반여동,반여중학교)",
    phone: 517131705,
    fax: "051-781-2991",
    zipCode: 48026,
    homepage: "http://www.banyo.ms.kr",
  },
  {
    id: 453,
    type: "공립",
    establush: "동래교육지원청",
    school: "연일중학교",
    address: "부산광역시 연제구 고분로 138 (연산동,연일중학교)",
    phone: 518671116,
    fax: "051-861-3557",
    zipCode: 47583,
    homepage: "http://yeonil.ms.kr/",
  },
  {
    id: 454,
    type: "공립",
    establush: "북부교육지원청",
    school: "주례중학교",
    address: "부산광역시 사상구 가야대로378번길 68 , 주례중학교 (주례동, 주례중학교)",
    phone: 513200300,
    fax: "051-323-8191",
    zipCode: 47009,
    homepage: "http://jurye.ms.kr",
  },
  {
    id: 455,
    type: "공립",
    establush: "해운대교육지원청",
    school: "부산수영중학교",
    address: "부산광역시 수영구 호암로 30 (광안동, 부산수영중학교)",
    phone: 517607700,
    fax: "051-753-6592",
    zipCode: 48256,
    homepage: "http://www.suyeong.ms.kr",
  },
  {
    id: 456,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신곡중학교",
    address: "부산광역시 해운대구 좌동순환로 316 (좌동)",
    phone: 517099905,
    fax: "051-709-9999",
    zipCode: 48110,
    homepage: "http://www.singok.ms.kr",
  },
  {
    id: 457,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신도중학교",
    address: "부산광역시 해운대구 좌동순환로 50 (좌동)",
    phone: 517478713,
    fax: "051-747-8716",
    zipCode: 48083,
    homepage: "http://www.sindo.ms.kr",
  },
  {
    id: 458,
    type: "공립",
    establush: "해운대교육지원청",
    school: "신정중학교",
    address: "부산광역시 기장군 정관읍 정관3로 44 (정관읍, 신정중학교)",
    phone: 517453700,
    fax: "051-727-1844",
    zipCode: 46012,
    homepage: "http://psinjung.ms.kr",
  },
  {
    id: 459,
    type: "공립",
    establush: "해운대교육지원청",
    school: "양운중학교",
    address: "부산광역시 해운대구 좌동순환로99번길 7 (좌동, 양운중학교)",
    phone: 517204605,
    fax: "051-731-3786",
    zipCode: 48080,
    homepage: "http://www.yangun.ms.kr",
  },
  {
    id: 460,
    type: "공립",
    establush: "해운대교육지원청",
    school: "인지중학교",
    address: "부산광역시 해운대구 반여로 142 , 인지중학교 (반여동, 인지중학교)",
    phone: 515200764,
    fax: "051-520-0763",
    zipCode: 48036,
    homepage: "http://www.inji.ms.kr",
  },
  {
    id: 461,
    type: "공립",
    establush: "해운대교육지원청",
    school: "장산중학교",
    address: "부산광역시 해운대구 선수촌로 47 (반여동,장산중학교)",
    phone: 515203506,
    fax: "051-532-9704",
    zipCode: 48038,
    homepage: "http://bjangsan.ms.kr",
  },
  {
    id: 462,
    type: "공립",
    establush: "해운대교육지원청",
    school: "재송여자중학교",
    address: "부산광역시 해운대구 재반로84번길 148 (재송동)",
    phone: 517196783,
    fax: "051-719-6788",
    zipCode: 48053,
    homepage: "http://www.jaesonggirl.ms.kr",
  },
  {
    id: 463,
    type: "공립",
    establush: "해운대교육지원청",
    school: "재송중학교",
    address: "부산광역시 해운대구 재반로165번길 16 (재송동)",
    phone: 517802207,
    fax: "051-781-4241",
    zipCode: 48051,
    homepage: "http://www.jaesong.ms.kr",
  },
  {
    id: 464,
    type: "공립",
    establush: "해운대교육지원청",
    school: "정관중학교",
    address: "부산광역시 기장군 정관읍 정관로 531 (정관읍)",
    phone: 517805400,
    fax: "051-780-5430",
    zipCode: 46015,
    homepage: "http://jeonggwan.ms.kr/",
  },
  {
    id: 465,
    type: "공립",
    establush: "해운대교육지원청",
    school: "한바다중학교",
    address: "부산광역시 수영구 광안로21번가길 57 (광안동)",
    phone: 517600700,
    fax: "051-753-8125",
    zipCode: 48296,
    homepage: "http://hanbada.ms.kr",
  },
  {
    id: 466,
    type: "공립",
    establush: "해운대교육지원청",
    school: "해강중학교",
    address: "부산광역시 해운대구 해운대해변로 17 (우동)",
    phone: 517402704,
    fax: "051-740-2799",
    zipCode: 48089,
    homepage: "http://www.haegang.ms.kr",
  },
  {
    id: 467,
    type: "사립",
    establush: "해운대교육지원청",
    school: "동수영중학교",
    address: "부산광역시 수영구 광서로 74 , 동수영중학교 (광안동)",
    phone: 517520046,
    fax: "051-751-9674",
    zipCode: 48245,
    homepage: "http://dsuyoung.ms.kr",
  },
  {
    id: 468,
    type: "사립",
    establush: "해운대교육지원청",
    school: "동아중학교",
    address: "부산광역시 수영구 호암로 55 (광안동, 동아중학교)",
    phone: 517921000,
    fax: "051-792-1045",
    zipCode: 48259,
    homepage: "http://www.dong-a.ms.kr",
  },
  {
    id: 469,
    type: "사립",
    establush: "해운대교육지원청",
    school: "장안중학교",
    address: "부산광역시 기장군 장안읍 좌천2길 43 , 장안중학교 (장안읍, 장안중/제일고등학교)",
    phone: 517199300,
    fax: "051- 719-9290",
    zipCode: 46033,
    homepage: "http://jang-an.ms.kr",
  },
  {
    id: 470,
    type: "사립",
    establush: "해운대교육지원청",
    school: "해운대여자중학교",
    address: "부산광역시 해운대구 우동1로85번길 25 (우동,해운대여자중학교)",
    phone: 517462948,
    fax: "051-741-6350",
    zipCode: 48085,
    homepage: "http://www.haeundaeg.ms.kr/~haeundae/newhome/",
  },
  {
    id: 471,
    type: "사립",
    establush: "해운대교육지원청",
    school: "해운대중학교",
    address: "부산광역시 해운대구 우동2로30번길 99 (우동, 해운대중학교)",
    phone: 517420315,
    fax: "051-741-5357",
    zipCode: 48086,
    homepage: "http://www.haeundae.ms.kr",
  },
  {
    id: 472,
    type: "공립",
    establush: "북부교육지원청",
    school: "송정중학교",
    address: "부산광역시 강서구 가락대로 230-72",
    phone: "051-979-6700",
    fax: "051-979-6767",
    zipCode: 46731,
    homepage: "http://school.busanedu.net/busansongjeong-m",
  },
  {
    id: 473,
    type: "공립",
    establush: "해운대교육지원청",
    school: "부흥중학교",
    address: "부산광역시 해운대구 좌동순환로 226 , 부흥중학교 (좌동, 부흥중학교)",
    phone: 517092305,
    fax: "051-702-0176",
    zipCode: 48077,
    homepage: "http://buheung.ms.kr/",
  },
  {
    id: 474,
    type: "공립",
    establush: "해운대교육지원청",
    school: "부산중앙중학교",
    address: "부산광역시 기장군 정관읍 정관덕산길 73 (정관읍)",
    phone: 517208780,
    fax: "051-720-8729",
    zipCode: 46022,
    homepage: "http://www.bsjungang.ms.kr",
  },
  {
    id: 475,
    type: "공립",
    establush: "북부교육지원청",
    school: "오션중학교",
    address: "부산광역시 강서구 명지동 3242-3",
    phone: null,
    fax: "051-741-5357",
    zipCode: 48086,
    homepage: "http://www.haeundae.ms.kr",
  },
  {
    id: 476,
    type: "사립",
    establush: "본청",
    school: "부산국제영화고등학교",
    address: "부산광역시 동구 고관로 68 , 부산국제영화고등학교 (초량동)",
    phone: 514670404,
    fax: "051-462-1917",
    zipCode: 48792,
    homepage: "http://www.bifh.co.kr",
  },
  {
    id: 477,
    type: "사립",
    establush: "본청",
    school: "경성전자고등학교",
    address: "부산광역시 서구 꽃마을로 25 (서대신동3가)",
    phone: 512532450,
    fax: "051-255-0472",
    zipCode: 49209,
    homepage: "http://kse.hs.kr",
  },
  {
    id: 478,
    type: "사립",
    establush: "본청",
    school: "경원고등학교",
    address: "부산광역시 부산진구 백양관문로 40 (당감동)",
    phone: 518982215,
    fax: "051-898-2219",
    zipCode: 47176,
    homepage: "http://www.kyungwon.hs.kr",
  },
  {
    id: 479,
    type: "사립",
    establush: "본청",
    school: "경일고등학교",
    address: "부산광역시 강서구 영강길 153-42 (명지동,경일고등학교)",
    phone: 512712371,
    fax: "051-271-2885",
    zipCode: 46725,
    homepage: "http://www.ki.hs.kr",
  },
  {
    id: 480,
    type: "사립",
    establush: "본청",
    school: "경혜여자고등학교",
    address: "부산광역시 북구 시랑로21번길 138 (구포동, 경혜여자고등학교)",
    phone: 513361042,
    fax: "051-336-1046",
    zipCode: 46592,
    homepage: "http://kyonghye.hs.kr/",
  },
  {
    id: 481,
    type: "공립",
    establush: "본청",
    school: "신정고등학교",
    address: "부산광역시 기장군 정관읍 정관3로 14 , 신정고등학교",
    phone: 517453472,
    fax: "051-727-9702",
    zipCode: 46011,
    homepage: "http://shinjeong.hs.kr",
  },
  {
    id: 482,
    type: "공립",
    establush: "본청",
    school: "신도고등학교",
    address: "부산광역시 해운대구 좌동순환로 64 (좌동, 신도고등학교)",
    phone: 517098400,
    fax: "051-746-9331",
    zipCode: 48083,
    homepage: "http://shindo.hs.kr",
  },
  {
    id: 483,
    type: "공립",
    establush: "본청",
    school: "센텀고등학교",
    address: "부산광역시 해운대구 해운대로 246 (재송동, 센텀고등학교)",
    phone: 517308800,
    fax: "051-730-8810",
    zipCode: 48059,
    homepage: "http://www.centum.hs.kr",
  },
  {
    id: 484,
    type: "공립",
    establush: "본청",
    school: "서부산공업고등학교",
    address: "부산광역시 사상구 백양대로768번길 115 (덕포동, 서부산공업고등학교)",
    phone: 513093114,
    fax: "051-309-3160",
    zipCode: 46940,
    homepage: "http://sth.hs.kr",
  },
  {
    id: 485,
    type: "공립",
    establush: "본청",
    school: "부산여자고등학교",
    address: "부산광역시 사하구 낙동대로520번길 36 , 부산여자고등학교 (하단동)",
    phone: 512000301,
    fax: "051-200-0351",
    zipCode: 49315,
    homepage: "http://busan-gh.hs.kr",
  },
  {
    id: 486,
    type: "공립",
    establush: "본청",
    school: "부산영상예술고등학교",
    address: "부산광역시 영도구 영상길 100 , 부산영상예술고등학교 (신선동3가)",
    phone: 514190324,
    fax: "051-419-0329",
    zipCode: 49072,
    homepage: "http://visual.hs.kr/",
  },
  {
    id: 487,
    type: "공립",
    establush: "본청",
    school: "부산일과학고등학교",
    address: "부산광역시 사하구 제석로 242 , 부산일과학고등학교 (당리동)",
    phone: 512906800,
    fax: "051-290-6809",
    zipCode: 49317,
    homepage: "http://bsis.hs.kr",
  },
  {
    id: 488,
    type: "공립",
    establush: "본청",
    school: "부산자동차고등학교",
    address: "부산광역시 사하구 하신중앙로3번나길 16 (장림동, 부산자동차고등학교)",
    phone: 512634825,
    fax: "051-265-9766",
    zipCode: 49480,
    homepage: "http://www.automotive.hs.kr",
  },
  {
    id: 489,
    type: "공립",
    establush: "본청",
    school: "부산장안고등학교",
    address: "부산광역시 기장군 장안읍 장안고길 8-16 (장안읍, 부산장안고등학교)",
    phone: 517201774,
    fax: "051-727-5920",
    zipCode: 46029,
    homepage: "http://jang-an.hs.kr/",
  },
  {
    id: 490,
    type: "공립",
    establush: "본청",
    school: "사직고등학교",
    address: "부산광역시 동래구 쇠미로119번길 51 , 사직고등학교 (사직동, 사직고등학교)",
    phone: 515900303,
    fax: "051-590-0309",
    zipCode: 47871,
    homepage: "http://www.sajik.hs.kr/",
  },
  {
    id: 491,
    type: "공립",
    establush: "본청",
    school: "사상고등학교",
    address: "부산광역시 사상구 강선로 24 , 사상고등학교 (덕포동)",
    phone: 513669606,
    fax: "051-366-9789",
    zipCode: 46953,
    homepage: "http://sasang.hs.kr",
  },
  {
    id: 492,
    type: "공립",
    establush: "본청",
    school: "분포고등학교",
    address: "부산광역시 남구 분포로 57 , 분포고등학교 (용호동, 분포고등학교)",
    phone: 516103304,
    fax: "051-611-0639",
    zipCode: 48516,
    homepage: "http://www.bunpo.hs.kr",
  },
  {
    id: 493,
    type: "공립",
    establush: "본청",
    school: "부흥고등학교",
    address: "부산광역시 해운대구 좌동순환로 248 (좌동, 부흥고등학교)",
    phone: 517096105,
    fax: "051-702-3293",
    zipCode: 48107,
    homepage: "http://www.buhungko.hs.kr",
  },
  {
    id: 494,
    type: "공립",
    establush: "본청",
    school: "부산체육고등학교",
    address: "부산광역시 영도구 중리북로21번길 5 , 부산체육고등학교 (동삼동)",
    phone: 514003005,
    fax: "051-403-5308",
    zipCode: 49118,
    homepage: "http://busport.hs.kr",
  },
  {
    id: 495,
    type: "공립",
    establush: "본청",
    school: "부산진여자상업고등학교",
    address: "부산광역시 부산진구 진남로 524 (양정동)",
    phone: 518503656,
    fax: "051-850-3699",
    zipCode: 47228,
    homepage: "http://jin.hs.kr/",
  },
  {
    id: 496,
    type: "공립",
    establush: "본청",
    school: "부산진고등학교",
    address: "부산광역시 부산진구 성지로94번길 54 (초읍동,부산진고등학교)",
    phone: 518100505,
    fax: "051-810-0508",
    zipCode: 47116,
    homepage: "http://www.pusanjin.hs.kr",
  },
  {
    id: 497,
    type: "공립",
    establush: "본청",
    school: "부산중앙여자고등학교",
    address: "부산광역시 동래구 온천천로 131 (명륜동,부산중앙여자고등학교)",
    phone: 515550370,
    fax: "051-554-0539",
    zipCode: 47735,
    homepage: "http://www.busan-jag.hs.kr",
  },
  {
    id: 498,
    type: "공립",
    establush: "본청",
    school: "부산중앙고등학교",
    address: "부산광역시 남구 진남로127번길 50 , 부산중앙고등학교 (대연동, 부산중앙고등학교)",
    phone: 516438272,
    fax: "051-643-9098",
    zipCode: 48448,
    homepage: "http://www.bjungang.hs.kr",
  },
  {
    id: 499,
    type: "공립",
    establush: "본청",
    school: "부산전자공업고등학교",
    address: "부산광역시 동래구 금강로59번길 51 (온천동,부산전자공업고등학교)",
    phone: 516060200,
    fax: "051-553-8224",
    zipCode: 47707,
    homepage: "http://kumgang.hs.kr/",
  },
  {
    id: 500,
    type: "사립",
    establush: "본청",
    school: "한얼고등학교",
    address: "부산광역시 남구 고동골로69번가길 54(문현동) 한얼고등학교",
    phone: 516375983,
    fax: "051-637-3581",
    zipCode: 48405,
    homepage: "http://haneol.hs.kr",
  },
  {
    id: 501,
    type: "사립",
    establush: "본청",
    school: "학산여자고등학교",
    address: "부산광역시 동래구 충렬사로51번길 62 , 학산여자고등학교 (명장동)",
    phone: 515251114,
    fax: "051-524-2679",
    zipCode: 47750,
    homepage: "http://www.hsgh.hs.kr",
  },
  {
    id: 502,
    type: "사립",
    establush: "본청",
    school: "지산고등학교",
    address: "부산광역시 금정구 오륜대로 50 (부곡동)",
    phone: 515802613,
    fax: "051-580-2619",
    zipCode: 46265,
    homepage: "http://www.jisan.hs.kr",
  },
  {
    id: 503,
    type: "사립",
    establush: "본청",
    school: "동명공업고등학교",
    address: "부산광역시 남구 신선로 410 (용당동,동명공업고등학교)",
    phone: 516102527,
    fax: "051-625-5444",
    zipCode: 48520,
    homepage: "http://tm.hs.kr/",
  },
  {
    id: 504,
    type: "사립",
    establush: "본청",
    school: "부산컴퓨터과학고등학교",
    address: "부산광역시 동구 초량로 80 부산컴퓨터과학고등학교 (초량동)",
    phone: 514600593,
    fax: "051-466-1787",
    zipCode: 48803,
    homepage: "http://www.pcs.hs.kr",
  },
  {
    id: 505,
    type: "사립",
    establush: "본청",
    school: "동의공업고등학교",
    address: "부산광역시 부산진구 양지로 50 동의공업고등학교 (전포동)",
    phone: 518117581,
    fax: "051-811-7588",
    zipCode: 47230,
    homepage: "http://www.dongeui-tech.hs.kr",
  },
  {
    id: 506,
    type: "사립",
    establush: "본청",
    school: "동아공업고등학교",
    address: "부산광역시 사하구 괴정로260번길 79 (괴정동)",
    phone: 512003802,
    fax: "051-205-6121",
    zipCode: 49394,
    homepage: "http://donga-tec.hs.kr",
  },
  {
    id: 507,
    type: "사립",
    establush: "본청",
    school: "동아고등학교",
    address: "부산광역시 사하구 괴정로 244번길 , 63 (괴정동,동아고등학교)",
    phone: 512905000,
    fax: "051-291-5008",
    zipCode: 49385,
    homepage: "http://dong-a.hs.kr",
  },
  {
    id: 508,
    type: "사립",
    establush: "본청",
    school: "동래여자고등학교",
    address: "부산광역시 금정구 체육공원로 20 (구서동)",
    phone: 515141227,
    fax: "051-516-5724",
    zipCode: 46252,
    homepage: "http://www.dongnae-gh.hs.kr",
  },
  {
    id: 509,
    type: "사립",
    establush: "본청",
    school: "데레사여자고등학교",
    address: "부산광역시 동구 정공단로47번길 14 (범일동, 데레사여자고등학교)",
    phone: 516401112,
    fax: "051-640-1120",
    zipCode: 48723,
    homepage: "http://www.trs.hs.kr",
  },
  {
    id: 510,
    type: "사립",
    establush: "본청",
    school: "덕문여자고등학교",
    address: "부산광역시 수영구 연수로310번길 114 (광안동, 덕문여자고등학교)",
    phone: 517561802,
    fax: "051-751-0920",
    zipCode: 48237,
    homepage: "http://www.dukmoon.hs.kr",
  },
  {
    id: 511,
    type: "사립",
    establush: "본청",
    school: "대진전자통신고등학교",
    address: "부산광역시 금정구 수림로 92 (장전동)",
    phone: 515828100,
    fax: "051-582-8100",
    zipCode: 46247,
    homepage: "http://www.pdj.hs.kr",
  },
  {
    id: 512,
    type: "사립",
    establush: "본청",
    school: "대연고등학교",
    address: "부산광역시 남구 조각공원로 20 (대연동, 대연고등학교)",
    phone: 516258114,
    fax: "051-611-4887",
    zipCode: 48530,
    homepage: "http://www.daeyeon.hs.kr",
  },
  {
    id: 513,
    type: "사립",
    establush: "본청",
    school: "해동고등학교",
    address: "부산광역시 사하구 사하로141번나길 11-15 , 해동고등학교 (괴정동)",
    phone: 512044442,
    fax: "051-203-8399",
    zipCode: 49394,
    homepage: "http://www.haedong.hs.kr",
  },
  {
    id: 514,
    type: "사립",
    establush: "본청",
    school: "해운대고등학교",
    address: "부산광역시 해운대구 우동3로11번길 43 (우동, 해운대고등학교)",
    phone: 517420312,
    fax: "051-742-0321",
    zipCode: 48086,
    homepage: "http://www.haeundae.hs.kr",
  },
  {
    id: 515,
    type: "사립",
    establush: "본청",
    school: "해운대관광고등학교",
    address: "부산광역시 해운대구 우동2로60번길 38 (우동,해운대관광고등학교)",
    phone: "051)7420041",
    fax: "051-741-4348",
    zipCode: 48086,
    homepage: "http://www.h-tour.hs.kr",
  },
  {
    id: 516,
    type: "사립",
    establush: "본청",
    school: "해운대여자고등학교",
    address: "부산광역시 해운대구 우동1로85번길 71 (우동, 해운대여자고등학교)",
    phone: 517416813,
    fax: "051-741-6812",
    zipCode: 48085,
    homepage: "http://hwdghs.hs.kr",
  },
  {
    id: 517,
    type: "사립",
    establush: "본청",
    school: "혜광고등학교",
    address: "부산광역시 중구 망양로259번길 5 (보수동1가,혜광고등학교)",
    phone: 512501081,
    fax: "051-250-1088",
    zipCode: 48903,
    homepage: "http://www.hyekwang.hs.kr",
  },
  {
    id: 518,
    type: "사립",
    establush: "본청",
    school: "혜화여자고등학교",
    address: "부산광역시 동래구 명장로99번길 23 , (명장동, 혜화여자고등학교)",
    phone: 515281740,
    fax: "051-528-5456",
    zipCode: 47769,
    homepage: "http://www.hye-hwa.hs.kr",
  },
  {
    id: 519,
    type: "사립",
    establush: "본청",
    school: "대광고등학교",
    address: "부산광역시 사하구 동매로 135",
    phone: "051-292-1875",
    fax: "051-292-3226",
    zipCode: 49412,
    homepage: "http://school.busanedu.net/daegwang-h",
  },
  {
    id: 520,
    type: "사립",
    establush: "본청",
    school: "장안제일고등학교",
    address: "부산광역시 기장군 장안읍 좌천2길 43 , 장안제일고등학교 (장안읍)",
    phone: 517199210,
    fax: "051-719-9290",
    zipCode: 46033,
    homepage: "http://www.janganjeil.hs.kr",
  },
  {
    id: 521,
    type: "사립",
    establush: "본청",
    school: "이사벨고등학교",
    address: "부산광역시 연제구 중앙대로 1197 (거제동, 이사벨중고등학교,지구촌고등학교)",
    phone: 515902116,
    fax: "051-590-2105",
    zipCode: 47506,
    homepage: "http://www.isabelle.hs.kr",
  },
  {
    id: 522,
    type: "사립",
    establush: "본청",
    school: "부일외국어고등학교",
    address: "부산광역시 사하구 감천로73번길 36 , 부일외국어고등학교 (감천동)",
    phone: 512087407,
    fax: "051-207-1855",
    zipCode: 49362,
    homepage: "http://www.puilschool.hs.kr",
  },
  {
    id: 523,
    type: "사립",
    establush: "본청",
    school: "부일전자디자인고등학교",
    address: "부산광역시 사하구 감천로73번길 36 (감천동) 부일전자디자인고등학교",
    phone: 512087403,
    fax: "051-293-5773",
    zipCode: 49362,
    homepage: "http://www.buil.hs.kr",
  },
  {
    id: 524,
    type: "사립",
    establush: "본청",
    school: "브니엘고등학교",
    address: "부산광역시 금정구 체육공원로 160 (구서동,브니엘고등학교)",
    phone: 515826611,
    fax: "051-582-6615",
    zipCode: 46251,
    homepage: "http://www.peniel.hs.kr",
  },
  {
    id: 525,
    type: "사립",
    establush: "본청",
    school: "브니엘여자고등학교",
    address: "부산광역시 금정구 체육공원로 154 , 브니엘여자고등학교 (구서동)",
    phone: 515829181,
    fax: "051-582-9183",
    zipCode: 46251,
    homepage: "http://ipeniel.hs.kr/",
  },
  {
    id: 526,
    type: "사립",
    establush: "본청",
    school: "브니엘예술고등학교",
    address: "부산광역시 금정구 체육공원로 154 , 브니엘예술고등학교 (구서동)",
    phone: 515139710,
    fax: "051-582-9179",
    zipCode: 46251,
    homepage: "http://penielart.or.kr",
  },
  {
    id: 527,
    type: "사립",
    establush: "본청",
    school: "사직여자고등학교",
    address: "부산광역시 동래구 쇠미로27번길 21 , 사직여자고등학교 (사직동)",
    phone: 517923806,
    fax: "051-792-3808",
    zipCode: 47873,
    homepage: "http://www.sajik-g.hs.kr/",
  },
  {
    id: 528,
    type: "사립",
    establush: "본청",
    school: "대양고등학교",
    address: "부산광역시 남구 지게골로 128-27 , 대양전자통신고등학교 (문현동)",
    phone: 516400515,
    fax: "051-640-0640",
    zipCode: 48464,
    homepage: "http://www.daeyang.hs.kr",
  },
  {
    id: 529,
    type: "사립",
    establush: "본청",
    school: "대명여자고등학교",
    address: "부산광역시 동래구 시실로 135 (명장동, 대명여자고등학교)",
    phone: 515263451,
    fax: "051-527-2150",
    zipCode: 47758,
    homepage: "http://www.daemyung.hs.kr",
  },
  {
    id: 530,
    type: "국립",
    establush: "본청",
    school: "부산외국어고등학교",
    address: "부산 연제구 고분로 144",
    phone: "051-850-1454",
    fax: "051-867-5094",
    zipCode: 47583,
    homepage: "http://school.busanedu.net/pfl-h/main.do",
  },
  {
    id: 531,
    type: "사립",
    establush: "본청",
    school: "배정고등학교",
    address: "부산광역시 남구 장고개로85번길 14-16 (문현동,배정고등학교)",
    phone: 516324743,
    fax: "051-643-4343",
    zipCode: 48477,
    homepage: "http://baejeong.hs.kr/",
  },
  {
    id: 532,
    type: "사립",
    establush: "본청",
    school: "배정미래고등학교",
    address: "부산광역시 남구 장고개로85번길 14-40 (문현동,배정미래고등학교)",
    phone: 516352291,
    fax: "051-647-9411",
    zipCode: 48477,
    homepage: "https://school.busanedu.net/bb-h/main.do",
  },
  {
    id: 533,
    type: "사립",
    establush: "본청",
    school: "부산관광고등학교",
    address: "부산광역시 서구 천마로 52 (남부민동)",
    phone: 512483123,
    fax: "051-248-3103",
    zipCode: 49263,
    homepage: "http://www.bstour.hs.kr",
  },
  {
    id: 534,
    type: "사립",
    establush: "본청",
    school: "부산대저고등학교",
    address: "부산광역시 강서구 공항로811번가길 46 , 부산대저고등학교 (대저2동, 대저중고등학교)",
    phone: 519737701,
    fax: "051-973-7740",
    zipCode: 46720,
    homepage: "http://daejeo.hs.kr",
  },
  {
    id: 535,
    type: "사립",
    establush: "본청",
    school: "부산동고등학교",
    address: "부산광역시 부산진구 동성로 30 , 36 (전포동)",
    phone: 518020624,
    fax: "051-806-1765",
    zipCode: 47310,
    homepage: "http://www.donggo.hs.kr",
  },
  {
    id: 536,
    type: "사립",
    establush: "본청",
    school: "부산동성고등학교",
    address: "부산광역시 부산진구 동성로 16 , 부산동성고등학교 (전포동)",
    phone: 518024631,
    fax: "051-802-9039",
    zipCode: 47310,
    homepage: "http://psdsdongsung.hr.kr/",
  },
  {
    id: 537,
    type: "사립",
    establush: "본청",
    school: "부산디지털고등학교",
    address: "부산광역시 중구 망양로 356 (영주동)",
    phone: 514600300,
    fax: "051-460-0399",
    zipCode: 48918,
    homepage: "http://digital.hs.kr",
  },
  {
    id: 538,
    type: "사립",
    establush: "본청",
    school: "부산마케팅고등학교",
    address: "부산광역시 부산진구 봉수로 13 (전포동, 부산마케팅고등학교)",
    phone: 518031264,
    fax: "051-803-1263",
    zipCode: 47310,
    homepage: "http://www.marketing.hs.kr",
  },
  {
    id: 539,
    type: "사립",
    establush: "본청",
    school: "부산문화여자고등학교",
    address: "부산광역시 해운대구 해운대로469번길 50 (우동, 부산문화여자고등학교)",
    phone: 517462952,
    fax: "051-743-6918",
    zipCode: 48064,
    homepage: "http://bs-moonhwa.hs.kr/",
  },
  {
    id: 540,
    type: "사립",
    establush: "본청",
    school: "부산보건고등학교",
    address: "부산광역시 영도구 절영로 163 (영선동4가)",
    phone: 514189253,
    fax: "051-418-9264",
    zipCode: 49079,
    homepage: "http://busanhc.com",
  },
  {
    id: 541,
    type: "사립",
    establush: "본청",
    school: "세연고등학교",
    address: "부산광역시 남구 진남로 82-19 , 세연고등학교 (대연동)",
    phone: 516324251,
    fax: "051-634-4521",
    zipCode: 48450,
    homepage: "https://school.busanedu.net/bts-h/main.do",
  },
  {
    id: 542,
    type: "사립",
    establush: "본청",
    school: "부산여자상업고등학교",
    address: "부산광역시 수영구 금련로 43 (망미동,부산여자상업고등학교)",
    phone: 517976709,
    fax: "051-7976-801",
    zipCode: 48239,
    homepage: "http://psgch.hs.kr",
  },
  {
    id: 543,
    type: "사립",
    establush: "본청",
    school: "부산예술고등학교",
    address: "부산광역시 금정구 체육공원로 , 20 (부곡동)",
    phone: 515141230,
    fax: "051-516-5720",
    zipCode: 46252,
    homepage: "http://pusanarts.hs.kr",
  },
  {
    id: 544,
    type: "사립",
    establush: "본청",
    school: "부산센텀여자고등학교",
    address: "부산 해운대구 해운대로469번길 50-1",
    phone: "051-742-1909",
    fax: "051-743-6918",
    zipCode: 48046,
    homepage: "http://school.busanedu.net/busan-cg-h/main.do",
  },
  {
    id: 545,
    type: "사립",
    establush: "본청",
    school: "부산정보고등학교",
    address: "부산광역시 부산진구 화지로 24 (양정동, 부산정보고등학교)",
    phone: 518530871,
    fax: "051-853-0873",
    zipCode: 47119,
    homepage: "http://psinfo.hs.kr",
  },
  {
    id: 546,
    type: "사립",
    establush: "본청",
    school: "부산정보관광고등학교",
    address: "부산광역시 금정구 동현로 37 (부곡동, 부산정보관광고등학교)",
    phone: 515187923,
    fax: "051-517-2044",
    zipCode: 46305,
    homepage: "http://www.pusan-ith.hs.kr/",
  },
  {
    id: 547,
    type: "사립",
    establush: "본청",
    school: "부산진여자고등학교",
    address: "부산광역시 부산진구 양정로 48 (양정동, 부산진여자고등학교)",
    phone: 5115995789,
    fax: "051-852-0503",
    zipCode: 47227,
    homepage: "http://www.busanjin.hs.kr",
  },
  {
    id: 548,
    type: "사립",
    establush: "본청",
    school: "동천고등학교",
    address: "부산광역시 남구 못골번영로 105 , 동천고등학교 (대연동)",
    phone: 516281300,
    fax: "051-624-0519",
    zipCode: 48429,
    homepage: "http://dongcheon.hs.kr",
  },
  {
    id: 549,
    type: "사립",
    establush: "본청",
    school: "동주여자고등학교",
    address: "부산광역시 중구 광복중앙로 18 (광복동3가, 동주여자고등학교)",
    phone: 512403006,
    fax: "051-246-4097",
    zipCode: 48949,
    homepage: "http://www.dongju.hs.kr",
  },
  {
    id: 550,
    type: "사립",
    establush: "본청",
    school: "동인고등학교",
    address: "부산광역시 동래구 쇠미로31번길 34 (사직동, 동인고등학교)",
    phone: 515017603,
    fax: "051-501-0712",
    zipCode: 47873,
    homepage: "http://www.dongin.hs.kr",
  },
  {
    id: 551,
    type: "사립",
    establush: "본청",
    school: "계성여자고등학교",
    address: "부산광역시 연제구 해맞이로109번길 47 (거제동,계성여자상업고등학교)",
    phone: 515034894,
    fax: "051-506-1782",
    zipCode: 47531,
    homepage: "http://gyeseong-gc.hs.kr",
  },
  {
    id: 552,
    type: "사립",
    establush: "본청",
    school: "광명고등학교",
    address: "부산광역시 영도구 와치로 131 (동삼동)",
    phone: 7043317706,
    fax: "051-405-6289",
    zipCode: 49094,
    homepage: "http://www.km.hs.kr",
  },
  {
    id: 553,
    type: "사립",
    establush: "본청",
    school: "금성고등학교",
    address: "부산광역시 동구 증산로 83 , 금성고등학교 (좌천동)",
    phone: 516321131,
    fax: "051-647-9707",
    zipCode: 48765,
    homepage: "http://bgs.hs.kr",
  },
  {
    id: 554,
    type: "사립",
    establush: "본청",
    school: "금정전자고등학교",
    address: "부산광역시 금정구 서금로 75 (서동,금정전자공업고등학교)",
    phone: 515400105,
    fax: "051-540-0107",
    zipCode: 46312,
    homepage: "http://kumjong.hs.kr",
  },
  {
    id: 555,
    type: "사립",
    establush: "본청",
    school: "남성여자고등학교",
    address: "부산광역시 중구 샘길 14 (대청동1가)",
    phone: 514610512,
    fax: "051-461-0581",
    zipCode: 48926,
    homepage: "http://www.psnamsung.hs.kr",
  },
  {
    id: 556,
    type: "사립",
    establush: "본청",
    school: "대덕여자고등학교",
    address: "부산광역시 사상구 백양대로804번길 42-55 (덕포동)",
    phone: 513098810,
    fax: "051-301-4525",
    zipCode: 46938,
    homepage: "http://daeduk.hs.kr",
  },
  {
    id: 557,
    type: "사립",
    establush: "본청",
    school: "대동고등학교",
    address: "부산광역시 사하구 을숙도대로709번길 27 , 대동고등학교 (신평동, 대동중고등학교)",
    phone: 512921697,
    fax: "051-292-1698",
    zipCode: 49397,
    homepage: "http://www.ddhs.hs.kr",
  },
  {
    id: 558,
    type: "사립",
    establush: "본청",
    school: "삼성여자고등학교",
    address: "부산광역시 사하구 감천로43번길 45 (감천동, 삼성여자고등학교)",
    phone: 517925500,
    fax: "051-792-5500",
    zipCode: 49362,
    homepage: "http://www.samsung-g.hs.kr",
  },
  {
    id: 559,
    type: "사립",
    establush: "본청",
    school: "삼정고등학교",
    address: "부산광역시 북구 시랑로118번길 53 (구포동,부산삼정고등학교)",
    phone: 513619802,
    fax: "051-361-9809",
    zipCode: 46644,
    homepage: "http://www.sj.hs.kr",
  },
  {
    id: 560,
    type: "국립",
    establush: "본청",
    school: "부산해사고등학교",
    address: "부산광역시 영도구 해양로 425 (동삼동)",
    phone: 514102000,
    fax: "051-412-9261",
    zipCode: 49111,
    homepage: "http://www.maritime.hs.kr",
  },
  {
    id: 561,
    type: "공립",
    establush: "본청",
    school: "개금고등학교",
    address: "부산광역시 부산진구 백양대로208번길 71 , 개금고등학교 (개금동)",
    phone: 518905600,
    fax: "051-894-1716",
    zipCode: 47169,
    homepage: "http://kaekeum.hs.kr",
  },
  {
    id: 562,
    type: "공립",
    establush: "본청",
    school: "개성고등학교",
    address: "부산광역시 부산진구 당감서로 137 (당감동, 개성고등학교)",
    phone: 518976404,
    fax: "051-897-6532",
    zipCode: 47158,
    homepage: "http://www.gaesung.hs.kr",
  },
  {
    id: 563,
    type: "공립",
    establush: "본청",
    school: "경남고등학교",
    address: "부산광역시 서구 망양로111번길 65 (동대신동3가)",
    phone: 512505000,
    fax: "051-250-5099",
    zipCode: 49201,
    homepage: "http://kyungnam.hs.kr",
  },
  {
    id: 564,
    type: "공립",
    establush: "본청",
    school: "경남공업고등학교",
    address: "부산광역시 부산진구 전포대로175번길 22 (전포동, 경남공업고등학교)",
    phone: 516077477,
    fax: "051-804-6905",
    zipCode: 47297,
    homepage: "http://www.knt.hs.kr",
  },
  {
    id: 565,
    type: "공립",
    establush: "본청",
    school: "경남여자고등학교",
    address: "부산광역시 동구 수정중로 29 (수정동, 경남여자고등학교)",
    phone: 516000802,
    fax: "051-467-2959",
    zipCode: 48777,
    homepage: "http://www.gyeongnamgirls.hs.kr",
  },
  {
    id: 566,
    type: "공립",
    establush: "본청",
    school: "구덕고등학교",
    address: "부산광역시 사상구 학감대로 81 (학장동, 구덕고등학교)",
    phone: 513209205,
    fax: "051-312-0803",
    zipCode: 47053,
    homepage: "http://www.gudeok.hs.kr",
  },
  {
    id: 567,
    type: "공립",
    establush: "본청",
    school: "금곡고등학교",
    address: "부산광역시 북구 화명신도시로 236 (금곡동, 금곡고등학교)",
    phone: 513302103,
    fax: "051-711-2105",
    zipCode: 46520,
    homepage: "http://www.geumgok.hs.kr/",
  },
  {
    id: 568,
    type: "공립",
    establush: "본청",
    school: "금명여자고등학교",
    address: "부산광역시 북구 양달로 101 (화명동)",
    phone: 513308672,
    fax: "051-3308-666",
    zipCode: 46515,
    homepage: "http://www.gmg.hs.kr",
  },
  {
    id: 569,
    type: "공립",
    establush: "본청",
    school: "금정고등학교",
    address: "부산광역시 동래구 명장로63번길 60-14 , 금정고등학교 (명장동)",
    phone: 515273252,
    fax: "051-521-3113",
    zipCode: 47767,
    homepage: "http://www.keumjeong.hs.kr",
  },
  {
    id: 570,
    type: "공립",
    establush: "본청",
    school: "금정여자고등학교",
    address: "부산광역시 금정구 서부로76번길 21 (서동)",
    phone: 515297580,
    fax: "051-712-3917",
    zipCode: 46313,
    homepage: "http://www.kjung.hs.kr",
  },
  {
    id: 571,
    type: "공립",
    establush: "본청",
    school: "기장고등학교",
    address: "부산광역시 기장군 기장읍 차성서로 77 (기장읍,기장고등학교)",
    phone: 517508211,
    fax: "051-722-0603",
    zipCode: 46068,
    homepage: "http://www.Gijang.hs.kr",
  },
  {
    id: 572,
    type: "공립",
    establush: "본청",
    school: "낙동고등학교",
    address: "부산광역시 북구 의성로 47번길 23 (덕천동) 낙동고등학교",
    phone: 513662673,
    fax: "051-366-2675",
    zipCode: 46549,
    homepage: "http://www.nakdong.hs.kr",
  },
  {
    id: 573,
    type: "공립",
    establush: "본청",
    school: "남산고등학교",
    address: "부산광역시 금정구 남산로 66 (남산동, 남산고등학교)",
    phone: 515149303,
    fax: "051-517-5460",
    zipCode: 46222,
    homepage: "http://www.ns.hs.kr",
  },
  {
    id: 574,
    type: "공립",
    establush: "본청",
    school: "부산소프트웨어마이스터고등학교",
    address: "부산광역시 강서구 가락대로 1393 (봉림동,부산산업과학고등학교)",
    phone: 519712152,
    fax: "051-971-6325",
    zipCode: 46708,
    homepage: "http://www.pusan-ish.hs.kr",
  },
  {
    id: 575,
    type: "공립",
    establush: "본청",
    school: "부산서여자고등학교",
    address: "부산광역시 서구 보수대로 202-6 (동대신동2가, 부산서여자고등학교)",
    phone: 512481561,
    fax: "051-248-1566",
    zipCode: 49215,
    homepage: "http://www.seo-gh.hs.kr",
  },
  {
    id: 576,
    type: "공립",
    establush: "본청",
    school: "양운고등학교",
    address: "부산광역시 해운대구 양운로 176 (좌동,양운고등학교)",
    phone: 517020691,
    fax: "051-702-0694",
    zipCode: 48075,
    homepage: "http://yangwoon.hs.kr",
  },
  {
    id: 577,
    type: "공립",
    establush: "본청",
    school: "연제고등학교",
    address: "부산광역시 연제구 쌍미천로30번길 31 (연산동, 연제고등학교)",
    phone: 518644807,
    fax: "051-864-4808",
    zipCode: 47589,
    homepage: "http://yeonje.hs.kr/",
  },
  {
    id: 578,
    type: "공립",
    establush: "본청",
    school: "영도여자고등학교",
    address: "부산광역시 영도구 중리북로21번길 42 (동삼동, 영도여자고등학교)",
    phone: 514000206,
    fax: "051-403-8715",
    zipCode: 49118,
    homepage: "http://www.yeongdo.hs.kr",
  },
  {
    id: 579,
    type: "공립",
    establush: "본청",
    school: "정관고등학교",
    address: "부산광역시 기장군 정관읍 정관로 534 (정관읍)",
    phone: 517805800,
    fax: "051-780-5898",
    zipCode: 46016,
    homepage: "http://jeonggwan.hs.kr",
  },
  {
    id: 580,
    type: "공립",
    establush: "본청",
    school: "주례여자고등학교",
    address: "부산광역시 사상구 가야대로366번길 146 (주례동, 주례여자고등학교)",
    phone: 513103507,
    fax: "051-310-3699",
    zipCode: 47010,
    homepage: "http://jurye.hs.kr",
  },
  {
    id: 581,
    type: "공립",
    establush: "본청",
    school: "충렬고등학교",
    address: "부산광역시 동래구 명장로106번길 26 (명장동, 충렬고등학교)",
    phone: 515314701,
    fax: "051-531-4708",
    zipCode: 47776,
    homepage: "http://www.pscr.hs.kr",
  },
  {
    id: 582,
    type: "공립",
    establush: "본청",
    school: "한국조형예술고등학교",
    address: "부산광역시 남구 동명로169번길 38 (용호동)",
    phone: 516202600,
    fax: "051-620-2699",
    zipCode: 48581,
    homepage: "http://www.adk.hs.kr",
  },
  {
    id: 583,
    type: "공립",
    establush: "본청",
    school: "해강고등학교",
    address: "부산광역시 해운대구 해운대해변로 33 (우동, 해강고등학교)",
    phone: 517498800,
    fax: "051-749-8710",
    zipCode: 48089,
    homepage: "http://www.haegang.hs.kr",
  },
  {
    id: 584,
    type: "공립",
    establush: "본청",
    school: "해운대공업고등학교",
    address: "부산광역시 해운대구 해운대로469번길 96 (우동, 해운대공업고등학교)",
    phone: 517927700,
    fax: "051-741-7116",
    zipCode: 48064,
    homepage: "http://www.hudt.hs.kr",
  },
  {
    id: 585,
    type: "공립",
    establush: "본청",
    school: "화명고등학교",
    address: "부산광역시 북구 화명신도시로 169 , 화명고등학교 (화명동, 화명고등학교)",
    phone: 513305704,
    fax: "051-363-8854",
    zipCode: 46524,
    homepage: "http://www.hwamyeong.hs.kr/",
  },
  {
    id: 586,
    type: "사립",
    establush: "본청",
    school: "가야고등학교",
    address: "부산광역시 부산진구 엄광로 152 (가야동,가야고등학교)",
    phone: 518931881,
    fax: "051-892-1888",
    zipCode: 47341,
    homepage: "http://www.gaya.hs.kr",
  },
  {
    id: 587,
    type: "사립",
    establush: "본청",
    school: "건국고등학교",
    address: "부산광역시 사하구 승학로17번길 76-8 (하단동,건국중.고등학교)",
    phone: 512920061,
    fax: "051-292-0049",
    zipCode: 49315,
    homepage: "http://www.keonkuk.hs.kr",
  },
  {
    id: 588,
    type: "국립",
    establush: "본청",
    school: "부산대학교사범대학부설고등학교",
    address: "부산광역시 금정구 기찰로22번길 30 (부곡동,부산대학교사범대학부설고등학교)",
    phone: 515151345,
    fax: "051-515-1346",
    zipCode: 46239,
    homepage: "http://pusan-ah.hs.kr",
  },
  {
    id: 589,
    type: "국립",
    establush: "본청",
    school: "부산기계공업고등학교",
    address: "부산광역시 해운대구 해운대로 569 (우동)",
    phone: 517415111,
    fax: "051-741-5045",
    zipCode: 48088,
    homepage: "http://www.bmt.hs.kr",
  },
  {
    id: 590,
    type: "사립",
    establush: "본청",
    school: "성도고등학교",
    address: "부산광역시 북구 시랑로132번길 87 , 성도고등학교 (구포동)",
    phone: 513320601,
    fax: "051-332-9951",
    zipCode: 46643,
    homepage: "http://www.sungdo.hs.kr",
  },
  {
    id: 591,
    type: "사립",
    establush: "본청",
    school: "성모여자고등학교",
    address: "부산광역시 부산진구 양지로 29-1 (양정동,성모여자고등학교)",
    phone: 518502212,
    fax: "051-850-2299",
    zipCode: 47228,
    homepage: "http://sung-mo.hs.kr",
  },
  {
    id: 592,
    type: "사립",
    establush: "본청",
    school: "영산고등학교",
    address: "부산광역시 해운대구 반송순환로 142-1 , 영산고등학교 (반송동, 영산고등학교)",
    phone: 515409500,
    fax: "051-540-9517",
    zipCode: 48015,
    homepage: "http://sungsim-ms.hs.kr",
  },
  {
    id: 593,
    type: "사립",
    establush: "본청",
    school: "성일여자고등학교",
    address: "부산광역시 사하구 다대로 161 (신평동,성일여자고등학교)",
    phone: 512069092,
    fax: "051-206-9093",
    zipCode: 49393,
    homepage: "http://sungil-g.hs.kr",
  },
  {
    id: 594,
    type: "사립",
    establush: "본청",
    school: "성지고등학교",
    address: "부산광역시 남구 유엔로 39 (우암동,성지고등학교)",
    phone: 516077500,
    fax: "051-643-3454",
    zipCode: 48471,
    homepage: "http://www.bssj.hs.kr",
  },
  {
    id: 595,
    type: "사립",
    establush: "본청",
    school: "세정고등학교",
    address: "부산광역시 부산진구 양정로 88-12 , 세정상업고등학교 (양정동)",
    phone: 518601500,
    fax: "051-862-2366",
    zipCode: 47227,
    homepage: "http://www.sejeong.hs.kr",
  },
  {
    id: 596,
    type: "사립",
    establush: "본청",
    school: "양정고등학교",
    address: "부산광역시 부산진구 양정로 46 (양정동,양정고등학교)",
    phone: 518501500,
    fax: "051-980-2076",
    zipCode: 47227,
    homepage: "http://www.yangjung.hs.kr/",
  },
  {
    id: 597,
    type: "사립",
    establush: "본청",
    school: "예문여자고등학교",
    address: "부산광역시 남구 동명로92번길 76 (용호동, 예문여자고등학교)",
    phone: 516601506,
    fax: "051-625-4596",
    zipCode: 48570,
    homepage: "http://www.yeamoon.hs.kr",
  },
  {
    id: 598,
    type: "사립",
    establush: "본청",
    school: "용인고등학교",
    address: "부산광역시 동래구 시실로107번길 37 , 용인고등학교 (명장동)",
    phone: 515250029,
    fax: "051-524-1613",
    zipCode: 47757,
    homepage: "http://psyongin.hs.kr",
  },
  {
    id: 599,
    type: "공립",
    establush: "본청",
    school: "동래고등학교",
    address: "부산광역시 동래구 충렬대로285번길 22 (칠산동,동래고등학교)",
    phone: 515550151,
    fax: "051-553-9644",
    zipCode: 47812,
    homepage: "http://dongnae.hs.kr/",
  },
  {
    id: 600,
    type: "공립",
    establush: "본청",
    school: "동래원예고등학교",
    address: "부산광역시 동래구 금강로 43 (온천동, 동래원예고등학교)",
    phone: 515503700,
    fax: "051-555-4635",
    zipCode: 47707,
    homepage: "http://wonyea.hs.kr/",
  },
  {
    id: 601,
    type: "공립",
    establush: "본청",
    school: "만덕고등학교",
    address: "부산광역시 북구 함박봉로140번길 98-12 , 만덕고등학교 (만덕동)",
    phone: 513303500,
    fax: "051-330-3595",
    zipCode: 46616,
    homepage: "http://www.mandeok.hs.kr/",
  },
  {
    id: 602,
    type: "공립",
    establush: "본청",
    school: "명호고등학교",
    address: "부산광역시 강서구 명지오션시티2로 97 (명지동,명호고등학교)",
    phone: 512201300,
    fax: "051-271-2607",
    zipCode: 46765,
    homepage: "http://myeongho.hs.kr/",
  },
  {
    id: 603,
    type: "공립",
    establush: "본청",
    school: "문현여자고등학교",
    address: "부산광역시 남구 고동골로 86-41 , 문현여자고등학교 (문현동, 문현여자고등학교)",
    phone: 516301500,
    fax: "051-630-1601",
    zipCode: 48409,
    homepage: "http://www.munhyun.hs.kr",
  },
  {
    id: 604,
    type: "공립",
    establush: "본청",
    school: "반여고등학교",
    address: "부산광역시 해운대구 반여로 136 (반여동, 반여고등학교)",
    phone: 515201705,
    fax: "051-526-0372",
    zipCode: 48036,
    homepage: "http://banyeo.hs.kr",
  },
  {
    id: 605,
    type: "공립",
    establush: "본청",
    school: "내성고등학교",
    address: "부산광역시 금정구 서동로31번길 11 (부곡동, 내성고등학교)",
    phone: 515800204,
    fax: "051-516-3974",
    zipCode: 46308,
    homepage: "http://www.naesung.hs.kr",
  },
  {
    id: 606,
    type: "공립",
    establush: "본청",
    school: "다대고등학교",
    address: "부산광역시 사하구 다대낙조2길 199 (다대동, 다대고등학교)",
    phone: 512659804,
    fax: "051-266-2695",
    zipCode: 49503,
    homepage: "http://www.dadae.hs.kr",
  },
  {
    id: 607,
    type: "공립",
    establush: "본청",
    school: "덕문고등학교",
    address: "부산광역시 강서구 동선길 172 (성북동)",
    phone: 519796100,
    fax: "051-972-9848",
    zipCode: 46769,
    homepage: "http://www.duckmun.hs.kr",
  },
  {
    id: 608,
    type: "공립",
    establush: "본청",
    school: "부산백양고등학교",
    address: "부산광역시 북구 백양대로1016번길 64 (구포동,부산백양고등학교)",
    phone: "051-600-6500",
    fax: "051-600-6558",
    zipCode: 46637,
    homepage: "http://www.baekyang.hs.kr",
  },
  {
    id: 609,
    type: "공립",
    establush: "본청",
    school: "부산동여자고등학교",
    address: "부산광역시 수영구 남천서로32번길 39 (남천동,부산동여자고등학교)",
    phone: 516105700,
    fax: "051-610-5801",
    zipCode: 48315,
    homepage: "http://bdong-g.hs.kr/",
  },
  {
    id: 610,
    type: "공립",
    establush: "본청",
    school: "부산남일고등학교",
    address: "부산광역시 수영구 망미배산로 48 , 85-23 (망미동)",
    phone: 517500150,
    fax: "051-750-0113",
    zipCode: 48209,
    homepage: "http://bsnamil.hs.kr",
  },
  {
    id: 611,
    type: "공립",
    establush: "본청",
    school: "부산남고등학교",
    address: "부산광역시 영도구 중리북로 5 (동삼동,부산남고등학교)",
    phone: 514037001,
    fax: "051-403-9324",
    zipCode: 49118,
    homepage: "http://www.busan-nam.hs.kr",
  },
  {
    id: 612,
    type: "공립",
    establush: "본청",
    school: "부산국제고등학교",
    address: "부산광역시 부산진구 백양관문로 105-70 , 부산국제고등학교 (당감동)",
    phone: 518908406,
    fax: "051-892-1352",
    zipCode: 47162,
    homepage: "http://www.gukje.hs.kr",
  },
  {
    id: 613,
    type: "공립",
    establush: "본청",
    school: "부산과학고등학교",
    address: "부산광역시 금정구 금샘로 455-1 (구서동, 부산과학고등학교)",
    phone: 515808500,
    fax: "051-580-8519",
    zipCode: 46235,
    homepage: "http://bss.hs.kr",
  },
  {
    id: 614,
    type: "공립",
    establush: "본청",
    school: "부산공업고등학교",
    address: "부산광역시 남구 수영로196번길 80 (대연동,부산공업고등학교)",
    phone: 516073800,
    fax: "051-623-3902",
    zipCode: 48472,
    homepage: "http://pusan-th.hs.kr",
  },
  {
    id: 615,
    type: "공립",
    establush: "본청",
    school: "부산고등학교",
    address: "부산광역시 동구 초량로40번길 23 , 부산고등학교 (초량동, 부산고등학교)",
    phone: 516000700,
    fax: "051-466-2344",
    zipCode: 48802,
    homepage: "http://busan.hs.kr",
  },
  {
    id: 616,
    type: "공립",
    establush: "본청",
    school: "부산강서고등학교",
    address: "부산광역시 강서구 공항로1309번길 96-7 , 부산강서고등학교 (대저1동, 강서고등학교)",
    phone: 519749800,
    fax: "051-971-4068",
    zipCode: 46702,
    homepage: "http://www.pusangangseo.hs.kr",
  },
  {
    id: 617,
    type: "공립",
    establush: "본청",
    school: "부경고등학교",
    address: "부산광역시 서구 망양로33번길 12 (서대신동3가, 부경고등학교)",
    phone: 516008530,
    fax: "051-600-8599",
    zipCode: 49210,
    homepage: "http://www.saja.hs.kr/",
  },
  {
    id: 618,
    type: "평생",
    establush: "본청",
    school: "학력인정국제금융고등학교",
    address: "부산광역시 사하구 하신중앙로3번길 34(장림동, 학력인정국제금융고등학교)",
    phone: "051-261-8141",
    fax: "051-261-4589",
    zipCode: 49479,
    homepage: "http://school.busanedu.net/2f-h/",
  },
  {
    id: 619,
    type: "평생",
    establush: "본청",
    school: "학력인정부경보건고등학교(병설중 포함)",
    address: "부산광역시 사하구 두송로 53(장림동, 학력인정부경보건고등학교)",
    phone: "051-261-4611",
    fax: "051-264-5514",
    zipCode: 49458,
    homepage: "http://school.busanedu.net/bk-h",
  },
  {
    id: 620,
    type: "평생",
    establush: "본청",
    school: "학력인정부산경호고등학교",
    address: "부산광역시 금정구 서금로 91-5(금사동, 학력인정부산경호고등학교)",
    phone: "051-523-0209",
    fax: "051-526-8871",
    zipCode: 46262,
    homepage: "http://school.busanedu.net/bsgh-h",
  },
  {
    id: 621,
    type: "평생",
    establush: "본청",
    school: "학력인정부산미용고등학교(병설중 포함)",
    address: "부산광역시 부산진구 백양순환로119번길 62(부암동, 학력인정부산미용고등학교)",
    phone: "051-805-6132",
    fax: "051-816-9423",
    zipCode: 47137,
    homepage: "http://school.busanedu.net/bba-h",
  },
  {
    id: 622,
    type: "평생",
    establush: "본청",
    school: "학력인정부산예원고등학교(병설중 포함)",
    address: "부산광역시 금정구 회천로 13번길 60(회동동, 학력인정부산예원고등학교)",
    phone: "051-529-4441",
    fax: "051-526-2043",
    zipCode: 46260,
    homepage: "http://school.busanedu.net/yewon-h",
  },
  {
    id: 623,
    type: "평생",
    establush: "본청",
    school: "학력인정부산조리고등학교",
    address: "부산광역시 사상구 낙동대로1390번길 65(삼락동, 학력인정부산조리고등학교)",
    phone: "051-303-2008",
    fax: "051-305-2004",
    zipCode: 46906,
    homepage: "https://school.busanedu.net/busanjori-h",
  },
  {
    id: 624,
    school: "일반",
  },
];
