import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as msg from "../../../lib/utils/msg";

// 업로드 중임을 알릴 Alert 컴포넌트
const UploadAlert = () => {
  // reducers
  // loading reducer의 alert state
  const alertClick = useSelector((state) => state.loadingReducer).alert;

  // alertClick이 true일 경우에 업로드 중이라는 Alert Display
  return <>{alertClick && <Alert children={msg.infoMsg.uploading()} />}</>;
};

export default UploadAlert;
