/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyPageGuidance from "../../Component/Mypage/MyPageGuidance";
import SelectOffice from "../../Component/Select/SelectOffice";
import { selOptionClear } from "../../redux/selOption/actions";
import CompleteAlert from "../../Component/Mypage/CompleteAlert";
import SelectTextForm from "../../Component/Select/SelectTextForm";
import useRedirect from "../../hooks/useRedirect";
// import UpdateButton from "./Mypage/UpdateButton";

import * as S from "components/templates/common-container/CommonContainer.styled";
import BasicLayout from "components/templates/basic-layout";

const MyPage = (props) => {
  // props
  // App.js 에서 페이지 타입을 설정한 props
  const pageType = props.pageType;

  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // firebase 로그인 정보 reducer
  const currentUser = useSelector((state) => state.currentUserReducer).accountInfo;
  // 사용자 정보 reducer
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo;

  useRedirect(pageType === "student" ? "learningcenter" : "teachingcenter", "home");

  // 마운트 시 최초 한 번만 실행
  useEffect(() => {
    dispatch(selOptionClear("MyPage", { currentUser, myInfo }));
  }, []);

  // 현재 계정 정보
  // const nowAccount = () => {
  //   // 서비스 제공 업체 정보
  //   const nowInfo = { ...currentUser }.providerData[0];

  //   // 구글이 있을 경우 true
  //   const isGoogle = nowInfo.providerId.indexOf("google.com") !== -1;

  //   // 현재 계정 이메일
  //   const nowEmail = nowInfo.email;

  //   // growing.com으로 되어 있으면 true
  //   const isGrowing = nowEmail.indexOf("growing.com") !== -1;

  //   // 이메일 내 아이디
  //   const nowID = nowEmail.substring(0, nowEmail.indexOf("@"));

  //   // return object
  //   return { isGoogle, isGrowing, nowID };
  // };

  // 계정 정보 변경 (growing.com으로 모든 계정을 변경하기 위함)
  // const changeAccount = () => {
  //   // 새로 변경할 이메일 (id만 두고 growing.com으로 변경)
  //   const newEmail = `${nowAccount().nowID}@growing.com`;
  //   // 변경할 이메일과 비밀번호
  //   const credential = EmailAuthProvider.credential(newEmail, "123456");
  //   // 현재 DB에 등록되어 있는 유저 정보
  //   const userRef = doc(db, "Users", myInfo.uid);

  //   // DB 이메일 변경
  //   const setEmail = async () =>
  //     await updateDoc(userRef, {
  //       email: newEmail,
  //     });

  //   // 비밀번호 123456으로 변경
  //   const updPW = async () =>
  //     await updatePassword(currentUser, "123456")
  //       .then(() => {
  //         alert(
  //           `변경 완료!\n새로운 이메일은 ${newEmail}입니다.\n새로운 비밀번호는 "123456"입니다.\n기존 구글 계정으로는 로그인 할 수 없습니다!`
  //         );
  //       })
  //       .catch((error) => {
  //         alert(error.message);
  //       });

  //   // 이메일 생성 및 구글 연결 해제
  //   const linkEmail = async () =>
  //     // 이메일 생성
  //     await linkWithCredential(currentUser, credential)
  //       .then(async () => {
  //         // 구글 연결 해제
  //         await unlink(currentUser, "google.com")
  //           .then(() => {
  //             // DB 이메일 변경
  //             setEmail();
  //             // 비밀번호 변경
  //             updPW();
  //           })
  //           .catch((error) => {
  //             alert(error.message);
  //           });
  //       })
  //       .catch((error) => {
  //         alert(error.message);
  //       });

  //   // 이메일 변경
  //   const changeEmail = async () =>
  //     // 계정의 이메일 업데이트
  //     await updateEmail(currentUser, newEmail)
  //       .then(() => {
  //         // DB 이메일 변경
  //         setEmail();
  //         // 비밀번호 변경
  //         updPW();
  //       })
  //       .catch((error) => {
  //         alert(error.message);
  //       });

  //   // 함수 실행 부분
  //   dispatch(loadingComponent(true));
  //   if (nowAccount().isGoogle) {
  //     // 현재 구글 로그인 방식일 경우 실행하는 함수
  //     linkEmail();
  //   } else if (!nowAccount().isGrowing) {
  //     // 현재 이메일 로그인 방식인 경우, growing.com이 아닌 경우 실행
  //     changeEmail();
  //   }
  //   setTimeout(() => dispatch(loadingComponent(false)), 2000);
  // };

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Card className="card-light my-5 mx-3">
            <Card.Body className="mx-3 my-5">
              <MyPageGuidance />
              <Card.Body className="m-5">
                <SelectTextForm title="이름" objectKey="name" placeholder="이름" disabled required />
                <SelectOffice required title="지점" disabled />
                <SelectTextForm title="전화번호" objectKey="phone" placeholder="01000000000" disabled required />
              </Card.Body>
              {/* {(nowAccount().isGoogle || !nowAccount().isGrowing) && (
            <Button onClick={changeAccount} disabled={loading}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                "계정 정보 변경 (이 버튼이 보이면 눌러주세요.)"
              )}
            </Button>
          )} */}
            </Card.Body>
          </Card>
          <CompleteAlert />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default MyPage;
