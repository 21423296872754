import { Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RequiredText } from "../../../Methods";

const DisplayStartEnd = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);

  return (
    <>
      <InputGroup>
        <Form.Control
          type="text"
          value={selOption.start && selOption.start.toLocaleDateString()}
          style={{ textAlign: "center" }}
          readOnly
        />
        <InputGroup.Text>~</InputGroup.Text>
        <Form.Control
          type="text"
          value={selOption.end && selOption.end.toLocaleDateString()}
          style={{ textAlign: "center" }}
          readOnly
        />
      </InputGroup>
      {!selOption.start && <RequiredText />}
    </>
  );
};
export default DisplayStartEnd;
