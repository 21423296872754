export default function gradeCalc(age) {
  switch (true) {
    case age > 0 && age <= 7:
      return "유치부";
    case age === 8:
      return "초1";
    case age === 9:
      return "초2";
    case age === 10:
      return "초3";
    case age === 11:
      return "초4";
    case age === 12:
      return "초5";
    case age === 13:
      return "초6";
    case age === 14:
      return "중1";
    case age === 15:
      return "중2";
    case age === 16:
      return "중3";
    case age === 17:
      return "고1";
    case age === 18:
      return "고2";
    case age === 19:
      return "고3";
    case age >= 20:
      return "성인";
    default:
      return "유효한 나이를 입력해주세요";
  }
}
