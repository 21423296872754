import db from "../../../firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  inputDataClear,
  inputDataQuestionModifySet,
} from "../../../redux/InputData/actions";
import { loadingComponent, loadingFalse } from "../../../redux/Loading/actions";
import { modalShowPopup } from "../../../redux/Modal_Show/actions";
import { setPopupModal } from "../../../redux/PopupModal/reducer";

const ControlButton = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // 업로드할 데이터를 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);

  const regQid = /^[A-Z]{2}[0-9]{4}$/;

  const loadClearData = () =>
    inputData.length === 0
      ? dispatch(inputDataQuestionModifySet(selOption.title))
      : dispatch(inputDataClear());

  // // 버튼 비활성화 조건, inputData 내 수정할 데이터 값이 하나도 없을 시 비활성화
  // const modifyNull = inputData.filter((row) => row.modify).length === 0;

  // // 버튼 비활성화 조건, inputData 내 null 값이 하나라도 존재 시 비활성화
  // const isNull = inputData
  //   .map((row) => (row.date === null || row.rank === null ? true : false))
  //   .some((e) => e === true);

  // 데이터 수정 함수, row.modify만 true일 때
  const modifyData = async (row) => {
    await updateDoc(doc(db, "Question", row.id), {
      answerArray: row.answerArray,
      answer: row.answer,
      label: row.label,
      num1: row.num1,
      num2: row.num2,
      num3: row.num3,
      num4: row.num4,
      num5: row.num5,
      numAnswer: row.numAnswer,
      question: row.question,
      type: row.type,
    });
  };

  // 데이터 삭제 함수, row.delete와 row.modify가 모두 true일 때
  const deleteData = async (row) => {
    await deleteDoc(doc(db, "Question", row.id));
  };

  // modify/delete 조건에 맞는 업로드 함수
  const dataUpload = () => {
    dispatch(loadingComponent(true));
    inputData
      // .filter((row) => row.modify)
      .forEach((res) => {
        switch (true) {
          case res.delete:
            deleteData(res);
            break;
          default:
            modifyData(res);
            break;
        }
      });
    setTimeout(() => {
      dispatch(loadingFalse());
      dispatch(modalShowPopup(false));
    }, 2000);
  };

  // 팝업 띄우기
  const openPopup = () => {
    dispatch(modalShowPopup(true));
    dispatch(setPopupModal("Upload?", "수정하시겠습니까?", dataUpload, "수정"));
  };

  return (
    <Row className="g-2 mx-2 mb-3">
      <Col md>
        <ButtonGroup>
          <Button
            onClick={loadClearData}
            disabled={!regQid.test(selOption.title)}
          >
            {inputData.length === 0 ? "데이터 불러오기" : "초기화"}
          </Button>
          <Button
            disabled={inputData.length === 0 || inputData[0].error}
            variant="warning"
            onClick={openPopup}
          >
            수정 완료
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default ControlButton;
